/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import DateFnsUtils from '@date-io/date-fns';
import { IconButton, InputAdornment } from '@material-ui/core';
import {
    Clear as ClearIcon,
    InsertInvitation as CalendarIcon,
} from '@material-ui/icons';
import { InlineDatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import {
    getRequiredFieldLabel,
    isArrayValidAndNotEmpty,
    isValidDate,
    isValidFunction,
} from '../../../constants/CommonUtil';
import { applicationDateFormat, applicationDateInputMask } from '../../../constants/constants';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const styles = () => ({
    input: {
        paddingTop: '0.75em',
        paddingBottom: '0.75em',
    },
});

class DateInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pickerRef: React.createRef(),
        };
    }
    onBlur = (e) => {
        const {
            onBlur,
            input,
        } = this.props;
        let onBlurHandler = null;
        if (isValidFunction(onBlur)) {
            onBlurHandler = onBlur;
        } else if (isObjectValidAndNotEmpty(input) && isValidFunction(input.onBlur)) {
            onBlurHandler = input.onBlur;
        }
        if (isValidFunction(onBlurHandler)) {
            onBlurHandler(e);
        }
    };
    onError = (_, error) => {
        const {
            form,
            field,
        } = this.props;
        if (isObjectValidAndNotEmpty(form)) {
            form.setFieldError(field.name, error);
        }
    };

    onSelectHandler = (value) => {
        const {
            meta,
            onChangeHandlers,
            actionHandlers,
        } = this.props;
        if (isArrayValidAndNotEmpty(onChangeHandlers) &&
            isObjectValidAndNotEmpty(actionHandlers)) {
            onChangeHandlers.forEach((action) => {
                const handler = actionHandlers[action];
                if (isValidFunction(handler)) {
                    handler(value, getStringFromObject('form', meta));
                }
            });
        }
    };

    handleDateSelect = (date) => {
        const {
            onChange,
            input,
            field,
            form,
        } = this.props;
        let onChangeHandler = null;
        if (isValidFunction(onChange)) {
            onChangeHandler = onChange;
        } else if (isObjectValidAndNotEmpty(input) && isValidFunction(input.onChange)) {
            onChangeHandler = input.onChange;
        } else if (isObjectValidAndNotEmpty(form) && isValidFunction(form.setFieldValue)) {
            form.setFieldValue(field.name, date, true);
            return;
        }
        if (isValidFunction(onChangeHandler)) {
            if (isValidDate(date)) {
                onChangeHandler(date.getTime());
                this.onSelectHandler(date.getTime());
            } else {
                onChangeHandler(null);
                this.onSelectHandler(null);
            }
        }
    };

    openPicker = (e) => {
        const { pickerRef } = this.state;
        if (pickerRef.current) {
            pickerRef.current.open(e);
        }
    };

    render() {
        const {
            value,
            input,
            field,
            label,
            minDate,
            maxDate,
            classes,
            required,
            disabled,
            InputProps,
            testId,
            fullWidth,
            showClearButton,
        } = this.props;
        const { pickerRef } = this.state;
        const touched = getStringFromObject('meta.touched', this.props);
        const error = getStringFromObject('meta.error', this.props);
        let dateValue = null;
        if (value) {
            dateValue = value;
        } else if (isObjectValidAndNotEmpty(input)) {
            dateValue = input.value;
        } else if (isObjectValidAndNotEmpty(field)) {
            dateValue = field.value;
        }

        let labelComponent = label;
        if (required) {
            labelComponent = getRequiredFieldLabel(label);
        }
        console.log('asda-ias-dadhfjka', testId, minDate, maxDate, dateValue);
        let dateConstraints = {};
        if (minDate) {
            dateConstraints = { minDate };
        }
        if (maxDate) {
            dateConstraints = { ...dateConstraints, maxDate };
        }
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <InlineDatePicker
                    id={testId}
                    keyboard
                    clearable
                    showTodayButton
                    variant="outlined"
                    fullWidth={fullWidth}
                    label={labelComponent}
                    value={dateValue ? new Date(dateValue) : null}
                    onChange={this.handleDateSelect}
                    onBlur={this.onBlur}
                    onError={this.onError}
                    error={!!(touched && error)}
                    helperText={touched && error}
                    format={applicationDateFormat}
                    {...dateConstraints}
                    mask={applicationDateInputMask}
                    InputProps={{
                        classes: {
                            input: classes.input,
                        },
                        endAdornment: (
                            <InputAdornment position="end">
                                {
                                    dateValue && showClearButton &&
                                        <IconButton onClick={() => { this.handleDateSelect(null); }}>
                                            <ClearIcon />
                                        </IconButton>
                                }
                                <IconButton onClick={this.openPicker}>
                                    <CalendarIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                        ...InputProps,
                        'test-id': testId,
                    }}
                    disabled={disabled}
                    InputLabelProps={{ shrink: true }}
                    ref={pickerRef}
                />
            </MuiPickersUtilsProvider>
        );
    }
}

DateInput.propTypes = {
    classes: PropTypes.object.isRequired,
    testId: PropTypes.string,
    maxDate: PropTypes.object,
    minDate: PropTypes.object,
    value: PropTypes.number,
    label: PropTypes.number,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    input: PropTypes.object,
    meta: PropTypes.object,
    field: PropTypes.object,
    form: PropTypes.object,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    onChangeHandlers: PropTypes.array,
    actionHandlers: PropTypes.object,
    InputProps: PropTypes.object,
    fullWidth: PropTypes.bool,
    showClearButton: PropTypes.bool,
};

DateInput.defaultProps = {
    maxDate: null,
    testId: '',
    minDate: null,
    value: null,
    label: null,
    onChange: null,
    onBlur: null,
    input: null,
    field: null,
    form: null,
    required: false,
    disabled: false,
    meta: {},
    onChangeHandlers: [],
    actionHandlers: {},
    InputProps: {},
    fullWidth: false,
    showClearButton: false,
};

export default withStyles(styles)(DateInput);

