import API from '../../../constants/api';

export const DOWNLOAD_PACKAGE = '@@acceptPackage/DOWNLOAD_PACKAGE';
export const DOWNLOAD_PACKAGE_SUCCESS = '@@acceptPackage/DOWNLOAD_PACKAGE_SUCCESS';

export const ACCEPT_PACKAGE_REQUEST = '@@acceptPackage/ACCEPT_PACKAGE_REQUEST';
export const ACCEPT_PACKAGE_SUCCESS = '@@acceptPackage/ACCEPT_PACKAGE_SUCCESS';
export const ACCEPT_PACKAGE_FAILURE = '@@acceptPackage/ACCEPT_PACKAGE_FAILURE';

export const ACCEPT_ALL = '@@acceptPackage/ACCEPT_ALL';

export const FETCH_PACKAGES = '@@acceptPackage/FETCH_PACKAGES';
export const FETCH_PACKAGES_SUCCESS = '@@acceptPackage/FETCH_ACCOUNT_VOUCHER_SUCCESS';
export const FETCH_PACKAGES_FAILURE = '@@acceptPackage/FETCH_ACCOUNT_VOUCHER_FAILURE';

export const FETCH_PACKAGE_DETAILS_REQUEST = '@@acceptPackage/FETCH_PACKAGE_DETAIL_REQUEST';
export const FETCH_PACKAGE_DETAILS_SUCCESS = '@@acceptPackage/FETCH_PACKAGE_DETAIL_SUCCESS';
export const FETCH_PACKAGE_DETAILS_FAILURE = '@@acceptPackage/FETCH_PACKAGE_DETAIL_FAILURE';

export const FETCH_MATERIAL_RECEIVE_RSD_REQUEST = '@@acceptPackage/FETCH_MATERIAL_RECEIVE_RSD_REQUEST';
export const FETCH_MATERIAL_RECEIVE_RSD_SUCCESS = '@@acceptPackage/FETCH_MATERIAL_RECEIVE_RSD_SUCCESS';
export const FETCH_MATERIAL_RECEIVE_RSD_FAILURE = '@@acceptPackage/FETCH_MATERIAL_RECEIVE_RSD_FAILURE';

export const IGNORE_SELECTED_PACKAGES_REQUEST = '@@acceptPackage/IGNORE_SELECTED_PACKAGE_REQUEST';
export const IGNORE_SELECTED_PACKAGES_SUCCESS = '@@acceptPackage/IGNORE_SELECTED_PACKAGE_SUCCESS';
export const IGNORE_SELECTED_PACKAGES_FAILURE = '@@acceptPackage/IGNORE_SELECTED_PACKAGE_FAILURE';

export const downloadPackage = payload => ({
    type: DOWNLOAD_PACKAGE,
    api: API.ACCEPT_PACKAGES.DOWNLOAD_PACKAGE,
    payload,
});

export const fetchAcceptPackages = (page, size, searchConstruct) => ({
    type: FETCH_PACKAGES,
    api: API.ACCEPT_PACKAGES.LIST,
    page,
    size,
    searchConstruct,
});

export const fetchPackageDetailsById = (notificationId, page, size, filters) => ({
    type: FETCH_PACKAGE_DETAILS_REQUEST,
    api: API.ACCEPT_PACKAGES.GET_PACKAGE_BY_ID,
    notificationId,
    page,
    size,
    filters,
});

export const fetchMaterialReceive = (api, successCallback, failureCallback) => ({
    type: FETCH_MATERIAL_RECEIVE_RSD_REQUEST,
    api,
    successCallback,
    failureCallback,
});

export const acceptPackage = (list, successCallback, failureCallback) => ({
    type: ACCEPT_PACKAGE_REQUEST,
    api: API.ACCEPT_PACKAGES.ACCEPT_PACKAGE,
    list,
    successCallback,
    failureCallback,
});

export const acceptAll = notificationId => ({
    type: ACCEPT_ALL,
    api: API.ACCEPT_PACKAGES.ACCEPT_ALL,
    notificationId,
});

export const ignoreSelectedPackages = (list, successCallback, failureCallback) => ({
    type: IGNORE_SELECTED_PACKAGES_REQUEST,
    api: API.ACCEPT_PACKAGES.IGNORE,
    list,
    successCallback,
    failureCallback,
});
