/**
 * Created by vinay on 19/12/18.
 */
import API from '../../../constants/api';

export const FETCH_APPOINTMENT_SLOT_REQUEST = '@@appointment/FETCH_APPOINTMENT_SLOT_REQUEST';
export const FETCH_APPOINTMENT_SLOT_FAILURE = '@@appointment/FETCH_APPOINTMENT_SLOT_FAILURE';
export const FETCH_APPOINTMENT_SLOT_SUCCESS = '@@appointment/FETCH_APPOINTMENT_SLOT_SUCCESS';

export const CREATE_APPOINTMENT_REQUEST = '@@appointment/CREATE_APPOINTMENT_REQUEST';
export const CREATE_APPOINTMENT_FAILURE = '@@appointment/CREATE_APPOINTMENT_FAILURE';
export const CREATE_APPOINTMENT_SUCCESS = '@@appointment/CREATE_APPOINTMENT_SUCCESS';

export const CANCEL_APPOINTMENT_REQUEST = '@@appointment/CANCEL_APPOINTMENT_REQUEST';
export const CANCEL_APPOINTMENT_FAILURE = '@@appointment/CANCEL_APPOINTMENT_FAILURE';
export const CANCEL_APPOINTMENT_SUCCESS = '@@appointment/CANCEL_APPOINTMENT_SUCCESS';

export const CONFIRM_APPOINTMENT_REQUEST = '@@appointment/CONFIRM_APPOINTMENT_REQUEST';
export const CONFIRM_APPOINTMENT_FAILURE = '@@appointment/CONFIRM_APPOINTMENT_FAILURE';
export const CONFIRM_APPOINTMENT_SUCCESS = '@@appointment/CONFIRM_APPOINTMENT_SUCCESS';

export const CLEAR_APPOINTMENT_SLOT_STATE = '@@appointment/CLEAR_APPOINTMENT_SLOT_STATE';

export const fetchAppointmentSlot = payload => ({
    type: FETCH_APPOINTMENT_SLOT_REQUEST,
    api: API.APPOINTMENT.APPOINTMENT_SLOTS,
    payload,
});

export const clearAppointmentSlotState = () => ({
    type: CLEAR_APPOINTMENT_SLOT_STATE,
});

export const createAppointmentRequest = (payload, onSuccessCallback, onFailureCallback) => ({
    type: CREATE_APPOINTMENT_REQUEST,
    api: API.APPOINTMENT.APPOINTMENT_BASE,
    payload,
    onSuccessCallback,
    onFailureCallback,
});

export const cancelAppointmentRequest = (appointmentUuid, onSuccessCallback, onFailureCallback) => ({
    type: CANCEL_APPOINTMENT_REQUEST,
    api: `${API.APPOINTMENT.APPOINTMENT_BASE}/${appointmentUuid}`,
    onSuccessCallback,
    onFailureCallback,
});

export const confirmAppointmentRequest = (appointmentUuid, onSuccessCallback, onFailureCallback) => ({
    type: CONFIRM_APPOINTMENT_REQUEST,
    api: `${API.APPOINTMENT.APPOINTMENT_BASE}/confirm/${appointmentUuid}`,
    onSuccessCallback,
    onFailureCallback,
});
