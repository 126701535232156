/**
 * Created by vinay on 19/12/18.
 */
export const ADVANCE_SEARCH_REQUEST = '@@advanceSearch/ADVANCE_SEARCH_REQUEST';
export const ADVANCE_SEARCH_FAILURE = '@@advanceSearch/ADVANCE_SEARCH_FAILURE';
export const ADVANCE_SEARCH_SUCCESS = '@@advanceSearch/ADVANCE_SEARCH_SUCCESS';

export const advanceSearchRequest = (api, jsonApi, tableJson, allCategories,
    category, page, size, filters, dateFilters = {}, apiParams = {}) => {
    console.log('searccch', filters);
    return {
        type: ADVANCE_SEARCH_REQUEST,
        category,
        api,
        tableJson,
        page,
        size,
        filters,
        dateFilters,
        jsonApi,
        allCategories,
        apiParams,
    };
};
