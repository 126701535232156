import API from '../../../constants/api';

export const FETCH_RECENT_PATIENTS_REQUEST = '@@patients/FETCH_RECENT_PATIENTS_REQUEST';
export const FETCH_RECENT_PATIENTS_SUCCESS = '@@patients/FETCH_RECENT_PATIENTS_SUCCESS';
export const FETCH_RECENT_PATIENTS_FAILURE = '@@patients/FETCH_RECENT_PATIENTS_FAILURE';

export const FETCH_ACTIVE_PATIENTS_REQUEST = '@@patients/FETCH_ACTIVE_PATIENTS_REQUEST';
export const FETCH_ACTIVE_PATIENTS_SUCCESS = '@@patients/FETCH_ACTIVE_PATIENTS_SUCCESS';
export const FETCH_ACTIVE_PATIENTS_FAILURE = '@@patients/FETCH_ACTIVE_PATIENTS_FAILURE';

export const FETCH_ALL_PATIENTS_REQUEST = '@@patients/FETCH_ALL_PATIENTS_REQUEST';
export const FETCH_ALL_PATIENTS_SUCCESS = '@@patients/FETCH_ALL_PATIENTS_SUCCESS';
export const FETCH_ALL_PATIENTS_FAILURE = '@@patients/FETCH_ALL_PATIENTS_FAILURE';

export const fetchRecentPatientList = (page, size, sortBy, sortDir, searchText) => ({
    api: API.PATIENTS.RECENT_PATIENTS,
    type: FETCH_RECENT_PATIENTS_REQUEST,
    page,
    size,
    sortBy,
    sortDir,
    searchText,
});

export const fetchActivePatientList = (page, size, sortBy, sortDir, filters) => ({
    api: API.PATIENTS.ACTIVE_PATIENTS,
    type: FETCH_ACTIVE_PATIENTS_REQUEST,
    page,
    size,
    sortBy,
    sortDir,
    filters,
});


export const fetchAllPatientList = (page, size, sortBy, sortDir, filters, displayCol) => ({
    api: API.PATIENTS.ALL_PATIENTS,
    type: FETCH_ALL_PATIENTS_REQUEST,
    page,
    size,
    sortBy,
    sortDir,
    filters,
    displayCol,
});
