import React, { Suspense } from 'react';
import { Route } from 'react-router';
import { attendanceList, employeeAttendance } from '../constants/constants';
import AttendanceContainer from '../containers/Attendance/AttendanceContainer';
import PageLoader from '../components/PageLoader/PageLoader';

const AttendanceListPage = React.lazy(() => import('../pages/AttendancePages/AttendanceListPage'));
const AttendanceEmployeePage = React.lazy(() => import('../pages/AttendancePages/AttendanceEmployeePage'));

const AttendanceRoutes = props => (
    <AttendanceContainer {...props}>
        <Suspense fallback={<PageLoader />}>
            <Route exact path={attendanceList} component={AttendanceListPage} />
            <Route exact path={employeeAttendance} component={AttendanceEmployeePage} />
        </Suspense>
    </AttendanceContainer>
);

export default AttendanceRoutes;
