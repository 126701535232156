import initialStates from '../../initialStates';
import {
    FETCH_INSURANCE_REQUEST_LINES_REQUEST,
    FETCH_INSURANCE_REQUEST_LINES_SUCCESS,
    FETCH_INSURANCE_REQUESTS_REQUEST,
    FETCH_INSURANCE_REQUESTS_SUCCESS,
} from './insuranceRequestsList-actions';

import { getStringFromObject } from '../../../constants/lodashUtils';

export const insuranceRequestLinesReducer = (state = initialStates.insuranceRequestLines, action) => {
    let newState = state;
    console.log('new action for refund for pagw', action);
    switch (action.type) {
    case FETCH_INSURANCE_REQUEST_LINES_REQUEST:
        newState = {
            ...newState,
            filters: getStringFromObject('payload.filters', action),
        };
        break;
    case FETCH_INSURANCE_REQUEST_LINES_SUCCESS:
        newState = {
            ...newState,
            ...action.payload,
        };
        break;
    default:
        newState = state;
    }

    if (newState !== state) {
        // only console.log if state has changed
        console.log('action:', action, 'state:', state, 'newState:', newState);
    }

    return newState;
};

export const insuranceRequestsReducer = (state = initialStates.insuranceRequests, action) => {
    let newState = state;
    console.log('new action for refund for pagw', action);
    switch (action.type) {
    case FETCH_INSURANCE_REQUESTS_REQUEST:
        newState = {
            ...newState,
            filters: getStringFromObject('payload.filters', action),
        };
        break;
    case FETCH_INSURANCE_REQUESTS_SUCCESS:
        newState = {
            ...newState,
            ...action.payload,
        };
        break;
    default:
        newState = state;
    }

    if (newState !== state) {
        // only console.log if state has changed
        console.log('action:', action, 'state:', state, 'newState:', newState);
    }

    return newState;
};
