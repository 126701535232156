import { takeLatest } from 'redux-saga/effects';
import {
    FETCH_ATTENDANCE_LIST_ACTION_REQUEST,
    FETCH_BY_EMPLOYEE_AND_PERIOD_REQUEST,
    FETCH_PERIOD_REQUEST,
    FETCH_PERIODS_REQUEST,
    SAVE_DUTY_ACTION_REQUEST,
    UPDATE_APPROVED_ATTENDANCE_REQUEST,
    REVERT_APPROVED_ATTENDANCE_REQUEST,
} from '../../redux/modules/attendance/attendance-actions';
import {
    fetchAttendanceListWorkerSaga,
    fetchByEmployeeAndPeriodWorkerSaga,
    fetchPeriodsWorkerSaga,
    saveDutyWorkerSaga,
    updateAttendanceWorkerSaga,
    revertApprovedAttendanceWorkerSaga,
} from './attendanceWorkerSaga';
import { commonGetWorkerSaga } from '../commonSaga/commonWorkerSaga';

export function* saveDutySaga() {
    yield takeLatest(SAVE_DUTY_ACTION_REQUEST, saveDutyWorkerSaga);
}

export function* fetchAttendanceListSaga() {
    yield takeLatest(FETCH_ATTENDANCE_LIST_ACTION_REQUEST, fetchAttendanceListWorkerSaga);
}

export function* fetchPeriodsSaga() {
    yield takeLatest(FETCH_PERIODS_REQUEST, fetchPeriodsWorkerSaga);
}

export function* updateAttendanceSaga() {
    yield takeLatest(UPDATE_APPROVED_ATTENDANCE_REQUEST, updateAttendanceWorkerSaga);
}

export function* revertApprovedAttendanceSaga() {
    yield takeLatest(REVERT_APPROVED_ATTENDANCE_REQUEST, revertApprovedAttendanceWorkerSaga);
}

export function* fetchPeriodSaga() {
    yield takeLatest(FETCH_PERIOD_REQUEST, commonGetWorkerSaga);
}

export function* fetchByEmployeeAndPeriodWatcherSaga() {
    yield takeLatest(FETCH_BY_EMPLOYEE_AND_PERIOD_REQUEST, fetchByEmployeeAndPeriodWorkerSaga);
}
