import initialStates from '../../initialStates';
import {
    FETCH_PACKAGES_FAILURE, FETCH_PACKAGES_SUCCESS,
    FETCH_PACKAGE_DETAILS_FAILURE, FETCH_PACKAGE_DETAILS_SUCCESS,
    FETCH_MATERIAL_RECEIVE_RSD_FAILURE, FETCH_MATERIAL_RECEIVE_RSD_SUCCESS,
    ACCEPT_PACKAGE_SUCCESS, DOWNLOAD_PACKAGE_SUCCESS,
    IGNORE_SELECTED_PACKAGES_SUCCESS,
} from './acceptPackage-actions';

export const acceptPackageReducer = (state = initialStates.acceptPackage, action) => {
    let newState;
    switch (action.type) {
    case FETCH_PACKAGES_SUCCESS:
        newState = {
            list: action.data.content,
            page: action.data.page,
            size: action.data.size,
            totalElements: action.data.totalElements,
        };
        break;
    case DOWNLOAD_PACKAGE_SUCCESS:
        newState = {
            list: action.data.content,
        };
        break;
    case ACCEPT_PACKAGE_SUCCESS:
    case FETCH_PACKAGES_FAILURE:
    default:
        newState = state;
    }
    return newState;
};

export const getPackageDetailsReducer = (state = initialStates.acceptPackageDetails, action) => {
    let newState;
    switch (action.type) {
    case FETCH_PACKAGE_DETAILS_SUCCESS:
        newState = {
            list: action.data.content,
            page: action.data.page,
            size: action.data.size,
            totalElements: action.data.totalElements,
            filters: action.filters,
        };
        break;
    case IGNORE_SELECTED_PACKAGES_SUCCESS:
        newState = {
            list: action.data.content,
        };
        break;
    case FETCH_PACKAGE_DETAILS_FAILURE:
    default:
        newState = state;
    }
    return newState;
};

export const materialReceiveReducer = (state = initialStates.materialReceive, action) => {
    let newState;
    switch (action.type) {
    case FETCH_MATERIAL_RECEIVE_RSD_SUCCESS:
        newState = {
            list: action.data,
        };
        break;
    case FETCH_MATERIAL_RECEIVE_RSD_FAILURE:
    default:
        newState = state;
    }
    return newState;
};
