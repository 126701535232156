import cloneDeep from 'clone-deep';
import { checkIfPrivilegeExistsForUser } from '../../constants/privilegeChecker';
import api from '../../constants/api';
import {
    getStartAndEndDateFromOrderTypeDateFilter,
    isArrayValidAndNotEmpty,
    ORDER_TYPE_DATE_FILTERS,
} from '../../constants/CommonUtil';
import {
    generateConstructFromFilterColumns,
    generateSearchFilterConstruct,
} from '../../components/GenericFilterComponent/GenericFilterComponentUtil';
import {
    COMBINEMODE,
    generateObjectWithCriteriaMode,
    getCombined,
} from '../../sagas/util';
import { getStringFromObject } from '../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

export const approvalQueueTabs = {
    request: {
        label: 'Approval Requests',
        link: '/approval/request',
        value: 'request',
    },
    pending: {
        label: 'Pending Approvals',
        link: '/approval/pending',
        value: 'pending',
    },
    refuse: {
        label: 'Refused',
        link: '/approval/refuse',
        value: 'refuse',
    },
};

export const getMatchingFilterConfig = (
    approvalConfig,
    privilegeChecker = checkIfPrivilegeExistsForUser,
) => {
    const approvalConfigRoles = Object.keys(approvalConfig);
    for (let i = 0; i < approvalConfigRoles.length; i += 1) {
        console.log('processing', approvalConfigRoles[i]);
        const theConfigForTheRole = approvalConfig[approvalConfigRoles[i]];
        if (isObjectValidAndNotEmpty(theConfigForTheRole)) {
            const {
                privilege,
            } = theConfigForTheRole;
            if (privilegeChecker(privilege)) {
                return theConfigForTheRole;
            }
        }
    }
    return null;
};

export const isFieldDisabled = (insuranceApprovalConfig, fieldName) => {
    let disabled = false;
    if (isObjectValidAndNotEmpty(insuranceApprovalConfig)) {
        const matchingFilterConfig = getMatchingFilterConfig(insuranceApprovalConfig);
        if (isObjectValidAndNotEmpty(matchingFilterConfig)) {
            console.log('asda-9jsd-asda', fieldName, matchingFilterConfig);
            if (isObjectValidAndNotEmpty(matchingFilterConfig.defaultFilters) &&
                isObjectValidAndNotEmpty(matchingFilterConfig.defaultFilters[fieldName])
            ) {
                // eslint-disable-next-line
                disabled = matchingFilterConfig.defaultFilters[fieldName].disabled;
            }
        }
    }
    return disabled;
};

export const approvalRequestFilters = (fixedFiltersKV) => {
    console.log('asdsadu0s-a0dusa-d');
    return ([
        // {
        //     name: 'Orders.startDate',
        //     type: 'date',
        //     label: 'From',
        //     startOfDay: true,
        //     disableClear: true,
        //     maxDateField: 'Orders.endDate',
        //     convertToGMT: true,
        //     searchConstructFields: {
        //         name: 'Orders.dateCreated',
        //         filterOperation: '>=',
        //     },
        // },
        // {
        //     name: 'Orders.endDate',
        //     type: 'date',
        //     label: 'To',
        //     endOfDay: true,
        //     additionalFieldToClear: ['Orders.startDate'],
        //     minDateField: 'Orders.startDate',
        //     defaultValueField: 'Orders.startDate',
        //     convertToGMT: true,
        //     searchConstructFields: {
        //         name: 'Orders.dateCreated',
        //         filterOperation: '<=',
        //     },
        // },
        {
            name: 'datePeriod',
            type: 'select',
            label: 'Period',
            valueField: 'key',
            disableClear: false,
            options: Object.keys(ORDER_TYPE_DATE_FILTERS).map(aPeriod => ({
                key: aPeriod,
                value: ORDER_TYPE_DATE_FILTERS[aPeriod],
            })),
        },
        {
            name: 'OrderType.name',
            type: 'select',
            label: 'Order Type',
            valueField: 'key',
            disableClear: true,
            options: [
                {
                    key: 'Drug Order',
                    value: 'Drug Order',
                },
                {
                    key: 'Dental Order',
                    value: 'Dental Order',
                },
            ],
            disabled: Object.keys(fixedFiltersKV || {}).includes('OrderType.name'),
            searchConstructFields: {
                name: 'OrderType.name',
                filterOperation: '=',
            },
        },
        {
            name: 'InsurancePayer.uuid',
            type: 'autocomplete',
            label: 'TPA',
            valueField: 'key',
            disableClear: true,
            dataSourceApi:
            // eslint-disable-next-line
                `${api.SEARCH.INSURANCE_PAYERS_BY_TYPE}?payerType=COMPANY&payerType=INSURANCE&payerType=ASSOCIATION&searchString=`,
            disabled: Object.keys(fixedFiltersKV || {}).includes('InsurancePayer.uuid'),
            searchConstructFields: {
                name: 'InsurancePayer.uuid',
                filterOperation: '=',
            },
        },
    ]);
};

export const insuranceRequestStates = {
    DRAFT: 'draft',
    DELAYED: 'delayed',
    APPROVED: 'approved',
    RE_APPROVED: 're_approve',
    REJECTED: 'rejected',
    PATIENT_REFUSED: 'patient_refused',
    INSURANCE_REFUSED: 'insurance_refused',
    REFERRED_OUT: 'referred_out',
};

export const insuranceRequestStatesLabels = {
    [insuranceRequestStates.REJECTED]: 'REJECTED',
    [insuranceRequestStates.DRAFT]: 'DRAFT',
    [insuranceRequestStates.DELAYED]: 'DELAYED',
    [insuranceRequestStates.APPROVED]: 'APPROVED',
    [insuranceRequestStates.PATIENT_REFUSED]: 'PATIENT REFUSED',
    [insuranceRequestStates.INSURANCE_REFUSED]: 'INSURANCE REFUSED',
    [insuranceRequestStates.REFERRED_OUT]: 'REFERRED OUT',
};

// to change
export const insuranceRequestFilters = (fixedFiltersKV) => {
    console.log('asdsadu0s-a0dusa-d');
    return ([
        // {
        //     name: 'InsuranceRequest.startDate',
        //     type: 'date',
        //     label: 'From',
        //     startOfDay: true,
        //     disableClear: true,
        //     maxDateField: 'InsuranceRequest.endDate',
        //     convertToGMT: true,
        //     searchConstructFields: {
        //         name: 'InsuranceRequest.dateCreated',
        //         filterOperation: '>=',
        //     },
        // },
        // {
        //     name: 'InsuranceRequest.endDate',
        //     type: 'date',
        //     label: 'To',
        //     endOfDay: true,
        //     additionalFieldToClear: ['InsuranceRequest.startDate'],
        //     minDateField: 'InsuranceRequest.startDate',
        //     defaultValueField: 'InsuranceRequest.startDate',
        //     convertToGMT: true,
        //     searchConstructFields: {
        //         name: 'InsuranceRequest.dateCreated',
        //         filterOperation: '<=',
        //     },
        // },
        {
            name: 'datePeriod',
            type: 'select',
            label: 'Period',
            valueField: 'key',
            disableClear: false,
            options: Object.keys(ORDER_TYPE_DATE_FILTERS).map(aPeriod => ({
                key: aPeriod,
                value: ORDER_TYPE_DATE_FILTERS[aPeriod],
            })),
        },
        {
            name: 'InsuranceRequestLine.state',
            type: 'select',
            label: 'State',
            valueField: 'key',
            disableClear: true,
            options: Object.keys(insuranceRequestStatesLabels).map(aInsuranceRequestState => ({
                key: aInsuranceRequestState,
                value: insuranceRequestStatesLabels[aInsuranceRequestState],
            })),
            searchConstructFields: {
                name: 'InsuranceRequestLine.state',
                filterOperation: '=',
            },
        },
        {
            name: 'OrderType.name',
            type: 'select',
            label: 'Order Type',
            valueField: 'key',
            disableClear: true,
            disabled: Object.keys(fixedFiltersKV || {}).includes('OrderType.name'),
            options: [
                {
                    key: 'Drug Order',
                    value: 'Drug Order',
                },
                {
                    key: 'Dental Order',
                    value: 'Dental Order',
                },
            ],
            searchConstructFields: {
                name: 'OrderType.name',
                filterOperation: '=',
            },
        },
        {
            name: 'InsurancePayer.uuid',
            type: 'autocomplete',
            label: 'TPA',
            valueField: 'key',
            disableClear: false,
            dataSourceApi:
            // eslint-disable-next-line
                `${api.SEARCH.INSURANCE_PAYERS_BY_TYPE}?payerType=COMPANY&payerType=INSURANCE&payerType=ASSOCIATION&searchString=`,
            disabled: Object.keys(fixedFiltersKV || {}).includes('InsurancePayer.uuid'),
            searchConstructFields: {
                name: 'InsurancePayer.uuid',
                filterOperation: '=',
            },
        },
    ]);
};

export const approvalRequestSearchFieldFilters = [
    {
        name: 'PatientIdentifier.identifier',
        filterOperation: '=',
    },
    {
        name: 'Person.secondaryIdentifier',
        filterOperation: '=',
    },
];

export const insuranceRequestSearchFieldFilters = [
    {
        name: 'PatientIdentifier.identifier',
        filterOperation: '=',
    },
    {
        name: 'PersonAttribute.idCardNumber',
        filterOperation: '=',
    },
];

export const getDefaultFiltersForApprovalQueues = (
    approvalConfig,
    privilegeChecker = checkIfPrivilegeExistsForUser,
) => {
    if (isObjectValidAndNotEmpty(approvalConfig)) {
        const matchingFilterConfig = getMatchingFilterConfig(approvalConfig, privilegeChecker);
        if (matchingFilterConfig) {
            const {
                privilege,
                defaultFilters,
            } = matchingFilterConfig;
            if (privilegeChecker(privilege)) {
                const filter = {};
                if (isObjectValidAndNotEmpty(defaultFilters)) {
                    Object.keys(defaultFilters).forEach((aFilter) => {
                        filter[aFilter] = defaultFilters[aFilter].value;
                    });
                }
                return filter;
            }
        }
    }
    return {};
};


export const isOnlyClinical = (props) => {
    let onlyClinical = getStringFromObject('onlyClinical', props.approvalQueue);
    const { pharmacistPrivilege } = props;
    console.log('asd-0ajkd-[aspdma', onlyClinical, pharmacistPrivilege);
    if ((onlyClinical === null || onlyClinical === undefined) && isArrayValidAndNotEmpty(pharmacistPrivilege)) {
        // if only clinical is null, means it is loaded first time.. if insurance, make it true
        onlyClinical = !checkIfPrivilegeExistsForUser(pharmacistPrivilege);
        console.log('asd-0ajkd-[aspdma', onlyClinical, pharmacistPrivilege);
    }
    console.log('asd-0ajkd-[aspdma', onlyClinical, props);
    return onlyClinical;
};


export const getMatchingDefaultAdditionalFilter = (
    approvalQueuesExtraFilters,
    privilegeChecker = checkIfPrivilegeExistsForUser,
) => {
    if (
        isObjectValidAndNotEmpty(approvalQueuesExtraFilters) &&
        isArrayValidAndNotEmpty(approvalQueuesExtraFilters.defaultSelectors) &&
        isArrayValidAndNotEmpty(approvalQueuesExtraFilters.options)
    ) {
        for (let i = 0; i < approvalQueuesExtraFilters.defaultSelectors.length; i += 1) {
            const anExtraFilter = approvalQueuesExtraFilters.defaultSelectors[i];
            if (
                privilegeChecker(anExtraFilter.selectByDefaultPrivilege) &&
                isArrayValidAndNotEmpty(anExtraFilter.selectOptions)
            ) {
                return anExtraFilter;
            }
        }
    }
    return null;
};


export const getKeyValueFromConstruct = (searchConstruct) => {
    let keyValObj = {};
    if (searchConstruct.type === 'combineMode') {
        const leftKeyVal = getKeyValueFromConstruct(searchConstruct.left);
        const rightKeyVal = getKeyValueFromConstruct(searchConstruct.right);
        keyValObj = {
            ...leftKeyVal,
            ...rightKeyVal,
        };
    } else if (searchConstruct.type === 'criteria') {
        keyValObj[searchConstruct.key] = searchConstruct.value;
    }
    return keyValObj;
};


export const getFixedFiltersKV = (additionalFilterForApproval) => {
    const defaultAdditionalFilter =
        getMatchingDefaultAdditionalFilter(additionalFilterForApproval);
    let defaultConstructKeyAndVals = {};

    if (isObjectValidAndNotEmpty(defaultAdditionalFilter)) {
        const fixedOptions = defaultAdditionalFilter.selectOptions.filter(anOption => anOption.isFixed);
        const fixedOptionsNames = fixedOptions.map(aFixedOption => aFixedOption.optionName);
        const matchingOptions =
            additionalFilterForApproval.options.filter(anOption => fixedOptionsNames.includes(anOption.key));
        console.log('defa', matchingOptions);
        matchingOptions.forEach((aMatchingOption) => {
            const { construct } = aMatchingOption;
            defaultConstructKeyAndVals = {
                ...getKeyValueFromConstruct(construct),
            };
        });
    }
    return defaultConstructKeyAndVals;
};

export const getApprovalQueueSearchConstruct = (
    approvalType,
    filtersToApply,
    searchFilter,
    onlyClinical,
    additionalFilters,
) => {
    const filters = cloneDeep(filtersToApply);
    let filterConfig = insuranceRequestFilters();
    let searchFilterConfig = insuranceRequestSearchFieldFilters;
    let dateFilterName = 'InsuranceRequest.dateCreated';
    if (approvalType === approvalQueueTabs.request.value) {
        filterConfig = approvalRequestFilters();
        dateFilterName = 'Orders.dateCreated';
        searchFilterConfig = approvalRequestSearchFieldFilters;
    }
    const datePeriod = getStringFromObject('datePeriod.value', filters);

    delete filters.datePeriod;

    let filterConstruct = generateConstructFromFilterColumns(filterConfig, filters);
    if (filters['EpisodeOfCare.dentalEpisode']) {
        filterConstruct =
            getCombined(
                filterConstruct,
                'AND',
                generateObjectWithCriteriaMode('EpisodeOfCare.dentalEpisode', true, '==='),
            );
    }
    console.log('asd-a0id-0asdia', filterConstruct, filters);
    let searchValueConstruct = null;
    if (searchFilter) {
        searchValueConstruct = generateSearchFilterConstruct(searchFilter, searchFilterConfig);
    }
    let searchConstruct = getCombined(filterConstruct, COMBINEMODE.AND, searchValueConstruct);

    if (onlyClinical) {
        searchConstruct = getCombined(
            searchConstruct,
            COMBINEMODE.AND,
            generateObjectWithCriteriaMode('OrderType.name', 'Drug Order', '!='),
        );
    }
    if (datePeriod) {
        const {
            startDate,
            endDate,
        } = getStartAndEndDateFromOrderTypeDateFilter(datePeriod);
        console.log('aspikanlsmasclakc,. c', startDate, endDate);
        const orderDateStarted =
            generateObjectWithCriteriaMode(dateFilterName, startDate.getTime(), '>=');
        const orderDateEnd =
            generateObjectWithCriteriaMode(dateFilterName, endDate.getTime(), '<=');
        searchConstruct =
            getCombined(
                searchConstruct,
                COMBINEMODE.AND,
                getCombined(
                    orderDateStarted,
                    COMBINEMODE.AND,
                    orderDateEnd,
                ),
            );
    }

    if (isArrayValidAndNotEmpty(additionalFilters)) {
        additionalFilters.forEach((anAdditionalFilter) => {
            searchConstruct = getCombined(
                searchConstruct,
                COMBINEMODE.AND,
                anAdditionalFilter.construct,
            );
        });
    }
    return searchConstruct;
};
