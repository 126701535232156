import axios from 'axios';
import cloneDeep from 'clone-deep';
import {
    isArrayValidAndNotEmpty,
    sortArrayOfObjectsByFieldValue,
} from '../constants/CommonUtil';
import { formatDate, formatDateTimeForDisplay, getDateInYYYYMMDDFormat } from '../constants/DateUtil';
import API from '../constants/api';
import {
    internalMoveFields,
    otherOperationTypes,
} from '../components/PurchaseOrderComponents/InternalMovesDialog/InternalMovesUtil';
import { getStringFromObject } from '../constants/lodashUtils';
import { NumberOf } from '../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../constants/nullCheckUtils';
// import { formatDate } from '../constants/DateUtil';

export const INTERNAL_MOVE_OPERATION = {
    SAVE: 'save',
    CONFIRM: 'confirm',
};
const EMPTY_ROW = {
    product: '',
    description: '',
    uom: '',
    quantity: '',
    cost: '',
    salePrice: '',
    mrp: '',
    expiryDate: '',
    uomCategoryId: 0,
    productId: 0,
};

export const UI_OBJECT = ({
    createDate: '',
    uuid: '',
    transactionType: '',
    [internalMoveFields.DEST_LOC]: '',
    [internalMoveFields.SOURCE_LOC]: '',
    status: '',
    [internalMoveFields.NOTES]: '',
    [internalMoveFields.PRODUCTS]: [cloneDeep(EMPTY_ROW)],
    date: new Date(),
});

const getBatchNameString = (batchName) => {
    if (!batchName) {
        return '';
    }
    const theBatchName = `${batchName}`;
    if (theBatchName.startsWith('NCD-')) {
        return theBatchName.substr(4, theBatchName.length);
    }
    return theBatchName;
};

const mapStockPickingLinesFromUiObject = (lines, sourceLocation) => {
    const stockPickingLines = [];
    console.log('this is lines', lines);
    if (isArrayValidAndNotEmpty(lines)) {
        lines.forEach((line, index) => {
            if (line.product && line.product.uuid) {
                const stockPickingLine = {
                    uuid: getStringFromObject('uuid', line),
                    product: {
                        key: getStringFromObject('product.uuid', line, null),
                        value: getStringFromObject('product.productName', line, ''),
                    },
                    name: getBatchNameString(getStringFromObject('description.value', line, '')),
                    batchName: getBatchNameString(getStringFromObject('description.value', line, '')),
                    stockProdLot: getStringFromObject('description.batch', line, null),
                    quantity: getStringFromObject('quantity', line, 0),
                    productUom: {
                        key: getStringFromObject('uom.uuid', line, null),
                        value: getStringFromObject('uom.name', line, ''),
                    },
                    cost: getStringFromObject('cost', line, 0),
                    salePrice: getStringFromObject('salePrice', line, 0),
                    mrp: getStringFromObject('mrp', line, 0),
                    expiryDate: line.expiryDate ? formatDate(line.expiryDate, 'yyyy-MM-dd hh:mm:ss') : null,
                    trackByBatch: getStringFromObject('product.trackByBatch', line, false),
                    sourceLocation,
                    // To start sequence from 1
                    sequence: index + 1,
                };
                stockPickingLines.push(stockPickingLine);
            }
        });
    }
    console.log('this is lines', stockPickingLines);
    return stockPickingLines;
};

const mapStockPickingLinesForAdjustInventoryFromUiObject = (lines, sourceLocation) => {
    const stockPickingLines = [];
    console.log('this is lines', lines);
    if (isArrayValidAndNotEmpty(lines)) {
        lines.forEach((line, index) => {
            if (line.product && line.product.uuid) {
                const stockPickingLine = {
                    uuid: getStringFromObject('uuid', line),
                    product: {
                        key: getStringFromObject('product.uuid', line, null),
                        value: getStringFromObject('product.productName', line, ''),
                    },
                    name: getBatchNameString(getStringFromObject('description.value', line, '')),
                    batchName: getBatchNameString(getStringFromObject('description.value', line, '')),
                    stockProdLot: getStringFromObject('description.batch', line, null),
                    quantity: getStringFromObject('changeInQuantity', line, 0),
                    productUom: {
                        key: getStringFromObject('uom.uuid', line, null),
                        value: getStringFromObject('uom.name', line, ''),
                    },
                    cost: getStringFromObject('cost', line, 0),
                    salePrice: getStringFromObject('salePrice', line, 0),
                    mrp: getStringFromObject('mrp', line, 0),
                    expiryDate: line.expiryDate ? formatDate(line.expiryDate, 'yyyy-MM-dd hh:mm:ss') : null,
                    trackByBatch: getStringFromObject('trackByBatch', line, false),
                    sourceLocation,
                    // To start sequence from 1
                    sequence: index + 1,
                };
                stockPickingLines.push(stockPickingLine);
            }
        });
    }
    console.log('this is lines', stockPickingLines);
    return stockPickingLines;
};

const mapStockPickingLinesForInventoryQuantityAdjustmentFromUiObject = (lines) => {
    const stockPickingLines = [];
    console.log('this is lines', lines);
    if (isArrayValidAndNotEmpty(lines)) {
        lines.forEach((line, index) => {
            if (line.product && line.product.key) {
                const stockPickingLine = {
                    uuid: getStringFromObject('uuid', line),
                    product: {
                        key: getStringFromObject('product.key', line, null),
                        value: getStringFromObject('product.value', line, ''),
                    },
                    name: getBatchNameString(getStringFromObject('stockProdLot.value', line, '')),
                    batchName: getBatchNameString(getStringFromObject('stockProdLot.value', line, '')),
                    stockProdLot: getStringFromObject('stockProdLot', line, null),
                    quantity: getStringFromObject('changeInQuantity', line, 0),
                    productUom: {
                        key: getStringFromObject('productUom.key', line, null),
                        value: getStringFromObject('productUom.value', line, ''),
                    },
                    cost: getStringFromObject('cost', line, 0),
                    salePrice: getStringFromObject('salePrice', line, 0),
                    mrp: getStringFromObject('mrp', line, 0),
                    // expiryDate: line.expiryDate ? formatDate(line.expiryDate, 'yyyy-MM-dd hh:mm:ss') : null,
                    trackByBatch: getStringFromObject('stockProdLot.value', line, '') !== '',
                    sourceLocation: getStringFromObject('sourceLocation', line, null),
                    // To start sequence from 1
                    sequence: index + 1,
                };
                stockPickingLines.push(stockPickingLine);
            }
        });
    }
    console.log('this is lines', stockPickingLines);
    return stockPickingLines;
};

export const mapInternalMovesFromUiObject = (uiObject, action, isStockAdjustment) => {
    const payload = {};
    console.log('uiobject', uiObject);
    if (isObjectValidAndNotEmpty(uiObject)) {
        payload.uuid = uiObject.uuid;
        payload.status = action;
        payload.origin = uiObject.reference;
        payload.notes = uiObject.notes;
        payload.name = uiObject.name;
        payload.operation = isStockAdjustment ? 'STOCK_ADJUSTMENT' : 'INTERNAL';
        payload.miscOperationType = getStringFromObject(`${internalMoveFields.OPERATION_TYPE}.key`, uiObject);
        payload.date = getStringFromObject('date', uiObject) ?
            new Date(getStringFromObject('date', uiObject)).getTime() : new Date().getTime();
        if (!isStockAdjustment) {
            payload.destLoc = {
                key: getStringFromObject('destinationLoc.uuid', uiObject, null),
                value: getStringFromObject('destinationLoc.name', uiObject, ''),
            };
            payload.sourceLoc = {
                key: getStringFromObject('sourceLoc.uuid', uiObject, null),
                value: getStringFromObject('sourceLoc.name', uiObject, ''),
            };
        }
        if (getStringFromObject('intentUuid', uiObject)) {
            payload.intentUuid = getStringFromObject('intentUuid', uiObject);
        }
        payload.stockPickingLinesDTOSet = mapStockPickingLinesFromUiObject(uiObject.products,
            isStockAdjustment ? {
                key: getStringFromObject('sourceLoc.uuid', uiObject, null),
                value: getStringFromObject('sourceLoc.name', uiObject, ''),
            } : null);
    }
    console.log('internalmovesmap', payload);
    return payload;
};

export const mapInternalMovesForAdjustInventoryFromUiObject = (uiObject, action, isStockAdjustment) => {
    const payload = {};
    console.log('uiobject', uiObject);
    if (isObjectValidAndNotEmpty(uiObject)) {
        payload.uuid = uiObject.uuid;
        payload.status = action;
        payload.origin = uiObject.reference;
        payload.notes = uiObject.notes;
        payload.name = uiObject.name;
        payload.operation = isStockAdjustment ? 'STOCK_ADJUSTMENT' : 'INTERNAL';
        payload.miscOperationType = getStringFromObject(`${internalMoveFields.OPERATION_TYPE}.key`, uiObject);
        payload.date = getStringFromObject('date', uiObject) ?
            new Date(getStringFromObject('date', uiObject)).getTime() : new Date().getTime();
        if (!isStockAdjustment) {
            payload.destLoc = {
                key: getStringFromObject('destinationLoc.uuid', uiObject, null),
                value: getStringFromObject('destinationLoc.name', uiObject, ''),
            };
            payload.sourceLoc = {
                key: getStringFromObject('sourceLoc.uuid', uiObject, null),
                value: getStringFromObject('sourceLoc.name', uiObject, ''),
            };
        }
        if (getStringFromObject('intentUuid', uiObject)) {
            payload.intentUuid = getStringFromObject('intentUuid', uiObject);
        }
        payload.stockPickingLinesDTOSet = mapStockPickingLinesForAdjustInventoryFromUiObject(uiObject.products,
            isStockAdjustment ? {
                key: getStringFromObject('sourceLoc.uuid', uiObject, null),
                value: getStringFromObject('sourceLoc.name', uiObject, ''),
            } : null);
    }
    console.log('internalmovesmap', payload);
    return payload;
};

export const mapInventoryQuantityAdjustmentFromUiObject = (uiObject, action, isStockAdjustment) => {
    const payload = {};
    console.log('uiobject', uiObject);
    if (isObjectValidAndNotEmpty(uiObject)) {
        payload.uuid = uiObject.uuid;
        payload.status = action;
        payload.origin = uiObject.reference;
        payload.notes = uiObject.notes;
        payload.name = uiObject.name;
        payload.operation = isStockAdjustment ? 'STOCK_ADJUSTMENT' : 'INTERNAL';
        payload.miscOperationType = getStringFromObject(`${internalMoveFields.OPERATION_TYPE}.key`, uiObject);
        payload.date = getStringFromObject('date', uiObject) ?
            new Date(getStringFromObject('date', uiObject)).getTime() : new Date().getTime();
        if (!isStockAdjustment) {
            payload.destLoc = {
                key: getStringFromObject('destinationLoc.uuid', uiObject, null),
                value: getStringFromObject('destinationLoc.name', uiObject, ''),
            };
            payload.sourceLoc = {
                key: getStringFromObject('sourceLoc.uuid', uiObject, null),
                value: getStringFromObject('sourceLoc.name', uiObject, ''),
            };
        }
        if (getStringFromObject('intentUuid', uiObject)) {
            payload.intentUuid = getStringFromObject('intentUuid', uiObject);
        }
        payload.stockPickingLinesDTOSet = mapStockPickingLinesForInventoryQuantityAdjustmentFromUiObject(uiObject.products);
    }
    console.log('internalmovesmap', payload);
    return payload;
};

export const getBatches = async (productUuid, locationUuid) => {
    const allowProductsWithoutBatch = true;
    const response = await axios.get(API.PRODUCT.GET_BATCH_AND_QUANTITY, {
        params: {
            productUuid,
            locationUuid,
            allowProductsWithoutBatch,
        },
    });
    return response;
};

const mapStockPickingLineUiObject = (lines, locationUuid, isConfirmed) => lines.map(async (stock, index) => {
    const productUuid = getStringFromObject('product.key', stock, null);
    let response = {};
    if (!isConfirmed) {
        response = await getBatches(productUuid, locationUuid);
    }
    console.log('asdfsdfdsfsdfdsf', response);
    const batchDetails = getStringFromObject('data.batchDetailsDtos', response, []);
    const obj = {
        uuid: getStringFromObject('uuid', stock),
        product: {
            productId: getStringFromObject('productId', stock, ''),
            productName: getStringFromObject('product.value', stock, ''),
            uuid: getStringFromObject('product.key', stock, null),
        },
        uom: {
            name: getStringFromObject('productUom.value', stock, ''),
            uuid: getStringFromObject('productUom.key', stock, null),
        },
        cost: getStringFromObject('cost', stock, 0),
        salePrice: getStringFromObject('salePrice', stock, 0),
        mrp: getStringFromObject('mrp', stock, 0),
        quantity: getStringFromObject('quantity', stock, 0),
        batchId: getStringFromObject('batchId', stock),
        description: {
            text: getStringFromObject('stockProdLot.value', stock, ''),
            value: getStringFromObject('stockProdLot.value', stock, ''),
        },
        expiryDate: stock.expiryDate ? getDateInYYYYMMDDFormat(new Date(stock.expiryDate)) : '',
        trackByBatch: getStringFromObject('productTrackByBatch', stock, false),
        sequence: stock.sequence || index,
    };
    obj.batchWithQty = [];
    if (isArrayValidAndNotEmpty(batchDetails)) {
        const selectedBatch = getStringFromObject('stockProdLot.value', stock, '');
        batchDetails.map((batch) => {
            if (selectedBatch && selectedBatch === getStringFromObject('batch.value', batch, '')) {
                obj.availableQuantity = NumberOf(getStringFromObject('quantity', batch));
            }
            if (!selectedBatch) {
                obj.availableQuantity = NumberOf(getStringFromObject('quantity', batch));
            }
            if (!getStringFromObject('batch.key', batch, false)) {
                // todo commenting this line as its overriding quantity set in above code
                // obj.quantity = getStringFromObject('quantity', batch, 0);
            } else {
                obj.batchWithQty.push({
                    ...batch,
                    text: `${getStringFromObject('batch.value', batch, '')}
                    (${getStringFromObject('quantity', batch, 0)})`,
                    value: getStringFromObject('batch.value', batch, ''),
                });
            }
            return obj;
        });
    }
    console.log('asdfsdfdsfsdfdsf1', obj, response);
    return obj;
});

const mapStockPickingLineForAdjustInventoryUiObject = (lines, locationUuid, isConfirmed) => lines.map(async (stock, index) => {
    const productUuid = getStringFromObject('product.key', stock, null);
    let response = {};
    if (!isConfirmed) {
        response = await getBatches(productUuid, locationUuid);
    }
    console.log('asdfsdfdsfsdfdsf', response);
    const batchDetails = getStringFromObject('data.batchDetailsDtos', response, []);
    const obj = {
        uuid: getStringFromObject('uuid', stock),
        product: {
            productId: getStringFromObject('productId', stock, ''),
            productName: getStringFromObject('product.value', stock, ''),
            uuid: getStringFromObject('product.key', stock, null),
        },
        uom: {
            name: getStringFromObject('productUom.value', stock, ''),
            uuid: getStringFromObject('productUom.key', stock, null),
        },
        cost: getStringFromObject('cost', stock, 0),
        salePrice: getStringFromObject('salePrice', stock, 0),
        mrp: getStringFromObject('mrp', stock, 0),
        quantity: getStringFromObject('quantity', stock, 0),
        batchId: getStringFromObject('batchId', stock),
        description: {
            text: getStringFromObject('stockProdLot.value', stock, ''),
            value: getStringFromObject('stockProdLot.value', stock, ''),
        },
        expiryDate: stock.expiryDate ? getDateInYYYYMMDDFormat(new Date(stock.expiryDate)) : '',
        trackByBatch: getStringFromObject('productTrackByBatch', stock, false),
        sequence: stock.sequence || index,
    };
    obj.batchWithQty = [];
    if (isArrayValidAndNotEmpty(batchDetails)) {
        const selectedBatch = getStringFromObject('stockProdLot.value', stock, '');
        batchDetails.map((batch) => {
            if (selectedBatch && selectedBatch === getStringFromObject('batch.value', batch, '')) {
                obj.description.batch = getStringFromObject('batch', batch);
                obj.quantityOnHand = NumberOf(getStringFromObject('quantity', batch));
                obj.quantity = NumberOf(getStringFromObject('quantity', batch)) + NumberOf(getStringFromObject('quantity', stock, 0));
                obj.changeInQuantity = getStringFromObject('quantity', stock, 0);
            }
            if (!selectedBatch) {
                obj.quantityOnHand = NumberOf(getStringFromObject('quantity', batch));
                obj.quantity = NumberOf(getStringFromObject('quantity', batch)) + NumberOf(getStringFromObject('quantity', stock, 0));
                obj.changeInQuantity = getStringFromObject('quantity', stock, 0);
            }
            if (!getStringFromObject('batch.key', batch, false)) {
                // todo commenting this line as its overriding quantity set in above code
                // obj.quantity = getStringFromObject('quantity', batch, 0);
            } else {
                obj.batchWithQty.push({
                    ...batch,
                    text: `${getStringFromObject('batch.value', batch, '')}
                    (${getStringFromObject('quantity', batch, 0)})`,
                    value: getStringFromObject('batch.value', batch, ''),
                });
            }
            return obj;
        });
    }
    console.log('asdfsdfdsfsdfdsf1', obj, response);
    return obj;
});

export const mapInternalMovesDtoToUiObject = async (stock, adjustInventory) => {
    let uiObject = cloneDeep(UI_OBJECT);
    console.log('mapInternalMovesDtoToUiObject', stock);
    if (isObjectValidAndNotEmpty(stock)) {
        const isConfirmed = ['done', 'confirmed'].indexOf(getStringFromObject('status', stock)) !== -1 && stock.uuid;
        const createDateTime = getStringFromObject('createDateTime', stock);
        uiObject = {
            ...uiObject,
            createDate: createDateTime ?
                formatDateTimeForDisplay(new Date(createDateTime)) :
                getStringFromObject('createDate', stock),
            uuid: getStringFromObject('uuid', stock, ''),
            notes: getStringFromObject('notes', stock, ''),
            destinationLoc: {
                name: getStringFromObject('destLoc.value', stock, ''),
                uuid: getStringFromObject('destLoc.key', stock, null),
            },
            sourceLoc: {
                name: getStringFromObject('sourceLoc.value', stock, ''),
                uuid: getStringFromObject('sourceLoc.key', stock, null),
            },
            status: getStringFromObject('status', stock, ''),
            receivedBy: getStringFromObject('receivedBy', stock, ''),
            name: getStringFromObject('name', stock, ''),
            transactionType: getStringFromObject('transactionType', stock, ''),
            date: getStringFromObject('date', stock) ? new Date(getStringFromObject('date', stock)) : null,
        };
        const miscOperationType = getStringFromObject('miscOperationType', stock);
        if (miscOperationType) {
            const theOpType = Object.values(otherOperationTypes).filter(anOpType => anOpType.key === miscOperationType);
            if (isArrayValidAndNotEmpty(theOpType)) {
                // eslint-disable-next-line
                uiObject[internalMoveFields.OPERATION_TYPE] = theOpType[0];
            }
        }
        if (adjustInventory) {
            uiObject.sourceLoc = {
                name: getStringFromObject('destLoc.value', stock, ''),
                uuid: getStringFromObject('destLoc.key', stock, null),
            };
            uiObject.destinationLoc = null;
        }
        const stockPickingLines = getStringFromObject('stockPickingLinesDTOSet', stock, []);
        let products = [];
        if (isArrayValidAndNotEmpty(stockPickingLines) && uiObject.sourceLoc.uuid) {
            products =
                await Promise.all(mapStockPickingLineUiObject(stockPickingLines, uiObject.sourceLoc.uuid, isConfirmed));
            products = sortArrayOfObjectsByFieldValue(products, 'sequence');
        } else {
            products = [cloneDeep(EMPTY_ROW)];
        }
        uiObject.products = products;
    }
    return uiObject;
};

export const mapInternalMovesDtoForAdjustInventoryToUiObject = async (stock, adjustInventory) => {
    let uiObject = cloneDeep(UI_OBJECT);
    console.log('mapInternalMovesDtoToUiObject', stock);
    if (isObjectValidAndNotEmpty(stock)) {
        const isConfirmed = ['done', 'confirmed'].indexOf(getStringFromObject('status', stock)) !== -1 && stock.uuid;
        const createDateTime = getStringFromObject('createDateTime', stock);
        uiObject = {
            ...uiObject,
            createDate: createDateTime ?
                formatDateTimeForDisplay(new Date(createDateTime)) :
                getStringFromObject('createDate', stock),
            uuid: getStringFromObject('uuid', stock, ''),
            notes: getStringFromObject('notes', stock, ''),
            destinationLoc: {
                name: getStringFromObject('destLoc.value', stock, ''),
                uuid: getStringFromObject('destLoc.key', stock, null),
            },
            sourceLoc: {
                name: getStringFromObject('sourceLoc.value', stock, ''),
                uuid: getStringFromObject('sourceLoc.key', stock, null),
            },
            status: getStringFromObject('status', stock, ''),
            receivedBy: getStringFromObject('receivedBy', stock, ''),
            name: getStringFromObject('name', stock, ''),
            transactionType: getStringFromObject('transactionType', stock, ''),
            date: getStringFromObject('date', stock) ? new Date(getStringFromObject('date', stock)) : null,
        };
        const miscOperationType = getStringFromObject('miscOperationType', stock);
        if (miscOperationType) {
            const theOpType = Object.values(otherOperationTypes).filter(anOpType => anOpType.key === miscOperationType);
            if (isArrayValidAndNotEmpty(theOpType)) {
                // eslint-disable-next-line
                uiObject[internalMoveFields.OPERATION_TYPE] = theOpType[0];
            }
        }
        if (adjustInventory) {
            uiObject.sourceLoc = {
                name: getStringFromObject('destLoc.value', stock, ''),
                uuid: getStringFromObject('destLoc.key', stock, null),
            };
            uiObject.destinationLoc = null;
        }
        const stockPickingLines = getStringFromObject('stockPickingLinesDTOSet', stock, []);
        let products = [];
        if (isArrayValidAndNotEmpty(stockPickingLines) && uiObject.sourceLoc.uuid) {
            products =
                await Promise.all(mapStockPickingLineForAdjustInventoryUiObject(stockPickingLines, uiObject.sourceLoc.uuid, isConfirmed));
            products = sortArrayOfObjectsByFieldValue(products, 'sequence');
        } else {
            products = [cloneDeep(EMPTY_ROW)];
        }
        uiObject.products = products;
    }
    return uiObject;
};
