/* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */
import React from 'react';
import connect from 'react-redux/lib/connect/connect';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { fetchTransactionsListRequest } from '../../redux/modules/transaction/transaction-actions';
import { getJsonPath, isArrayValidAndNotEmpty } from '../../constants/CommonUtil';
import {
    ERP_TRANSACTION_TYPES,
    ERP_TRANSACTION_TYPES_DISPLAY,
    ERP_TRANSACTION_TYPES_PRIVILEGES,
    PAGE,
    SIZE,
    SORT_COL,
    SORT_ORDER_DESC,
} from '../../constants/constants';
import SearchField from '../SearchField/SearchField';
import PurchaseOrderDialog from './PurchaseOrderDialog';
import { fetchPurchaseOrderRequest } from '../../redux/modules/purchaseOrder/purchaseOrder-actions';
import MakeBillDialog from './MakeBillDialog/MakeBillDialog';
import { fetchAccountInvoiceById } from '../../redux/modules/accountInvoice/accountInvoice-actions';
import { fetchAccountExpenseRequestById } from '../../redux/modules/accountExpense/accountExpense-actions';
import ExpenseDialog from '../BookManagementComponents/ExpenseDialog';
import PettyCashExpenseDialog from './EmployeeDialogs/ExpenseDialog';
import AddStockDialog from './AddStockDialog';
import InternalMovesDialog from './InternalMovesDialog/InternalMovesDialog';
import TransferDialog from './TransferDialog';
import GoodReturnViewDialog from './GoodReturnDialog/GoodReturnViewDialog';
import PrepaidExpenseDialog from '../BookManagementComponents/PrepaidExpenseDialog';
import MaterialTable from '../MaterialTableV2/MaterialTable';
import RecieveStockViewDialog from './RecieveStockViewDialog';
import { checkIfPrivilegeExistsForUser } from '../../constants/privilegeChecker';
import CashCloseDialog from '../CashClose/CashCloseDialog';
import ConsolidatedCashCloseDialog from '../ConsolidatedCashClose/ConsolidatedCashCloseDialog';
import CreditOrDebitMemoDialog from './CreditOrDebitMemo/CreditOrDebitMemoDialog';
import SalaryAdvanceDialog from './EmployeeDialogs/SalaryAdvanceDialog';
import PettyCashDialog from './EmployeeDialogs/PettyCashDialog';
import PaidOnAccountDialog from '../BookManagementComponents/PaidOnAccount/PaidOnAccountDialog';
import AccountVoucherDialog from '../../containers/PurchaseContainers/PurchasePaymentsContainer/AccountVoucherDialog';
import IntentDialog from './IntentDialog/IntentDialog';
import MaterialReceiveDialog from './MaterialReceiveDialog/MaterialReceiveDialog';
import { getStringFromObject } from '../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

const style = theme => ({
    container: {
        border: `1px solid ${theme.palette.borderColor}`,
        borderRadius: '3px',
        padding: '0 6px',
        background: theme.palette.common.white,
    },
    advancedText: {
        color: theme.palette.secondaryTextColor,
        cursor: 'pointer',
    },
    heading: {
        fontSize: '1.1rem',
        paddingBottom: '8px',
    },
    link: {
        cursor: 'pointer',
        color: 'blue',
        textDecoration: 'underline',
        overflowWrap: 'break-word',
    },
});

class SearchPopover extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            MenuDialog: null,
            schema: {},
            dialogProps: {},
        };
    }

    componentWillMount() {
        const {
            page, size, sortCol, sortOrder, transactionTypeIgnoreList, transactionTypes,
        } = this.props;
        this.fetchListOfTransactionInternal(page, size, sortCol, sortOrder, {
            typeIgnoreList: transactionTypeIgnoreList,
            types: this.getTransactionBasedOnPrivileges(transactionTypes),
        });
    }

    componentDidMount() {
        fetch(getJsonPath('/RecentTransactionList.json'))
            .then(resp => resp.json())
            .then((json) => {
                this.setState({
                    schema: json,
                });
            })
            .catch((error) => {
                console.error(`There has been a problem with your fetch operation:${error.message}`);
            });
    }

    getTransactionBasedOnPrivileges = (types) => {
        if (isArrayValidAndNotEmpty(types)) {
            return types.filter(type => checkIfPrivilegeExistsForUser(
                getStringFromObject(type, ERP_TRANSACTION_TYPES_PRIVILEGES, [])));
        }
        return [];
    };

    fetchListOfTransactionInternal = (page, size, sortCol, sortOrder, filters) => {
        this.props.dispatch(fetchTransactionsListRequest(page, size, sortCol, sortOrder, filters));
    };

    handleSearch = (searchString) => {
        const {
            page, size, sortCol, sortOrder, transactionTypeIgnoreList, transactionTypes,
        } = this.props;
        this.fetchListOfTransactionInternal(
            page, size, sortCol, sortOrder, {
                typeIgnoreList: transactionTypeIgnoreList,
                types: this.getTransactionBasedOnPrivileges(transactionTypes),
                searchString,
            },
        );
    };

    handleClickTransaction = (e, id, uuid) => {
        const { dispatch, recentTransactions } = this.props;
        let menuDialog = null;
        let dialogProps = {};
        const transaction = recentTransactions.find((event) => {
            console.log('halskdfaslkdfhas', event);
            return event.uuid === uuid;
        });
        console.log('a88aea02-3bb0-4935-b6d4-ce3973d64374', id, uuid, transaction);
        switch (transaction.type) {
        case ERP_TRANSACTION_TYPES[0]: // purchase order
            menuDialog = PurchaseOrderDialog;
            dispatch(fetchPurchaseOrderRequest(transaction.targetId));
            dialogProps = {};
            break;
        case ERP_TRANSACTION_TYPES[1]: // purchase bill
            menuDialog = MakeBillDialog;
            dispatch(fetchAccountInvoiceById(transaction.targetId));
            dialogProps = {};
            break;
        case ERP_TRANSACTION_TYPES[4]: // account expense
            menuDialog = ExpenseDialog;
            dispatch(fetchAccountExpenseRequestById(transaction.targetId));
            dialogProps = {
                isSearch: true,
            };
            break;
        case ERP_TRANSACTION_TYPES[21]: // petty cash recoup expense
            menuDialog = PettyCashExpenseDialog;
            dialogProps = {
                expenseId: transaction.targetId,
            };
            break;
        case ERP_TRANSACTION_TYPES[5]: // Receive Stock
            console.log('0i-0asid-8-2qi0pk12as', transaction);
            menuDialog = RecieveStockViewDialog;
            dialogProps = {
                targetId: transaction.targetId,
            };
            break;
        case ERP_TRANSACTION_TYPES[7]: // Receive Stock
            menuDialog = CreditOrDebitMemoDialog;
            // dispatch(fetchAccountInvoiceById(transaction.targetId));
            dialogProps = {
                invoiceId: transaction.targetId,
            };
            break;
        case ERP_TRANSACTION_TYPES[9]: // Add Stock
            menuDialog = AddStockDialog;
            dialogProps = {
                targetId: transaction.targetId,
            };
            break;
        case ERP_TRANSACTION_TYPES[10]: // Bill Pay
            menuDialog = AccountVoucherDialog;
            dialogProps = {
                accountVoucherId: transaction.targetId,
            };
            break;
        case ERP_TRANSACTION_TYPES[11]: // Receive Payment
            menuDialog = AccountVoucherDialog;
            dialogProps = {
                accountVoucherId: transaction.targetId,
            };
            break;
        case ERP_TRANSACTION_TYPES[12]: // Fund Transfer
            menuDialog = TransferDialog;
            dialogProps = {
                accountMoveId: transaction.targetId,
                isView: true,
            };
            break;
        case ERP_TRANSACTION_TYPES[13]: // Inventory Adjustment
            menuDialog = InternalMovesDialog;
            dialogProps = {
                targetId: transaction.targetId,
                adjustInventory: true,
            };
            break;
        case ERP_TRANSACTION_TYPES[15]: // Internal Move
            menuDialog = InternalMovesDialog;
            dialogProps = {
                targetId: transaction.targetId,
            };
            break;
        case ERP_TRANSACTION_TYPES[16]: // Return Goods
            menuDialog = GoodReturnViewDialog;
            dialogProps = {
                targetId: transaction.targetId,
            };
            break;
        case ERP_TRANSACTION_TYPES[17]: // Salary Advance
            menuDialog = SalaryAdvanceDialog;
            dialogProps = {
                advanceId: transaction.targetId,
            };
            break;
        case ERP_TRANSACTION_TYPES[18]: // Petty Cash
            menuDialog = PettyCashDialog;
            dialogProps = {
                advanceId: transaction.targetId,
            };
            break;
        case ERP_TRANSACTION_TYPES[19]: // Prepaid Expense
            menuDialog = PrepaidExpenseDialog;
            dialogProps = {
                targetId: transaction.targetId,
            };
            break;
        case ERP_TRANSACTION_TYPES[20]: // Prepaid Expense
            menuDialog = CashCloseDialog;
            dialogProps = {
                cashCloseId: transaction.targetId,
            };
            break;
        case ERP_TRANSACTION_TYPES[22]: // PaidOnAccount
        case ERP_TRANSACTION_TYPES[23]: // PaidOnAccount
            menuDialog = PaidOnAccountDialog;
            dialogProps = {
                paidOnAccountId: transaction.targetId,
            };
            break;
        case ERP_TRANSACTION_TYPES[24]: // Stock Intent
            menuDialog = IntentDialog;
            dialogProps = {
                targetId: transaction.targetId,
            };
            break;
        case ERP_TRANSACTION_TYPES[25]: // Material Receive
            menuDialog = MaterialReceiveDialog;
            dialogProps = {
                targetId: transaction.targetId,
            };
            break;
        case ERP_TRANSACTION_TYPES[26]: // Prepaid Expense
            menuDialog = ConsolidatedCashCloseDialog;
            dialogProps = {
                consolidatedCashCloseId: transaction.targetId,
            };
            break;
        default:
        }
        this.setState({ MenuDialog: menuDialog, dialogProps });
        console.log('jk.bsdfkac askjdfbajks', transaction);
    };

    handlePageChange = (page, size) => {
        const {
            sortCol, sortOrder, transactionTypeIgnoreList, transactionTypes,
        } = this.props;
        this.fetchListOfTransactionInternal(page, size, sortCol, sortOrder, {
            typeIgnoreList: transactionTypeIgnoreList,
            types: transactionTypes,
        });
    };

    handleClose = () => {
        this.setState({ MenuDialog: null, dialogProps: {} });
    };

    render() {
        const {
            classes,
            recentTransactions,
            handleAdvancedSearch,
            filters,
            totalElements,
            label,
        } = this.props;
        const {
            page, size,
        } = this.props;
        const { MenuDialog, dialogProps, schema } = this.state;
        const recentTrans = recentTransactions.map(e => ({ ...e, displayType: ERP_TRANSACTION_TYPES_DISPLAY[e.type] }));
        return (
            <div style={{ width: '75em', minHeight: '25em' }}>
                <div className="p2" style={{ background: '#fafafa' }}>
                    <Grid container className={classes.heading}>
                        Search your transactions
                    </Grid>
                    <Grid container justify="center">
                        <Grid item lg={12} md={12} sm={12}>
                            <SearchField
                                textField
                                fullWidth
                                showSearchIcon
                                disableUnderline
                                label={label}
                                searchValue={getStringFromObject('searchString', filters)}
                                getDataBySearch={this.handleSearch}
                                backgroundColor="white"
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        test-id="advance-search"
                        container
                        onClick={handleAdvancedSearch}
                        className={classNames(classes.advancedText, 'mt-1')}
                        justify="flex-end"
                    >
                        Advanced Search
                    </Grid>
                </div>
                <div className="p2" style={{ maxHeight: '35em', overflow: 'auto' }}>
                    <Grid container className={classes.heading}>Recent Transactions</Grid>
                    {
                        isArrayValidAndNotEmpty(recentTransactions) ?
                            isObjectValidAndNotEmpty(schema) &&
                            <MaterialTable
                                schema={schema}
                                uniqueKey="uuid"
                                totalElements={totalElements}
                                onPageChange={this.handlePageChange}
                                onRowClick={this.handleClickTransaction}
                                page={page}
                                size={size}
                                data={recentTrans}
                                rowsPerPageOptions={[10, 25, 50, 100]}
                            />
                            :
                            'No recent transaction.'
                    }
                </div>
                {
                    (MenuDialog != null) &&
                        <MenuDialog
                            {...dialogProps}
                            open
                            handleClose={this.handleClose}
                        />
                }
            </div>
        );
    }
}

SearchPopover.propTypes = {
    classes: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    recentTransactions: PropTypes.array,
    handleAdvancedSearch: PropTypes.func,
    transactionTypeIgnoreList: PropTypes.array,
    transactionTypes: PropTypes.array,
    filters: PropTypes.object,
    page: PropTypes.number,
    totalElements: PropTypes.number,
    size: PropTypes.number,
    sortCol: PropTypes.string,
    sortOrder: PropTypes.string,
    label: PropTypes.string,
};

SearchPopover.defaultProps = {
    recentTransactions: [],
    transactionTypes: [],
    transactionTypeIgnoreList: [],
    filters: {},
    handleAdvancedSearch: () => {},
    page: PAGE,
    totalElements: 0,
    size: SIZE,
    sortCol: SORT_COL,
    sortOrder: SORT_ORDER_DESC,
    label: 'Purchase Orders, Stock Receipts, Stock move, Stock additions, Adjust Inventory',
};

const mapStateToProps = state => ({
    recentTransactions: getStringFromObject('transaction.list', state, []),
    filters: getStringFromObject('transaction.filters', state, {}),
    totalElements: getStringFromObject('transaction.totalElements', state, {}),
    page: getStringFromObject('transaction.page', state, PAGE),
    size: getStringFromObject('transaction.size', state, SIZE),
    sortCol: getStringFromObject('transaction.sortCol', state, SORT_COL),
    sortOrder: getStringFromObject('transaction.sortOrder', state, SORT_ORDER_DESC),
});

export default connect(mapStateToProps)(withStyles(style)(SearchPopover));
