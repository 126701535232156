import React from 'react';
import deepEquals from 'react-fast-compare';
import PropTypes from 'prop-types';
import cloneDeep from 'clone-deep';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton/IconButton';
import Divider from '@material-ui/core/Divider/Divider';
import Search from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import Chip from '@material-ui/core/Chip';

import OutlinedTextField from '../OutlinedTextField';
import PopoverFilter from '../GenericFilterComponent/PopoverFilter';
import {
    getTextFieldChangedValue,
    isArrayValidAndNotEmpty,

} from '../../constants/CommonUtil';
import { getFilterNameAndValueMap } from './GenericFilterComponentUtil';
import { getStringFromObject } from '../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

import DateRangePicker from '../FormFieldComponents/DateInput/DateRangePicker';

const MAX_NO_OF_CHARS_IN_FILTER_CHIP = 10;
const styles = theme => ({
    button: {
        backgroundColor: '#FAD403 !important',
        color: 'white',
        width: '3rem',
        height: '3rem',
        margin: theme.spacing.unit,
    },
    divider: {
        width: 1,
        height: 28,
        margin: 4,
    },
});

class GenericFilterWithSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            anchorEl: null,
            searchText: getStringFromObject('filterValues.searchText', props),
        };
    }

    componentWillReceiveProps(nextProps) {
        if (!deepEquals(nextProps.filterValues, this.props.filterValues)) {
            this.setState({ searchText: getStringFromObject('filterValues.searchText', nextProps, '') });
        }
    }

    onDeleteFilter = filterName => () => {
        const newFilterValues = cloneDeep(this.props.filterValues);
        delete newFilterValues[filterName];
        this.props.saveFilter(newFilterValues);
    };

    getFilteredValuesChips = () => {
        const {
            filterSchema,
            filterValues,
        } = this.props;

        // Installed version of material ui does'nt support text ellipsis
        const getEllipsisValue =
            (text, noOfChars) => (text && text.length > noOfChars ? `${text.substring(0, noOfChars)}...` : text);

        if (isObjectValidAndNotEmpty(filterValues) && isArrayValidAndNotEmpty(filterSchema)) {
            const filterAndValMap = getFilterNameAndValueMap(filterValues, filterSchema, true);
            console.log('filterAndValMapfilterAndValMap', filterAndValMap);
            if (isObjectValidAndNotEmpty(filterAndValMap)) {
                return Object.keys(filterAndValMap).map(aFilterKey => (
                    filterAndValMap[aFilterKey] &&
                    aFilterKey !== 'datePeriod' &&
                        <Chip
                            testId={aFilterKey}
                            key={aFilterKey}
                            className="m0-5"
                            onDelete={this.onDeleteFilter(aFilterKey)}
                            label={
                                `${getStringFromObject('label', filterAndValMap[aFilterKey]).toUpperCase()
                                }: ${getEllipsisValue(getStringFromObject(
                                    'value', filterAndValMap[aFilterKey] || '',
                                ), MAX_NO_OF_CHARS_IN_FILTER_CHIP)}`
                            }
                        />
                ));
            }
        }
        return null;
    };

    getDateRangeFilterOnTop = () => {
        const { filterSchema } = this.props;
        if (isArrayValidAndNotEmpty(filterSchema)) {
            const dateRangeFilter = filterSchema.find(aScheme => aScheme.type === 'dateRange');
            if (dateRangeFilter && dateRangeFilter.showOnTop) {
                return dateRangeFilter;
            }
        }
        return {};
    }


    handleClose = () => {
        this.setState({
            anchorEl: null,
            open: false,
        });
    };

    handleSearch = (value) => {
        const text = getTextFieldChangedValue(value) || '';
        this.setState({ searchText: text }, () => {
            console.log('asd-ad-a0sis', text, this.props.filterValues);
            if (!text) {
                this.handleApplyFilter(this.props.filterValues);
            }
        });
    };

    handleClickSearch = () => {
        this.handleApplyFilter(this.props.filterValues);
    };

    handleKeyPressSearch = (e) => {
        if ((e.keyCode === 13 || e.which === 13) && e.shiftKey === false) {
            this.handleApplyFilter(this.props.filterValues);
        }
    };

    handleApplyFilter = (filter) => {
        let filters = {};
        if (isObjectValidAndNotEmpty(filter)) {
            filters = { ...filter };
        }
        filters.searchText = this.state.searchText;
        console.log('asd90uad09aass', filters, filter);
        this.props.saveFilter(filters);
    };

    handleFilter = (event) => {
        this.setState({
            open: true,
            anchorEl: event.currentTarget,
        });
    };

    handleDateRangeSelect = name => (value) => {
        console.log('handleDateRangeSelect', name, value);
        const newFilterColumnValues = {
            ...this.props.filterValues,
            [name]: value,
        };
        this.handleApplyFilter(newFilterColumnValues);
    }

    render() {
        const {
            filterSchema,
            filterValues,
            classes,
            hideFilters,
            renderChips,
            disabled,
            startAdornment,
        } = this.props;
        const topLevelDateRange = this.getDateRangeFilterOnTop();
        const searchBarWidth = topLevelDateRange.showOnTop ? 9 : 12;
        console.log('asdj9asudasdas', { filterValues, filterSchema });
        const filterChips = this.getFilteredValuesChips();
        return (
            <Grid container alignItems="center" justify="space-between" spacing={16}>
                <Grid
                    item
                    lg={searchBarWidth}
                    sm={searchBarWidth}
                    md={searchBarWidth}
                >
                    <OutlinedTextField
                        id="search-header"
                        testId="search-header"
                        style={{ width: '90%' }}
                        placeholder={this.props.placeholder}
                        value={this.state.searchText}
                        onChange={this.handleSearch}
                        disabled={disabled}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        testId="clickSearch"
                                        onClick={this.handleClickSearch}
                                        disabled={disabled}
                                    >
                                        <Search />
                                    </IconButton>
                                    {
                                        !hideFilters &&
                                            <React.Fragment>
                                                <Divider className={classes.divider} />
                                                <IconButton
                                                    testId="filter-btn"
                                                    onClick={this.handleFilter}
                                                    disabled={disabled}
                                                >
                                                    <FilterListIcon />
                                                </IconButton>
                                            </React.Fragment>
                                    }
                                </InputAdornment>
                            ),
                            startAdornment: startAdornment ? (
                                <InputAdornment position="start">
                                    {startAdornment}
                                </InputAdornment>
                            ) : null,
                        }}
                        onKeyPress={this.handleKeyPressSearch}
                    />
                </Grid>
                {
                    topLevelDateRange.showOnTop && (
                        <Grid item sm={2}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateRangePicker
                                    onRangeSelect={this.handleDateRangeSelect(topLevelDateRange.name)}
                                    selectedValues={getStringFromObject(topLevelDateRange.name, filterValues)}
                                    options={topLevelDateRange.options}
                                    defaultOption={topLevelDateRange.defaultOption}
                                    label={topLevelDateRange.label}
                                    size="large"
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    )
                }
                {
                    filterChips && renderChips &&
                    <Grid item sm={12} md={12} lg={12}>
                        {filterChips}
                    </Grid>
                }
                {
                    this.state.open &&
                    <PopoverFilter
                        filterSchema={filterSchema}
                        anchorEl={this.state.anchorEl}
                        saveFilter={this.handleApplyFilter}
                        handleClose={this.handleClose}
                        filterValues={filterValues}
                    />
                }
            </Grid>
        );
    }
}

GenericFilterWithSearch.propTypes = {
    classes: PropTypes.object.isRequired,
    saveFilter: PropTypes.func,
    placeholder: PropTypes.string,
    filterValues: PropTypes.object,
    filterSchema: PropTypes.array,
    hideFilters: PropTypes.bool,
    renderChips: PropTypes.bool,
    disabled: PropTypes.bool,
    startAdornment: PropTypes.string,
};

GenericFilterWithSearch.defaultProps = {
    saveFilter: () => {},
    filterValues: {},
    filterSchema: [],
    placeholder: 'Search',
    hideFilters: false,
    renderChips: true,
    disabled: false,
    startAdornment: null,
};

export default withStyles(styles)(GenericFilterWithSearch);
