import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import cloneDeep from 'clone-deep';
import deepEqual from 'react-fast-compare';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Grid from '@material-ui/core/Grid/Grid';
import Close from '@material-ui/icons/Close';
import connect from 'react-redux/lib/connect/connect';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import { Field, withFormik } from 'formik';
import classNames from 'classnames';

import {
    apiCatchBlockFunction, getJsonPath,
    getUrlWithApiParams,
    isArrayValidAndNotEmpty,


} from '../../../constants/CommonUtil';
import {
    ACTION_HANDLERS,
    setBatchValuesOfProduct,
    setValuesOfProductIntoRow,
} from '../InternalMovesHandlers';
import API from '../../../constants/api';
import {
    mapStockIntentDtoToUiObject,
    mapStockIntentFromUiObject,
    UI_OBJECT,
} from '../../../mapper/StockIntentMapper';
import {
    createStockIntentRequest,
    fetchStockIntentRequest,
    clearStockIntentSelectState,
    markAsComplete,
} from '../../../redux/modules/stockIntent/stockIntent-actions';
import ActionButton from '../../ActionButton/ActionButton';
import SideLabelReadOnlyText from '../../FormFieldComponents/SideLabelReadOnlyText/SideLabelReadOnlyText';
import FormikReactSelectMaterial from '../../Formik/FieldComponents/FormikReactSelectMaterial';
import FormikTable from '../../Formik/FormikTable/FormikTable';
import {
    APPLICATION_CONFIG_URL,
    ROWS_PER_PAGE,
    STOCK_MANAGEMENT_PRIVILEGES,
} from '../../../constants/constants';
import { required } from '../../../constants/FormValidations';
import Spinner from '../../Spinner/Spinner';
import Print from '../../../containers/RegistrationAppComponents/PrintHTML/PrintHTML';
import FormikTextField from '../../Formik/FieldComponents/FormikTextField';
import { errorMessage } from '../../../redux/modules/message/message-actions';
import PrintPDF from '../../../containers/RegistrationAppComponents/PrintHTML/PrintPDF';
import { hideSpinner, showSpinner } from '../../../redux/modules/spinner/spinner';
import {
    IntentFields,
    // otherOperationsWhichRequireDestInput,
    operationsWhichRequireSourceInput,
    operationTypes,
} from './IntentUtil';
import DrugBarcodeEntry from './DrugBarcodeEntry';
import { getDateInYYYYMMDDFormat } from '../../../constants/DateUtil';
import PurchaseOrderDialog from '../PurchaseOrderDialog';
import InternalMovesDialog from '../InternalMovesDialog/InternalMovesDialog';
import { checkIfPrivilegeExistsForUser } from '../../../constants/privilegeChecker';
import { getIndexToAdd } from '../InternalMovesDialog/InternalMovesUtil';
import dialogComponentStyles from '../../DialogComponent/DialogComponentStyles';
import { getStringFromObject, setStringPropertyToObject } from '../../../constants/lodashUtils';
import { NumberOf } from '../../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const formName = 'stockIntentFormm';

class IntentDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            schema: null,
            loading: false,
            print: false,
            printBarcode: false,
            itemsToPrint: [],
            operationType: '',
            createPO: false,
            createStockMove: false,
            intentId: null,
        };
    }

    componentDidMount() {
        const { targetId, setValues } = this.props;
        if (!targetId) {
            setValues(cloneDeep(UI_OBJECT));
        }
        const jsonApi = getJsonPath('/StockManagement/Intent.json');
        this.getAllBatchesForPurchaseOrderInternal();
        fetch(jsonApi)
            .then(resp => resp.json())
            .then((json) => {
                console.log('josnschema', json);
                this.setState({
                    schema: json,
                });
            })
            .catch((error) => {
                console.error(`There has been a problem with your fetch operation:${error.message}`);
            });
    }

    componentWillReceiveProps(nextProps) {
        if (!deepEqual(nextProps.stockIntent, this.props.stockIntent)) {
            if (isArrayValidAndNotEmpty(nextProps.stockIntent)) {
                this.initializeForm(nextProps.stockIntent[0]);
                this.setState({
                    operationType: nextProps.stockIntent[0].type,
                });
            }
        }

        if (!deepEqual(nextProps.selectedStockIntent, this.props.selectedStockIntent)) {
            console.log('componentWillReceiveProps', nextProps);
            if (isObjectValidAndNotEmpty(nextProps.selectedStockIntent)) {
                this.initializeForm(nextProps.selectedStockIntent);
                this.setState({
                    operationType: nextProps.selectedStockIntent.type,
                });
            }
        }
        if (!deepEqual(nextProps.miscOps, this.props.miscOps)) {
            this.fetchMiscOperationHelper();
        }
        const oldOpType = this.getMiscOpType();
        const newOpType = this.getMiscOpType(nextProps);
        if (newOpType !== oldOpType) {
            const {
                setFieldValue,
            } = this.props;
            const {
                miscOpsHelper,
            } = this.state;
            if (newOpType) {
                const stockLocUuid = getStringFromObject(newOpType, miscOpsHelper);
                console.log('asja9sj-afj-asfa', stockLocUuid, newOpType, oldOpType);
                if (stockLocUuid) {
                    if (operationsWhichRequireSourceInput.includes(newOpType)) {
                        setFieldValue(IntentFields.DEST_LOC, {
                            uuid: stockLocUuid,
                        });
                        setFieldValue(IntentFields.SOURCE_LOC, null);
                    }
                    // else if (otherOperationsWhichRequireDestInput.includes(newOpType)) {
                    //     setFieldValue(IntentFields.SOURCE_LOC, {
                    //         uuid: stockLocUuid,
                    //     });
                    //     setFieldValue(IntentFields.DEST_LOC, null);
                    // }
                }
            } else {
                setFieldValue(IntentFields.DEST_LOC, null);
                setFieldValue(IntentFields.SOURCE_LOC, null);
            }
        }

        if (!deepEqual(nextProps.selectedStockIntent, this.props.selectedStockIntent)) {
            if (isObjectValidAndNotEmpty(nextProps.selectedStockIntent)) {
                this.initializeForm(nextProps.selectedStockIntent);
                this.setState({
                    operationType: nextProps.selectedStockIntent.type,
                });
            }
        }
    }

    componentWillUnmount() {
        this.props.dispatch(clearStockIntentSelectState());
    }

    onAddProductViaBarcode = async (productInfo, barcodeProductQty) => {
        console.log('asd0dasu90asdd', productInfo, barcodeProductQty);
        const {
            dispatch,
            values,
            setFieldValue,
            setFieldTouched,
        } = this.props;
        try {
            const operationType = getStringFromObject('operationType.key', values);
            const indexToAddTo = getIndexToAdd(values, 'products');

            const response = await axios.get(`${API.PRODUCT.GET_PRODUCT_BY_CODE}${productInfo.produceCode}&codeField=${productInfo.codeField}`);
            const productDetails = response.data;
            const rowValue = {};
            rowValue.product = productDetails;
            rowValue.uom = productDetails.erpUomDto;
            rowValue.taxes = productDetails.taxes || [];
            rowValue.batchWithQty = [];
            rowValue.allBatches = [];
            rowValue.quantity = NumberOf(barcodeProductQty);
            rowValue.isInternalLocation = false;
            console.log('datafrombarcode', response.data);
            console.log('datafrombarcode', values, operationType, indexToAddTo);

            // getting batch
            // const lastIndex = getStringFromObject('products.length', values);
            // if (index === (lastIndex - 1).toString()) {
            //     // Add row automatically..
            //     setFieldValue(`products.${lastIndex}`, EMPTY_ROW);
            // }
            let newRowValue = setValuesOfProductIntoRow(rowValue, { sourceLoc: values.requestLoc });
            const {
                locationUuid,
                productUuid,
            } = newRowValue;
            if (operationType === 'MATERIAL_WITHDRAWAL') {
                if (locationUuid) {
                    const response2 = await axios.get(API.PRODUCT.GET_BATCH_AND_QUANTITY, {
                        params: {
                            productUuid,
                            locationUuid,
                        },
                    });
                    newRowValue = setBatchValuesOfProduct(response2, newRowValue);
                }
                console.log('newRowValuenewRowValue', newRowValue);
                if (!isArrayValidAndNotEmpty(newRowValue.batchWithQty)) {
                    dispatch(errorMessage('This product has no batches at location'));
                    return;
                }
                const foundBatch = newRowValue.batchWithQty.find(aBatch => (
                    getStringFromObject('batch.value', aBatch) === productInfo.batchName
                ));
                // if (!isObjectValidAndNotEmpty(foundBatch)) {
                //     dispatch(errorMessage(`Batch not found with name ${productInfo.batchName} in location`));
                //     return;
                // }
                newRowValue.description = foundBatch;
                const expiryDateTimeInMillis = getStringFromObject('expiryDate', foundBatch);
                const availableQuantity = getStringFromObject('quantity', foundBatch);
                if (expiryDateTimeInMillis) {
                    newRowValue.expiryDate = getDateInYYYYMMDDFormat(new Date(expiryDateTimeInMillis));
                }
                if (availableQuantity) {
                    newRowValue.availableQuantity = availableQuantity;
                }
                // if (NumberOf(availableQuantity) < NumberOf(barcodeProductQty)) {
                //     dispatch(errorMessage('This batch does not have enough quantity in this location'));
                //     return;
                // }
            }
            console.log('asdhasdasd9asd-09asd-s', newRowValue, indexToAddTo, rowValue);
            setFieldValue(`products[${indexToAddTo}]`, newRowValue);
            setFieldTouched(`products[${indexToAddTo}].product`);
        } catch (e) {
            apiCatchBlockFunction(e, dispatch);
        }
    };

    getDialogHeader = () => {
        const {
            adjustInventory,
            miscOps,
            values,
        } = this.props;
        if (miscOps) {
            return getStringFromObject(`${IntentFields.OPERATION_TYPE}.value`, values) || 'Misc Operations';
        } else if (adjustInventory) {
            return 'Adjust Inventory';
        }
        return 'Intent';
    };

    getMiscOpType = (props = this.props) => {
        const {
            values,
        } = props;
        return getStringFromObject(`${IntentFields.OPERATION_TYPE}.key`, values);
    };

    getMiscOpsFields = () => {
        const {
            values,
            setFieldValue,
        } = this.props;
        console.log('kkiiasdjoajdsao', values);
        const opType = this.getMiscOpType();
        return (
            <Grid item lg={3} md={3} sm={6}>
                <Grid container style={{ padding: '1em' }}>
                    <Field
                        name={IntentFields.OPERATION_TYPE}
                        component={FormikReactSelectMaterial}
                        dataSourceConfig={{
                            text: 'value',
                            value: 'key',
                        }}
                        label="Operation Type"
                        required
                        isDisabled={this.isConfirmed()}
                        placeholder="Operation Type"
                        setFieldValue={setFieldValue}
                        options={Object.values(operationTypes)}
                        validate={required}
                    />
                </Grid>
                <Grid container style={{ padding: '1em' }}>
                    {
                        operationsWhichRequireSourceInput.includes(opType) &&
                        this.getSourceLocationField()
                    }
                    {/* {
                        otherOperationsWhichRequireDestInput.includes(opType) &&
                        this.getDestLocationField()
                    } */}
                </Grid>
            </Grid>
        );
    };

    getSchema = () => {
        const { schema } = this.state;
        let tableSchema = cloneDeep(schema);
        if (isObjectValidAndNotEmpty(schema)) {
            console.log('confirmedschema not', schema);
            if (this.isConfirmed()) {
                console.log('confirmedschema', tableSchema);
                tableSchema = {
                    ...tableSchema,
                    tableCells: [
                        ...(tableSchema.tableCells),
                        {
                            label: 'Quantity Received',
                            noLabel: true,
                            fieldType: 'textField',
                            type: 'number',
                            id: 'quantity',
                            name: 'quantityReceived',
                            headerStyle: {
                                width: '20%',
                                fontSize: '14px',
                            },
                        },

                    ],
                };
            }
            tableSchema = {
                ...tableSchema,
                tableCells: [
                    ...(tableSchema.tableCells),
                    {
                        label: 'Current Quantity',
                        noLabel: true,
                        fieldType: 'reduxFormText',
                        type: 'number',
                        id: 'currentStockQuantity',
                        name: 'currentStockQuantity',
                        headerStyle: {
                            width: '30%',
                            fontSize: '14px',
                        },
                    },

                ],
            };
        }
        return tableSchema;
    };

    getSourceLocationField = () => {
        const {
            setFieldValue,
        } = this.props;
        return (
            <Field
                name={IntentFields.REQUEST_LOC}
                component={FormikReactSelectMaterial}
                dataSourceConfig={{
                    text: 'name',
                    value: 'uuid',
                }}
                label="Location"
                isDisabled={this.isConfirmed()}
                required
                placeholder="Location"
                setFieldValue={setFieldValue}
                autocomplete
                dataSourceApi={API.SEARCH.STOCK_LOCATION}
                validate={required}
                testId="location"
            />
        );
    };

    getAllBatchesForPurchaseOrderInternal = (id) => {
        const {
            dispatch, targetId,
        } = this.props;
        let api = '';
        if (targetId) {
            if (NumberOf(targetId) === 0) {
                api = `${API.STOCK_INTENT.FETCH_STOCK_INTENT_BY_UUID}${targetId}`;
            } else {
                api = `${API.STOCK_INTENT.FETCH_STOCK_INTENT_BY_ID}${targetId}`;
            }
            dispatch(fetchStockIntentRequest(api));
        } else if (id) {
            api = `${API.STOCK_INTENT.FETCH_STOCK_INTENT_BY_UUID}${id}`;
            dispatch(fetchStockIntentRequest(api));
        }
        console.log('getAllBatchesForPurchaseOrderInternal', NumberOf(targetId), ' ==', targetId);

        console.log('kljasdflaksjdfh', this.props);
    };

    isConfirmed = () => {
        const {
            values,
        } = this.props;
        return ['DONE', 'CONFIRMED', 'COMPLETE'].indexOf(getStringFromObject('status', values)) !== -1 && values.uuid;
    };

    resetLoading = (loading = false) => this.setState({ loading: Boolean(loading) });

    initializeForm = (formValues) => {
        const { dispatch, setValues } = this.props;
        this.setState({ loading: true }, () => {
            const uiObjectPromise = mapStockIntentDtoToUiObject(formValues);
            uiObjectPromise.then((response) => {
                console.log('mapStockIntentDtoToUiObject response', response);

                setValues(response);
                this.resetLoading();
            }).catch((error) => {
                console.log('mapStockIntentDtoToUiObject error', error);
                apiCatchBlockFunction(error, dispatch);
                this.resetLoading();
            });
        });
    };

    handleCreateNew = () => {
        this.initializeForm({ ...UI_OBJECT });
    }

    openCreatePO = (id) => {
        this.setState({
            createPO: true,
            intentId: id,
        });
    }
    openCreateStockMove = (id) => {
        this.setState({
            createStockMove: true,
            intentId: id,
        });
    }
    closeDialog = () => {
        const { intentId } = this.state;
        this.getAllBatchesForPurchaseOrderInternal(intentId);
        this.setState({
            createStockMove: false,
            intentId: null,
            createPO: false,
        });
    }

    handleSave = () => {
        const { adjustInventory, setFieldValue } = this.props;
        setFieldValue('operation', adjustInventory ? 'STOCK_ADJUSTMENT' : 'INTERNAL');
        setFieldValue('status', 'DRAFT');
        this.props.submitForm();
        // if (adjustInventory) {
        //     dispatch(adjustInventoryRequest(mapStockIntentFromUiObject(formValues, 'draft', true)));
        // } else {
        //     dispatch(createInternalMoveRequest(mapStockIntentFromUiObject(formValues, 'draft')));
        // }
    };
    handleMarkAsComplete = () => {
        const { setFieldValue } = this.props;
        setFieldValue('status', 'COMPLETED');
        this.props.submitForm();
    };

    handleConfirm = () => {
        const { adjustInventory, setFieldValue } = this.props;
        setFieldValue('operation', adjustInventory ? 'STOCK_ADJUSTMENT' : 'INTERNAL');
        setFieldValue('status', 'CONFIRMED');
        this.props.submitForm();
        // const { dispatch, handleClose } = this.props;
        // if (adjustInventory) {
        //     dispatch(adjustInventoryRequest(
        //         mapStockIntentFromUiObject(formValues, 'done', true), handleClose));
        // } else {
        // dispatch(createStockIntentRequest(mapStockIntentFromUiObject(formValues, 'done'), handleClose));
        // }
    };

    handlePrint = () => {
        this.setState(prev => ({
            print: !prev.print,
        }));
    };

    handleSelectBatchOption = async (action, index) => {
        const itemsToPrint = [];
        const { dispatch, values } = this.props;
        const itemUuid = getStringFromObject(`products.[${index}].uuid`, values);
        let type = '';
        console.log('vaidadfnaldfa', getStringFromObject(`products.[${index}]`, values), action);
        const quantity = NumberOf(getStringFromObject(`products.[${index}].quantity`, values));
        if (action === 'printProductBarcode') {
            type = 'STOCK_MOVE_PRODUCT';
        } else if (action === 'printBatchBarcode') {
            type = 'STOCK_MOVE_BATCH';
        }
        if (itemUuid && type) {
            try {
                dispatch(showSpinner());
                const response = await axios.get(getUrlWithApiParams(API.BATCHES.PRINT, { itemUuid, quantity, type }));
                if (isObjectValidAndNotEmpty(response.data)) {
                    const failureReason = getStringFromObject('failureReason', response.data);
                    if (failureReason) {
                        dispatch(errorMessage(failureReason));
                    } else {
                        const token = getStringFromObject('downloadToken', response.data);
                        itemsToPrint.push({
                            url: `${API.FILE.DOWNLOAD_PDF_WITH_TOKEN}${token}`,
                            name: 'inventoryProductPrint',
                        });
                    }
                }
                // dispatch(hideSpinner());
            } catch (e) {
                apiCatchBlockFunction(e, dispatch);
            } finally {
                dispatch(hideSpinner());
            }
        }
        this.setState(prevState => ({
            printBarcode: !prevState.printBarcode,
            itemsToPrint,
        }));
    };

    render() {
        const {
            open,
            handleClose,
            classes,
            handleSubmit,
            values,
            // adjustInventory,
            company,
            miscOps,
            setFieldValue,
            barcodeParser,
        } = this.props;
        const {
            schema,
            loading,
            print,
            printBarcode,
            itemsToPrint,
            operationType,
            createPO,
            createStockMove,
            intentId,
        } = this.state;
        const tableSchema = cloneDeep(schema);
        console.log('operationType', operationType);
        // const opType = this.getMiscOpType();
        // if (otherOperationsWhichRequireDestInput.includes(opType)) {
        //     setStringPropertyToObject('tableCells[1].creatable', tableSchema, true);
        //     setStringPropertyToObject('tableCells[8].fieldType', tableSchema, 'textField');
        // }
        const isConfirmed = this.isConfirmed();
        const createDate = getStringFromObject('createDate', values);
        let receivedBy = getStringFromObject('receivedBy', values, '');
        const createdBy = getStringFromObject('createdBy', values);
        const refNo = getStringFromObject('refNo', values);
        console.log('createdby', createdBy, values);
        if (receivedBy === '') {
            receivedBy = 'Automatic';
        }
        console.log('jklhlkjsadfklj', values, miscOps);
        const printData = {
            ...values,
            company,
            createDate,
            receivedBy,
            name: getStringFromObject('name', values, ''),
        };
        console.log('ddddddddjkhdaskjdf', printData);
        return (
            <React.Fragment>
                <form onSubmit={handleSubmit}>
                    <Dialog
                        open={open}
                        fullScreen
                        classes={{
                            paper: classes.paper,
                        }}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle disableTypography id="form-dialog-title" className={classes.title}>
                            <Grid container justify="space-between">
                                <div className={classes.header}>
                                    {
                                        this.getDialogHeader()
                                    }
                                </div>
                                <Close
                                    onClick={handleClose}
                                    className={classNames(classes.closeIcon, 'cursor-pointer')}
                                    test-id="intent-dialog-close"
                                />
                            </Grid>
                        </DialogTitle>
                        <DialogContent style={{ padding: '0' }}>
                            <div style={{ background: '#fff', padding: '1em 2em 2em' }}>
                                <Grid container spacing={16} justify="space-between">
                                    {
                                        <Grid item lg={3} md={3} sm={6}>
                                            <Grid container style={{ padding: '1em' }}>
                                                {this.getSourceLocationField()}
                                            </Grid>
                                            <Grid container style={{ padding: '1em' }}>
                                                <Field
                                                    name={IntentFields.OPERATION_TYPE}
                                                    component={FormikReactSelectMaterial}
                                                    dataSourceConfig={{
                                                        text: 'value',
                                                        value: 'key',
                                                    }}
                                                    label="Operation Type"
                                                    required
                                                    isDisabled={this.isConfirmed()}
                                                    placeholder="Operation Type"
                                                    setFieldValue={setFieldValue}
                                                    options={Object.values(operationTypes)}
                                                    validate={required}
                                                    testId="operation-type"
                                                />
                                            </Grid>
                                        </Grid>
                                    }
                                    <Grid item style={{ padding: '2em' }}>
                                        <Grid container direction="column" spacing={16}>
                                            {
                                                createDate &&
                                                <Grid item>

                                                    <SideLabelReadOnlyText
                                                        input={{ value: createDate }}
                                                        label="Date Created :"
                                                        classes={classes}
                                                    />

                                                </Grid>
                                            }
                                            {
                                                isObjectValidAndNotEmpty(createdBy) && (
                                                    <Grid item>

                                                        <SideLabelReadOnlyText
                                                            input={{ value: getStringFromObject('value', createdBy) }}
                                                            label="Created By :"
                                                            classes={classes}
                                                        />

                                                    </Grid>
                                                )
                                            }
                                            {
                                                refNo && (
                                                    <Grid item>

                                                        <SideLabelReadOnlyText
                                                            input={{ value: refNo }}
                                                            label="Ref No: :"
                                                            classes={classes}
                                                        />

                                                    </Grid>
                                                )
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {
                                    values.requestLoc && !isConfirmed &&
                                    <DrugBarcodeEntry
                                        dispatch={this.props.dispatch}
                                        onConfirmQuantity={this.onAddProductViaBarcode}
                                        barcodeParser={barcodeParser}
                                    />
                                }
                                <Grid container className="mt-2">
                                    {
                                        values.requestLoc && isObjectValidAndNotEmpty(tableSchema) &&
                                        <FormikTable
                                            fieldName={IntentFields.PRODUCTS}
                                            isEditable={!isConfirmed}
                                            actionHandlers={ACTION_HANDLERS}
                                            dispatch={this.props.dispatch}
                                            styles={{ minHeight: '20em' }}
                                            {...(this.getSchema())}
                                            tableRoot={{ minHeight: '20em', overflow: 'visible' }}
                                            actionParams={{
                                                sourceLoc: values.requestLoc,
                                            }}
                                            rowsPerPageOptions={ROWS_PER_PAGE}
                                            handleSelectAction={this.handleSelectBatchOption}
                                        />
                                    }
                                </Grid>
                                {
                                    values.requestLoc &&
                                    <Grid container className="mt-2" justify="space-between">
                                        <Grid item lg={3} md={3} sm={4}>
                                            <Field
                                                name={IntentFields.NOTES}
                                                component={FormikTextField}
                                                label="Memo"
                                                multiline
                                                rows={5}
                                                rowsMax={5}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={isConfirmed}
                                            />
                                        </Grid>
                                    </Grid>
                                }
                            </div>
                        </DialogContent>
                        <DialogActions style={{ padding: '12px' }}>
                            {
                                values.requestLoc && !isConfirmed &&
                                <div>
                                    <ActionButton
                                        testId="stock-move-misc-save"
                                        disableRipple
                                        disabled={isConfirmed}
                                        onClick={this.handleSave}
                                    >
                                        Save
                                    </ActionButton>
                                    <ActionButton
                                        testId="stock-move-misc-confirm"
                                        disableRipple
                                        disabled={isConfirmed}
                                        className="ml-1"
                                        onClick={this.handleConfirm}
                                    >
                                        Confirm
                                    </ActionButton>
                                </div>
                            }
                            {
                                isConfirmed && getStringFromObject('status', values) !== 'COMPLETE' &&
                                <div>
                                    <ActionButton
                                        test-id="stock-move-misc-adjust-print"
                                        disableRipple
                                        onClick={this.handleMarkAsComplete}
                                    >
                                        Mark as Complete
                                    </ActionButton>
                                    {
                                        (
                                            checkIfPrivilegeExistsForUser(STOCK_MANAGEMENT_PRIVILEGES.internalMove)
                                        ) &&
                                        <ActionButton
                                            className="ml-1"
                                            test-id="stock-move-misc-adjust-print"
                                            disableRipple
                                            onClick={() => this.openCreateStockMove(getStringFromObject('uuid', values))}
                                        >
                                            Create StockMove.
                                        </ActionButton>
                                    }
                                    {
                                        (
                                            checkIfPrivilegeExistsForUser(STOCK_MANAGEMENT_PRIVILEGES.purchaseOrder)
                                        ) &&

                                        <ActionButton
                                            className="ml-1"
                                            test-id="stock-move-misc-adjust-print"
                                            disableRipple
                                            onClick={() => this.openCreatePO(getStringFromObject('uuid', values))}
                                        >
                                            Create P.O.
                                        </ActionButton>
                                    }
                                </div>
                            }
                            {
                                refNo &&
                                <div>
                                    <ActionButton
                                        className="ml-1"
                                        test-id="stock-move-misc-adjust-print"
                                        disableRipple
                                        onClick={this.handlePrint}
                                    >
                                        Print
                                    </ActionButton>
                                    <ActionButton
                                        className="ml-1"
                                        test-id="stock-move-misc-adjust-new"
                                        disableRipple
                                        onClick={this.handleCreateNew}
                                    >
                                        New
                                    </ActionButton>
                                </div>
                            }
                        </DialogActions>
                    </Dialog>
                </form>
                <Print
                    data={printData}
                    print={print}
                    url={`${APPLICATION_CONFIG_URL}/HtmlPrint/StockManagement/StockIntent.html`}
                />
                {
                    loading &&
                    <Spinner
                        canShow
                    />
                }
                <PrintPDF
                    print={printBarcode}
                    itemsToPrint={itemsToPrint}
                />
                {
                    createPO && intentId && <PurchaseOrderDialog
                        open
                        intentUuid={intentId}
                        handleClose={this.closeDialog}
                    />
                }
                {
                    createStockMove && intentId && <InternalMovesDialog
                        open
                        intentConv
                        intentUuid={intentId}
                        handleClose={this.closeDialog}
                    />

                }
            </React.Fragment>
        );
    }
}

IntentDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    dispatch: PropTypes.func,
    handleClose: PropTypes.func.isRequired,
    values: PropTypes.object,
    stockIntent: PropTypes.array,
    selectedStockIntent: PropTypes.object,
    open: PropTypes.bool.isRequired,
    createPO: PropTypes.func,
    createStockMove: PropTypes.func,

    targetId: PropTypes.number,
    adjustInventory: PropTypes.bool,
    miscOps: PropTypes.bool,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    setValues: PropTypes.func.isRequired,
    company: PropTypes.string.isRequired,
    createdBy: PropTypes.string.isRequired,
    barcodeParser: PropTypes.string,
};

IntentDialog.defaultProps = {
    dispatch: () => { },
    createPO: () => { console.log('createPO not a valid function'); },
    createStockMove: () => { console.log('createStockMove not a valid function'); },
    values: {},
    stockIntent: [],
    selectedStockIntent: {},
    targetId: null,
    adjustInventory: false,
    miscOps: false,
    barcodeParser: '',
};

const mapStateToProps = (state, props) => {
    console.log('s');
    return ({
        stockIntent: getStringFromObject('stockIntentReducer.stockIntent', state, []),
        selectedStockIntent: getStringFromObject('stockIntentReducer.selected', state, {}),
        miscOps: (
            props.miscOps ||
            (!!getStringFromObject('stockIntentReducer.selected.miscOperationType', state)) ||
            (!!getStringFromObject('stockMove.stockMovesByPurchaseOrder[0].miscOperationType', state))
        ),
        company: getStringFromObject('appConfiguration.companyName', state),
        barcodeParser: getStringFromObject('appConfiguration.barcodeParser', state),
    });
};

const handleSubmitForm = (values, { props, ...formikBag }) => {
    console.log('fandfjkajghajkgbfajkg', values, formikBag);
    const dispatch = getStringFromObject('dispatch', props);
    console.log('fandfjkajghajkgbfajkgss', values, formikBag, values.status === 'DRAFT');
    if (isArrayValidAndNotEmpty(values.products)) {
        if (getStringFromObject('status', values) === 'COMPLETED') {
            const api = `${API.STOCK_INTENT.MARK_AS_COMPLTE}${getStringFromObject('uuid', values)}`;
            console.log('handleMarkAsCompletevaluesSubmit', getStringFromObject('status', values) === 'COMPLETED', values);
            dispatch(markAsComplete(api));
        } else {
            dispatch(createStockIntentRequest(
                mapStockIntentFromUiObject(values, values.status),
            ));
        }
    } else {
        dispatch(errorMessage('There are no items'));
    }
};

const validate = (values) => {
    let errors = {};
    const products = getStringFromObject('products', values, []);
    if (isArrayValidAndNotEmpty(products)) {
        products.forEach((rowValue, index) => {
            console.log('intentRow values', rowValue);
            if (isObjectValidAndNotEmpty(rowValue)) {
                if (rowValue && rowValue.product.uuid) {
                    const quantity = getStringFromObject('quantity', rowValue, 0);
                    if (
                        (Number(quantity) < 1)
                    ) {
                        errors = setStringPropertyToObject(`products.${index}.quantity`,
                            errors, 'Can not be less than 1');
                    }
                }
            }
        });
    }
    return errors;
};

export default connect(mapStateToProps)(withFormik({
    mapPropsToValues: () => cloneDeep(UI_OBJECT),
    validate,
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: false,
    displayName: formName,
    handleSubmit: handleSubmitForm,
})(withStyles(dialogComponentStyles)(IntentDialog)));
