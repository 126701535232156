import { getStringFromObject } from '../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../constants/nullCheckUtils';

export const mapSupplierFromUiObj = (obj) => {
    const partner = {
        ...obj,
        name: getStringFromObject('name', obj, ''),
        bank: getStringFromObject('bank', obj, null) || null,
        company: getStringFromObject('isCompany', obj, false),
        jobPosition: getStringFromObject('jobPosition', obj, ''),
        street: getStringFromObject('street', obj, ''),
        city: getStringFromObject('cityTown', obj, ''),
        state: getStringFromObject('state', obj, null),
        zipCode: getStringFromObject('pinCode', obj, ''),
        country: getStringFromObject('country', obj, null),
        email: getStringFromObject('email', obj, ''),
        phone: getStringFromObject('phone', obj, ''),
        mobile: getStringFromObject('mobile', obj, ''),
        fax: getStringFromObject('fax', obj, ''),
        website: getStringFromObject('website', obj, ''),
        title: getStringFromObject('title', obj, null),
        paymentTerms: getStringFromObject('paymentTerms', obj, null),
        supplier: true,
        active: getStringFromObject('active', obj, false),
        uuid: getStringFromObject('uuid', obj, null),
        comment: getStringFromObject('comment', obj, ''),
    };
    const companyName = getStringFromObject('companyName', obj, null);
    if (isObjectValidAndNotEmpty(companyName) && companyName.key) {
        partner.companyName = companyName;
    } else {
        partner.companyName = null;
    }
    const subCompany = getStringFromObject('subCompany', obj, null);
    if (isObjectValidAndNotEmpty(subCompany)) {
        partner.subCompany = subCompany.key;
    }
    if (isObjectValidAndNotEmpty(obj.accountReceivable)) {
        partner.accReceivables = {
            key: getStringFromObject('accountReceivable.id', obj) || null,
            value: getStringFromObject('accountReceivable.value', obj) || '',
        };
    }
    if (isObjectValidAndNotEmpty(obj.accountPayable)) {
        partner.accPayables = {
            key: getStringFromObject('accountPayable.id', obj) || null,
            value: getStringFromObject('accountPayable.value', obj) || '',
        };
    }

    return partner;
};

export const mapUiObjFromSupplier = obj => ({
    ...obj,
    name: getStringFromObject('name', obj, ''),
    isCompany: getStringFromObject('company', obj, false),
    companyName: getStringFromObject('companyName', obj, null),
    jobPosition: getStringFromObject('jobPosition', obj, ''),
    street: getStringFromObject('street', obj, ''),
    cityTown: getStringFromObject('city', obj, ''),
    state: getStringFromObject('state', obj, null),
    pinCode: getStringFromObject('zipCode', obj, ''),
    country: getStringFromObject('country', obj, null),
    email: getStringFromObject('email', obj, ''),
    phone: getStringFromObject('phone', obj, ''),
    mobile: getStringFromObject('mobile', obj, ''),
    fax: getStringFromObject('fax', obj, ''),
    website: getStringFromObject('website', obj, ''),
    title: getStringFromObject('title', obj, null),
    accountReceivable: getStringFromObject('accReceivables', obj, null) || null,
    accountPayable: getStringFromObject('accPayables', obj, null) || null,
    paymentTerms: getStringFromObject('paymentTerms', obj, null),
    supplier: true,
    uuid: getStringFromObject('uuid', obj, null),
    active: getStringFromObject('active', obj, true),
    comment: getStringFromObject('comment', obj, ''),
    subCompany:
        getStringFromObject('subCompany', obj) ?
            { key: getStringFromObject('subCompany', obj), value: getStringFromObject('subCompany', obj) } :
            null,
});
