import { APPLICATION_CONFIG_URL } from './constants';
import { getJsonPath } from './CommonUtil';

const serverContext = '/nuacare-core';
const queueContext = '/nuacare-queue-mgmt';
const insuranceClientContext = '/nuacare-insurance-client';

const api = {
    HOLD_AND_RECALL: {
        PUSH: `${serverContext}/rest/nuacare/v1/core/holdAndRecall/push?patientUuid=`,
        POP: `${serverContext}/rest/nuacare/v1/core/holdAndRecall/pop`,
        PAGES: `${serverContext}/rest/nuacare/v1/core/holdAndRecall/findAll`,
    },
    BILLING_HISTORY: `${serverContext}/rest/nuacare/v1/accountInvoice/billingHistory`,
    RESET_DB_FOR_PICKINGS: `${serverContext}/rest/nuacare/v1/core/misc/resetMovesForPickings`,
    GET_RECENT_RESET_JOBS: `${serverContext}/rest/nuacare/v1/core/misc/getRecentResetJobs`,
    GET_DYNAMIC_PROPERTY: `${serverContext}/web/nuacare/v1/commons/getDynamicProp?propName=`,
    GET_CLAIM_PREFIX: `${serverContext}/web/nuacare/v1/commons/getClaimPrefix`,
    UPDATE_APPLICATION_NUMBER: `${serverContext}/web/nuacare/v1/common/updateApplicationVersionNumber`,
    ROOM_DEPARTMENT_MAPPING: {
        LIST:
            `${serverContext}/rest/nuacare/v1/erp-service/departmentCategoryMapping/list`,
        CREATE:
            `${serverContext}/rest/nuacare/v1/erp-service/departmentCategoryMapping/createOrUpdate`,
        DELETE:
            `${serverContext}/rest/nuacare/v1/erp-service/departmentCategoryMapping/delete?id=`,
    },
    GET_SERVER_TIME: `${serverContext}/rest/nuacare/v1/documents/printdata/getCurrentServerTime`,
    COMPLETE_BILLING_HISTORY_PRINT:
        `${serverContext}/rest/nuacare/v1/erp-service/erpReport/patientPayments`,
    BILLING_HISTORY_FOR_VISIT: `${serverContext}/rest/nuacare/v1/accountInvoice/billingHistoryOfVisit`,
    MARK_INVOICE_LINE_AS_CANCELLED:
        `${serverContext}/rest/nuacare/v1/accountInvoice/markInvoiceLineAsCancelled?invoiceLineUuid=`,
    GET_PRODUCT_BY_CODE: `${serverContext}/rest/nuacare/v1/registration/registrationProductsByCode`,
    GET_PRODUCT_BY_CODE_FOR_ORDER: `${serverContext}/rest/nuacare/v1/registration/registrationProductsByCodeForOrder`,
    CHECK_DISCOUNT_VALIDITY: `${serverContext}/rest/nuacare/v1/registration/isDiscountCodeValid?discountCode=`,
    FETCH_PRODUCT_FOR_PROVIDER:
        `${serverContext}/rest/nuacare/v1/registration/getBillableProductForProvider?providerUuid=`,
    REFETCH_PRODUCTS_WITH_IDS:
        `${serverContext}/rest/nuacare/v1/registration/findProductsWithIds?personUuid=`,
    SEARCH_PRODUCTS:
        `${serverContext}/rest/nuacare/v1/registration/registrationProductsSearch`,
    PRODUCT_COVERAGE:
        `${serverContext}/rest/nuacare/v1/registration/findProductCoverageDTO`,
    PRODUCT_COVERAGE_DTOS:
        `${serverContext}/rest/nuacare/v1/registration/findProductCoverageDTOs`,
    GET_PRODUCT_WITH_COVERAGE:
        `${serverContext}/rest/nuacare/v1/insurance/getProductWithCoverageForPatient`,
    FETCH_LAST_N_VISIT_INVOICES:
        `${serverContext}/rest/nuacare/v1/registration/getInvoicesOfLastNVisits`,
    GET_UOMS: `${serverContext}/rest/nuacare/v1/product/uom/suggestion?name=`,
    SEARCH_DISCOUNT_HEADS: `${serverContext}/rest/nuacare/v1/registration/searchDiscountHeaders?searchString=`,
    GET_TOKEN_NUMBER_FOR_PATIENT: `${serverContext}/rest/nuacare/v1/queue/token`,
    TRIGGER_OF_CHART_OF_ACCOUNTS_CONSOLIDATION: `${serverContext}/rest/nuacare/v1/jobTrigger?type=regenerateChartOfAccountsData`,
    REGISTRATION: {
        GET_MATCHING_INSURANCE_DETAILS:
            `${serverContext}/rest/nuacare/v1/registration/getMatchingInsuranceDetails`,
    },
    PATIENT: {
        LIST_PATIENTS_WITH_APPOINTMENTS_TODAY: `${serverContext}/rest/nuacare/v1/appointment`,
        PAID_APPOINTMENTS:
            `${serverContext}/rest/nuacare/v1/appointment/getPaidAppointmentsForPatient?patientUuid=`,
        REBUILD_INDEX: `${serverContext}/rest/nuacare/v1/search/updatePatientIndex/`,
        EVICT_FROM_CACHE:
            `${serverContext}/rest/nuacare/v1/patient//evictPatientFromCache?patientUuid=`,
        GET_PAYER_METADA: `${serverContext}/rest/nuacare/v1/registration/getPatientPaymentMetadata?patientUuid=`,
        LAST_VISIT_EPISODE_ATTRIBUTES:
            `${serverContext}/rest/nuacare/v1/registration/getLastVisitEpisodeAttributes?visitUuid=`,
        COUNTS_OF_APPOINTMENTS_AND_EMERGENCY_CASES:
            `${serverContext}/rest/nuacare/v1/appointment/getCountsOfAppointmentsAndEmergencyCases`,
        LIST_ALL_PATIENTS: `${serverContext}/rest/nuacare/v1/search/personDetails`,
        SEARCH_PATIENTS_WITH_APPOINTMENT_DETAILS:
            `${serverContext}/rest/nuacare/v1/search/personDetailsWithAppointmentDetails`,
        ADD: `${serverContext}/rest/nuacare/v1/registration/register`,
        CREATE_INS_PATIENT: `${serverContext}/rest/nuacare/v1/registration/registerPatientAndCreateInsurancePolicyAndScheme`,
        REMOVE_REG_DOC: `${serverContext}/rest/nuacare/v1/registration/removeRegistrationUploadedDocument?registrationDocumentUuid=`,
        SAVE_REG_DOCS: `${serverContext}/rest/nuacare/v1/registration/saveRegistrationDocuments?patientUuid=`,
        UPDATE: `${serverContext}/rest/nuacare/v1/registration?patientUuid=`,
        FETCH: `${serverContext}/rest/nuacare/v1/registration/getPatientDetails?patientUuid=`,
        COMPLETE_PATIENT_DETAILS:
            `${serverContext}/rest/nuacare/v1/patient/getCompletePatientDetailsDtoFromPatient?patientUuid=`,
        PATIENT_REGISTRATION_DETAILS:
            `${serverContext}/rest/nuacare/v1/patient/getCompletePatientDetails?patientUuid=`,
        GET_UNINVOICED_ORDERS_OF_PATIENT:
            `${serverContext}/rest/nuacare/v1/registration/getOldOrdersOfPatient`,
        GET_UNINVOICED_ORDERS_OF_PATIENT_ACROSS_EPISODES:
            `${serverContext}/rest/nuacare/v1/registration/getOldOrdersOfPatientOfAllEpisodes`,
        CHECK_IF_UNINVOICED_ORDERS_EXISTS:
            `${serverContext}/rest/nuacare/v1/registration/checkIfOldOrdersOfPatientOfAllEpisodesExists`,
        GET_PATIENT_REMARK:
            `${serverContext}/rest/nuacare/v1/patient/getPatientRemark?patientUuid=`,
        GET_BLOCK_DETAILS:
            `${serverContext}/rest/nuacare/v1/patient/getBlockDetails?patientUuid=`,
        BLOCK_PATIENT:
            `${serverContext}/rest/nuacare/v1/patient/blockPatient?patientUuid=`,
        UPDATE_PATIENT_REMARK:
            `${serverContext}/rest/nuacare/v1/patient/updatePatientRemark?patientUuid=`,
        VALIDATE_SECONDARY_IDENTIFIER:
            `${serverContext}/rest/nuacare/v1/patient/validateSecondaryIdentifier?patientUuid=`,
        VALIDATE_PRIMARY_IDENTIFIER:
            `${serverContext}/rest/nuacare/v1/patient/validatePrimaryIdentifier?patientUuid=`,
        GET_REGISTRATION_CARD:
            `${serverContext}/rest/nuacare/v1/print/regCardPrint?personUuid=`,
        FETCH_LAST_CONSULTED_DOCTOR: `${serverContext}/rest/nuacare/v1/patient/getLastConsultedDoctor?patientUuid=`,
        APPLICABLE_DOCTORS_WITH_VALID_EPISODE:
            `${serverContext}/rest/nuacare/v1/core/episodeOfCare/getActiveEpisodesProvidersForPatient?patientUuid=`,
        // TENTATIVE_APPOINTMENTS_LIST: `${reportsContext}/rest/nuacare/v1/cloudConnector/getTentativeAppointments`,
        CONFIRM_APPOINTMENT: `${serverContext}/rest/nuacare/v1/appointment/confirmPortalAppointment`,
        // LOAD_PATIENT_FROM_CLOUD: `${reportsContext}/rest/nuacare/v1/cloudConnector/getMember`,
        // LINK_PATIENT: `${reportsContext}/rest/nuacare/v1/cloudConnector/linkMemberToPatient`,
        SEND_APPOINTMENT_REMINDER:
            `${serverContext}/rest/nuacare/v1/core/smsJob/appointment?appointmentUuid=`,
        SEND_RECALL_REMINDER:
            `${serverContext}/rest/nuacare/v1/core/smsJob/recall?recallUuid=`,
    },
    VISIT: {
        PATIENT_VISITS: `${serverContext}/rest/nuacare/v1/mrs-service/visit/getAllPatientVisits?patientUuid=`,
        GET_ALL_VISITS: `${serverContext}/rest/nuacare/v1/mrs-service/visit/allVisits`,
        MY_PATIENTS: `${serverContext}/rest/nuacare/v1/mrs-service/visit/activeForProvider`,
        GET_LAST_SIGNATURE: `${serverContext}/rest/nuacare/v1/mrs-service/visit/getLastPatientSignature?patientUuid=`,
        CHANGE_PAT_SIGN:
            `${serverContext}/rest/nuacare/v1/mrs-service/visit/updatePatientSignatureForVisit?visitUuid=`,
        CLOSE_ACTIVE_VISIT: `${serverContext}/rest/nuacare/v1/mrs-service/visit/closeLatestOpenVisit`,
        CHANGE_VISIT_TYPE: `${serverContext}/rest/nuacare/v1/mrs-service/visit/changeVisitType?visitUuid=`,
        ADD: `${serverContext}/rest/nuacare/v1/registration/addVisit`,
        FETCH: `${serverContext}/rest/nuacare/v1/registration/getRegistrationDetailsToDisplay?personUuid=`,
        REPORT: `${serverContext}/rest/nuacare/v1/documents/searchVisits`,
    },
    ALLERGY: {
        LIST: `${serverContext}/rest/nuacare/v1/mrs-service/allergy/list?patientUuid=`,
    },
    FISCAL_YEAR: {
        CREATE: `${serverContext}/rest/nuacare/v1/erpService/metaData/generateAccountingYear`,
        PAGES: `${serverContext}/rest/nuacare/v1/erpService/metaData/fiscalYear?`,
        SEARCH: `${serverContext}/rest/nuacare/v1/erpService/metaData/accountFiscalYears/suggestions?searchString=`,
    },
    ACCOUNT_PERIOD: {
        LIST: `${serverContext}/rest/nuacare/v1/erpService/metaData/period?fiscalYear=`,
        LIST_ALL: `${serverContext}/rest/nuacare/v1/erpService/metaData/allPeriods?fiscalYear=`,
        EDIT: `${serverContext}/rest/nuacare/v1/erpService/metaData/editperiod`,
        CLOSE: `${serverContext}/rest/nuacare/v1/erpService/metaData/closeOrDraftPeriod?id=`,
    },
    CONSUMPTION: `${serverContext}/rest/nuacare/v1/consumptionMetadata/getConsumptionMetadataForYear`,
    EPISODE_OF_CARE: {
        GET_ACTIVE_EPISODE_OF_DOC_AND_PATIENT:
            `${serverContext}/rest/nuacare/v1/registration/getActiveEpisodesForPatientAndProvider`,
        GET_UNPAID_ORDERS_COVERAGE_DTO_OF_ENCOUNTER:
            `${serverContext}/rest/nuacare/v1/registration/getCoverageDtosForProductsInEncounter`,
        GET_UNPAID_ORDERS_FOR_EPISODE:
            `${serverContext}/rest/nuacare/v1/core/episodeOfCare/orderCountForEncounter`,
        GET_EPISODE_DETAILS_FOR_CONS_CREATION:
            `${serverContext}/rest/nuacare/v1/core/episodeOfCare/getActiveEpisodesForProvider`,
        GET_PENDING_REFERRALS_FOR_EPISODE:
            `${serverContext}/rest/nuacare/v1/core/episodeOfCare/getPendingReferralsOrTransfersForPatientInEpisode`,
        GET_COUNTS_OF_ORDERS_AND_REFERRALS:
            `${serverContext}/rest/nuacare/v1/core/episodeOfCare/getOrderCountAndReferralsCountForEpisode`,
        RECYCLE_EPISODE:
            `${serverContext}/rest/nuacare/v1/core/episodeOfCare/recycleEpisode`,
        GET_DETAILS:
            `${serverContext}/rest/nuacare/v1/core/episodeOfCare/getDetails?episodeOfCareUuid=`,
        GET_PRIMARY_DOCTOR_OF_EPISODE_TO_BILL:
            `${serverContext}/rest/nuacare/v1/core/episodeOfCare/getPrimaryDoctorOfEpisodeToRebill?episodeOfCareUuid=`,
        GET_EPISODES_OF_PATIENT:
            `${serverContext}/rest/nuacare/v1/core/episodeOfCare/getEpisodeOfCaresForPatient?patientUuid=`,
        GET_UNBILLED_ORDERS_IN_EPISODE:
            `${serverContext}/rest/nuacare/v1/core/episodeOfCare/getUnpaidOrdersInEpisode?episodeOfCareUuid=`,
        GET_INVOICE_LINES_OF_EPISODE:
            `${serverContext}/rest/nuacare/v1/core/episodeOfCare/getInvoiceLinesOfEpisode?episodeOfCareUuid=`,
        GET_NO_OF_EPISODE_FOR_PATIENT:
            `${serverContext}/rest/nuacare/v1/core/episodeOfCare/getCountOfEpisodeOfCaresForPatient?patientUuid=`,
        EXTEND_EPISODE_OF_CARE:
            `${serverContext}/rest/nuacare/v1/core/episodeOfCare/extendEpisodeOfCare?episodeOfCareUuid=`,
        CHECK_SAME_DEPT_AND_SPECIALITY:
            `${serverContext}/rest/nuacare/v1/core/episodeOfCare/areDoctorsOfSameDepartmentAndSpeciality?doctor1Uuid=`,
        GET_APPROVALS_FOR_QUEUE:
            `${serverContext}/rest/nuacare/v1/core/episodeOfCare/getOrdersInApproval?episodeOfCareUuid=`,
        CANCEL_EPISODE:
            `${serverContext}/rest/nuacare/v1/core/episodeOfCare/cancelEpisode?episodeOfCareUuid=`,
        REVIVE_EPISODE:
            `${serverContext}/rest/nuacare/v1/core/episodeOfCare/reviveEpisode?episodeOfCareUuid=`,
        INCREASE_FOLLOW_UP:
            `${serverContext}/rest/nuacare/v1/core/episodeOfCare/increaseFollowUpCount?episodeOfCareUuid=`,
        TOGGLE_MATERNITY_CASE:
            `${serverContext}/rest/nuacare/v1/core/episodeOfCare/makeEpisodeMaternity?episodeOfCareUuid=`,
        GET_REFERRAL_TYPE:
            `${serverContext}/rest/nuacare/v1/core/episodeOfCare/getReferralTypeOfEpisodeProvider?episodeProviderUuid=`,
        DEACTIVATE_EP_PROV:
            `${serverContext}/rest/nuacare/v1/core/episodeOfCare/deactivateEpisodeProvider?episodeProviderUuid=`,
        // To change from cash to association and back
        CHANGE_PAYMENT_TYPE: `${serverContext}/rest/nuacare/v1/core/episodeOfCare/changePaymentType`,
        CHANGE_POLICY_EXPIRY: `${serverContext}/rest/nuacare/v1/core/episodeOfCare/changePolicyExpiryDate`,
        ADD_REFERRAL_DOCTOR: `${serverContext}/rest/nuacare/v1/core/episodeOfCare/addReferralDoctor`,
        CHECK_IF_REFERRAL_ALLOWED: `${serverContext}/rest/nuacare/v1/core/episodeOfCare/checkIfReferralAllowed`,
        ADD_REFERRAL_TO_ENCOUNTER: `${serverContext}/rest/nuacare/v1/core/episodeOfCare/addReferralToAnEncounter`,
        CHECK_IF_PENDING_REFERRAL_PRESENT:
            `${serverContext}/rest/nuacare/v1/core/episodeOfCare/checkIfPendingReferralPresent`,
    },
    INSURANCE_REQUESTS: {
        LIST_LINES: `${serverContext}/rest/nuacare/v1/insurance/getProcessedRequestLines`,
        REQUEST_LIST: `${serverContext}/rest/nuacare/v1/insurance/getProcessedRequests`,
    },
    APP_CONFIGURATION_JSON: getJsonPath('/AppConfiguration.json', APPLICATION_CONFIG_URL),
    WORKFLOW_CONFIGURATION_JSON: getJsonPath('/WorkflowConfiguration.json', APPLICATION_CONFIG_URL),
    PROVIDER: {
        SEARCH: `${serverContext}/rest/nuacare/v1/search/provider?searchString=`,
        ADD_OR_UPDATE_USER_PROPERTY:
            `${serverContext}/rest/nuacare/v1/mrs-service/provider/addOrUpdateUserProperty`,
        GET_ALL_USER_PROPERTY:
            `${serverContext}/rest/nuacare/v1/mrs-service/provider/getUserProperties`,
    },
    LOGIN: {
        AUTHENTICATE:
            `${serverContext}/web/nuacare/v1/session/authenticate`,
        SESSION: `${serverContext}/web/nuacare/v1/session/`,
        VALIDATE_USERNAME_PASSWORD: `${serverContext}/web/nuacare/v1/session/validateUsernameAndPassword`,
        VALIDATE_USER_AND_GET_PRIVILEGES:
            `${serverContext}/web/nuacare/v1/session/validateUserGetPrivileges`,
        CHANGE_PASSWORD: `${serverContext}/rest/nuacare/v1/hr/employee/user/pass`,
        UPDATE_USER_NAME: `${serverContext}/rest/nuacare/v1/hr/employee/updateUserName`,
        FORCE_USER_TO_RELOGIN: `${serverContext}/rest/nuacare/v1/hr/employee/forceAnUserToReLogIn`,
    },
    QUEUES: {
        GET_TV_SCREEN: `${serverContext}/app/getNextToken`,
        PATIENT_LIST: `${serverContext}/rest/nuacare/v1/queue/user`,
        CALL_TOKEN: `${serverContext}/rest/nuacare/v1/queue/callToken`,
        CANCEL_TOKEN: `${serverContext}/rest/nuacare/v1/queue/cancelToken?tokenUuid=`,
        DELETE_QUEUE: `${serverContext}/rest/nuacare/v1/queue/deleteQueue?queueUuid=`,
        CHANGE_DOCTOR_STATUS: `${serverContext}/rest/nuacare/v1/queue/changeDoctorStatus?queueUuid=`,
        PLEASE_WAIT: `${serverContext}/rest/nuacare/v1/queue/please-wait?queueStatus=`,
        COUNT_OF_TABS: `${serverContext}/rest/nuacare/v1/queue/countOfAllQueues`,
        QUEUE_FOR_DOC: `${serverContext}/rest/nuacare/v1/queue/getQueueForDoctor?providerUuid=`,
        PATIENT_LIST_PROVIDER: `${serverContext}/rest/nuacare/v1/queue/provider`,
        GET_ALL_TOKENS_FOR_PATIENT: `${serverContext}/rest/nuacare/v1/queue/getTokensOfPatient?patientUuid=`,
        GET_TOTAL_WAITING: `${serverContext}/rest/nuacare/v1/queue/getTotalWaitingTokensForProvider?providerUuid=`,
        RECALL_TOKEN: `${serverContext}/rest/nuacare/v1/queue/Recall`,
        ADJUST_QUEUE_TIMING: `${serverContext}/rest/nuacare/v1/queue/adjustQueueTiming`,
        UPDATE_ROOM_NUMBER: `${serverContext}/rest/nuacare/v1/queue/updateRoomNumber`,
    },
    EMPLOYEE: {
        GET_SUGGESTION: `${serverContext}/rest/nuacare/v1/hr/employee`,
        GET_ALL: `${serverContext}/rest/nuacare/v1/hr/employee/all`,
        GET_DEPARTMENT_SUGGESTION: `${serverContext}/rest/nuacare/v1/hr/employee/department?name=`,
        GET_EMPLOYEE_BY_DEPARTMENT: `${serverContext}/rest/nuacare/v1/hr/employee/manager`,
        ALL_DESIGNATIONS: `${serverContext}/rest/nuacare/v1/hr/employee/position`,
        GET_POSITION_SUGGESTIONS: `${serverContext}/rest/nuacare/v1/hr/employee/getPositionSuggestions?searchString=`,
        JOB_DOCUMENT: `${serverContext}/rest/nuacare/v1/master/hrJob/getDocument?id=`,
        ALL_WORK_HOUR_TEMPLATE: `${serverContext}/rest/nuacare/v1/hr/employee/template`,
        GET_ROLES: `${serverContext}/rest/nuacare/v1/master/medicalUsers/getRoles?searchString=`,
        GET_USER_MANAGEMENT_DETAILS:
            `${serverContext}/rest/nuacare/v1/hr/employee/userManagementDetails?employeeUuid=`,
        SAVE_USER_MANAGEMENT_DETAILS:
            `${serverContext}/rest/nuacare/v1/hr/employee/saveUserManagementDetails?employeeUuid=`,
        GET_DETAILS: `${serverContext}/rest/nuacare/v1/hr/employee/getDetails`,
        GET_RETIRED_STATUS: `${serverContext}/rest/nuacare/v1/hr/employee/getRetiredStatus?employeeUuid=`,
        UPDATE_RETIRED: `${serverContext}/rest/nuacare/v1/hr/employee/updateRetiredStatus`,
        GET_EMPLOYEE_SUBCOMPANY: `${serverContext}/rest/nuacare/v1/hr/employee/findSubCompany`,
    },
    SALARYADVANCE: {
        GET_EMPLOYEE: `${serverContext}/rest/nuacare/v1/hr/employee`,
        GET_ONE: `${serverContext}/rest/nuacare/v1/employeeAdvance/details?id=`,
        GET_RESIDUE: `${serverContext}/rest/nuacare/v1/employeeAdvance/residue`,
        GET_ALL: `${serverContext}/rest/nuacare/v1/employeeAdvance/all?state=ISSUED&type=ADVANCE`,
        ADD_ADVANCED_SALARY: `${serverContext}/rest/nuacare/v1/employeeAdvance/create`,
    },
    PETTYCASH: {
        GET_ALL: `${serverContext}/rest/nuacare/v1/employeeAdvance/all?state=ISSUED&type=PETTY`,
        GET_ONE: `${serverContext}/rest/nuacare/v1/employeeAdvance/details?id=`,
        ADD_PETTY_CASH: `${serverContext}/rest/nuacare/v1/employeeAdvance/create`,
        RECOUP: `${serverContext}/rest/nuacare/v1/employeeAdvance/recoup`,
        CANCEL_PETTY_CASH_EXPENSE: `${serverContext}/rest/nuacare/v1/employeeAdvance/cancelPettyCashExpense?expenseId=`,
    },
    WORKFLOW: {
        REQUEST: `${serverContext}/rest/nuacare/v1/workflow/request`,
        UPDATE: `${serverContext}/rest/nuacare/v1/workflow/update`,
        GET_DATA: `${serverContext}/rest/nuacare/v1/workflow/instance`,
    },
    INBOX: {
        GET_MESSAGES: `${serverContext}/rest/nuacare/v1/workflow/inbox`,
    },
    FILE: {
        UPLOAD: `${serverContext}/rest/nuacare/v1/file/resumableUpload/hr`,
        UPLOAD_ORDER_REPORT: `${serverContext}/rest/nuacare/v1/file/resumableUpload/emrReports`,
        BASE_64: `${serverContext}/rest/nuacare/v1/file/base64Upload/hr`,
        DOWNLOAD: `${serverContext}/rest/nuacare/v1/file/download?path=hr&name=`,
        GET_FILE_TYPES: `${serverContext}/rest/nuacare/v1/file/getFileTypes?uploadFiles=`,
        DOWNLOAD_DOCUMENTS_APP: `${serverContext}/rest/nuacare/v1/file/download?path=documents&name=`,
        DOWNLOAD_ORDER_REPORT: `${serverContext}/rest/nuacare/v1/file/download?path=emrReports&name=`,
        DOWNLOAD_WITH_TOKEN: `${serverContext}/rest/nuacare/v1/file/download/withToken/`,
        DOWNLOAD_WITH_TOKEN_CHUNKED: `${serverContext}/rest/nuacare/v1/file/downloadChunked/withToken/`,
        DOWNLOAD_PDF_WITH_TOKEN: `${serverContext}/rest/nuacare/v1/file/download/pdfWithToken/`,
        READ_FILE: `${serverContext}/rest/nuacare/v1/file/readFile?filePath=`,
        UPLOAD_FILE_HTML5:
            `${serverContext}/web/nuacare/v1/file/upload/hr`,
        UPLOAD_STUDY_DOCUMENT: `${serverContext}/rest/nuacare/v1/file/resumableUpload/studyDocument`,
        DOWNLOAD_STUDY_DOCUMENT: `${serverContext}/rest/nuacare/v1/file/download?path=studyDocument&name=`,
        UPLOAD_INSURANCE_PAYER_DOCUMENT: `${serverContext}/rest/nuacare/v1/file/resumableUpload/payerDocument`,
        DOWNLOAD_INSURANCE_PAYER_DOCUMENT: `${serverContext}/rest/nuacare/v1/file/download?path=payerDocument&name=`,
        CREATE_UPLOAD_FILE_INFO_FOR_FILE_IN_LOCAL_SERVER:
            `${serverContext}/rest/nuacare/v1/file/createUploadFileInfoForFileInLocalServer`,
    },
    PROFILE: {
        FETCH_CURRENT_PROFILE: `${serverContext}/rest/nuacare/v1/hr/employee/profile/current`,
        UPDATE_CURRENT_PROFILE: `${serverContext}/rest/nuacare/v1/hr/employee/profile/current/update`,
        FETCH_EMPLOYEE_PROFILE: `${serverContext}/rest/nuacare/v1/hr/employee/profile`,
    },
    ASSETS: {
        FETCH: `${serverContext}/rest/nuacare/v1/hr/asset/all`,
        VIEW: `${serverContext}/rest/nuacare/v1/hr/asset`,
        RETIRE: `${serverContext}/rest/nuacare/v1/hr/asset/retire`,
        UN_RETIRE: `${serverContext}/rest/nuacare/v1/hr/asset/unRetire/`,
        GET_CATEGORIES_LIST: `${serverContext}/rest/nuacare/v1/master/assetCategory`,
        GET_MANUFACTURERS_LIST: `${serverContext}/rest/nuacare/v1/master/assetManufacturer`,
        CREATE_MANUFACTURER: `${serverContext}/rest/nuacare/v1/master/assetManufacturer`,
        ADD: `${serverContext}/rest/nuacare/v1/hr/asset/add`,
        DELETE: `${serverContext}/rest/nuacare/v1/hr/asset/delete`,
        ACCESSION_NUMBER: `${serverContext}/rest/nuacare/v1/hr/asset/generateId`,
        FETCH_CATEGORY: `${serverContext}/rest/nuacare/v1/hr/asset/category`,
        FETCH_MANUFACTURER: `${serverContext}/rest/nuacare/v1/hr/asset/manufacturer`,
        COMPUTE_DEPRECIATION_BOARD: `${serverContext}/rest/nuacare/v1/hr/asset/generateDepreciationBoard`,
        DEPRECIATE: `${serverContext}/rest/nuacare/v1/hr/asset/depreciate`,
        UPDATE_STATE: `${serverContext}/rest/nuacare/v1/hr/asset/changeState/`,
        UPDATE_LOCATION: `${serverContext}/rest/nuacare/v1/hr/asset/changeLocation/`,
    },
    DEPARTMENT: {
        FETCH: `${serverContext}/rest/nuacare/v1/hr/employee/department`,
        AUTOSUGGEST: `${serverContext}/rest/nuacare/v1/hr/employee/department?page=0&size=10&name=`,
        AUTOSUGGEST_WITH_ALL: `${serverContext}/rest/nuacare/v1/hr/employee/department?` +
            'showAll=true&page=0&size=10&name=',
    },
    ANNOUNCEMENTS: {
        FETCH: `${serverContext}/rest/nuacare/v1/hr/announcement`,
        ADD: `${serverContext}/rest/nuacare/v1/hr/announcement/addAnnouncement`,
        FOR_CURRENT_USER: `${serverContext}/rest/nuacare/v1/hr/announcement/forCurrentUser`,
        EDIT: `${serverContext}/rest/nuacare/v1/hr/announcement/create`,
    },
    OBSERVATIONS: {
        SAVE_OBS: `${serverContext}/rest/nuacare/v1/obs/saveObs`,
        GET_OBS_CONCEPT_DETAILS: `${serverContext}/rest/nuacare/v1/obs/getObsConceptDetails`,
        GET_OBS_OF_ENCOUNTER: `${serverContext}/rest/nuacare/v1/obs/getObsOfEncounter?encounterUuid=`,
    },
    HOLIDAYS: {
        FETCH: `${serverContext}/rest/nuacare/v1/hr/holiday?page=`,
        UPCOMING_YEARS: `${serverContext}/rest/nuacare/v1/hr/holiday/searchHolidayYear?upcoming=true&searchString=`,
        ALL_YEARS: `${serverContext}/rest/nuacare/v1/hr/holiday/searchHolidayYear?searchString=`,
        DELETE_YEAR: `${serverContext}/rest/nuacare/v1/hr/holiday/deleteYear?uuid=`,
        CHECK_CONFLICTING_DATES: `${serverContext}/rest/nuacare/v1/hr/holiday/checkIfDatesConflict?uuid=`,
        ADD: `${serverContext}/rest/nuacare/v1/hr/holiday/create`,
        FOR_YEAR: `${serverContext}/rest/nuacare/v1/hr/holiday/year`,
        UPCOMING_HOLIDAYS: `${serverContext}/rest/nuacare/v1/hr/holiday/upcoming`,
    },
    ENCOUNTERS: {
        OPEN: `${serverContext}/rest/nuacare/v1/hr/employee/encounter/open`,
        INCOMPLETE: `${serverContext}/rest/nuacare/v1/hr/employee/encounter/inComplete`,
        INCOMPLETE_QUEUE:
            `${serverContext}/rest/nuacare/v1/mrs-service/encounter/inCompleteFaster`,
        MARK_AS_INCOMPLETE:
            `${serverContext}/rest/nuacare/v1/mrs-service/encounter/changeEncounterStatus?encounterUuid=`,
        GET_ENCOUNTERS_IN_VISIT:
            `${serverContext}/rest/nuacare/v1/mrs-service/encounter/searchEncounters?visitUuid=`,
        GET_ENCOUNTER: `${serverContext}/rest/nuacare/v1/mrs-service/encounter/getEncounter?encounterUuid=`,
        GET_ALL_ENCOUNTER: `${serverContext}/rest/nuacare/v1/mrs-service/encounter/getAllEncountersForPatientSeekable?patientUuid=`,
    },
    PATIENTS: {
        RECENT_PATIENTS: `${serverContext}/rest/nuacare/v1/queue/getRecentQueueForDoctor`,
        ACTIVE_PATIENTS: `${serverContext}/rest/nuacare/v1/hr/employee/encounter/active`,
        ALL_PATIENTS: `${serverContext}/rest/nuacare/v1/search/personDetails`,
    },
    PRE_AUTH: {
        GET_PREAUTH_DETAILS: `${insuranceClientContext}/rest/nuacare/insurance/client/v1/nphies/bridge/getPreAuth?reloadRequired=false&insuranceRequestUuid=`,
        ADD_PREAUTH_REQ: `${insuranceClientContext}/rest/nuacare/insurance/client/v1/nphies/bridge/preAuth/create`,
        GET_NPHIES_DETAILS_FOR_ITEM: `${insuranceClientContext}/rest/nuacare/insurance/client/v1/nphies/bridge/getNphiesDetailsForItem`,
        GET_CODED_DIAGNOSIS_DTO_FOR_ORDER: `${serverContext}/rest/nuacare/v1/mrs-service/orders/getCodedDiagnosisDtoForOrder`,
    },
    LEAVES: {
        AVAILED_LEAVES: `${serverContext}/rest/nuacare/v1/hr/employee/leaves/availed`,
        ALL_LEAVES: `${serverContext}/rest/nuacare/v1/hr/employee/leaves/allLeaves`,
        ADD_OR_UPDATE: `${serverContext}/rest/nuacare/v1/hr/employee/leaves/update`,
        LEAVE_TYPES: `${serverContext}/rest/nuacare/v1/workflow/leave/types?employeeUuid=`,
        BALANCE_LEAVES: `${serverContext}/rest/nuacare/v1/hr/employee/leaves/balance`,
        BALANCE_FOR_EMPLOYEE: `${serverContext}/rest/nuacare/v1/hr/employee/leaves/leaveBalanceForDate`,
        ALL_BALANCES: `${serverContext}/rest/nuacare/v1/hr/employee/leaves/getAllLeaveBalances`,
        ADJUST_BALANCE: `${serverContext}/rest/nuacare/v1/hr/employee/leaves/adjustLeaveBalance`,
        PLANS: `${serverContext}/rest/nuacare/v1/hr/employee/leaves/plans`,
        REMOVE_LEAVE_PLAN: `${serverContext}/rest/nuacare/v1/hr/employee/leave/deleteLeavePlan?employeeLeavePlanUuid=`,
        LEAVE_PLANS_FOR_EMPLOYEE: `${serverContext}/rest/nuacare/v1/hr/employee/leaves`,
        GET_LEAVE_BALANCE: `${serverContext}/rest/nuacare/v1/hr/employee/getLeaveBalanceForEmployee?employeeUuid=`,
        LEAVE_PLANS_UPDATE: `${serverContext}/rest/nuacare/v1/hr/employee/leave/updateLeavePlans?employeeUuid=`,
    },
    ATTENDANCE: {
        SAVE_DUTY: `${serverContext}/rest/nuacare/v1/hr/employee/attendance/create`,
        PERIOD_BY_UUID: `${serverContext}/rest/nuacare/v1/hr/employee/attendance/period/byUuid/`,
        FETCH_DUTY_LOGS: `${serverContext}/rest/nuacare/v1/hr/employee/attendance/dutyLogs`,
        APPROVE_ATTENDANCE: `${serverContext}/rest/nuacare/v1/hr/employee/attendance/approved`,
        REVERT_APPROVED_ATTENDANCE: `${serverContext}/rest/nuacare/v1/hr/employee/attendance/revert`,
        REGENERATE_ATTENDANCE_FOR_EMPLOYEE:
            `${serverContext}/rest/nuacare/v1/hr/employee/regenerateAttendanceForEmployee?employeeUuids=`,
        ADD_ATTENDANCE_FOR_EMPLOYEE:
            `${serverContext}/rest/nuacare/v1/hr/employee/attendance/addAttendanceForEmployee?employeeUuid=`,
        FETCH_ATTENDANCE_LIST: `${serverContext}/rest/nuacare/v1/hr/employee/attendance/pages`,
        NON_APPROVED_ATTENDANCE_MONTHS:
            `${serverContext}/rest/nuacare/v1/hr/employee/attendance/period?status=DRAFT&sortOrder=asc`,
        GET_BY_EMPLOYEE_AND_PERIOD: `${serverContext}/rest/nuacare/v1/hr/employee/attendance/getByEmployeeAndPeriod`,
    },
    PAYROLL: {
        OPEN_PERIODS: `${serverContext}/rest/nuacare/v1/hr/payroll/periods/suggestions?state=OPEN`,
        CURRENT_EMPLOYEE_PAYSLIP: `${serverContext}/rest/nuacare/v1/hr/payroll/payslip/byPeriod?periodUuid=`,
        PAYSLIP_PRINT: `${serverContext}/rest/nuacare/v1/hr/payroll/payslip/print?uuid=`,
        CHANGE_BATCH_DATE: `${serverContext}/rest/nuacare/v1/hr/payroll/advice/changeDate?uuid=`,
        PENDING_APPROVALS: `${serverContext}/rest/nuacare/v1/hr/payroll/pending/approval?periodUuid=`,
        PENDING_PAYSLIP: `${serverContext}/rest/nuacare/v1/hr/payroll/pending/payslip?periodUuid=`,
        ALL_PERIODS: `${serverContext}/rest/nuacare/v1/hr/payroll/periods/suggestions`,
        EMPLOYEES_BY_DEPT: `${serverContext}/rest/nuacare/v1/hr/payroll/batch/department`,
        PAYROLL_BY_EMPLOYEE: `${serverContext}/rest/nuacare/v1/hr/payroll/batch/employee`,
        EMPLOYEES_BY_DEPT_AND_NAME: `${serverContext}/rest/nuacare/v1/hr/payroll/batch/department/employee`,
        FETCH_EMPLOYEE_INPUT: `${serverContext}/rest/nuacare/v1/hr/payroll/inputs`,
        FETCH_SALARY_COMPUTATION: `${serverContext}/rest/nuacare/v1/hr/payroll/computeInputs`,
        SAVE_EMPLOYEE_INPUT: `${serverContext}/rest/nuacare/v1/hr/payroll/inputs/update`,
        SAVE_PAYSLIP_BATCH: `${serverContext}/rest/nuacare/v1/hr/payroll/batch/update`,
        FETCH_PAYSLIP_BATCHES: `${serverContext}/rest/nuacare/v1/hr/payroll/batch/all`,
        FETCH_PAYSLIP_BATCH_DETAILS: `${serverContext}/rest/nuacare/v1/hr/payroll/batch`,
        FETCH_PAYSLIP_DETAILS_BY_UUID: `${serverContext}/rest/nuacare/v1/hr/payroll/payslip?uuid=`,
        GENERATE_PAYMENT_SLIPS: `${serverContext}/rest/nuacare/v1/hr/payroll/batch/generate/all`,
        GENERATE_PAYMENT_ADVICE: `${serverContext}/rest/nuacare/v1/hr/payroll/advice/generate?payslipUuidList=`,
        GENERATE_PAYMENT_ADVICE_ALL: `${serverContext}/rest/nuacare/v1/hr/payroll/advice/generate/all`,
        VERIFY_PAYMENT_BATCH: `${serverContext}/rest/nuacare/v1/hr/payroll/batch/verify`,
        FETCH_PAYMENT_ADVICE_LIST: `${serverContext}/rest/nuacare/v1/hr/payroll/advice/all`,
        FETCH_PAYMENT_ADVICE: `${serverContext}/rest/nuacare/v1/hr/payroll/advice`,
        REMOVE_PAYSLIPS_FROM_PAYMENT_ADVICE: `${serverContext}/rest/nuacare/v1/hr/payroll/advice/removePayslips/`,
        SAVE_PAYMENT_ADVICE: `${serverContext}/rest/nuacare/v1/hr/payroll/advice/save`,
        FETCH_PAYSLIPS: `${serverContext}/rest/nuacare/v1/hr/payroll/payslip/all`,
        FETCH_INPUT_CODES: `${serverContext}/rest/nuacare/v1/hr/payroll/input/codes`,
        FETCH_PAYROLL_REPORTS: `${serverContext}/rest/nuacare/v1/hr/payroll/payslip/between`,
        FETCH_PAYROLL_REPORTS_CSV: `${serverContext}/rest/nuacare/v1/hr/payroll/payslip/between/csv`,
        ALL_CONTRACTS: `${serverContext}/rest/nuacare/v1/hr/employee/contract/type`,
        ALL_SALARY_STRUCTURES: `${serverContext}/rest/nuacare/v1/hr/employee/salary`,
        GET_STRUCTURE_DETAILS: `${serverContext}/rest/nuacare/v1/hr/employee/structure/getDetails`,
        DELETE_CONTRACT: `${serverContext}/rest/nuacare/v1/hr/employee/contract/`,
        ADD_OR_UPDATE_CONTRACTS_OF_EMPLOYEE: `${serverContext}/rest/nuacare/v1/hr/employee/contracts/addOrUpdate?employeeUuid=`,
    },
    EMPLOYEE_PAYROLL: {
        PAYROLL_DETAILS: `${serverContext}/rest/nuacare/v1/hr/payroll/dashboard`,
        PAYSLIP_DETAILS: `${serverContext}/rest/nuacare/v1/hr/payroll/employee/payslip`,
    },
    CALENDAR: {
        DETAILS: `${serverContext}/rest/nuacare/v1/hr/employee/calendar`,
        WORK_HOURS: `${serverContext}/rest/nuacare/v1/hr/employee/calendar/working-hours`,
    },
    TRIAL_BALANCE:
        `${serverContext}/rest/nuacare/v1/erpService/metaData/getTrialBalanceData?startDate=`,
    PROFIT_AND_LOSS:
        `${serverContext}/rest/nuacare/v1/erpService/metaData/getPAndL?startDate=`,
    BALANCE_SHEET:
        `${serverContext}/rest/nuacare/v1/erpService/metaData/getBalanceSheet?startDate=`,
    SEARCH: {
        REFERRAL_PROVIDERS:
            `${serverContext}/rest/nuacare/v1/lab/getReferralProviders?typeOfCareUuid=`,
        PRODUCT: `${serverContext}/rest/nuacare/v1/product/searchProduct?size=20&name=`,
        PRODUCT_UNDER_CATEGORY:
            `${serverContext}/rest/nuacare/v1/product/searchProductUnderProductCategory`,
        SALES_TAXES: `${serverContext}/rest/nuacare/v1/accountInvoice/salesTax/suggestion?name=`,
        PURCHASE_TAXES: `${serverContext}/rest/nuacare/v1/accountInvoice/purchaseTax/suggestion`,
        PRODUCT_UNDER_CATEG:
            `${serverContext}/rest/nuacare/v1/product/searchProductsUnderCategory?productCategoryUuid=`,
        CONS_PRODUCTS: `${serverContext}/rest/nuacare/v1/master/medicalUsers/searchConsultationProducts?searchString=`,
        PROCEDURE_CODE:
            `${serverContext}/rest/nuacare/v1/concept/searchReferenceTermsByCode?source=PROCEDURE_CODES&searchString=`,
        DEVICE_CODE:
            `${serverContext}/rest/nuacare/v1/concept/searchReferenceTermsByCode?source=DEVICE_CODES&searchString=`,
        LAB_SAMPLE: `${serverContext}/rest/nuacare/v1/master/typeOfSample/search?searchString=`,
        PRODUCT_SERVICE: `${serverContext}/rest/nuacare/v1/product/suggestion?isProduct=false&typeOfBarter=SALES&name=`,
        PRODUCT_DRUG_NONDRUG:
            `${serverContext}/rest/nuacare/v1/product/billables/suggestion?isProduct=true&typeOfBarter=SALES&name=`,
        ORDER_TYPE: `${serverContext}/rest/nuacare/v1/master/search/OrderType?searchString=`,
        MASTER_SEARCH_BY_MODEL: `${serverContext}/rest/nuacare/v1/master/search`,
        SEARCH_PRODUCTS_WITH_ID:
            `${serverContext}/rest/nuacare/v1/master/productCategory/search?searchString=`,
        BILLABLE_PRODUCT_BASE: `${serverContext}/rest/nuacare/v1/product/billables/suggestion`,
        BILLABLE_PRODUCT: `${serverContext}/rest/nuacare/v1/product/billables/suggestion?size=20&payerType=`,
        BILLABLE_DRUG: `${serverContext}/rest/nuacare/v1/product/drugs/suggestion?size=20&payerType=`,
        BILLABLE_SERVICES: `${serverContext}/rest/nuacare/v1/product/services/suggestion?size=20&payerType=`,
        RES_PARTNER: `${serverContext}/rest/nuacare/v1/accountInvoice/resPartner?size=15&&searchString=`,
        RES_PARTNER_BASE: `${serverContext}/rest/nuacare/v1/accountInvoice/resPartner?size=15`,
        SUPPLIER: `${serverContext}/rest/nuacare/v1/accountInvoice/resPartner?size=15&isSupplier=true&searchString=`,
        PAYOR: `${serverContext}/rest/nuacare/v1/accountInvoice/resPartner?size=15&isPayor=true&searchString=`,
        CUSTOMER:
            // eslint-disable-next-line
            `${serverContext}/rest/nuacare/v1/accountInvoice/resPartner?size=15&isPayor=false&isCustomer=true&searchString=`,
        WAREHOUSE: `${serverContext}/rest/nuacare/v1/product/warehouse/suggestion?size=15&replenishMode=PURCHASE_ORDER`,
        STOCK_LOCATION:
            `${serverContext}/rest/nuacare/v1/erpService/metaData/stockLocation/suggestions?size=20&searchString=`,
        STOCK_LOCATION_SUB_COMPANY:
            `${serverContext}/rest/nuacare/v1/erpService/metaData/stockLocationSuggestions?size=20&subCompany=`,
        STOCK_LOCATION_WITH_REPLENISHMENT: replenishmentMode => (
            `${serverContext}/rest/nuacare/v1/erpService/metaData/stockLocation/suggestions?size=20&replenishmentMode=${replenishmentMode}&searchString=`
        ),
        PRIV_BASED_STOCK_LOCATION:
            `${serverContext}/rest/nuacare/v1/erpService/metaData/stockLocation/getAllPrivilegeBasedLocations`,
        INTERNAL_STOCK_LOCATION: `${serverContext}/rest/nuacare/v1/erpService/metaData/stockLocation/suggestions?size=20&usage=internal&searchString=`,
        DOCTOR: `${serverContext}/rest/nuacare/v1/search/doctor?searchString=`,
        ORDER_TYPE_MODALITIES: `${serverContext}/rest/nuacare/v1/master/orderType/getOrderTypeModalities?orderType=`,
        UOM_BY_CAT: `${serverContext}/rest/nuacare/v1/product/uombycat/suggestion?size=20`,
        UOM_OF_PROD: `${serverContext}/rest/nuacare/v1/product/getUnitOfMeasuresOfProduct?productId=`,
        RES_PARTNER_TITLE: `${serverContext}/rest/nuacare/v1/erpService/metaData/resPartnerTitle/suggestions`,
        DRUG: `${serverContext}/rest/nuacare/v1/master/genericDrugs/search?searchString=`,
        DRUG_SEARCH: `${serverContext}/rest/nuacare/v1/search/searchDrugKV?searchString=`,
        DRUG_FORM: `${serverContext}/rest/nuacare/v1/master/conceptDrugForms/searchDrugForm?searchString=`,
        DRUG_ROUTES: `${serverContext}/rest/nuacare/v1/master/drugRoutes/search?searchString=`,
        DRUG_DOSE_UNIT: `${serverContext}/rest/nuacare/v1/master/conceptDrugForms/searchDoseUnit?searchString=`,
        DRUG_FREQUENCY: `${serverContext}/rest/nuacare/v1/master/conceptDrugForms/searchFrequency?searchString=`,
        UOM_SUGGESTION: `${serverContext}/rest/nuacare/v1/product/uom/suggestion?page=0&size=10&name=`,
        UOM_SEARCH_FOR_LAB: `${serverContext}/rest/nuacare/v1/master/unitOfMeasure/search?searchString=`,
        DOSAGE_INSTRUCTION: `${serverContext}/rest/nuacare/v1/master/dosageInstructions/search?searchString=`,
        WARNINGS: `${serverContext}/rest/nuacare/v1/master/drugInstructions/search?searchString=`,
        DRUG_UNIT: `${serverContext}/rest/nuacare/v1/master/conceptDrugForms/searchDoseUnit?searchString=`,
        ACCOUNT_HEADS: `${serverContext}/rest/nuacare/v1/master/productService/account?searchString=`,
        STATES: `${serverContext}/rest/nuacare/v1/master/search/ResCountryState?searchString=`,
        COUNTRY: `${serverContext}/rest/nuacare/v1/master/search/ResCountry?searchString=`,
        ACCOUNTS: `${serverContext}/rest/nuacare/v1/accountAccount/suggestions`,
        VIEW_ACCOUNTS: `${serverContext}/rest/nuacare/v1/master/accountAccount/parent`,
        ACCOUNTS_USER_TYPE: `${serverContext}/rest/nuacare/v1/accountAccount/userType/suggestions?searchString=`,
        PRODUCT_CATEGORY: `${serverContext}/rest/nuacare/v1/master/search/ProductCategory?searchString=`,
        INS_PRODUCT_CATEGORY: `${serverContext}/rest/nuacare/v1/insurance/searchInsuranceProductCategory?searchString=`,
        USER_TYPE: `${serverContext}/rest/nuacare/v1/master/search/AccountAccountType?searchString=`,
        COMPANY: `${serverContext}/rest/nuacare/v1/master/search/ResCompany?searchString=`,
        FETCH_TAXES: `${serverContext}/rest/nuacare/v1/master/productService/taxes?searchString=`,
        ACCOUNT_TYPES: `${serverContext}/rest/nuacare/v1/master/accountAccount/account-types`,
        VISIT_TYPE: `${serverContext}/rest/nuacare/v1/search/visitType?searchString=`,
        BATCHES_SUGGESTIONS: `${serverContext}/rest/nuacare/v1/product/batch/suggestion?page=0&size=20&name=`,
        ACCOUNT_JOURNAL:
            `${serverContext}/rest/nuacare/v1/erpService/metaData/accountJournal/suggestions?size=20&searchString=`,
        ANALYTICAL_ACCOUNTS:
            `${serverContext}/rest/nuacare/v1/genericreport/getAutoSuggestValues?name=analyticalAccount&searchStr=`,
        ANALYTICAL_ACCOUNTS_VIEW_TYPES:
            `${serverContext}/rest/nuacare/v1/genericreport/getAutoSuggestValues?name=analyticalAccountCostViewAccounts&searchStr=`,
        ACCOUNT_PERIOD:
            `${serverContext}/rest/nuacare/v1/erpService/metaData/accountPeriod/suggestions?size=20&searchString=`,
        ACCOUNT_PERIOD_CURRENT_OR_PAST:
            `${serverContext}/rest/nuacare/v1/erpService/metaData/accountPeriod/suggestions?size=20&showCurrentOrPast=true&searchString=`,
        ACCOUNT_PAYMENT_TERM: `${serverContext}/rest/nuacare/v1/erpService/metaData//accountPaymentTerm/suggestions?size=20&searchString=`,
        DIAGNOSIS: `${serverContext}/rest/nuacare/v1/search/diagnosis?size=20&searchString=`,
        INSURANCE_PAYERS: `${serverContext}/rest/nuacare/v1/insurance/payers/search`,
        CSV_TEMPLATES_BASE: `${serverContext}/rest/nuacare/v1/insurance/searchCsvTemplates`,
        CSV_TEMPLATES: `${serverContext}/rest/nuacare/v1/insurance/searchCsvTemplates?searchString=`,
        INSURANCE_PAYERS_BY_TYPE: `${serverContext}/rest/nuacare/v1/insurance/payers/searchByPayerType`,
        INSURANCE_SCHEME: `${serverContext}/rest/nuacare/v1/insurance/scheme/searchInsuranceScheme`,
        INSURANCE_SCHEME_UNDER_POLICY: `${serverContext}/rest/nuacare/v1/insurance/scheme/searchInsuranceSchemeUnderPolicy`,
        SEARCH_POLICIES: `${serverContext}/rest/nuacare/v1/insurance/payers/searchInsurancePolicies`,
        SEARCH_POLICIES_UNDER_TPA_AND_SUB:
            `${serverContext}/rest/nuacare/v1/insurance/payers/searchPolicies`,
        ORDERABLE_ITEMS: `${serverContext}/rest/nuacare/v1/search/searchOrderableItems`,
        ALL_ITEMS: `${serverContext}/rest/nuacare/v1/search/searchAllItems`,
        PRODUCT_CATEGORY_WITH_PARENT: `${serverContext}/rest/nuacare/v1/master/productService/category`,
        BILLABLE_PRODUCT_CATEGORY: `${serverContext}/rest/nuacare/v1/master/productCategory/productCategories?name=`,
        DEPARTMENT: `${serverContext}/rest/nuacare/v1/master/department/search?searchString=`,
        SCHEMES: `${serverContext}/rest/nuacare/v1/insurance/cards/suggestions?searchString=`,
        INS_COMPANY: `${serverContext}/rest/nuacare/v1/insurance/payers/suggestions?type=COMPANY&searchString=`,
        PAYER_SEARCH: `${serverContext}/rest/nuacare/v1/insurance/payers/suggestions?type=`,
        LOCATIONS: `${serverContext}/rest/nuacare/v1/search/location?searchString=`,
        LOCATION_WITH_TAG: `${serverContext}/rest/nuacare/v1/search/location`,
        SEARCH_BANKS: `${serverContext}/rest/nuacare/v1/hr/employee/bank/all?name=`,
        BED_TYPE: `${serverContext}/rest/nuacare/v1/ipd/bed/searchBedType?name=`,
        GENERIC_DRUG: `${serverContext}/rest/nuacare/v1/master/genericDrugsAttribute/searchGenericDrugKV?searchString=`,
        NON_DRUG_SEARCH: `${serverContext}/rest/nuacare/v1/search/searchDrugKV?onlyNonDrug=true&searchString=`,
        SEARCH_GTINS_BY_PRODUCT_UUID: `${serverContext}/rest/nuacare/v1/product/searchGtinsByProductUuid`,
        SEARCH_COUNTRY: `${serverContext}/rest/nuacare/v1/registration/searchCountry`,
    },
    VALIDATE_BATCH_NUMBER: `${serverContext}/rest/nuacare/v1/product/batch/validate?name=`,
    CHECK_IF_BATCH_EXISTS:
        `${serverContext}/rest/nuacare/v1/product/getProductBatchIdFromCodeAndBatchNoAndLocation?productCode=`,
    GET_DOCTOR_SPECIALITY: `${serverContext}/rest/nuacare/v1/search/getDoctorSpecialityLevel?providerUuid=`,
    RES_PARTNER: {
        CREATE: `${serverContext}/rest/nuacare/v1/erpService/metaData/resPartner`,
    },
    PURCHASE_ORDER: {
        CREATE: `${serverContext}/rest/nuacare/v1/purchaseOrder/save`,
        FETCH_ONE: `${serverContext}/rest/nuacare/v1/purchaseOrder/lines`,
        FETCH_ONE_BY_UUID: `${serverContext}/rest/nuacare/v1/purchaseOrder/linesFromPOUuid?uuid=`,
        PAGES: `${serverContext}/rest/nuacare/v1/purchaseOrder/pages`,
        MARK_RECEIVE_STOCK: `${serverContext}/rest/nuacare/v1/purchaseOrder/markProductReceived`,
        CANCEL_PO: `${serverContext}/rest/nuacare/v1/purchaseOrder/cancel?purchaseOrderUuid=`,
        IS_INVOICED: `${serverContext}/rest/nuacare/v1/purchaseOrder/isInvoiced?uuid=`,
        HAS_RECEIVED_GOODS: `${serverContext}/rest/nuacare/v1/purchaseOrder/hasReceivedGoods?uuid=`,
        EXPORT_AS_CSV: `${serverContext}/rest/nuacare/v1/purchaseOrder/export?uuid=`,
    },
    PURCHASE_BILL: {
        PAGES: `${serverContext}/rest/nuacare/v1/accountInvoice/pages`,
        CONS_AMT: `${serverContext}/rest/nuacare/v1/accountInvoice/consolidatedAmounts`,
        DOWNLOAD_AS_CSV: `${serverContext}/rest/nuacare/v1/accountInvoice/downloadAsCsv`,
    },
    STOCK: {
        RECEIVE_STOCK: `${serverContext}/rest/nuacare/v1/erpService/stockMove/receiveStock`,
        RETURN_STOCK: `${serverContext}/rest/nuacare/v1/erpService/stockMove/returnStock`,
        GOOD_RECEIPTS: `${serverContext}/rest/nuacare/v1/erpService/stockMove/goodReceipts`,
        ADJUST_INVENTORY: `${serverContext}/rest/nuacare/v1/erpService/stockMove/adjustInventory`,
        FETCH_STOCK_MOVES_BY_PURCHASE_ORDER_BY_UUID:
            `${serverContext}/rest/nuacare/v1/erpService/stockMove/findByPurchaseOrder?purchaseOrderUuid=`,
        FETCH_STOCK_MOVES_BY_PICKING_ID:
            `${serverContext}/rest/nuacare/v1/erpService/stockMove/findByPickingId?pickingId=`,
        GET_STOCK_OF_PRODUCT_IN_LOCATION:
            `${serverContext}/rest/nuacare/v1/product/getStockOfProductInLocation`,
        STOCK_LOCATIONS:
            `${serverContext}/rest/nuacare/v1/erpService/metaData/stockLocation/getAll`,
        GET_INTENT: `${serverContext}/rest/nuacare/v1/erpService/stockIntent/getIntent?uuid=`,
        GET_STOCK_VALUE_GENERATIONS: `${serverContext}/rest/nuacare/v1/stockValue/getGenerationsForYear`,
        GENERATE_STOCK_VALUE_FOR_MONTH: `${serverContext}/rest/nuacare/v1/stockValue/generateForMonth?date=`,
    },
    ACCEPT_PACKAGES: {
        LIST: `${serverContext}/rest/nuacare/v1/rsd/getPackages`,
        GET_PACKAGE_BY_ID: `${serverContext}/rest/nuacare/v1/rsd/getPackageById?id=`,
        MATERIAL_RECEIVE: `${serverContext}/rest/nuacare/v1/rsd/materialReceive?id=`,
        ACCEPT_PACKAGE: `${serverContext}/rest/nuacare/v1/rsd/acceptPackage`,
        IGNORE: `${serverContext}/rest/nuacare/v1/rsd/ignore`,
        DOWNLOAD_PACKAGE: `${serverContext}/rest/nuacare/v1/rsd/packageDownload`,
        ACCEPT_ALL: `${serverContext}/rest/nuacare/v1/rsd/acceptAll`,
    },
    MATERIAL_RECEIVE: {
        ADD_OR_UPDATE: `${serverContext}/rest/nuacare/v1/materialReceive/addOrUpdate`,
        PAGE: `${serverContext}/rest/nuacare/v1/materialReceive/pages`,
        LIST: `${serverContext}/rest/nuacare/v1/materialReceive/list`,
        GET_MATERIAL_RECEIVE: `${serverContext}/rest/nuacare/v1/materialReceive/getMaterialReceive?uuid=`,
        GET_MATERIAL_RECEIVE_BY_ID: `${serverContext}/rest/nuacare/v1/materialReceive/getById?id=`,
        CONFIRM: `${serverContext}/rest/nuacare/v1/materialReceive/confirmMaterialReceive?uuid=`,
        CREATE_INVOICE: `${serverContext}/rest/nuacare/v1/materialReceive/createInvoiceForMaterialRecieve?materialUuid=`,

    },
    PATIENT_PACKAGES: {
        GET_ACTIVE_PACKAGES_OF_PATIENT: `${serverContext}/rest/nuacare/v1/core/packages/getAllPackages?patientUuid=`,
        CHECK_IF_PROD_CAN_BE_BILLED_IN_PACKAGE:
            `${serverContext}/rest/nuacare/v1/core/packages/checkIfProductCanBeBilledInPackage?packageUsageUuid=`,
        UPDATE: `${serverContext}/rest/nuacare/v1/core/packages/updatePackageUsageLine`,
        UPDATE_PACKAGE_USAGE_SEQUENCE: `${serverContext}/rest/nuacare/v1/core/packages/updatePackageUsageSequence`,
    },
    CSV_DOWNLOAD: {
        REQUEST: `${serverContext}/rest/nuacare/v1/downloadcsv/addDownloadRequest`,
        REQUEST_STATUS: `${serverContext}/rest/nuacare/v1/downloadcsv/checkDownloadRequest`,
    },
    BATCHES: {
        PAGES: `${serverContext}/rest/nuacare/v1/erpService/metaData/batches/pages`,
        BY_NAME_AND_PRODUCT: `${serverContext}/rest/nuacare/v1/erpService/metaData/batches/byNameAndProduct`,
        CREATE: `${serverContext}/rest/nuacare/v1/erpService/batch/create`,
        UPDATE: `${serverContext}/rest/nuacare/v1/erpService/batch/update`,
        PRINT: `${serverContext}/rest/nuacare/v1/erp-service/erpReport/barcodePrint`,
    },
    SUPPLIER: {
        PAGES: `${serverContext}/rest/nuacare/v1/erpService/metaData/resPartner/pages`,
        CREATE: `${serverContext}/rest/nuacare/v1/erpService/metaData/resPartner`,
        FIND: `${serverContext}/rest/nuacare/v1/erpService/metaData/resPartner?uuid=`,
        FIND_BY_ID: `${serverContext}/rest/nuacare/v1/erpService/metaData/resPartnerById?id=`,
    },
    PAYORS: {
        GET_POLICY_AND_SCHEME_UNDER_SUB_INS:
            `${serverContext}/rest/nuacare/v1/registration/getPolicyAndSchemeUnderSubInsurer?subInsurerUuid=`,
        RES_PARTNER_PAGES: `${serverContext}/rest/nuacare/v1/erpService/metaData/resPartner/pages`,
        PAGES: `${serverContext}/rest/nuacare/v1/insurance/payers/list`,
        CREATE: `${serverContext}/rest/nuacare/v1/insurance/payers/add`,
        UPDATE_PRICELIST_IN_BATCH:
            `${serverContext}/rest/nuacare/v1/insurance/updatePriceListItems?priceListUuid=`,
        DETAILS: `${serverContext}/rest/nuacare/v1/insurance/payers`,
        IS_NAME_UNIQUE: `${serverContext}/rest/nuacare/v1/insurance/payers/isNameUnique`,
        IS_CODE_UNIQUE: `${serverContext}/rest/nuacare/v1/insurance/payers/isCodeUnique`,
        VALIDATE: `${serverContext}/rest/nuacare/v1/insurance/payers/validate`,
        SAVE_CATEGORY: `${serverContext}/rest/nuacare/v1/insurance/scheme/operation`,
        SAVE_PRICE_LIST: `${serverContext}/rest/nuacare/v1/insurance/products`,
        SAVE_FORMULARY_LIST: `${serverContext}/rest/nuacare/v1/payerFormulary/products`,
        SAVE_PRICE_LIST_ITEM: `${serverContext}/rest/nuacare/v1/insurance/product/update`,
        SAVE_FORMULARY_LIST_ITEM: `${serverContext}/rest/nuacare/v1/payerFormulary/editPayerFormularyForSingleProduct`,
        SAVE_PRICE_LIST_ITEMS: `${serverContext}/rest/nuacare/v1/insurance/product/batchUpdate?state=`,
        DELETE_PRODUCT_FROM_PRICE_LIST:
            `${serverContext}/rest/nuacare/v1/insurance/product/delete?productPriceForPayerUuid=`,
        DELETE_FORMULARY_ITEM: `${serverContext}/rest/nuacare/v1/payerFormulary/deletePayerFormularyItem?uuid=`,
        SEARCH_PRICELISTS:
            `${serverContext}/rest/nuacare/v1/insurance/searchPricelists?searchString=`,
        GET_INS_PROD_CATEG:
            `${serverContext}/rest/nuacare/v1/insurance/getInsuranceProductCategoryForProduct?productUuid=`,
        FETCH_PRICE_LIST: `${serverContext}/rest/nuacare/v1/insurance/pricelist`,
        FETCH_PRICE_LIST_BY_UUID: `${serverContext}/rest/nuacare/v1/insurance/priceListByUuid`,
        FETCH_ALL_PRICE_LISTS: `${serverContext}/rest/nuacare/v1/insurance/fetchAllPriceLists`,
        FETCH_FORMULARY_LIST: `${serverContext}/rest/nuacare/v1/payerFormulary/fetchByPayer`,
        FETCH_FORMULARY_LIST_BY_UUID: `${serverContext}/rest/nuacare/v1/payerFormulary/fetchByFormularyUuid`,
        FETCH_ALL_FORMULARY_LISTS: `${serverContext}/rest/nuacare/v1/payerFormulary/fetchAllFormulariesForPayer`,
        CHANGE_ALL_FORMULARY_STATE: `${serverContext}/rest/nuacare/v1/payerFormulary/changeStateForAllFormularies`,
        CHANGE_ONE_FORMULARY_STATE: `${serverContext}/rest/nuacare/v1/payerFormulary/changeStateForSingleFormulary`,
        DOWNLOAD_FORMULARY_LIST_FOR_PAYER:
            `${serverContext}/rest/nuacare/v1/payerFormulary/downloadFormularyListForPayer?payerUuid=`,
        DOWNLOAD_FORMULARY_LIST_WITH_UUID:
            `${serverContext}/rest/nuacare/v1/payerFormulary/downloadFormularyListWithUuid?formularyUuid=`,
        FETCH_CATEGORIES: `${serverContext}/rest/nuacare/v1/insurance/payers/schemes`,
        FETCH_SCHEME: `${serverContext}/rest/nuacare/v1/insurance/scheme/getByUuid?schemeUuid=`,
        VALIDATE_SCHEME: `${serverContext}/rest/nuacare/v1/insurance/scheme/validate`,
        FETCH_POLICIES: `${serverContext}/rest/nuacare/v1/insurance/payers/policies`,
        ACTIVATATE_DEACTIVATE_PAYER: `${serverContext}/rest/nuacare/v1/insurance/payers/activateOrDeactivate`,
        ACTIVATE_DEACTIVATE_SCHEME: `${serverContext}/rest/nuacare/v1/insurance/scheme/activateOrDeactivate?schemeUuid=`,
        SAMPLE_CSV_DATA: `${serverContext}/rest/nuacare/v1/insurance/payers/sampleCSV?payerType=`,
        GET_SCHEME_DETAILS: `${serverContext}/rest/nuacare/v1/insurance/cards/getDetails?schemeUuid=`,
        FETCH_POLICY_VIEW: `${serverContext}/rest/nuacare/v1/insurance/policyView`,
        FETCH_INSURANCE_SCHEME_VIEW: `${serverContext}/rest/nuacare/v1/insurance/insuranceSchemeView`,
        DOWNLOAD_PRICELIST_FOR_PAYER:
            `${serverContext}/rest/nuacare/v1/insurance/payers/downloadPriceListForPayer?payerUuid=`,
        DOWNLOAD_PRICELIST_WITH_UUID:
            `${serverContext}/rest/nuacare/v1/insurance/payers/downloadPriceListWithUuid?pricelistUuid=`,
        PAYER_TYPE_SHORT_FORM:
            `${serverContext}/rest/nuacare/v1/insurance/payer/getPayerTypeShortForm?payerUuid=`,
        ALL_INSURANCE_SCHEME_UNDER_POLICY:
            `${serverContext}/rest/nuacare/v1/insurance/scheme/getAllSchemesOfPolicy`,
        ALL_INSURANCE_SCHEME_OF_POLICY:
            `${serverContext}/rest/nuacare/v1/insurance/scheme/getAllSchemesUnderAPolicy`,
        GET_SUPPLIMENTAL_PROD_IDS: `${serverContext}/rest/nuacare/v1/insurance/getAllSupplementalProdIds`,
        CHANGE_ONE_FORMULARY_EBP_SUPPORTED: `${serverContext}/rest/nuacare/v1/payerFormulary/changeEbpSupportedForSingleFormulary`,
        ADD_OR_UPDATE_FORMULARY_ITEM_TYPE_INFO: `${serverContext}/rest/nuacare/v1/payerFormulary/addOrUpdateFormularyItemTypeInfo?formularyUuid=`,
        GET_PAYER_FORMULARY_ITEM_TYPES: `${serverContext}/rest/nuacare/v1/payerFormulary/getPayerFormularyItemTypes`,
    },
    PRODUCT: {
        PRODUCT: `${serverContext}/rest/nuacare/v1/product`,
        GET_PRODUCT_OR_BATCH_FROM_BARCODE: `${serverContext}/rest/nuacare/v1/product/findProductCodeOrBatchCode?id=`,
        GET_PRODUCT_CURRENT_QUANTITY: `${serverContext}/rest/nuacare/v1/product/getTotalStockQuantOfAProduct?productUuid=`,
        GET_P_USAGE_LINES:
            `${serverContext}/rest/nuacare/v1/productUsageLine/fetchProductUsageLines?activeOnly=true&withBatch=true`,
        GET_PRODUCTS_WITHIN_SAME_GROUP_OF_PARENT: `${serverContext}/rest/nuacare/v1/productUsageLine/getProductsWithInSameGroupAndParent?parentProductUuid=`,
        PAGES: `${serverContext}/rest/nuacare/v1/master/conceptDrugForms`,
        CREATE: `${serverContext}/rest/nuacare/v1/master/conceptDrugForms`,
        GET_DRUG_FORM_BY_UUID: `${serverContext}/rest/nuacare/v1/master/conceptDrugForms/getDrugFormByUuid?uuid=`,
        SAVE_PRODUCT_GTIN: `${serverContext}/rest/nuacare/v1/master/conceptDrugForms/saveProductGtinMap?productUuid=`,
        GET_BATCH_AND_QUANTITY: `${serverContext}/rest/nuacare/v1/product/getBatchDetailsForProductAtLocation`,
        GET_BATCH_DETAILS_BY_BATCH_NAME:
            `${serverContext}/rest/nuacare/v1/product/getBatchDetailsForProductAtLocationAndBatchNumber`,
        GET_PRODUCT_BY_CODE: `${serverContext}/rest/nuacare/v1/product/getProductDetailsFromCode?productCode=`,
        GET_PRODUCT_ID: `${serverContext}/rest/nuacare/v1/product/getProductId?productUuid=`,
        RETIRE_PRODUCT: `${serverContext}/rest/nuacare/v1/master/conceptDrugForms/deleteInBatch`,
        ACTIVATE_PRODUCT: `${serverContext}/rest/nuacare/v1/master/conceptDrugForms/activate`,
        GET_PRODUCT_DETAILS: `${serverContext}/rest/nuacare/v1/product/id`,
        GET_LIST_PRICE: `${serverContext}/rest/nuacare/v1/product/getListPriceOfProduct?productId=`,
        GET_CATEG_NAME_AND_INS_CATEG:
            `${serverContext}/rest/nuacare/v1/product/getCategoryAndInsuranceCateg?payerType=`,
        ORDERABLE_SERVICES: `${serverContext}/rest/nuacare/v1/master/orderableService`,
        ORDER_TYPE: `${serverContext}/rest/nuacare/v1/master/orderType`,
        PRICELIST_ENTRIES:
            `${serverContext}/rest/nuacare/v1/erpService/metaData/getPricelistDetailsForProduct?productUuid=`,
        GET_PRODUCT_BY_UUID: `${serverContext}/rest/nuacare/v1/product/getProductDetailsFromUuid?productUuid=`,
        IS_PRODUCT_GTIN_MAP_PRESENT_IN_STOCK_PRODUCTION_LOT:
            `${serverContext}/rest/nuacare/v1/product/isProductGtinMapPresentInStockProductionLot`,
    },
    CONSUMABLE: {
        PAGES: `${serverContext}/rest/nuacare/v1/master/productConsumable`,
        CREATE: `${serverContext}/rest/nuacare/v1/master/productConsumable`,
        FETCH_CATEGORY: `${serverContext}/rest/nuacare/v1/master/productService/category?searchString=`,
        SUGGESTIONS: `${serverContext}/rest/nuacare/v1/master/productService/category?parent=Consumables&searchString=`,
        FETCH_UOM: `${serverContext}/rest/nuacare/v1/master/productService/uom?searchString=`,
        RETIRE_CONSUMABLE: `${serverContext}/rest/nuacare/v1/master/productConsumable/deleteInBatch`,
        ACTIVATE_CONSUMABLE: `${serverContext}/rest/nuacare/v1/master/productConsumable/activate`,
    },
    PRODUCT_CATEGORY: {
        BASE: `${serverContext}/rest/nuacare/v1/master/productCategory`,
        PAGES: `${serverContext}/rest/nuacare/v1/master/productCategory/products`,
        CREATE: `${serverContext}/rest/nuacare/v1/master/productCategory`,
        RETIRE_CATEGORY: `${serverContext}/rest/nuacare/v1/master/productCategory/deleteInBatch`,
        ACTIVATE_CATEGORY: `${serverContext}/rest/nuacare/v1/master/productCategory/activate`,
        CONS_CATEGS: `${serverContext}/web/nuacare/v1/common/getConsultationCategories`,
    },
    BED_TYPE: {
        PAGES: `${serverContext}/rest/nuacare/v1/ipd/bed/getBedTypeList`,
        CREATE: `${serverContext}/rest/nuacare/v1/ipd/bed/addBedType`,
    },
    BED_LAYOUT: {
        FETCH_LOCATION_LAYOUT: `${serverContext}/rest/nuacare/v1/ipd/bed/bedLocationForLocation?uuid=`,
        CREATE_LAYOUT: `${serverContext}/rest/nuacare/v1/ipd/bed/create`,
        EDIT_LAYOUT: `${serverContext}/rest/nuacare/v1/ipd/bed/editBedLayout`,

    },
    BED: {
        CREATE: `${serverContext}/rest/nuacare/v1/ipd/bed/addBed`,
    },
    SERVICES: {
        PAGES: `${serverContext}/rest/nuacare/v1/master/productCategory/services`,
        ANALYTICAL_DISTRIBUTION: `${serverContext}/rest/nuacare/v1/master/analyticalDistribution`,
    },
    DOWNLOAD_FILE: {
        WITH_TOKEN: `${serverContext}/web/nuacare/v1/file/download/withToken`,
    },
    VITALS_QUEUE: {
        FETCH_QUEUE: `${serverContext}/rest/nuacare/v1/mrs-service/vitalsQueues`,
        FETCH_QUEUE_OPTIMIZED: `${serverContext}/rest/nuacare/v1/mrs-service/vitalsQueues/optimizedPages`,
        SAVE_VITALS: `${serverContext}/rest/nuacare/v1/obs/saveVitals`,
    },
    ORDERS_QUEUE: {
        FETCH_QUEUE: `${serverContext}/rest/nuacare/v1/mrs-service/orders/getOrdersOfOrderTypeQueue`,
        SAVE_ORDERS: `${serverContext}/rest/nuacare/v1/obs/saveVitals`,
    },
    ACCOUNT_INVOICE: {
        CREATE: `${serverContext}/rest/nuacare/v1/accountInvoice`,
        EDIT_INVOICE: `${serverContext}/rest/nuacare/v1/accountInvoice//editInvoice`,
        CANCEL: `${serverContext}/rest/nuacare/v1/accountInvoice/cancelInvoice/`,
        CREATE_CREDIT_MEMO: `${serverContext}/rest/nuacare/v1/accountInvoice/creditMemo`,
        DELETE_CREDIT_MEMO: `${serverContext}/rest/nuacare/v1/accountInvoice/deleteCreditMemo?accountInvoiceUuid=`,
        CHANGE_CREDIT_MEMO_DATE: `${serverContext}/rest/nuacare/v1/accountInvoice/changeCreditMemoDate?accountInvoiceUuid=`,
        BY_PURCHSE_ORDER: `${serverContext}/rest/nuacare/v1/accountInvoice/byPurchaseOrder?purchaseOrderUuid=`,
        CHANGE_ORDER_CONCEPT:
            `${serverContext}/rest/nuacare/v1/registration/updateOrderConcept?mrsOrderUuid=`,
        GET_INVOICE: `${serverContext}/rest/nuacare/v1/accountInvoice`,
        FETCH_INVOICES: `${serverContext}/rest/nuacare/v1/accountInvoice/getInvoicesDetails?invoiceUuids=`,
        REG_BILL: `${serverContext}/rest/nuacare/v1/registration/getRegistrationBill?invoiceUuid=`,
        PENDING_OPEN_INVOICES: `${serverContext}/rest/nuacare/v1/accountInvoice/openInvoicesForPatient?patientUuid=`,
        INVOICE_PAYMENTS: `${serverContext}/rest/nuacare/v1/accountInvoice/invoicePayments`,
        OPEN_INVOICE_PAYMENTS: `${serverContext}/rest/nuacare/v1/accountInvoice/invoicePayments/open`,
        GET_PRINT_DOCUMENTS: `${serverContext}/rest/nuacare/v1/accountInvoice/getPrintDocuments?uuid=`,
        PREVIOUS_PURCHASE_INVOICE_LINES: `${serverContext}/rest/nuacare/v1/accountInvoice/previousPurchaseInvoiceLines`,
        GET_PAGES: `${serverContext}/rest/nuacare/v1/accountInvoice/getCustomerInvoicesOptimised`,
        CREATE_ISSUE_INVOICE: `${serverContext}/rest/nuacare/v1/accountInvoice/createIssueInvoice`,
        GET_ISSUE_INVOICE_PRINT: `${serverContext}/rest/nuacare/v1/accountInvoice/getIssueInvoicePrint`,
    },
    ACCOUNT_EXPENSE: {
        CREATE: `${serverContext}/rest/nuacare/v1/accountExpense/create`,
        FETCH: `${serverContext}/rest/nuacare/v1/accountExpense`,
        FETCH_PAGES: `${serverContext}/rest/nuacare/v1/accountExpense/pages`,
        GET_EMPLOYEE_SHARE_STATUS: `${serverContext}/rest/nuacare/v1/accountExpense/getEmployeeShareExistsOrNot`,
    },
    ACCOUNT_VOUCHER: {
        FETCH_PAGES: `${serverContext}/rest/nuacare/v1/advancePayment/pages`,
        FETHC_BY_ID: `${serverContext}/rest/nuacare/v1/advancePayment/findOne`,
        EDIT_PAYMENT_DATE: `${serverContext}/rest/nuacare/v1/advancePayment/editVoucherPaymentDate?id=`,
        FIND_INVOICE_PAYMENT: `${serverContext}/rest/nuacare/v1/advancePayment/findPayment`,
        FETCH_SEEKABLE_PAGES: `${serverContext}/rest/nuacare/v1/advancePayment/seekablePages`,
        PAY_OR_RECEIVE_ON_ACCOUNT: `${serverContext}/rest/nuacare/v1/advancePayment/payOrReceiveOnAccount`,
        CANCEL_VOUCHER: `${serverContext}/rest/nuacare/v1/advancePayment/cancelVoucher?voucherId=`,
    },
    ADVANCE_PAYMENT: {
        CREATE: `${serverContext}/rest/nuacare/v1/advancePayment/user`,
        FETCH_BY_ID: `${serverContext}/rest/nuacare/v1/advancePayment/id/`,
        FETCH_BY_UUID: `${serverContext}/rest/nuacare/v1/advancePayment/uuid/`,
    },
    ERP_TRANSACTION: {
        PAGES: `${serverContext}/rest/nuacare/v1/erpService/metaData/transaction/pages`,
    },
    PAYMENT: {
        REGISTER_PAYMENT: `${serverContext}/rest/nuacare/v1/accountInvoice/makePayment`,
    },
    MASTERS:
    {
        SALARY_RULE: `${serverContext}/rest/nuacare/v1/master/salaryRule`,
        SALARY_STRUCTURE: `${serverContext}/rest/nuacare/v1/master/salaryStructure`,
        RULE_INPUT_CODE: `${serverContext}/rest/nuacare/v1/master/ruleInputCode`,
        HR_JOBS: `${serverContext}/rest/nuacare/v1/master/hrJob`,
        SALES_SHOP: `${serverContext}/rest/nuacare/v1/master/saleShop`,
        HR_LEAVE_PLAN: `${serverContext}/rest/nuacare/v1/master/hrLeavePlan`,
        HR_LEAVE_TYPE: `${serverContext}/rest/nuacare/v1/master/leaveType`,
        CONTRACT_TYPE: `${serverContext}/rest/nuacare/v1/master/contractType`,
        WORK_HOUR_TEMPLATE: `${serverContext}/rest/nuacare/v1/master/workHourTemplate`,
        WORK_SHIFT: `${serverContext}/rest/nuacare/v1/master/workShift`,
        PRIVILEGE: `${serverContext}/rest/nuacare/v1/master/privilege`,
        ROLE: `${serverContext}/rest/nuacare/v1/master/role`,
        ACCOUNT_TAX: `${serverContext}/rest/nuacare/v1/master/accountTax`,
        HR_DEPARTMENT: `${serverContext}/rest/nuacare/v1/master/hrDepartment`,
        DEFAULT_JOURNAL_CREATE: `${serverContext}/rest/nuacare/v1/master/defaultJournal/create`,
        DEFAULT_JOURNAL_PAGES: `${serverContext}/rest/nuacare/v1/master/defaultJournal/pages`,
        IR_SEQUENCE_CREATE: `${serverContext}/rest/nuacare/v1/master/irSequence/create`,
        IR_SEQUENCE_PAGES: `${serverContext}/rest/nuacare/v1/master/irSequence/pages`,
    },
    MEDICATION: {
        GENERICS: `${serverContext}/rest/nuacare/v1/master/genericDrugs`,
        FREQUENCY: `${serverContext}/rest/nuacare/v1/master/dosageFrequency`,
        DRUG_ROUTES: `${serverContext}/rest/nuacare/v1/master/drugRoutes`,
        DRUG_CLASS: `${serverContext}/rest/nuacare/v1/master/drugClass`,
        DOSAGE_INSTRUCTION: `${serverContext}/rest/nuacare/v1/master/dosageInstructions`,
        DOSAGE_FORM: `${serverContext}/rest/nuacare/v1/master/dosageForm`,
        ALLERGY_REACTION: `${serverContext}/rest/nuacare/v1/master/allergyReaction`,
        ALLERGY_SEVERITY: `${serverContext}/rest/nuacare/v1/master/allergySeverity`,
        DOSAGE_UNITS: `${serverContext}/rest/nuacare/v1/master/drugDosageForms`,
        WARNINGS: `${serverContext}/rest/nuacare/v1/master/drugInstructions`,

    },
    DIAGNOSIS: {
        LIST: `${serverContext}/rest/nuacare/v1/master/diagnosis`,
    },
    HR_PAYROLL_PERIOD: {
        PAGE: `${serverContext}/rest/nuacare/v1/hr/payroll/payrollPeriods`,
        CREATE: `${serverContext}/rest/nuacare/v1/hr/payroll/period/create`,
        CREATE_FOR_YEAR: `${serverContext}/rest/nuacare/v1/hr/payroll/period/createForAYear`,
        UPDATE_STATE: `${serverContext}/rest/nuacare/v1/hr/payroll/period/updateState`,
    },
    PRIV_CARD_COMPANY: {
        PAGE: `${serverContext}/rest/nuacare/v1/erp-service/patientPrivilegeCard/privilegeCardCompany/pages`,
        ADD_OR_UPDATE: `${serverContext}/rest/nuacare/v1/erp-service/patientPrivilegeCard/privilegeCardCompany/addOrUpdate`,
        ACTIVATE_OR_DEACTIVATE: `${serverContext}/rest/nuacare/v1/erp-service/patientPrivilegeCard/privilegeCardCompany/activateOrDeactivate`,
    },
    APPOINTMENT: {
        APPOINTMENT_TYPES: `${serverContext}/rest/nuacare/v1/department/list/appointmentTypes`,
        APPOINTMENT_SLOTS: `${serverContext}/rest/nuacare/v1/appointment/slotsForDay`,
        APPOINTMENT_BASE: `${serverContext}/rest/nuacare/v1/appointment`,
        CHECK_IN_PAID_APPOINTMENT:
            `${serverContext}/rest/nuacare/v1/registration/checkInPatientAndAddToQueue?appointmentUuid=`,
        APPOINTMENT_LIST: `${serverContext}/rest/nuacare/v1/appointment/list`,
        RECALL_LIST: `${serverContext}/rest/nuacare/v1/appointment/recallList`,
        ADD_COMMENT_TO_RECALL_LIST:
            `${serverContext}/rest/nuacare/v1/appointment/recallList/addComment?recallListUuid=`,
        ADD_TO_RECALL_LIST: `${serverContext}/rest/nuacare/v1/appointment/recallList/add`,
        SEARCH_PATIENT: `${serverContext}/rest/nuacare/v1/appointment/searchPatient?`,
        GET_PROVIDERS_WITH_MINIMAL_DEPENDENT_VALUES: `${serverContext}/rest/nuacare/v1/providerAvailability/getProvidersWithMinimalDependentValues`,
        SEARCH_PROVIDER:
            `${serverContext}/rest/nuacare/v1/providerAvailability/providersWithDependentValues?searchString=`,
        SEARCH_PROVIDER_MINIMAL_DETAILS: `${serverContext}/rest/nuacare/v1/providerAvailability/providersWithDependentValues?minimal=true`,
        SEARCH_PROVIDER_IGNORE_PRODUCTS:
            `${serverContext}/rest/nuacare/v1/providerAvailability/providersWithDependentValuesForProducts`,
        GET_PROVIDER_AVAILABILITY_FOR_PROVIDER:
            `${serverContext}/rest/nuacare/v1/providerAvailability/getProviderAvailability?providerUuid=`,
        SEARCH_PROVIDER_OF_DEPARTMENT:
            `${serverContext}/rest/nuacare/v1/providerAvailability/providersWithDependentValues?department=`,
        SEARCH_PROVIDERS_FOR_PRODUCT_CATEGORY:
            `${serverContext}/rest/nuacare/v1/providerAvailability/providersForCurrentProductCategory?productCategoryUuid=`,
        SEARCH_SERVICE:
            `${serverContext}/rest/nuacare/v1/department/service/search?searchString=`,
        SEARCH_ALL_SERVICE:
            `${serverContext}/rest/nuacare/v1/department/service/all?searchString=`,
        SEARCH_DEPARTMENT:
            `${serverContext}/rest/nuacare/v1/department/department/search?searchString=`,
        DEPARTMENT: {
            FETCH_LIST: `${serverContext}/rest/nuacare/v1/department/list/departments`,
            BASE_URL: `${serverContext}/rest/nuacare/v1/department`,
        },
        PROVIDERS_IN_SERVICE: `${serverContext}/rest/nuacare/v1/providerAvailability/providers?serviceTypeUuid=`,
        DOCTOR_SLOT: {
            CREATE: `${serverContext}/rest/nuacare/v1/providerAvailability/weekRepeatTemplate`,
            ACTIVE_APPOINTMENTS: `${serverContext}/rest/nuacare/v1/providerAvailability/getActiveAppointment`,
            DELETE_PROVIDER: `${serverContext}/rest/nuacare/v1/providerAvailability/provider/activeAppointment`,
            UPDATE_DAILY_SCHEDULE: `${serverContext}/rest/nuacare/v1/providerAvailability/dailySchedule`,
            UPDATE_SLOT: `${serverContext}/rest/nuacare/v1/providerAvailability/updateSlots`,
            DELETE_SLOT: `${serverContext}/rest/nuacare/v1/providerAvailability/removeSlots`,
            RUN_SCHEDULER: `${serverContext}/rest/nuacare/v1/providerAvailability/weekRepeatTemplate/runScheduler`,
        },
        SLOT_TEMPLATE: `${serverContext}/rest/nuacare/v1/providerAvailability/template`,
        SERVICES_FOR_PROVIDER:
            // eslint-disable-next-line
            `${serverContext}/rest/nuacare/v1/providerAvailability/providersWithDependentValuesForProvider?providerUuid=`,
    },
    ADD_STOCK: {
        CREATE: `${serverContext}/rest/nuacare/v1/erpService/stockMove/addBatches`,
        PAGES: `${serverContext}/rest/nuacare/v1/erpService/stockMove/pages`,
    },
    ACCOUNT_TRANSFER: {
        CREATE: `${serverContext}/rest/nuacare/v1/accountMove/create`,
        FETCH_BY_ID: `${serverContext}/rest/nuacare/v1/accountMove/fundTransfer`,
        PAGES: `${serverContext}/rest/nuacare/v1/accountMove/fundTransfer/pages`,
        GET_ACCOUNT_BALANCE: `${serverContext}/rest/nuacare/v1/accountAccount/balance?accountId=`,
    },
    ACCOUNT_MOVES: {
        GET_PAGES: `${serverContext}/rest/nuacare/v1/accountMove/pages`,
        GET_SEEKABLE_PAGES: `${serverContext}/rest/nuacare/v1/accountMove/optimizedPages`,
        GET_ONE: `${serverContext}/rest/nuacare/v1/accountMove?uuid=`,
        GET_ONE_BY_ID: `${serverContext}/rest/nuacare/v1/accountMove/id`,
    },
    RECEIVE_PAYMENT: {
        OPEN_INVOICES_FOR_USER: `${serverContext}/rest/nuacare/v1/accountInvoice/supplierOpenInvoices`,
        INVOICES_FOR_USER: `${serverContext}/rest/nuacare/v1/accountInvoice/all/forUser`,
        CREATE: `${serverContext}/rest/nuacare/v1/accountInvoice/makePayment`,
    },
    ACCOUNT_HEAD: {
        CREATE: `${serverContext}/rest/nuacare/v1/master/accountAccount`,
        PAGES: `${serverContext}/rest/nuacare/v1/master/accountAccount`,
        FIND_BALANCES: `${serverContext}/rest/nuacare/v1/master/accountAccount/getAccountBalances?id=`,
        GET_SANITY_CHECK_DATA: `${serverContext}/rest/nuacare/v1/master/accountAccount/getSanityChecksData`,
        TOP_NODES: `${serverContext}/rest/nuacare/v1/accountAccount/top`,
        CHILD_NODES: `${serverContext}/rest/nuacare/v1/accountAccount/child`,
        TOP_NODES_V2: `${serverContext}/rest/nuacare/v1/accountAccount/top_v2`,
        CHILD_NODES_v2: `${serverContext}/rest/nuacare/v1/accountAccount/child_v2`,
        UPDATE_BALANCE: `${serverContext}/rest/nuacare/v1/accountAccount/updateBalance`,
    },
    INTERNAL_MOVE: {
        CREATE: `${serverContext}/rest/nuacare/v1/erpService/stockMove/internalMoves`,
        REVERSE: `${serverContext}/rest/nuacare/v1/erpService/stockMove/reverseTransfer`,
    },
    STOCK_INTENT: {
        CREATE: `${serverContext}/rest/nuacare/v1/erpService/stockIntent/addOrUpdate`,
        FETCH_STOCK_INTENT_BY_UUID: `${serverContext}/rest/nuacare/v1/erpService/stockIntent/getIntent?uuid=`,
        FETCH_STOCK_INTENT_BY_ID: `${serverContext}/rest/nuacare/v1/erpService/stockIntent/getIntentById?id=`,
        MARK_AS_COMPLTE: `${serverContext}/rest/nuacare/v1/erpService/stockIntent/completeIntent?intentUuid=`,
        PAGES: `${serverContext}/rest/nuacare/v1/erpService/stockIntent/pages`,
        LIST: `${serverContext}/rest/nuacare/v1/erpService/stockIntent/list`,
    },
    REPORT: {
        SQL_LIVE_REPORT: '/rest/nuacare/v1/genericreport/getSqlReport',
        ADD_REPORT: '/rest/nuacare/v1/genericreport/addReportRequest',
        DOWNLOAD_REPORT: '/rest/nuacare/v1/file/download/withToken/',
        REPORT_STATUS: '/rest/nuacare/v1/genericreport/checkReportStatus',
        GET_AGING_REPORT_PAYER: `${serverContext}/rest/nuacare/v1/accountInvoice/aging-report/payer`,
        DOWNLOAD_AGING_REPORT_PAYER: `${serverContext}/rest/nuacare/v1/accountInvoice/aging-report/download`,
        GET_PAYOR_INVOICES: `${serverContext}/rest/nuacare/v1/accountInvoice/aging-report/invoice`,
        WRITE_OFF: `${serverContext}/rest/nuacare/v1/accountInvoice/aging-report/writeoff`,
        FAVORITE_REPORTS: '/rest/nuacare/v1/favouriteReport',
    },
    ADDRESS: {
        PARTNER_ADDRESS: `${serverContext}/rest/nuacare/v1/erpService/metaData/address?partnerUuid=`,
        COMPANY_ADDRESS: `${serverContext}/rest/nuacare/v1/erpService/metaData/address?companyUuid=`,
        WAREHOUSE_ADDRESS: `${serverContext}/rest/nuacare/v1/erpService/metaData/address?warehouseUuid=`,
        COMPANY_HEADER: `${serverContext}/rest/nuacare/v1/erpService/metaData/companyHeader?subCompany=`,
    },
    INSURANCE_PRODUCT_CATEGORIES: {
        GET_ALL: `${serverContext}/rest/nuacare/v1/insurance/insurance-categories`,
        SAVE_CATEGORY: `${serverContext}/rest/nuacare/v1/insurance/insurance-category`,
    },
    QUEUE: {
        FETCH_QUEUE: `${serverContext}/rest/nuacare/v1/insurance`,
        FETCH_QUEUE_COUNT: `${serverContext}/rest/nuacare/v1/insurance/getCountOfQueue`,
        SEARCH_SERVICE_IN_QUEUE: `${queueContext}/web/nuacare/queue/searchServicesInQueue?queueId=`,
        CALL_NEXT_TOKEN: `${queueContext}/web/nuacare/queue/callNextToken?queueId=`,
        FIND_ONE: `${queueContext}/queue/findOne?queueId=`,
    },
    ORDER: {
        ORDER_DETAILS: `${serverContext}/rest/nuacare/v1/insurance/order`,
        SAVE_ORDERS: `${serverContext}/rest/nuacare/v1/mrs-service/orders/saveOrdersOnBehalf`,
        MRS_ORDER_DETAIL: `${serverContext}/rest/nuacare/v1/mrs-service/orders/getOrderDetails`,
        ORDER_OPERATION: `${serverContext}/rest/nuacare/v1/insurance/order`,
        SEND_TO_BILLING: `${serverContext}/rest/nuacare/v1/insurance/order/billing`,
        SEND_BACK_TO_APPROVAL: `${serverContext}/rest/nuacare/v1/insurance/moveOrderBackToApproval?mrsOrderUuid=`,
        CHECK_IF_ORDERED_BEFORE:
            `${serverContext}/rest/nuacare/v1/insurance/checkIfOrderHasBeenOrderedBefore?mrsOrderUuid=`,
        PROCESS_DELAYED_REQUEST:
            `${serverContext}/rest/nuacare/v1/insurance/order/delayedRequests`,
        GET_PRODUCT_DETAILS: `${serverContext}/rest/nuacare/v1/insurance/product/details`,
        SET_REPORTING_DOC: `${serverContext}/rest/nuacare/v1/mrs-service/orders/setReportingDoctorToOrder`,
        MARK_ORDER_AS_REPORTED: `${serverContext}/rest/nuacare/v1/mrs-service/orders/markOrderAsReported`,
        MARK_ORDER_AS_REVIEWED: `${serverContext}/rest/nuacare/v1/mrs-service/orders/markOrderAsReviewed`,
        MARK_ORDER_AS_VIEWED: `${serverContext}/rest/nuacare/v1/mrs-service/orders/markOrderAsViewed`,
        ADD_REPORT_FOR_ORDER: `${serverContext}/rest/nuacare/v1/mrs-service/orders/addReportToOrder`,
        DELETE_TEMP_REPORT:
            `${serverContext}/rest/nuacare/v1/mrs-service/orders/deleteTempReportForOrder`,
        CLINICAL_INBOX: `${serverContext}/rest/nuacare/v1/mrs-service/orders/getReportOrdersQueue`,
        MARK_PATIENT_REFUSED:
            `${serverContext}/rest/nuacare/v1/mrs-service/orders/markOrderAsPatientRefused?orderUuid=`,
        PROCESS_OR_DISCONTINUE_ORDER:
            `${serverContext}/rest/nuacare/v1/mrs-service/orders/processOrDiscontinueOrder`,
        REVERT_ORDER:
            `${serverContext}/rest/nuacare/v1/erpService/stockMove/revertFulfillmentStatus?orderUuid=`,
        GET_OBS_OF_ORDER: `${serverContext}/rest/nuacare/v1/obs/getObsOfOrder`,
        LAB_RESULTS_FOR_ACQUISITION_PRINT:
            `${serverContext}/rest/nuacare/v1/lab/getAccessionDetailsToPrint?acquisitionUuid=`,
        ORDERS_BILLED_FOR_PATIENT:
            `${serverContext}/rest/nuacare/v1/mrs-service/orders/ordersOfOrderTypeBilledForPatient`,
        ABORT_INSURANCE_REQUEST:
            `${serverContext}/rest/nuacare/v1/insurance/abortInsuranceRequest`,
        ALL_ORDERS_IN_ENCOUNTER: `${serverContext}/rest/nuacare/v1/mrs-service/orders/getAllOrdersOfInAEncounter`,
    },
    INSURANCE_INVOICE: {
        FETCH_INSURANCE_INVOICE_LIST: `${serverContext}/rest/nuacare/v1/accountInvoice/pages`,
        FETCH_INSURANCE_INVOICE_LINE: `${serverContext}/rest/nuacare/v1/accountInvoice/findOne/`,
        FETCH_INSURANCE_INVOICE_LINE_BY_PRODUCT_ID:
            `${serverContext}/rest/nuacare/v1/insuranceInvoice/insuranceInvoiceLine/new`,
    },
    JOURNAL_ENTRIES: {
        CREATE: `${serverContext}/rest/nuacare/v1/accountJournal/entry/create`,
        POST: `${serverContext}/rest/nuacare/v1/accountMove/post?uuids=`,
        GET_ACCOUNT_TYPE: `${serverContext}/rest/nuacare/v1/accountAccount/type?accountName=`,
    },
    ANALYTICAL_ACCOUNTS: {
        LIST: `${serverContext}/rest/nuacare/v1/erp-service/analyticalAccount/all`,
        LINE_LIST: `${serverContext}/rest/nuacare/v1/erp-service/analyticalAccount/getAnalyticalAccountLines`,
        CREATE_OR_UPDATE: `${serverContext}/rest/nuacare/v1/erp-service/analyticalAccount/createOrUpdate`,
        ACCOUNTS_UNDER_PARENT: `${serverContext}/rest/nuacare/v1/erp-service/analyticalAccount/findAccountsUnder`,
        SEARCH: `${serverContext}/rest/nuacare/v1/erp-service/analyticalAccount/suggestAccounts?searchString=`,
    },
    CLAIMS: {
        LIST: `${serverContext}/rest/nuacare/v1/insurance/claims`,
        CREATE_ERROR_JOB: `${serverContext}/rest/nuacare/v1/insurance/claims/createClaimsErrorCheckJob`,
        OTHER_CLAIM_COPAYS:
            `${serverContext}/rest/nuacare/v1/insurance/claims/getClaimsAndCopayCollectedInOtherClaims?claimUuid=`,
        CLAIM_REPORT_ORDERS: `${serverContext}/rest/nuacare/v1/core/jobQueue/addClaimOrderWithoutReportCsvJob`,
        CLAIM_CSV_DOWNLOAD_JOB: `${serverContext}/rest/nuacare/v1/insurance/claims/createClaimDownloadCsvJob`,
        SEARCH: `${serverContext}/rest/nuacare/v1/insurance/claims/searchClaims`,
        SEARCH_DRAFTS: `${serverContext}/rest/nuacare/v1/insurance/claims/searchClaims?states=DRAFT,REVERTED&searchString=`,
        MERGE: `${serverContext}/rest/nuacare/v1/insurance/claims/mergeClaims?fromClaim=`,
        UNMERGE: `${serverContext}/rest/nuacare/v1/insurance/claims/unmergeClaim?claimUuid=`,
        CLAIM_COUNT: `${serverContext}/rest/nuacare/v1/insurance/claims/claimCount`,
        DETAILS_OF_CLAIM: `${serverContext}/rest/nuacare/v1/insurance/claims/details?claimUuid=`,
        DETAILS_OF_CLAIM_FOR_MERGE:
            `${serverContext}/rest/nuacare/v1/insurance/claims/getDetailsForMerge?claimUuid=`,
        CHANGE_INSURANCE_CARD_OF_CLAIM:
            `${serverContext}/rest/nuacare/v1/core/episodeOfCare/changeEpisodeInsuranceCard?episodeOfCareUuid=`,
        ARCHIVE_CLAIM: `${serverContext}/rest/nuacare/v1/insurance/claims/archiveClaims?claimUuid=`,
        UCAF_DETAILS: `${serverContext}/rest/nuacare/v1/insurance/claims/claimForm?claimUuid=`,
        UPLOAD_CLAIM_DOCS: `${serverContext}/rest/nuacare/v1/insurance/claims/uploadClaimDocuments?claimUuid=`,
        DELETE_CLAIM_DOCS: `${serverContext}/rest/nuacare/v1/insurance/claims/removeClaimDocument?claimUuid=`,
        ADD_APPROVAL_INFO: `${serverContext}/rest/nuacare/v1/insurance/claims/addApprovalInfo?claimUuid=`,
        DELETE_APPROVAL_INFO: `${serverContext}/rest/nuacare/v1/insurance/claims/deleteApprovalInfo?claimUuid=`,
        CONFIRM_CLAIM: `${serverContext}/rest/nuacare/v1/insurance/claims/confirmClaims?claimUuid=`,
        CONFIRM_CLAIMS_IN_BULK: `${serverContext}/rest/nuacare/v1/insurance/claims/confirmClaimsInBulk?claimUuid=`,
        REVERT_CLAIM: `${serverContext}/rest/nuacare/v1/insurance/claims/unConfirmClaims?claimUuid=`,
        UCAF_PRINT: `${serverContext}/rest/nuacare/v1/insurance/claims/claimFormJasper?claimUuid=`,
        CONS_INV_PRINT: `${serverContext}/rest/nuacare/v1/insurance/claims/consolidatedClaimInvoice?claimUuid=`,
        CONS_INV_PRINT_BY_COUNTERPART: `${serverContext}/rest/nuacare/v1/insurance/claims/consolidatedClaimInvoiceByCounterPartInvoice`,
        CONS_INV_PRINT_MULTI:
            `${serverContext}/rest/nuacare/v1/insurance/claims/consolidatedMultiClaimInvoice?claimUuid=`,
        CLAIMS_OF_EPISODE:
            `${serverContext}/rest/nuacare/v1/insurance/claims/getClaimsForEpisode?episodeOfCareUuid=`,
        GET_OTHER_CLAIM_OF_CLAIM_EPISODE:
            `${serverContext}/rest/nuacare/v1/insurance/claims/getOtherClaimsOfClaimEpisode?claimUuid=`,
        CHANGE_PAYER_OF_CLAIM:
            `${serverContext}/rest/nuacare/v1/insurance/claims/changePayerOfClaim`,
        DOWNLOAD_CLAIM: `${serverContext}/rest/nuacare/v1/insurance/claims/downloadAll`,
        PRINT_ORDER_REPORTS: `${serverContext}/rest/nuacare/v1/insurance/claims/printOrderReports`,
        CHECK_VITALS: `${serverContext}/rest/nuacare/v1/insurance/claims/checkIfVitalsEnteredForClaims?claimUuid=`,
        DOWNLOAD_E_CLAIM: `${serverContext}/rest/nuacare/v1/insurance/claims/exportEClaim?type=CLAIM&claimUuid=`,
        DOWNLOAD_STATUS: `${serverContext}/rest/nuacare/v1/mrs-service/commonTask/findWithRefAndType`,
        GET_ERROR_CHECK_LIST: `${serverContext}/rest/nuacare/v1/insurance/claims/getPagesOfClaimCheckJobs`,
        PROCESS_CCD_DATA: `${serverContext}/rest/nuacare/v1/insurance/claims/processCsvData?claimUuid=`,
        PROCESS_CCD_DATA_FOR_MULTIPLE_CLAIMS:
            `${serverContext}/rest/nuacare/v1/insurance/claims/processCsvDataForMultipleClaims`,
        MARK_FOR_RESUBMISSION: `${serverContext}/rest/nuacare/v1/insurance/claims/resubmitClaim?claimUuid=`,
        UNDO_MARK_FOR_RESUBMISSION: `${serverContext}/rest/nuacare/v1/insurance/claims/undoResubmitClaim?claimUuid=`,
        MARK_OR_UNMARK_ALL_FOR_RESUBMISSION: `${serverContext}/rest/nuacare/v1/insurance/claims/markOrUnmarkMultipleClaims`,
        GET_CLAIMS_FOR_BUNDLE_CREATION:
            `${serverContext}/rest/nuacare/v1/insurance/claims/getClaimsForBundleCreation`,
        GET_COUNT_AND_AMOUNT_OF_CLAIMS_FOR_STATES:
            `${serverContext}/rest/nuacare/v1/insurance/claims/getCountAndAmountOfClaimsForStates`,
        ADD_ENCOUNTER_TO_CLAIM: `${serverContext}/rest/nuacare/v1/insurance/claims/addEncounterToClaim?claimUuid=`,
        PRINT_SELECTIVE_PRESCRIPTIONS: `${serverContext}/rest/nuacare/v1/insurance/claims/printSelectivePrescriptions`,
        REVIVE_CLAIMS: `${serverContext}/rest/nuacare/v1/insurance/claims/reviveClaims?claimUuid=`,
        GET_CLAIMS_LEFT_TO_PUSH: `${serverContext}/rest/nuacare/v1/insurance/claims/getClaimsLeftToPush?bundleUuid=`,
        GET_COPAY_USAGE_DATA_EXCLUDING_CLAIM:
            `${serverContext}/rest/nuacare/v1/insurance/claims/getCoPayUsageDataExcludingClaim`,
        GET_COPAY_USAGE_DATA_EXCLUDING_INVOICE:
            `${serverContext}/rest/nuacare/v1/insurance/claims/getCoPayUsageDataExcludingInvoice`,
        UPDATE_CLAIM_DETAILS: `${serverContext}/rest/nuacare/v1/insurance/claims/updateClaimDetails`,
        UPDATE_EPISODE_PAYER_DETAILS: `${serverContext}/rest/nuacare/v1/insurance/claims/updateEpisodePayerDetails`,
        RESET_INVOICES_OF_EPISODE:
            `${serverContext}/rest/nuacare/v1/insurance/claims/resetInvoicesOfEpisode`,
        GET_PARTNER_DTO_FOR_PAYER: `${serverContext}/rest/nuacare/v1/accountInvoice/getPartnerDtoForPayer`,
    },
    BUNDLES: {
        LIST: `${serverContext}/rest/nuacare/v1/insurance/bundles`,
        SEEKABLE_LIST: `${serverContext}/rest/nuacare/v1/insurance/bundles/seekAblePages`,
        WRITE_OFF_AMOUNT: `${serverContext}/rest/nuacare/v1/insurance/bundles/writeOffBundle?bundleUuid=`,
        EDIT_WRITE_OFF_AMOUNT: `${serverContext}/rest/nuacare/v1/insurance/bundles/editWriteOff?bundleUuid=`,
        EDIT_PAYMENT: `${serverContext}/rest/nuacare/v1/insurance/bundles/editPayment?bundleUuid=`,
        SET_BUNDLE_DISCOUNTS: `${serverContext}/rest/nuacare/v1/insurance/bundles/setBundleDiscounts`,
        MARK_AS_PAID: `${serverContext}/rest/nuacare/v1/insurance/bundles/markBundleAsPaid?bundleUuid=`,
        MARK_AS_UNPAID: `${serverContext}/rest/nuacare/v1/insurance/bundles/markBundleAsUnpaid?bundleUuid=`,
        CREATE_BUNDLE: `${serverContext}/rest/nuacare/v1/insurance/bundles/createBundle`,
        EDIT_BUNDLE_DETAILS: `${serverContext}/rest/nuacare/v1/insurance/bundles/editBundleDetails`,
        CREATE_BUNDLE_FROM_FILTER: `${serverContext}/rest/nuacare/v1/insurance/bundles/createBundleFromClaims`,
        DETAILS: `${serverContext}/rest/nuacare/v1/insurance/bundles/details?bundleUuid=`,
        ARCHIVE_BUNDLE: `${serverContext}/rest/nuacare/v1/insurance/bundles/archiveBundles?bundleUuid=`,
        GET_TASKS_ASSOCIATED_WITH_A_BUNDLE: `${serverContext}/rest/nuacare/v1/insurance/bundles/getTasksAssociatedWithABundle?bundleUuid=`,
        REGISTER_PAYMENT: `${serverContext}/rest/nuacare/v1/insurance/bundles/receivePayment?bundleUuid=`,
        SUBMIT_BUNDLES: `${serverContext}/rest/nuacare/v1/insurance/bundles/submitBundles?bundleUuid=`,
        DOWNLOAD_BUNDLE: `${serverContext}/rest/nuacare/v1/insurance/bundles/downloadAll?bundleUuid=`,
        BUNDLE_PAYERS: `${serverContext}/rest/nuacare/v1/insurance/bundles/bundlePayers?bundleUuid=`,
        PRINT_CREDIT_STATEMENT: `${serverContext}/rest/nuacare/v1/insurance/bundles/creditStatement`,
        PRINT_POLICY_STATEMENT: `${serverContext}/rest/nuacare/v1/insurance/bundles/printPolicyStatement?bundleUuid=`,
        PRINT_COVER_LETTER: '/rest/nuacare/v1/jobTrigger/bundle/print?uuid=',
        PRINT_TAX_SUBMISSION_LETTER: '/rest/nuacare/v1/jobTrigger/bundle/taxSubmission?uuid=',
        PROCESS_CCD_DATA: `${serverContext}/rest/nuacare/v1/insurance/bundles/processCsvData?bundleUuid=`,
        DELETE_BUNDLE: `${serverContext}/rest/nuacare/v1/insurance/bundles/deleteBundle?bundleUuid=`,
        DELETE_PAYMENT: `${serverContext}/rest/nuacare/v1/insurance/bundles/deletePayment?bundlePaymentId=`,
        SUBMISSION_SUMMARY: `${serverContext}/rest/nuacare/v1/insurance/bundles/bundlePushSummary?bundleUuid=`,
        CLAIMS: `${serverContext}/rest/nuacare/v1/insurance/bundles/claimsOfBundle`,
        SEARCH_CLAIMS_IN_BUNDLE:
            `${serverContext}/rest/nuacare/v1/insurance/bundles/searchClaimsOfBundle?bundleUuid=`,
        PUSH_BUNDLE:
            `${serverContext}/rest/nuacare/v1/insurance/bundles/createPushJobForBundle?bundleUuid=`,
        REMOVE_CLAIM_FROM_BUNDLE:
            `${serverContext}/rest/nuacare/v1/insurance/bundles/removeClaimFromBundle?claimUuid=`,
        ADD_CLAIM_TO_BUNDLE:
            `${serverContext}/rest/nuacare/v1/insurance/bundles/addClaimToBundle?claimUuid=`,
        DOWNLOAD_BUNDLE_CSV: '/rest/nuacare/v1/miscReports/downloadBundleCsv?bundleUuid=',
        DOWNLOAD_SUBMISSION_ERRORS: `${serverContext}/rest/nuacare/v1/insurance/bundles/downloadSubmissionErrors?bundleUuid=`,
        MARK_BUNDLE_FOR_RESUBMISSION:
            `${serverContext}/rest/nuacare/v1/insurance/bundles/markBundleForRePush?bundleUuid=`,
        MERGE: `${serverContext}/rest/nuacare/v1/insurance/bundles/mergeBundles`,
        UN_MERGE: `${serverContext}/rest/nuacare/v1/insurance/bundles/unMergeBundle`,
        PRE_CHECK: `${serverContext}/rest/nuacare/v1/insurance/bundles/isPreCheckRequired?bundleUuid=`,
        CHECK_ERRORS: `${serverContext}/rest/nuacare/v1/insurance/bundles/checkPossibleErrors?bundleUuid=`,
        DOWNLOAD_ERRORS: `${serverContext}/rest/nuacare/v1/insurance/bundles/downloadPossibleErrorDetails?bundleUuid=`,

    },
    NOTIFICATIONS: {
        GET_NOTIFICATION_SETTINGS: `${serverContext}/rest/nuacare/v1/hr/hr/notifications/getNotificationSettings`,
        SAVE_NOTIFICATION_SETTINGS: `${serverContext}/rest/nuacare/v1/hr/hr/notifications/saveNotificationSettings`,
        COUNT_OF_UNREAD: `${serverContext}/rest/nuacare/v1/hr/hr/notifications/countOfUnread`,
        READ_ANNOUNCEMENTS: `${serverContext}/rest/nuacare/v1/hr/hr/notifications/readNotifications`,
        ALL_NOTIFICATION_FOR_USER: `${serverContext}/rest/nuacare/v1/hr/hr/notifications/`,
    },
    SERVICE_SETTING: {
        GET_PRODUCTS_LIST: `${serverContext}/rest/nuacare/v1/master/productService`,
    },
    PACKAGE_SERVICES: {
        PACKAGE: `${serverContext}/rest/nuacare/v1/master/packageProduct`,
        FIND_PACKAGE:
            `${serverContext}/rest/nuacare/v1/master/packageProduct/findPackageByProduct?productId=`,
        DELETE_PACKAGE_LINE:
            `${serverContext}/rest/nuacare/v1/master/packageProduct/removeLineFromPackage?packageProductLine=`,
    },
    CONCEPTS_UNDER_CONCEPT_SET: `${serverContext}/rest/nuacare/v1/concept/getConceptsUnderConceptSet`,
    GET_ORDER_TYPES: `${serverContext}/rest/nuacare/v1/mrs-service/orders/getAllOrderTypes`,
    CLINICAL_FAVORITES: {
        SAVE: `${serverContext}/rest/nuacare/v1/mrs-service/favorites/addOrUpdateFavsForProvider`,
        FIND_BY_NAME: `${serverContext}/rest/nuacare/v1/mrs-service/favorites/findFavoritesByName`,
        FIND_BY_TYPE: `${serverContext}/rest/nuacare/v1/mrs-service/favorites/findFavorites`,
        FIND_FAV_CONCEPTS_BY_NAME:
            `${serverContext}/rest/nuacare/v1/mrs-service/favorites/findFavoriteConceptsForFavoriteName?names=`,
    },
    WEB_SOCKET: {
        OPD_QUEUE_LIST: '/topic/public/provider/queue/opd/',
        RECENT_LIST: '/topic/public/provider/queue/recent/',
        OPEN_ENCOUNTER: '/topic/public/provider/queue/open/',
        MISSED_QUEUE: '/topic/public/provider/queue/missed/',
    },
    GLOBAL_PROPERTY: {
        // OPEN API, no specific privilege needed
        GET_PROPERTIES_WEB: `${serverContext}/web/nuacare/v1/common/getProperty?list=`,
        GET_SINGLE_PROPERTY: `${serverContext}/rest/nuacare/v1/search/globalProperty?property=`,
        GET_PROPERTIES: `${serverContext}/rest/nuacare/v1/master/globalProperties/getProperty?list=`,
        GET_DISCOUNT_PROPERTIES: `${serverContext}/rest/nuacare/v1/master/globalProperties/getDiscountGlobalProperty`,
        PUT_PROPERTIES: `${serverContext}/rest/nuacare/v1/master/globalProperties/updateProperty`,
        UPDATE_PASSCODE: `${serverContext}/rest/nuacare/v1/master/globalProperties/updatePasscode?type=`,
        PAGE: `${serverContext}/rest/nuacare/v1/master/globalProperties`,
        GET_PROPERTIES_PAGE: `${serverContext}/rest/nuacare/v1/master/globalProperties/getPropertyPage?list=`,
    },
    PREPAID_EXPENSE: {
        BASE_URL: `${serverContext}/rest/nuacare/v1/erpService/prepaidExpense`,
        SAVE: `${serverContext}/rest/nuacare/v1/erpService/prepaidExpense/create`,
        PAGES: `${serverContext}/rest/nuacare/v1/erpService/prepaidExpense/pages`,
        GENERATE_PERIODIC_LINE: `${serverContext}/rest/nuacare/v1/erpService/prepaidExpense/generatePeriodicLines/`,
    },
    AUTO_TEXTS: {
        GET_ALL: `${serverContext}/rest/nuacare/v1/autoText/allGlobal`,
        ADD_OR_UPDATE: `${serverContext}/rest/nuacare/v1/autoText/addOrUpdate`,
        ACTIVATE_DEACTIVATE: `${serverContext}/rest/nuacare/v1/autoText/enableOrDisable`,
        DELETE: `${serverContext}/rest/nuacare/v1/autoText/delete`,
        CATEGORY: {
            SEARCH_CATEGORY: `${serverContext}/rest/nuacare/v1/autoText/category/searchAutoText?searchString=`,
            GET_ALL: `${serverContext}/rest/nuacare/v1/autoText/category/allCategories`,
            ADD_OR_UPDATE: `${serverContext}/rest/nuacare/v1/autoText/category/addOrUpdate`,
            ACTIVATE_DEACTIVATE: `${serverContext}/rest/nuacare/v1/autoText/category/enableOrDisable`,
        },
    },
    SHOP: {
        GET_DETAILS: `${serverContext}/rest/nuacare/v1/product/shopDetails?salesDone=true`,
        GET_REGISTRATION_DETAILS:
            `${serverContext}/rest/nuacare/v1/product/shopDetails?salesDone=true`,
        GET_SHOPS:
            `${serverContext}/rest/nuacare/v1/product/shopDetails`,
    },
    DISCOUNT_CODE: {
        FIND_ONE: `${serverContext}/rest/nuacare/v1/discountCode/findCode?code=`,
        FIND_ACTIVE: `${serverContext}/rest/nuacare/v1/discountCode/findAllActiveCodes`,
        ADD_OR_UPDATE: `${serverContext}/rest/nuacare/v1/discountCode/addOrUpdate`,
        FIND_ALL_FOR_USER: `${serverContext}/rest/nuacare/v1/discountCode/findAllActiveCodesForUser`,
    },
    VISIT_DOCUMENT: {
        SAVE_VISIT_DOCS: `${serverContext}/rest/nuacare/v1/visitDocuments/create`,
        CREATE_VISIT_DOCS: `${serverContext}/rest/nuacare/v1/visitDocuments/createWithoutSaving`,
        DELETE: `${serverContext}/rest/nuacare/v1/visitDocuments/`,
        FETCH_TEMPLATES: `${serverContext}/rest/nuacare/v1/printTemplates`,
        FETCH_ALL: `${serverContext}/rest/nuacare/v1/visitDocuments/getAll`,
        PRINT_SEQUENCE_NUMBER: `${serverContext}/rest/nuacare/v1/print/sequenceNumberPrint`,
    },
    ON_BEHALF: {
        FETCH_BEHALF_DETAILS:
            `${serverContext}/rest/nuacare/v1/mrs-service/provider/fetchOnBehalfDetails?providerUuid=`,
        UPDATE_ON_BEHALF:
            `${serverContext}/rest/nuacare/v1/mrs-service/provider/updateOnBehalfDetails?providerUuid=`,
    },
    CASH_CLOSE: {
        GET_COLLECTIONS: `${serverContext}/rest/nuacare/v1/erp-service/cashClose/getCollection`,
        PAGES: `${serverContext}/rest/nuacare/v1/erp-service/cashClose/pages`,
        CREATE: `${serverContext}/rest/nuacare/v1/erp-service/cashClose/create`,
        UPDATE_PRINT_STATUS: `${serverContext}/rest/nuacare/v1/erp-service/cashClose/print`,
        FETCH_WITH_UUID: `${serverContext}/rest/nuacare/v1/erp-service/cashClose/findWithUuid`,
        FETCH_WITH_ID: `${serverContext}/rest/nuacare/v1/erp-service/cashClose/findWithId`,
    },
    CONSOLIDATED_CASH_CLOSE: {
        GET_COLLECTIONS: `${serverContext}/rest/nuacare/v1/erp-service/consolidatedCashClose/getConsolidatedCollectionsForType`,
        PAGES: `${serverContext}/rest/nuacare/v1/erp-service/consolidatedCashClose/pages`,
        CREATE: `${serverContext}/rest/nuacare/v1/erp-service/consolidatedCashClose/createConsolidatedCashClose`,
        UPDATE_PRINT_STATUS: `${serverContext}/rest/nuacare/v1/erp-service/consolidatedCashClose/print`,
        FETCH_WITH_UUID: `${serverContext}/rest/nuacare/v1/erp-service/consolidatedCashClose/findWithUuid`,
        FETCH_WITH_ID: `${serverContext}/rest/nuacare/v1/erp-service/consolidatedCashClose/findWithId`,
    },
    LAB: {
        TESTS: `${serverContext}/rest/nuacare/v1/master/test`,
        TESTS_WITH_CONSTRUCT: `${serverContext}/rest/nuacare/v1/master/test/getTests`,
        PANELS_WITH_CONSTRUCT: `${serverContext}/rest/nuacare/v1/master/panel/getPanels`,
        TESTSEARCH: `${serverContext}/rest/nuacare/v1/master/test/searchForTest?idNameMap=true&searchString=`,
        TESTSEARCH_FOR_SAMPLETYPE: `${serverContext}/rest/nuacare/v1/master/test/searchForTestWithSample?idNameMap=true&`,
        TESTSEARCHLOCALEPREFERRED: `${serverContext}/rest/nuacare/v1/master/test/searchForTest?idNameMap=true&localePreferred=true&searchString=`,
        TESTS_AND_PANEL_SEARCH: `${serverContext}/rest/nuacare/v1/master/test/testAndPanelSearch?searchString=`,
        PANELS: `${serverContext}/rest/nuacare/v1/master/panel`,
        DEPARTMENT: `${serverContext}/rest/nuacare/v1/master/department`,
        SAMPLE: `${serverContext}/rest/nuacare/v1/master/typeOfSample`,
        DICTIONARY: `${serverContext}/rest/nuacare/v1/master/dictionary`,
        UOM: `${serverContext}/rest/nuacare/v1/master/unitOfMeasure`,
        VALIDATE_UOM_NAME: `${serverContext}/rest/nuacare/v1/master/unitOfMeasure/isNameUnique?name=`,
        VALIDATE_TEST_NAME: `${serverContext}/rest/nuacare/v1/concept/checkIfConceptNameIsUnique`,
        TEMPLATES: `${serverContext}/rest/nuacare/v1/master/testNoteTemplate`,
        ADD_OR_UPDATE_TEMPLATE: `${serverContext}/rest/nuacare/v1/master/testNoteTemplate/addOrUpdate`,
        ACTIVATE_OR_DEACTIVATE_TEMPLATE: `${serverContext}/rest/nuacare/v1/master/testNoteTemplate/activateOrDeactivate`,
        TEST_METHOD: `${serverContext}/rest/nuacare/v1/master/testMethod/search?searchString=`,
        TEST_METHODS: `${serverContext}/rest/nuacare/v1/master/testMethod`,
    },
    REPORT_TEMPLATE: {
        SEARCH_TEMPLATES: `${serverContext}/rest/nuacare/v1/printTemplates/searchTemplates?searchString=`,
        SEARCH_TEMPLATE_WITH_PARENT: `${serverContext}/rest/nuacare/v1/printTemplates`,
        SEARCH_CATEGORY: `${serverContext}/rest/nuacare/v1/mrs-service/reportTemplate/searchCategories?searchString=`,
        SEARCH_CATEGORY_PLAIN: `${serverContext}/rest/nuacare/v1/mrs-service/reportTemplate/searchCategories`,
        ADD_OR_UPDATE: `${serverContext}/rest/nuacare/v1/mrs-service/reportTemplate`,
        GET_BY_UUID: `${serverContext}/rest/nuacare/v1/mrs-service/reportTemplate/findByUuid?reportTemplateUuid=`,
    },
    DENTAL_SETTING: {
        CONDITIONS: `${serverContext}/rest/nuacare/v1/master/dentalConditions`,
        TREATMENTS: `${serverContext}/rest/nuacare/v1/master/dentalTreatments`,
        TREATMENTS_SEARCH_UNDER: `${serverContext}/rest/nuacare/v1/master/dentalTreatments/searchUnder?searchString=`,
    },
    PRIVILEGE_CARD: {
        ADD_OR_UPDATE: `${serverContext}/rest/nuacare/v1/erp-service/patientPrivilegeCard/addOrUpdate`,
        FETCH_ALL: `${serverContext}/rest/nuacare/v1/erp-service/patientPrivilegeCard`,
        ACTIVATE_DEACTIVATE:
            `${serverContext}/rest/nuacare/v1/erp-service/patientPrivilegeCard/activateOrDeactivate?cardUuid=`,
        CHECK_IF_CARD_AND_MOB_MATCHES:
            `${serverContext}/rest/nuacare/v1/erp-service/patientPrivilegeCard/checkIfCardNumberMatches`,
        SEARCH: `${serverContext}//rest/nuacare/v1/erp-service/patientPrivilegeCard/privilegeCardCompany/search?searchString= `,
    },
    DUTY_ROSTER: {
        ADD_OR_UPDATE: `${serverContext}/rest/nuacare/v1/hr/duty-roster`,
        FETCH_LIST: `${serverContext}/rest/nuacare/v1/hr/duty-roster`,
        SEARCH: `${serverContext}/rest/nuacare/v1/hr/duty-roster/search`,
    },
    LOCATION: {
        ADD_OR_UPDATE: `${serverContext}/rest/nuacare/v1/location/addOrUpdate`,
        FETCH_LIST: `${serverContext}/rest/nuacare/v1/location`,
        FETCH_ADMISSION_LOCATION: `${serverContext}/rest/nuacare/v1/location/admissionLocations`,
    },
    STOCK_LOCATION: {
        ADD_OR_UPDATE: `${serverContext}/rest/nuacare/v1/master/stockLocation`,
        FETCH_LIST: `${serverContext}/rest/nuacare/v1/master/stockLocation`,
    },
    DUTY_ROSTER_SHIFT: {
        ADD_OR_UPDATE: `${serverContext}/rest/nuacare/v1/hr/duty-roster-shift`,
        FETCH_LIST: `${serverContext}/rest/nuacare/v1/hr/duty-roster-shift`,
        SEARCH: `${serverContext}/rest/nuacare/v1/hr/duty-roster-shift/search?searchString=`,
    },
    DUTY_ROSTER_EMP_SHIFT: {
        ADD_OR_UPDATE: `${serverContext}/rest/nuacare/v1/hr/duty-roster-employee-shift`,
        DELETE: `${serverContext}/rest/nuacare/v1/hr/duty-roster-employee-shift/deleteShifts`,
        GET_PREV_EMP_SHIFT:
            `${serverContext}/rest/nuacare/v1/hr/duty-roster-employee-shift/getPreviousEmployeeShifts`,
        FIND_BY_ROSTER:
            `${serverContext}/rest/nuacare/v1/hr/duty-roster-employee-shift/getByRoster?rosterUuid=`,
        ALL:
            `${serverContext}/rest/nuacare/v1/hr/duty-roster-employee-shift/getAllEmployeesShift`,
    },
    RE_ORDER_RULES: {
        ADD_OR_UPDATE: `${serverContext}/rest/nuacare/v1/erpService/orderPoints/addOrUpdate`,
        DELETE: `${serverContext}/rest/nuacare/v1/erpService/orderPoints/deleteOrderPoint?orderPointUuid=`,
        ALL: `${serverContext}/rest/nuacare/v1/erpService/orderPoints/all`,
        GENERATE_CONSUMPTION_DETAILS:
            `${serverContext}/rest/nuacare/v1/erpService/metaData/consumption/generateForAYear?date=`,
        GENERATE_CONSUMPTION_DETAILS_FOR_MONTH:
            `${serverContext}/rest/nuacare/v1/erpService/metaData/consumption/generateForAMonth?date=`,
        DOWNLOAD: `${serverContext}/rest/nuacare/v1/erpService/metaData/orderpoint/generate`,
        FOR_PRODUCT:
            `${serverContext}/rest/nuacare/v1/erpService/orderPoints/getReorderPointsForProduct`,
    },
    CASH_CLOSE_TYPES: {
        ADD_OR_UPDATE: `${serverContext}/rest/nuacare/v1/erp-service/cashCloseType/createOrUpdate`,
        SEARCH: `${serverContext}/rest/nuacare/v1/erp-service/cashCloseType/searchTypes?searchString=`,
        ALL: `${serverContext}/rest/nuacare/v1/erp-service/cashCloseType/getAll`,
    },
    UPLOAD_CSV_DATA: `${serverContext}/rest/nuacare/v1/master/csvUpload/uploadCSVData?type=`,
    GENERAL_QUEUE: {
        SEARCH_QUEUE: `${queueContext}/web/nuacare/queue/searchQueue?onlyLive=true&searchString=`,
        ASSIGN_NEXT_TOKEN: `${queueContext}/web/nuacare/queue/assignNextToken?queueId=`,
        WAITING_TOKENS: `${queueContext}/web/nuacare/queue/waitingTokens?page=0&size=100`,
        ADD_TO_QUEUE: `${queueContext}/token/addToQueue`,
        GET_TOKEN: `${queueContext}/token/getToken`,
        UPDATE_TOKEN: `${queueContext}/token/updateJsonDataForToken`,
        RESET_TOKEN: `${queueContext}/token/resetToken`,
    },
    WORKMAIL_BOX: {
        PAGE: `${serverContext}/rest/nuacare/v1/erpService/workBoxMail/all`,
    },
    SUPPLEMENT_PRODUCT: {
        ADD_OR_UPDATE: `${serverContext}/rest/nuacare/v1/insurance/supplementProduct/addOrUpdate`,
        FETCH_FOR_PAYOR: `${serverContext}/rest/nuacare/v1/insurance/supplementProduct/fetchByPayer?payerUuid=`,
    },
    ERROR_LOG: `${serverContext}/rest/nuacare/v1/error/add`,
    CHECK_JOB_STATUS: `${serverContext}/rest/nuacare/v1/core/jobQueue/findStatusOfLastPurchaseReqJob`,
    DOWNLOAD_JOB_CSV: `${serverContext}/rest/nuacare/v1/core/jobQueue/downloadCsv?jobUuid=`,
    PAGE_OF_JOBS: `${serverContext}/rest/nuacare/v1/core/jobQueue/pagesOfJob`,
    PAGE_OF_TASKS: `${serverContext}/rest/nuacare/v1/mrs-service/commonTask/pages`,
    TRIGGER_REQUISITION_JOB: `${serverContext}/rest/nuacare/v1/core/jobQueue/addPurchaseOrIntMoveJob`,
    INSURANCE_CLIENT: {
        ELIGIBILITY_CHECK: schemeUuid => (
            `${insuranceClientContext}/rest/nuacare/insurance/client/v1/process/${schemeUuid}/eligibility_request`
        ),
        PUSH_CLAIM: `${insuranceClientContext}/rest/nuacare/insurance/client/v1/nphies/bridge/claim/push`,
        GET_HIDP_PROFILE: `${insuranceClientContext}/rest/nuacare/insurance/client/v1/nphies/getHidpPatientProfile?patientKey=`,
        DISCOVER_AND_GET_COVERAGES: `${insuranceClientContext}/rest/nuacare/insurance/client/v1/nphies/bridge/eligibility/discoverAndGetCoverages`,
        INSURANCE_PAYERS: `${insuranceClientContext}/rest/nuacare/insurance/client/v1/nphies/insurancePayer/search`,
        INSURANCE_PAYERS_FULL_DTO: `${insuranceClientContext}/rest/nuacare/insurance/client/v1/nphies/insurancePayer/searchForCompleteDto`,
        GET_VALID_NPHIES_AND_SFDA_CODES: `${insuranceClientContext}/rest/nuacare/insurance/client/v1/nphies/bridge/getValidNphiesAndSfdaCodes`,
    },
    EVICT: {
        DRUG:
            `${serverContext}/rest/nuacare/v1/jobTrigger/evictWithId?type=com.nuchange.nuacare.common.api.rest.domain.mrs.Drug&id=`,
        PRODUCT:
            `${serverContext}/rest/nuacare/v1/jobTrigger/evictWithId?type=com.nuchange.nuacare.common.api.rest.domain.ProductProduct&id=`,
        EPISODE:
            `${serverContext}/rest/nuacare/v1/jobTrigger/evictWithId?type=com.nuchange.nuacare.common.api.rest.domain.mrs.EpisodeOfCare&id=`,
        VISIT:
            `${serverContext}/rest/nuacare/v1/jobTrigger/evictWithId?type=com.nuchange.nuacare.common.api.rest.domain.mrs.Visit&id=`,
        CONCEPT:
            `${serverContext}/rest/nuacare/v1/jobTrigger/evictWithId?type=com.nuchange.nuacare.common.api.rest.domain.mrs.Concept&id=`,
        PERSON:
            `${serverContext}/rest/nuacare/v1/jobTrigger/evictWithId?type=com.nuchange.nuacare.common.api.rest.domain.mrs.Person&id=`,
        PATIENT:
            `${serverContext}/rest/nuacare/v1/jobTrigger/evictWithId?type=com.nuchange.nuacare.common.api.rest.domain.mrs.Patient&id=`,
    },
    IS_PRODUCT_EXEMPTED_FROM_TAX: `${serverContext}/rest/nuacare/v1/erpService/metaData/isPartnerExemptedFromTax`,
    GET_COPAY_DETAILS:
        `${serverContext}/rest/nuacare/v1/core/episodeOfCare/getCopayDetailsOfEpisode?episodeOfCareUuid=`,
    GET_COPAY_DETAILS_OF_CLAIM:
        `${serverContext}/rest/nuacare/v1/insurance/claims/getCopayCollectedInClaim?claimUuid=`,
    CLEAR_LOADING_CACHE:
        `${serverContext}/rest/nuacare/v1/core/misc/clearCache?type=`,
    CLEAR_CACHE_REGION:
        `${serverContext}/rest/nuacare/v1/jobTrigger/evictCacheRegion?cacheName=`,
    GET_QR:
        `${serverContext}/rest/nuacare/v1/core/misc/base64qr`,
    PAID_ON_ACCOUNT: {
        BASE: `${serverContext}/rest/nuacare/v1/erpService/paidOnAccount`,
        FIND: `${serverContext}/rest/nuacare/v1/erpService/paidOnAccount/find`,
        REFUND: `${serverContext}/rest/nuacare/v1/erpService/paidOnAccount/refund`,
        PAGES: `${serverContext}/rest/nuacare/v1/erpService/paidOnAccount/pages`,
        UNRECONCILED: `${serverContext}/rest/nuacare/v1/erpService/paidOnAccount/unreconciled`,
    },
    BANK_RECONCILIATION: {
        GET_MOVE_LINES_FOR_RECONCILE:
            `${serverContext}/rest/nuacare/v1/erp-service/accountBankStatement/moveLines?accountUuid=`,
        ADD_OR_UPDATE:
            `${serverContext}/rest/nuacare/v1/erp-service/accountBankStatement/addOrUpdate`,
        FIND_ONE:
            `${serverContext}/rest/nuacare/v1/erp-service/accountBankStatement/getByUuid?uuid=`,
        CHANGE_TO_DRAFT:
            `${serverContext}/rest/nuacare/v1/erp-service/accountBankStatement/changeToDraft?uuid=`,
        REMOVE_STALE_LINES:
            `${serverContext}/rest/nuacare/v1/erp-service/accountBankStatement/removeStaleLines?uuid=`,
        GET_OPENING_BALANCE:
            `${serverContext}/rest/nuacare/v1/erpService/metaData/getOpeningBalanceOfAccount?accountUuid=`,
        ALL:
            `${serverContext}/rest/nuacare/v1/erp-service/accountBankStatement/bankStatements?page=`,
    },
    ACCOUNT_CONSOLIDATION: {
        GET_CONSOLIDATION_AMOUNT:
            `${serverContext}/rest/nuacare/v1/erp-service/accountConsolidation/getConsolidatedAmount?uuid=`,
        ADD_OR_UPDATE:
            `${serverContext}/rest/nuacare/v1/erp-service/accountConsolidation/addOrUpdate`,
        FIND_ONE:
            `${serverContext}/rest/nuacare/v1/erp-service/accountConsolidation/getByUuid?uuid=`,
        CHANGE_TO_DRAFT:
            `${serverContext}/rest/nuacare/v1/erp-service/accountConsolidation/changeToDraft?uuid=`,
        REMOVE_STALE_LINES:
            `${serverContext}/rest/nuacare/v1/erp-service/accountConsolidation/removeStaleLines?uuid=`,
        GET_OPENING_BALANCE:
            `${serverContext}/rest/nuacare/v1/erpService/metaData/getOpeningBalanceOfAccount?accountUuid=`,
        ALL:
            `${serverContext}/rest/nuacare/v1/erp-service/accountConsolidation/getAccountConsolidations?page=`,
    },
    ACCOUNT: {
        GENERATE_OPENING_ENTRIES: `${serverContext}/rest/nuacare/v1/accountAccount/generateOpeningBalance?date=`,
        CHECK_IF_ENTRIES_EXISTS:
            `${serverContext}/rest/nuacare/v1/accountAccount/checkIfExistingOpeningMovesPresentForPeriod?date=`,
    },
    DOCUMENTS: {
        LIST: `${serverContext}/rest/nuacare/v1/hr/document/pages?page=`,
        ADD_OR_UPDATE: `${serverContext}/rest/nuacare/v1/hr/document/addOrUpdate`,
        DELETE: `${serverContext}/rest/nuacare/v1/hr/document/delete/`,
    },
    DOCUMENT_CATEGORY: {
        LIST: `${serverContext}/rest/nuacare/v1/hr/documentCategory/pages?page=`,
        ADD_OR_UPDATE: `${serverContext}/rest/nuacare/v1/hr/documentCategory/addOrUpdate`,
        DELETE: `${serverContext}/rest/nuacare/v1/hr/documentCategory/delete/`,
        SEARCH: `${serverContext}/rest/nuacare/v1/hr/documentCategory/search?searchString=`,
    },
    GENERIC_DRUG: {
        SEARCH: `${serverContext}/rest/nuacare/v1/master/genericDrugsAttribute/searchGenericDrug?searchString=`,
        SEARCH_OPTICAL_GENERICS:
            `${serverContext}/rest/nuacare/v1/master/genericDrugsAttribute/searchOpticalGenerics?searchString=`,
        LIST: `${serverContext}/rest/nuacare/v1/master/genericDrugsAttribute/list`,
        ADD_OR_UPDATE: `${serverContext}/rest/nuacare/v1/master/genericDrugsAttribute/addOrUpdate`,
    },
    LIMITED_SERVICES: {
        LIST: `${serverContext}/rest/nuacare/v1/master/insuranceOrderLimit`,
    },
    CASH_PAYMENT_VOUCHER: {
        GET: `${serverContext}/rest/nuacare/v1/advancePayment/getDraftCashPaymentVoucher`,
        GET_DEBIT_CREDIT_LINES_FOR_ACCOUNT:
            `${serverContext}/rest/nuacare/v1/accountMove/debitAndCreditLinesWithResidueForAccount`,
        CREATE_OR_UPDATE: `${serverContext}/rest/nuacare/v1/accountMove/createOrUpdateCashPaymentVoucher`,
        GET_DRAFT_VOUCHER_FOR_ACCOUNT: `${serverContext}/rest/nuacare/v1/advancePayment/getDraftVoucherForAccount`,

    },
    SFDA: {
        LIST: `${serverContext}/rest/nuacare/v1/insurance/sfdaGtinMap/list`,
        ADD: `${serverContext}/rest/nuacare/v1/insurance/sfdaGtinMap/addOrUpdate`,
        DELETE: `${serverContext}/rest/nuacare/v1/insurance/sfdaGtinMap/deleteById?id=`,
    },
    DASHBOARD: {
        ANNIVERSARY: `${serverContext}/rest/nuacare/v1/hr/employee/getAnniversaries`,
        BIRTHDAY: `${serverContext}/rest/nuacare/v1/hr/employee/getBirthdays`,
    },
    ORGANISATION: {
        FETCH: `${serverContext}/rest/nuacare/v1/master/organization/fetch`,
        SAVE: `${serverContext}/rest/nuacare/v1/master/organization/save`,
    },
    MEDICAL_PACKAGES: {
        PAGES: `${serverContext}/rest/nuacare/v1/medicalPackage/pages`,
        ADD_OR_UPDATE: `${serverContext}/rest/nuacare/v1/medicalPackage/addOrUpdate`,
        DELETE: `${serverContext}/rest/nuacare/v1/medicalPackage/delete`,
        BILL_MEDICAL_PACKAGES: `${serverContext}/rest/nuacare/v1/medicalPackage/getBilledMedicalPackages`,
        GET_SEQUENCE_NUMBER_PRINT: `${serverContext}/rest/nuacare/v1/print/getMedicalPackagesSequenceNumberPrint`,
    },
    SEQUENCE: {
        GET_CURRENT_SEQUENCE: `${serverContext}/rest/nuacare/v1/core/misc/getCurrentSequence`,
        UPDATE_SEQUENCE: `${serverContext}/rest/nuacare/v1/core/misc/updateSequence`,
    },
    INSURANCE: {
        GET_INSURANCE_PAYER_BY_RECEIVER_CODE: `${serverContext}/rest/nuacare/v1/insurance/getInsurancePayerByReceiverCode`,
    },
    SUB_INSURER_GROUP: {
        GET_CHILD_SUB_INSURERS: `${serverContext}/rest/nuacare/v1/insurance/subInsurerGroup/getChildSubInsurers/`,
        GET_SUB_INSURER_GROUP_UNDER_TPA: `${serverContext}/rest/nuacare/v1/insurance/subInsurerGroup/getAllUnderTpa?tpaPayerUuid=`,
        SAVE_SUB_INSURER_GROUP_UNDER_TPA: `${serverContext}/rest/nuacare/v1/insurance/subInsurerGroup/addOrUpdate`,
        DELETE_SUB_INSURER_GROUP_UNDER_TPA: `${serverContext}/rest/nuacare/v1/insurance/subInsurerGroup/delete?id=`,
    },
    GET_LATEST_PRODUCT_WEIGHT: `${serverContext}/rest/nuacare/v1/product/getLatestProductWeight`,
};
export default api;
