import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Field, change } from 'redux-form';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import { errorMessage } from '../redux/modules/message/message-actions';
import ReadOnlyTextField from '../components/ReadOnlyTextField/ReadOnlyTextField';
import SideLabelTextField from '../components/FormFieldComponents/SideLabelTextField/SideLabelTextField';
import SideLabelSelectField from '../components/FormFieldComponents/SideLabelSelectField/SideLabelSelectField';
import ButtonGroup from '../components/ButtonGroup/ButtonGroup';
import ReduxFormChipWithLabel from '../components/FormFieldComponents/ReduxFormChip/ReduxFormChipWithLabel';
import ReduxFormMaterialTable from '../components/FormFieldComponents/ReduxFormMaterialTable/ReduxFormMaterialTable';
import ReduxFormText from '../components/FormFieldComponents/ReduxFormText/ReduxFormText';
import ReduxFormRadio from '../components/FormFieldComponents/ReduxFormRadio/ReduxFormRadio';
import {
    choices,
    dateOfBirth,
    email,
    landlineNumber,
    maxLength,
    maxValue,
    minLength,
    minValue,
    number,
    phoneNumber,
    regex,
    required,
    dateShouldGreaterThan,
    dateNotLessThanToday,
    dateNotMoreThanToday,
    valueLessThanField,
    requiredOnlyIfNotEmpty,
    valueLessThanOrEqualToField,
} from './FormValidations';
import SideLabelReadOnlyText from '../components/FormFieldComponents/SideLabelReadOnlyText/SideLabelReadOnlyText';
import ReduxFormChipFileUpload from '../components/FormFieldComponents/ReduxFormChipFileUpload/ReduxFormChipFileUpload';
import SideLabelAutosuggest from '../components/FormFieldComponents/SideLabelAutosuggest/SideLabelAutosuggest';
import ReduxFormSelectField from '../components/FormFieldComponents/ReduxFormSelectField/ReduxFormSelectField';
import ReduxFormSwitch from '../components/FormFieldComponents/ReduxFormSwitch/ReduxFormSwitch';
import SelectFieldWithAction from '../components/FormFieldComponents/SelectFieldWithAction/SelectFieldWithAction';
import API from './api';
import {
    getRequiredFieldLabel,
    isArrayValidAndNotEmpty,
    isValidFunction,
} from './CommonUtil';
import ReduxFormCheckbox from '../components/FormFieldComponents/ReduxFormCheckbox/ReduxFormCheckbox';
import ReduxFormTextField from '../components/FormFieldComponents/ReduxFormTextField/ReduxFormTextField';
import InputCode from '../components/WorkflowComponent/InputCodeTable';
import TextFieldWithAsyncValidation
    from '../components/FormFieldComponents/ReduxFormTextField/TextFieldWithAsyncValidation';
import ReduxFormMenuButton from '../components/FormFieldComponents/SelectFieldWithAction/ReduxFormMenuButton';
import ReduxFormMultiFileUpload
    from '../components/FormFieldComponents/ReduxFormChipFileUpload/ReduxFormMultiFileUpload';
import MenuActionButton from '../components/FormFieldComponents/MenuActionButton/MenuActionButton';
import TextFieldWithSideInfo from '../components/FormFieldComponents/ReduxFormTextField/TextFieldWithSideInfo';
import ReactToggle from '../components/ReduxFormToggle/ReactToggle';
import OutlinedTextField from '../components/OutlinedTextField';
import NumberTextField from '../components/NumberTextField';
import ReduxFormReactSelectMaterial from '../components/ReduxFormReactSelectMaterial';
import ReduxFormNativeSelect from '../containers/RegistrationAppComponents/FormComponents/ReduxFormNativeSelect';
import DateInput from '../components/FormFieldComponents/DateInput/DateInput';
import RemoteTextValue from '../components/ReadOnlyTextField/RemoteTextValue';
import { getStringFromObject } from './lodashUtils';
import { isObjectValidAndNotEmpty } from './nullCheckUtils';

const validationFunctions = {
    required,
    number,
    choices,
    email,
    regex,
    minValue,
    maxValue,
    maxLength,
    minLength,
    phoneNumber,
    landlineNumber,
    dateOfBirth,
    dateShouldGreaterThan,
    dateNotLessThanToday,
    dateNotMoreThanToday,
    valueLessThanField,
    requiredOnlyIfNotEmpty,
    valueLessThanOrEqualToField,
};

const generateNewOrReturnOldFunction = (baseValidationFunctionName, valueForFunction, formValues, member) => {
    let valueForValidation = valueForFunction;
    if (
        baseValidationFunctionName === 'dateShouldGreaterThan' ||
        baseValidationFunctionName === 'valueLessThanField' ||
        baseValidationFunctionName === 'requiredOnlyIfNotEmpty' ||
        baseValidationFunctionName === 'valueLessThanOrEqualToField'
    ) {
        valueForValidation = member ? `${member}.${valueForValidation}` : valueForValidation;
        valueForValidation = getStringFromObject(valueForValidation, formValues);
    }
    console.log('jsadfhlaksdfhskdjf', baseValidationFunctionName, valueForValidation, formValues);
    if (!validationFunctions[`${baseValidationFunctionName}-${valueForValidation}`]) {
        if (validationFunctions[baseValidationFunctionName]) {
            validationFunctions[`${baseValidationFunctionName}-${valueForValidation}`] =
                validationFunctions[baseValidationFunctionName](valueForValidation);
        }
    }
    return validationFunctions[`${baseValidationFunctionName}-${valueForValidation}`];
};

// const getSuggestionValue = suggestion => suggestion.value;

const getSearchApi = (api, formValues, member, prependMember) => {
    let dataSourceApi = api;
    const reg = /\{(.*?)\}/;
    let t = reg.exec(dataSourceApi);
    while (t !== null) {
        let matchedParam = t[1];
        if (prependMember) {
            matchedParam = `${member}.${t[1]}`;
        }
        const value = getStringFromObject(matchedParam, formValues, '');
        dataSourceApi = dataSourceApi.replace(new RegExp(reg), value);
        t = reg.exec(dataSourceApi);
    }
    return dataSourceApi;
};
const checkParamPresentInDataSourceApi = (mandatoryParams, formValues) => {
    if (isArrayValidAndNotEmpty(mandatoryParams)) {
        for (let i = 0; i < mandatoryParams.length; i += 1) {
            const aMandatoryParam = mandatoryParams[i];
            if (formValues[aMandatoryParam] === undefined) {
                return false;
            }
        }
    }
    return true;
};

const callGetApi = async (api) => {
    const response = await axios(api);
    return response;
};

// const mapSearchResult = responseData => responseData; */

const handleOnValueChange = ({
    val, obj, fieldsToChange, formValues, dispatch, formName,
}) => {
    if (isArrayValidAndNotEmpty(fieldsToChange)) {
        fieldsToChange.map((f) => {
            if (getStringFromObject('fromObj', f, false)) {
                /*
                    check if we have to take value from selected object
                 */
                dispatch(change(formName, f.field, getStringFromObject(f.value, obj, null)));
            } else if (getStringFromObject('fromApi', f, false)) {
                /*
                    check if we have to take value from api call
                 */
                let api = getStringFromObject('api', f, '');
                let param = val;
                if (f.convertToTimestamp) {
                    param = new Date(val).getTime();
                }
                api = `${api}${param}`;
                callGetApi(api)
                    .then((response) => {
                        dispatch(change(formName, f.field, response.data));
                    })
                    .catch((e) => {
                        dispatch(errorMessage(e.response && e.response.data && e.response.data.exception));
                    });
            } else {
                /*
                    if no options then we will take from formValues
                 */
                dispatch(change(formName, f.field, getStringFromObject(f.value, formValues, null)));
            }
            return null;
        });
    }
    console.log('asdfsdafsdaf', val);
};

export const getValidationFunction = (
    validations,
    editable,
    formValues,
    customValidationFunctions,
    member = '',
) => {
    let isMandatory = false;
    let validate = [];
    if (validations && (editable)) {
        Object.keys(validations).map((validation) => {
            if (validationFunctions[validation]) {
                if (validation === 'required') {
                    isMandatory = true;
                }
                if (
                    validation === 'required' ||
                    validation === 'email' ||
                    validation === 'number' ||
                    validation === 'dateOfBirth' ||
                    validation === 'phoneNumber' ||
                    validation === 'landlineNumber' ||
                    validation === 'dateNotLessThanToday' ||
                    validation === 'dateNotMoreThanToday' ||
                    validation === 'phoneNumberValidators'
                ) {
                    validate = validations[validation] ? validate.concat(validationFunctions[validation]) : validate;
                } else {
                    console.log('asd=-aiod-a0sdjopadmlasd', validations[validation]);
                    validate =
                        (validations[validation] || validations[validation] === 0) ?
                            validate.concat(generateNewOrReturnOldFunction(validation, validations[validation],
                                formValues, member)) :
                            validate;
                }
            }
            if (isObjectValidAndNotEmpty(customValidationFunctions) &&
                isValidFunction(customValidationFunctions[validation])) {
                validate = validate.concat(customValidationFunctions[validation]);
            }
            return null;
        });
    }
    return { validate, isMandatory };
};

const generateFormFields = ({
    fieldData,
    fields,
    actionHandlers,
    ...otherProps
}) => {
    let inputComponent = null;
    const {
        dataSourceConfig,
        options,
        fieldType,
        noLabel,
        gridStyle,
        tableCells,
        fullWidth,
        validations,
        dataSourceApi,
        mandatoryApiParams,
        replaceText,
        showInReadOnlyMode,
        hideInEditableMode,
        showInNonEditableMode,
        enableIfFormValue,
        enableIfNotNull,
        isComponent,
        fieldsToChange,
        useMemberForValidation,
        ...propsForInput
    } = fieldData;
    const { paramMap } = otherProps;
    const {
        handleButtonAction,
        handleSelectAction,
        disabled,
        formValues,
        dispatch,
        formName,
        fieldChoices,
        actionParams,
        customValidationFunctions,
        rowValue = {},
        addRef,
        handleKeyUp,
    } = otherProps;
    const changeProps = {
        formValues,
        formName,
        dispatch,
        fieldsToChange,
    };
    let { label } = propsForInput;
    let inputProps = {
        actionHandlers,
        InputProps: {
            ...propsForInput.InputProps,
        },
        fullWidth,
        formValues,
        ...propsForInput,
    };

    let fieldName = fieldData.name;
    let isField = true;

    if (otherProps.member) {
        fieldName = `${otherProps.member}.${fieldData.name}`;
    }

    let fieldTypeSwitch = fieldType;
    if (!otherProps.editable && (
        (fieldType === 'textField') ||
        (fieldType === 'selectField')
    )) {
        fieldTypeSwitch = 'reduxFormText';
    }
    if (!otherProps.editable && (
        (fieldType === 'sideLabelTextField') ||
        (fieldType === 'autosuggest') ||
        (fieldType === 'outlinedAutosuggest')
    )) {
        fieldTypeSwitch = 'sideLabelReadOnlyText';
    }
    const {
        validate,
        isMandatory,
    } = getValidationFunction(
        validations,
        otherProps.editable,
        formValues,
        customValidationFunctions,
        useMemberForValidation && otherProps.member ? otherProps.member : '',
    );
    switch (fieldTypeSwitch) {
    case 'date':
        inputComponent = DateInput;
        break;
    case 'title':
        inputComponent = <div><h3 style={{ fontWeight: 400 }}>{fieldData.title}</h3></div>;
        isField = false;
        break;
    case 'reduxFormText':
        inputComponent = ReduxFormText;
        inputProps.dataSourceConfig = dataSourceConfig;
        break;
    case 'remoteTextValue':
        inputProps.dataSourceApi = dataSourceApi;
        inputComponent = RemoteTextValue;
        break;
    case 'text':
        isField = false;
        inputComponent = <div style={{ ...fieldData.style }} >{fieldData.text}</div>;
        break;
    case 'emptyDiv':
        isField = false;
        inputComponent = <div />;
        break;
    case 'switch':
        inputComponent = ReduxFormSwitch;
        break;
    case 'numericField':
        inputComponent = NumberTextField;
        break;
    case 'chipUpload':
        inputComponent = ReduxFormChipFileUpload;
        inputProps.uploadApi = getStringFromObject('uploadApi', inputProps) || API.FILE.UPLOAD;
        inputProps.downloadApi = getStringFromObject('downloadApi', inputProps) || API.FILE.DOWNLOAD_WITH_TOKEN;
        break;
    case 'chipMultiUpload':
        inputComponent = ReduxFormMultiFileUpload;
        inputProps.uploadApi = getStringFromObject('uploadApi', inputProps) || API.FILE.UPLOAD;
        inputProps.downloadApi = getStringFromObject('downloadApi', inputProps, API.FILE.DOWNLOAD_WITH_TOKEN);
        // inputProps.downloadApi = API.FILE.DOWNLOAD_WITH_TOKEN;
        break;
    case 'readOnlyTextField':
        inputComponent = ReadOnlyTextField;
        break;
    case 'sideLabelReadOnlyText':
        inputComponent = SideLabelReadOnlyText;
        inputProps.dataSourceConfig = dataSourceConfig;
        break;
    case 'sideLabelTextField':
        inputComponent = SideLabelTextField;
        break;
    case 'sideLabelSelectField':
        inputComponent = SideLabelSelectField;
        inputProps = {
            ...inputProps,
            dataSourceConfig,
            dataSourceApi: getSearchApi(dataSourceApi, formValues),
            menuitems: getStringFromObject('choices', fieldData, []).map(aChoice => (
                <MenuItem
                    value={aChoice[fieldData.dataSourceConfig.value]}
                    key={aChoice[fieldData.dataSourceConfig.value]}
                >
                    {aChoice[fieldData.dataSourceConfig.text]}
                </MenuItem>
            )),
        };
        break;
    case 'selectFieldWithAction':
        inputComponent = SelectFieldWithAction;
        inputProps = {
            ...inputProps,
            handleSelectAction,
        };
        if (fieldData.takeChoicesFromRow) {
            inputProps.menuitems = fieldChoices;
        } else {
            inputProps.menuitems = fieldData.choices.map(aChoice => (
                <MenuItem
                    value={aChoice[fieldData.dataSourceConfig.value]}
                    key={aChoice[fieldData.dataSourceConfig.value]}
                >
                    {aChoice[fieldData.dataSourceConfig.text]}
                </MenuItem>
            ));
        }
        break;
    case 'menuActionButton':
        inputComponent = MenuActionButton;
        inputProps = {
            ...inputProps,
            handleSelectAction,
            choices: fieldData.choices,
            label: fieldData.label,
            dataSourceConfig,
            rowValue,
        };
        break;
    case 'buttonWithAction':
        inputComponent = ReduxFormMenuButton;
        inputProps = {
            ...inputProps,
            handleSelectAction,
            choices: fieldData.choices,
            label: fieldData.label,
        };
        isField = false;
        console.log('fhakdfhkadfa', inputComponent, inputProps);
        break;
    case 'buttonGroup':
        inputComponent = (
            <ButtonGroup
                {...propsForInput}
                change={otherProps.change}
                formValues={otherProps.formValues}
                disabled={disabled || !otherProps.editable}
                member={otherProps.member}
                handleButtonAction={handleButtonAction}
            />);
        isField = false;
        break;
    case 'chipWithLabel':
        inputComponent = ReduxFormChipWithLabel;
        inputProps.actionColor = true;
        break;
    case 'materialTable':
        console.log('asdfasfeww', fieldName);
        inputComponent =
            ((checkParamPresentInDataSourceApi(mandatoryApiParams, dataSourceApi, formValues)) ? (
                (<ReduxFormMaterialTable
                    fieldName={fieldName}
                    tableCells={tableCells}
                    formValues={formValues}
                    dispatch={dispatch}
                    formName={formName}
                    {...inputProps}
                    dataSourceApi={getSearchApi(dataSourceApi, formValues)}
                    isEditable={otherProps.editable}
                />)
            ) : <React.Fragment />);
        isField = false;
        break;
    case 'inputCode':
        inputComponent = (
            <InputCode
                {...inputProps}
                name={fieldName}
                dispatch={dispatch}
                formName={formName}
                isEditable={otherProps.editable}
            />
        );
        isField = false;
        break;
    case 'radio':
        console.log('fieldNameRequired', fieldName);
        inputComponent = ReduxFormRadio;
        inputProps.dataSourceConfig = dataSourceConfig;
        break;
    case 'checkbox':
        inputComponent = ReduxFormCheckbox;
        inputProps = {
            ...inputProps,
            rowValue,
        };
        break;
    case 'textField':
        inputComponent = ReduxFormTextField;
        break;
    case 'outlinedTextField':
        inputComponent = OutlinedTextField;
        if (!otherProps.editable) {
            inputComponent = ReduxFormText;
        }
        label = (isMandatory ? getRequiredFieldLabel(label) : label);
        inputProps = {
            ...inputProps,
            label,
        };
        break;
    case 'textFieldWithAsynValidation':
        inputComponent = TextFieldWithAsyncValidation;
        break;
    case 'textFieldWithSideInfo':
        inputComponent = TextFieldWithSideInfo;
        break;
    case 'selectField':
        inputComponent = ReduxFormSelectField;
        if (fieldData.takeChoicesFromRow) {
            inputProps.menuItems = fieldChoices;
        } else {
            inputProps.menuItems = fieldData.choices.map(aChoice => (
                <MenuItem
                    value={aChoice[fieldData.dataSourceConfig.value]}
                    key={aChoice[fieldData.dataSourceConfig.value]}
                >
                    {aChoice[fieldData.dataSourceConfig.text]}
                </MenuItem>
            ));
        }
        break;
    case 'reactSelectField':
        inputComponent = ReduxFormReactSelectMaterial;
        inputProps = {
            ...inputProps,
            options,
            dataSourceConfig: fieldData.dataSourceConfig,
        };
        console.log('asdaj9dadop', inputProps);
        break;
    case 'outlinedSelectField':
        inputComponent = ReduxFormNativeSelect;
        label = (isMandatory ? getRequiredFieldLabel(label) : label);
        if (fieldData.takeChoicesFromRow) {
            inputProps.menuItems = fieldChoices;
        } else {
            inputProps.menuItems = fieldData.choices.map(aChoice => (
                <option
                    value={aChoice[fieldData.dataSourceConfig.value]}
                    key={aChoice[fieldData.dataSourceConfig.value]}
                >
                    {aChoice[fieldData.dataSourceConfig.text]}
                </option>
            ));
            inputProps.menuItems.unshift(
                <option
                    value={null}
                    key={`${label}-no-choice`}
                />,
            );
        }
        break;
    case 'autosuggest':
        inputComponent = SideLabelAutosuggest;
        inputProps = {
            ...inputProps,
            dataSourceApi: getSearchApi(dataSourceApi, formValues, otherProps.member, propsForInput.prependMember),
            dataSourceConfig,
        };
        console.log('aursotteeifhe', inputProps);
        break;
    case 'outlinedAutosuggest':
        inputComponent = ReduxFormReactSelectMaterial;
        label = (isMandatory ? getRequiredFieldLabel(label) : label);
        inputProps = {
            ...inputProps,
            dataSourceApi: getSearchApi(dataSourceApi, formValues, otherProps.member, propsForInput.prependMember),
            autocomplete: getStringFromObject('autocomplete', inputProps, true),
            dataSourceConfig,
            label,
        };
        console.log('outlinedAutosuggest', inputProps);
        break;
    case 'toggle':
        inputComponent = ReactToggle;
        break;
    default:
        inputComponent = ReduxFormTextField;
    }

    if (!otherProps.editable) {
        inputProps.disabled = true;
        inputProps.InputProps = {
            ...inputProps.InputProps,
            disabled: true,
            disableUnderline: true,
        };
    } else {
        inputProps.disabled = false;
    }

    label = noLabel ? '' : label;
    if (isField) {
        return (
            <Field
                component={inputComponent}
                {...inputProps}
                paramMap={paramMap}
                actionParams={actionParams}
                fieldMember={otherProps.member}
                name={fieldName}
                testId={fieldName}
                label={label}
                disabled={disabled || !otherProps.editable}
                validate={validate}
                isMandatory={isMandatory}
                fields={fields}
                dispatch={dispatch}
                isComponent={isComponent}
                inputRef={(inputRef) => {
                    if (addRef && typeof addRef === 'function' && validations && validations.required) {
                        addRef(inputRef, fieldName);
                    }
                }}
                handleKeyUp={() => {
                    if (handleKeyUp && typeof handleKeyUp === 'function') {
                        handleKeyUp();
                    }
                }}
                onValueChange={(val, obj) => handleOnValueChange({ val, obj, ...changeProps })}
            />
        );
    }
    return inputComponent;
};

generateFormFields.propTypes = {
    fieldData: PropTypes.object,
    fields: PropTypes.object,
    actionHandlers: PropTypes.object,
};

generateFormFields.defaultProps = {
    fieldData: {},
    fields: {},
    actionHandlers: {},
};

export default (generateFormFields);
