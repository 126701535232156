import initialStates from '../../initialStates';
import {
    CLEAR_TO_INITIAL_STATE,
    FETCH_QUEUE_DATA_FAILURE,
    FETCH_QUEUE_DATA_REQUEST,
    FETCH_QUEUE_DATA_SUCCESS,
    RESET_QUEUE_DATA,
    SAVE_FILTERS,
} from './approvalQueue-actions';

export const approvalQueueReducer = (state = initialStates.approvalQueue, action = {}) => {
    let newState = state;
    switch (action.type) {
    case FETCH_QUEUE_DATA_SUCCESS:
        if (action.data) {
            const listOfData = action.data.content;
            // let finalList = [];
            // if (isArrayValidAndNotEmpty(listOfData)) {
            //     listOfData.forEach((aDays) => {
            //         finalList = finalList.concat(aDays.data);
            //     });
            // }
            newState = Object.assign({}, state, {
                isLoading: false,
                list: listOfData,
                page: action.page,
                size: action.size,
                totalElements: action.data.totalElements,
                filters: action.filters,
                sortOrder: action.sortOrder,
                sortCol: action.sortCol,
                approvalType: action.approvalType,
                forwardSeekMap: action.data.forwardSeekMap,
                backwardSeekMap: action.data.backwardSeekMap,
                additionalFilters: action.additionalFilters,
                first: action.data.first,
                last: action.data.last,
            });
        }
        break;
    case RESET_QUEUE_DATA:
        newState = Object.assign({}, state, { list: [] });
        break;
    case CLEAR_TO_INITIAL_STATE:
        newState = initialStates.approvalQueue;
        break;
    case FETCH_QUEUE_DATA_REQUEST:
        newState = Object.assign(
            {},
            state,
            {
                approvalType: action.approvalType,
                isLoading: true,
                onlyClinical: action.onlyClinical,
                additionalFilters: action.additionalFilters,
            });
        break;
    case FETCH_QUEUE_DATA_FAILURE:
        newState = Object.assign({}, state, { isLoading: false });
        break;
    case SAVE_FILTERS:
        newState = Object.assign({}, state, { filters: action.filters, isSearch: action.isSearch });
        break;
    default:
        newState = state;
    }

    return newState;
};

export default approvalQueueReducer;

