import cloneDeep from 'clone-deep';
import {
    getIndexFromFieldName,
    isArrayValidAndNotEmpty, isEmpty,
    isValidFunction,
    roundedValue, valueToFixedTwoDigits,
} from '../../../constants/CommonUtil';
import { INVOICE_LINE_UI_OBJECT } from '../../../mapper/AccountInvoiceMapper';
import { applyDiscountBasedOnConfig } from '../PurchaseOrderActionHandlers';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { NumberOf } from '../../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const EMPTY_ROW = {
    product: '',
    description: '',
    uom: '',
    assetCategory: '',
    quantity: '',
    rate: '',
    amount: '',
    taxes: '',
    total: '',
};

const getLineOperation = (rowValue) => {
    if (rowValue.uuid) {
        return 2;
    }
    return rowValue.operation || 1;
};

const setLine = (rowValue, index, form) => {
    const newRowValue = cloneDeep(rowValue);
    const setFieldValue = getStringFromObject('setFieldValue', form);
    if (!isValidFunction(setFieldValue)) {
        return;
    }
    const valuesLength = getStringFromObject('values.accountInvoiceLines.length', form);
    if (index === (valuesLength - 1).toString()) {
        // Add row automatically..
        setFieldValue(`accountInvoiceLines.${valuesLength}`, EMPTY_ROW);
    }
    if (isObjectValidAndNotEmpty(newRowValue) && isValidFunction(setFieldValue)) {
        const quantity = Math.max(NumberOf(newRowValue.quantity), 0);
        // const factor = uom && uom.factor ? Number(uom.factor) : 1;
        const rate = NumberOf(newRowValue.rate);
        const subTotal = (quantity * rate);
        const discountPerc = NumberOf(newRowValue.discount);
        const discountPerc2 = NumberOf(newRowValue.discount2);
        const discountAmount = roundedValue(subTotal * (discountPerc / 100));
        const discountAmount2 = roundedValue((subTotal - discountAmount) * (discountPerc2 / 100));
        const amount = subTotal - (discountAmount + discountAmount2);
        const taxes = newRowValue.taxes || [];
        let totalTax = 0;
        if (isArrayValidAndNotEmpty(taxes)) {
            taxes.forEach((tax) => {
                const newTax = (NumberOf(tax.amount) * amount);
                totalTax += NumberOf(newTax);
            });
        }
        totalTax = roundedValue(totalTax);
        // Resetting after tax calculation
        newRowValue.totalTax = valueToFixedTwoDigits(totalTax);
        newRowValue.total = valueToFixedTwoDigits(amount + totalTax);
        newRowValue.quantity = quantity;
        newRowValue.discount = discountPerc;
        newRowValue.discount = discountPerc;
        newRowValue.discount2 = discountPerc2;
        newRowValue.discountAmount = valueToFixedTwoDigits(discountAmount);
        newRowValue.discountAmount2 = valueToFixedTwoDigits(discountAmount2);
        newRowValue.paramMap = { productUomCategroyId: getStringFromObject('uom.uuid', newRowValue) };
        newRowValue.amount = valueToFixedTwoDigits(subTotal);
        newRowValue.amountBeforeTax = valueToFixedTwoDigits(amount);
        newRowValue.operation = getLineOperation(newRowValue);
        console.log(newRowValue);
        setFieldValue(`accountInvoiceLines.${index}`, newRowValue);
    }
};

// Autocomplete on select handlers
const handleProductSelect = (product, fieldPath, form, autoDiscountConfig) => {
    console.log('as09dua0sduas09', product, fieldPath, form, autoDiscountConfig);
    console.log('as09dua0sduas09-213123', autoDiscountConfig, product);
    if (fieldPath && isObjectValidAndNotEmpty(form)) {
        const index = getIndexFromFieldName(fieldPath);
        if (index != null) {
            let rowValue = cloneDeep(getStringFromObject(`values.accountInvoiceLines.${index}`, form, {}));
            rowValue.product = product;
            rowValue.uom = getStringFromObject('erpUomDto', product, null);
            rowValue.taxes = getStringFromObject('taxes', product, []);
            rowValue.quantity = Math.max(NumberOf(rowValue.quantity), 1);
            const rate = NumberOf(getStringFromObject('cost', product, 0));
            rowValue = applyDiscountBasedOnConfig(autoDiscountConfig, product, rowValue);
            rowValue.rate = valueToFixedTwoDigits(rate);
            setLine(rowValue, index, form, true);
        }
    }
};

const handleTaxSelect = (value, fieldPath, form) => {
    if (fieldPath && isObjectValidAndNotEmpty(form)) {
        const index = getIndexFromFieldName(fieldPath);
        if (index != null) {
            const rowValue = cloneDeep(getStringFromObject(`values.accountInvoiceLines.${index}`, form, {}));
            if (isObjectValidAndNotEmpty(rowValue)) {
                rowValue.taxes = Array.isArray(value) ? value : [];
                setLine(rowValue, index, form);
            }
        }
    }
};

const quantityChangeHandler = (value, fieldPath, form) => {
    if (fieldPath && isObjectValidAndNotEmpty(form)) {
        const index = getIndexFromFieldName(fieldPath);
        if (index != null) {
            const rowValue = cloneDeep(getStringFromObject(`values.accountInvoiceLines.${index}`, form, {}));
            if (isObjectValidAndNotEmpty(rowValue)) {
                rowValue.quantity = value;
                setLine(rowValue, index, form);
            }
        }
    }
};

const rateChangeHandler = (value, fieldPath, form) => {
    if (fieldPath && isObjectValidAndNotEmpty(form)) {
        const index = getIndexFromFieldName(fieldPath);
        if (index != null) {
            const rowValue = cloneDeep(getStringFromObject(`values.accountInvoiceLines.${index}`, form, {}));
            if (isObjectValidAndNotEmpty(rowValue)) {
                rowValue.rate = value;
                setLine(rowValue, index, form);
            }
        }
    }
};

const discountChangeHandler = (value, fieldPath, form) => {
    if (fieldPath && isObjectValidAndNotEmpty(form)) {
        const index = getIndexFromFieldName(fieldPath);
        if (index != null) {
            const rowValue = cloneDeep(getStringFromObject(`values.accountInvoiceLines.${index}`, form, {}));
            if (isObjectValidAndNotEmpty(rowValue)) {
                rowValue.discount = value;
                setLine(rowValue, index, form);
            }
        }
    }
};
const discountChangeHandler2 = (value, fieldPath, form) => {
    if (fieldPath && isObjectValidAndNotEmpty(form)) {
        const index = getIndexFromFieldName(fieldPath);
        if (index != null) {
            const rowValue = cloneDeep(getStringFromObject(`values.accountInvoiceLines.${index}`, form, {}));
            if (isObjectValidAndNotEmpty(rowValue)) {
                rowValue.discount2 = value;
                setLine(rowValue, index, form);
            }
        }
    }
};

const totalChangeHandler = (value, fieldPath, form) => {
    if (fieldPath && isObjectValidAndNotEmpty(form)) {
        const index = getIndexFromFieldName(fieldPath);
        if (index != null) {
            const rowValue = cloneDeep(getStringFromObject(`values.accountInvoiceLines.${index}`, form, {}));
            if (isObjectValidAndNotEmpty(rowValue)) {
                const amountBeforeTax = NumberOf(value);
                const rate = NumberOf(rowValue.rate);
                const quantity = NumberOf(rowValue.quantity);
                const priceSubTotal = rate * quantity;
                if (rate <= 0 || amountBeforeTax > priceSubTotal) {
                    return;
                }
                const discountAmount = priceSubTotal - amountBeforeTax;
                const discount = roundedValue((discountAmount / priceSubTotal) * 100);
                rowValue.amountBeforeTax = valueToFixedTwoDigits(amountBeforeTax);
                rowValue.discount = valueToFixedTwoDigits(discount);
                rowValue.discountAmount = valueToFixedTwoDigits(discountAmount);
                setLine(rowValue, index, form);
            }
        }
    }
};

const rowChangeHandler = (value, fieldPath, form) => {
    if (fieldPath && isObjectValidAndNotEmpty(form)) {
        const index = getIndexFromFieldName(fieldPath);
        if (index != null) {
            const rowValue = cloneDeep(getStringFromObject(`values.accountInvoiceLines.${index}`, form, {}));
            let operation = rowValue.operation || 1;
            if (isObjectValidAndNotEmpty(rowValue)) {
                if (rowValue.uuid) {
                    operation = 2;
                }
            }
            const setFieldValue = getStringFromObject('setFieldValue', form);
            if (isValidFunction(setFieldValue)) {
                setFieldValue(`accountInvoiceLines[${index}].operation`, operation);
            }
        }
    }
};

const deleteRowHandler = (selectedRows, form, instance) => {
    const setFieldValue = getStringFromObject('setFieldValue', form);
    const values = cloneDeep(getStringFromObject('values.accountInvoiceLines', form, []));
    if (isArrayValidAndNotEmpty(selectedRows) && isValidFunction(setFieldValue) && isArrayValidAndNotEmpty(values)) {
        selectedRows.forEach((idx) => {
            values.splice(idx, 1);
            if (values.length === 0) {
                values.push(INVOICE_LINE_UI_OBJECT);
            }
            if (instance != null) {
                instance.setState({ selected: selectedRows.splice(idx, -1) });
            }
        });
        setFieldValue('accountInvoiceLines', values);
    }
};

const deleteUnusedRowHandler = (selectedRows, form, instance) => {
    const setFieldValue = getStringFromObject('setFieldValue', form);
    const values = cloneDeep(getStringFromObject('values.accountInvoiceLines', form, []));
    if (isValidFunction(setFieldValue) && isArrayValidAndNotEmpty(values)) {
        const lines = [];
        values.forEach((line) => {
            if (!isEmpty(line.product)) {
                lines.push(line);
            }
        });
        if (instance != null && lines.length !== values.length) {
            instance.setState({ selected: [] });
        }
        setFieldValue('accountInvoiceLines', lines);
    }
};

export const ACTION_HANDLERS = autoDiscountConfig => ({
    handleProductSelect: (product, fieldPath, form) => {
        console.log('aushdadaosdha', autoDiscountConfig);
        handleProductSelect(product, fieldPath, form, autoDiscountConfig);
    },
    rateChangeHandler,
    discountChangeHandler,
    discountChangeHandler2,
    quantityChangeHandler,
    rowChangeHandler,
    deleteRowHandler,
    handleTaxSelect,
    totalChangeHandler,
    deleteUnusedRowHandler,
});

export default ACTION_HANDLERS;
