import { generateNameSearchConstruct, generateObjectWithCriteriaMode, getCombined } from '../util';
import { isArrayValidAndNotEmpty } from '../../constants/CommonUtil';
import { getStringFromObject } from '../../constants/lodashUtils';

const personNameActiveAttr = ['PersonName.givenName', 'PersonName.middleName', 'PersonName.familyName'];
const patientIdentifier = 'PatientIdentifier.identifier';
const patientSecondaryIdentifier = 'Person.secondaryIdentifier';

export const generateActivePatientConstruct = (filters) => {
    let searchConstruct = null;
    const searchText = getStringFromObject('searchText', filters);
    if (searchText) {
        searchConstruct = generateNameSearchConstruct(personNameActiveAttr, searchText);
        const identifierCriteria = generateObjectWithCriteriaMode(patientIdentifier, searchText, ':');
        searchConstruct = getCombined(identifierCriteria, 'OR', searchConstruct);
        searchConstruct = getCombined(
            searchConstruct,
            'OR',
            generateObjectWithCriteriaMode(patientSecondaryIdentifier, searchText, ':',
            ));
    }
    const providers = getStringFromObject('provider', filters, []);
    if (isArrayValidAndNotEmpty(providers)) {
        let providerConstruct = null;
        providers.forEach((provider) => {
            const providerUuid = getStringFromObject('key', provider);
            if (providerUuid) {
                providerConstruct = getCombined(
                    providerConstruct,
                    'OR',
                    generateObjectWithCriteriaMode('Provider.uuid', providerUuid, '='),
                );
            }
        });
        searchConstruct = getCombined(searchConstruct, 'AND', providerConstruct);
    }
    return { searchConstruct };
};

const personNameAllAttr = ['personNames.givenName', 'personNames.middleName', 'personNames.familyName'];
const identifier = 'identifier.identifier';
const personSecondaryIdentifier = 'person.secondaryIdentifier';

export const generateAllPatientSearchConstruct = (searchText) => {
    let searchConstruct = null;
    if (searchText) {
        searchConstruct = generateNameSearchConstruct(personNameAllAttr, searchText, {
            firstName: personNameAllAttr[0],
            middleName: personNameAllAttr[1],
            lastName: personNameAllAttr[2],
        });
        const identifierCriteria = generateObjectWithCriteriaMode(identifier, searchText, '%');
        searchConstruct = getCombined(identifierCriteria, 'OR', searchConstruct);
        const secondaryIdentifierCriteria = generateObjectWithCriteriaMode(personSecondaryIdentifier, searchText, '%');
        searchConstruct = getCombined(secondaryIdentifierCriteria, 'OR', searchConstruct);
    }
    return { searchConstruct };
};
