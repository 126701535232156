import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import ReactSelectMaterial from '../../components/ReactSelectMaterial/ReactSelectMaterial';
import api from '../../constants/api';
import DateInput from '../../components/FormFieldComponents/DateInput/DateInput';
import { formatDateToString, parseDate } from '../../constants/DateUtil';
import { apiCatchBlockFunction } from '../../constants/CommonUtil';
import ActionButton from '../../components/ActionButton/ActionButton';
import { errorMessage } from '../../redux/modules/message/message-actions';
import { hideSpinner, showSpinner } from '../../redux/modules/spinner/spinner';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

class RegenerateAttendanceForEmployee extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            employee: null,
            startDate: null,
            endDate: null,
            period: null,
        };
    }

    onChangeValue = fieldName => (changedValue) => {
        this.setState({
            [fieldName]: changedValue,
        });
    };

    onChangePeriod = (period) => {
        let startDate = '';
        let endDate = '';
        if (isObjectValidAndNotEmpty(period)) {
            startDate = parseDate(period.startDate, 'yyyy-MM-dd').getTime();
            endDate = parseDate(period.endDate, 'yyyy-MM-dd').getTime();
        }
        this.setState({
            period,
            startDate,
            endDate,
        });
    };
    onRegenerateAttendance = () => {
        const {
            employee,
            startDate,
            endDate,
        } = this.state;
        const {
            dispatch,
        } = this.props;

        if (!isObjectValidAndNotEmpty(employee)) {
            dispatch(errorMessage('Please enter an employee'));
            return;
        }

        if (!endDate && !startDate) {
            dispatch(errorMessage('Date range is mandatory'));
            return;
        }

        const stDate = formatDateToString(new Date(startDate), 'dd/MM/yyyy');
        const enDate = formatDateToString(new Date(endDate), 'dd/MM/yyyy');

        dispatch(showSpinner());
        const p = Promise.race([
            axios.get(
                `${api.ATTENDANCE.REGENERATE_ATTENDANCE_FOR_EMPLOYEE
                }${employee.key}&fromDateString=${stDate}&toDateString=${enDate}`,
            ),
            new Promise((resolve) => {
                setTimeout(() => resolve('resolved in 5 sec'), 3000);
            }),
        ]);
        p.then(() => {
            console.log('asd-9asudas0aas');
            dispatch(hideSpinner());
            this.props.handleClose();
        });
        p.catch(e => apiCatchBlockFunction(e, dispatch));
    };
    render() {
        const {
            employee,
            startDate,
            endDate,
            period,
        } = this.state;
        const {
            dispatch,
        } = this.props;
        console.log(dispatch, endDate);
        return (
            <React.Fragment>
                <Grid container spacing={16}>
                    <Grid item sm={6} md={6} lg={6}>
                        <ReactSelectMaterial
                            fullWidth
                            dataSourceConfig={{
                                text: 'value',
                                value: 'key',
                            }}
                            autoFocus
                            label="Employee"
                            autocomplete
                            dataSourceApi={`${api.EMPLOYEE.GET_SUGGESTION}?name=`}
                            onChange={this.onChangeValue('employee')}
                            value={employee}
                            required
                            testId="employee"
                        />
                    </Grid>
                    <Grid item sm={6} md={6} lg={6}>
                        <ReactSelectMaterial
                            dataSourceApi={`${api.PAYROLL.ALL_PERIODS}?size=4&searchString=`}
                            dataSourceConfig={{
                                value: 'uuid',
                                text: 'displayWithDates',
                            }}
                            onChange={this.onChangePeriod}
                            label="Period"
                            value={period}
                            testId="period"
                        />
                    </Grid>
                    <Grid item sm={3} md={3} lg={3}>
                        <DateInput
                            label="Start Date"
                            value={startDate}
                            onChange={this.onChangeValue('startDate')}
                        />
                    </Grid>
                    <Grid item sm={3} md={3} lg={3}>
                        <DateInput
                            label="End Date"
                            value={endDate}
                            onChange={this.onChangeValue('endDate')}
                        />
                    </Grid>
                    <Grid item sm={12} md={12} lg={12} className="flexChildren justify-content-right">
                        <ActionButton
                            onClick={this.onRegenerateAttendance}
                            testId="regenerate-attendance"
                        >
                            Regenerate Attendance
                        </ActionButton>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

RegenerateAttendanceForEmployee.propTypes = {
    dispatch: PropTypes.func,
    handleClose: PropTypes.func,
};

RegenerateAttendanceForEmployee.defaultProps = {
    dispatch: () => {},
    handleClose: () => {},
};

export default RegenerateAttendanceForEmployee;

