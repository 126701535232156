import React from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'react-fast-compare';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Close from '@material-ui/icons/Clear';
import withStyles from '@material-ui/core/styles/withStyles';
import { Field, getFormValues, initialize, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import ReduxFormReactSelectMaterial from '../ReduxFormReactSelectMaterial';
import { minValue, required } from '../../constants/FormValidations';
import API from '../../constants/api';
import { APPLICATION_CONFIG_URL, PAYMENT_METHODS_MAP } from '../../constants/constants';
import {
    PERIOD_TYPE,
    PREPAID_EXPENSE_STATUS,
    prepaidExpenseFieldNames,
    prepaidExpenseSchema,
    refactorPeriodicLines,
    style,
} from './PrepaidExpenseUtil';
import ActionButton from '../ActionButton/ActionButton';
import DateInput from '../FormFieldComponents/DateInput/DateInput';
import MaterialTable from '../MaterialTableV2/MaterialTable';
import {
    getAllowedDefaultAccountTypes,
    isArrayValidAndNotEmpty,


} from '../../constants/CommonUtil';
import OutlinedTextField from '../OutlinedTextField';
import ReduxFormSelectField
    from '../../containers/RegistrationAppComponents/FormComponents/ReduxFormSelectField/ReduxFormSelectField';
import {
    clearSelectedExpenseState,
    createJournalEntryForPeriodicLine,
    fetchPrepaidExpenseById,
    generatePeriodicLine,
    savePrepaidExpense,
} from '../../redux/modules/prepaidExpense/prepaidExpense-actions';
import { displayWarning } from '../../redux/modules/warningDialog/warningDialog-actions';
import { getSupplierSubCompany } from '../PurchaseOrderComponents/MakeBillDialog/MakeBillDialogUtil';
import Print from '../../containers/RegistrationAppComponents/PrintHTML/PrintHTML';
import { add, multiply } from '../../constants/PrecisionUtil';
import { getStringFromObject } from '../../constants/lodashUtils';
import { NumberOf } from '../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

const formName = 'prepaidExpense';

class PrepaidExpenseDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            readOnly: false,
            print: false,
            subCompany: '',
        };
    }

    componentDidMount() {
        const { targetId, dispatch, prepaidExpense } = this.props;
        if (isObjectValidAndNotEmpty(prepaidExpense)) {
            this.initializeForm(this.props);
        } else if (targetId) {
            dispatch(fetchPrepaidExpenseById(targetId));
        } else {
            dispatch(initialize(formName, {
                paymentDetails: {
                    date: new Date(),
                },
                paymentDate: new Date(),
                date: new Date(),
                status: 'draft',
            }));
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!deepEqual(nextProps.prepaidExpense, this.props.prepaidExpense)) {
            this.initializeForm(nextProps);
        }
    }


    componentDidUpdate(prevProps) {
        const oldSupplier = getStringFromObject(prepaidExpenseFieldNames.PAYEE, prevProps.formValues);
        const newSupplier = getStringFromObject(prepaidExpenseFieldNames.PAYEE, this.props.formValues);
        if (!deepEqual(oldSupplier, newSupplier)) {
            this.getSubCompanyOfSupplier(newSupplier);
            if (!this.state.readOnly) {
                this.props.change(prepaidExpenseFieldNames.PREPAIDACCOUNT, null);
                this.props.change(prepaidExpenseFieldNames.EXPENSEACCOUNT, null);
                this.props.change('creditAccount', null);
            }
        }
    }

    componentWillUnmount() {
        this.props.dispatch(clearSelectedExpenseState());
    }

    getSubCompanyOfSupplier = async (supplier) => {
        const subCompany = await getSupplierSubCompany(supplier, this.props.dispatch);
        console.log('asd09audas09dsa', subCompany);
        this.setState({
            subCompany,
        });
    };

    initializeForm = (nextProps) => {
        const { prepaidExpense } = nextProps;
        this.setState({ readOnly: isObjectValidAndNotEmpty(prepaidExpense) });
        let tax = 0;
        const taxes = getStringFromObject('taxes', prepaidExpense);
        const totalAmount = NumberOf(getStringFromObject('totalAmount', prepaidExpense));
        let amountAfterTax = totalAmount;
        if (isArrayValidAndNotEmpty(taxes)) {
            taxes.forEach((t) => {
                tax += NumberOf(t.amount);
            });
            amountAfterTax = add(totalAmount, multiply(totalAmount, tax));
        }
        nextProps.dispatch(initialize(formName, {
            ...prepaidExpense,
            partner: {
                uuid: getStringFromObject('partner.key', prepaidExpense),
                name: getStringFromObject('partner.value', prepaidExpense),
            },
            periodicLines: refactorPeriodicLines(getStringFromObject('periodicLines', prepaidExpense, [])),
            amountAfterTax,
            totalTax: tax,
        }));
    };

    handleView = () => {
        this.setState(prev => ({ readOnly: !prev.readOnly }), () => {
            if (this.state.readOnly) {
                this.props.reset();
            }
        });
    };

    handlePrint = () => {
        this.setState(prev => ({ print: !prev.print }));
    };

    handleCompute = () => {
        const { dispatch, prepaidExpense } = this.props;
        const uuid = getStringFromObject('uuid', prepaidExpense);
        if (uuid) {
            dispatch(generatePeriodicLine(uuid));
        }
    };

    handleSave = (formValues) => {
        const payload = { ...formValues, status: PREPAID_EXPENSE_STATUS.draft };
        console.log('fnajksdflad save', payload);
        this.savePrepaidExpenseInternal(payload);
    };

    handleConfirm = (formValues) => {
        const payload = { ...formValues, status: PREPAID_EXPENSE_STATUS.confirmed };
        console.log('fnajksdflad confirm', payload);
        this.savePrepaidExpenseInternal(payload);
    };

    savePrepaidExpenseInternal = (values) => {
        const paymentMethod = getStringFromObject('paymentMethod', values);
        let paymentDetails = getStringFromObject('paymentDetails', values, null);
        if (paymentMethod !== 'CHEQUE') {
            paymentDetails = null;
        }
        const periodicLines = getStringFromObject('periodicLines', values, []);
        const payload = {
            ...values,
            shouldGenerateLines: isArrayValidAndNotEmpty(periodicLines),
            periodicLines: [],
            partner: {
                key: getStringFromObject('partner.uuid', values, null),
                value: getStringFromObject('partner.name', values),
            },
            paymentDetails,
        };
        this.props.dispatch(savePrepaidExpense(payload));
    };

    crateJournalEntryForLine = (uuid) => {
        this.props.dispatch(createJournalEntryForPeriodicLine(uuid));
    };

    handleSelectOption = (action, index) => {
        const { formValues, dispatch } = this.props;
        const uuid = getStringFromObject(`periodicLines.${index}.uuid`, formValues);
        if (uuid && action === 'createMove') {
            dispatch(displayWarning(
                'Are you sure want to create a Journal Entry for selected expense ? This operation can not be undone.',
                this.crateJournalEntryForLine,
                uuid,
            ));
        }
    };

    calculateAmountAfterTax = (amount, tax) => {
        const { change } = this.props;
        const amountAfterTax = add(amount, multiply(amount, tax));
        change('totalTax', tax);
        change('amountAfterTax', amountAfterTax);
    }

    handleAmountChange = (amount) => {
        const { formValues } = this.props;
        const totalTax = NumberOf(getStringFromObject('totalTax', formValues));
        this.calculateAmountAfterTax(NumberOf(amount), totalTax);
    }

    handleTaxSelect = (taxes) => {
        const { formValues } = this.props;
        const totalAmount = NumberOf(getStringFromObject('totalAmount', formValues));
        let tax = 0;
        if (isArrayValidAndNotEmpty(taxes)) {
            taxes.forEach((t) => {
                tax += NumberOf(t.amount);
            });
        }
        this.calculateAmountAfterTax(NumberOf(totalAmount), tax);
    }

    render() {
        const {
            open,
            handleClose,
            classes,
            formValues,
            handleSubmit,
            company,
        } = this.props;
        const {
            readOnly,
            subCompany,
            print,
        } = this.state;
        const periodClosed = getStringFromObject('periodClosed', formValues, false);
        const paymentMethod = getStringFromObject('paymentMethod', formValues);
        const name = getStringFromObject('name', formValues);
        const status = getStringFromObject('status', formValues);
        const uuid = getStringFromObject('uuid', formValues);
        let periodicLines = getStringFromObject('periodicLines', formValues, []);
        if (isArrayValidAndNotEmpty(periodicLines)) {
            periodicLines = periodicLines.map(line => ({
                ...line,
                taxAmount: add(multiply(NumberOf(getStringFromObject('totalTax', formValues, 0)),
                    NumberOf(getStringFromObject('amount', line, 0))), NumberOf(getStringFromObject('amount', line, 0))),
                tax: multiply(NumberOf(getStringFromObject('totalTax', formValues, 0)), NumberOf(getStringFromObject('amount', line, 0))),
            }));
        }
        let canCompute = true;
        if (isArrayValidAndNotEmpty(periodicLines)) {
            // if any of the lines are done, don't allow to compute
            canCompute = periodicLines.every(l => l.moveCheck === 'SCHEDULED');
        }
        const isEditAllowed = (status === 'draft') || (canCompute && (status === 'confirmed'));
        const shouldGenerateLines = status && (status !== PREPAID_EXPENSE_STATUS.stopped);
        console.log('asda9sudy9audass', this.props);
        return (
            <React.Fragment>
                <form>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        fullScreen
                        scroll="paper"
                        aria-labelledby="form-dialog-title"
                        classes={{
                            paper: classes.paper,
                        }}
                    >
                        <DialogTitle
                            id="form-dialog-title"
                            className={classes.title}
                            disableTypography
                        >
                            <Grid container justify="space-between">
                                <div>
                                    Prepaid Expense {name ? `(${name})` : ''}
                                </div>
                                <Close className="cursor-pointer" onClick={handleClose} />
                            </Grid>
                        </DialogTitle>
                        <DialogContent className={classes.paper}>
                            <Grid container spacing={32} justify="flex-start" className="mt-1">
                                <Grid item lg={3} sm={3} md={3}>
                                    <Field
                                        testId="supplier"
                                        name={prepaidExpenseFieldNames.PAYEE}
                                        component={ReduxFormReactSelectMaterial}
                                        dataSourceConfig={{
                                            text: 'name',
                                            value: 'uuid',
                                        }}
                                        label="Supplier"
                                        placeholder="Choose a Supplier"
                                        isDisabled={readOnly}
                                        autocomplete
                                        onSelectHandlers={['onSelectPayee']}
                                        validate={[required]}
                                        required
                                        dataSourceApi={API.SEARCH.SUPPLIER}
                                    />
                                </Grid>
                                <Grid item lg={3} sm={3} md={3}>
                                    <Field
                                        testId="credit-account"
                                        name="creditAccount"
                                        component={ReduxFormReactSelectMaterial}
                                        dataSourceConfig={{
                                            text: 'value',
                                            value: 'key',
                                        }}
                                        required
                                        label="Bank/Credit Account"
                                        placeholder="Choose account"
                                        validate={[required]}
                                        isDisabled={readOnly}
                                        dataSourceApi={
                                            `${API.SEARCH.ACCOUNTS}?type=${getAllowedDefaultAccountTypes('prepaidExpenseCreditAccountTypes', this.props.allowedAccountTypes)}&typeIgnoreList=consolidation,view&` +
                                            `accountType=&subCompany=${subCompany || ''}&searchString=`
                                        }
                                    />
                                </Grid>
                                <Grid item lg={6} md={6} sm={6}>
                                    <div style={{ float: 'right' }}>
                                        {
                                            uuid && isEditAllowed && !periodClosed &&
                                            <ActionButton onClick={this.handleView} primary={false}>
                                                {readOnly ? 'Edit' : 'Cancel Edit'}
                                            </ActionButton>
                                        }
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container spacing={32} justify="flex-start" className="mt-1">
                                <Grid item lg={3} sm={3} md={3}>
                                    <Field
                                        name="paymentDate"
                                        component={DateInput}
                                        label="Payment Date"
                                        required
                                        placeholder="Payment Date"
                                        disabled={readOnly}
                                        validate={[required]}
                                    />
                                </Grid>
                                <Grid item lg={3} sm={3} md={3}>
                                    <Field
                                        testId="payment-method"
                                        name="paymentMethod"
                                        component={ReduxFormSelectField}
                                        validate={[required]}
                                        required
                                        label="Payment Method"
                                        disabled={readOnly}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        menuItems={Object.values(PAYMENT_METHODS_MAP).map(method => (
                                            <MenuItem key={method.value} value={method.value}>
                                                {method.label}
                                            </MenuItem>
                                        ))}
                                    />
                                </Grid>
                                {
                                    (paymentMethod === 'CHEQUE') &&
                                    <React.Fragment>
                                        <Grid item lg={3} sm={3} md={3}>
                                            <Field
                                                name="paymentDetails.refNumber"
                                                component={OutlinedTextField}
                                                label="Cheque Number"
                                                required
                                                type="number"
                                                placeholder="Cheque Number"
                                                disabled={readOnly}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                validate={[required, minValue(1)]}
                                            />
                                        </Grid>
                                        <Grid item lg={3} sm={3} md={3}>
                                            <Field
                                                name="paymentDetails.bankName"
                                                component={OutlinedTextField}
                                                label="Bank Name"
                                                required
                                                type="text"
                                                placeholder="Bank Name"
                                                disabled={readOnly}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                validate={[required]}
                                            />
                                        </Grid>
                                        <Grid item lg={2} sm={3} md={3}>
                                            <Field
                                                name="paymentDetails.date"
                                                component={DateInput}
                                                label="Cheque Date"
                                                required
                                                placeholder="Cheque Date"
                                                disabled={readOnly}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                validate={[required]}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                }
                            </Grid>
                            <Grid container spacing={32} justify="flex-start" className="mt-1">
                                <Grid item lg={3} sm={3} md={3}>
                                    <Field
                                        testId="prepaid-account"
                                        name={prepaidExpenseFieldNames.PREPAIDACCOUNT}
                                        component={ReduxFormReactSelectMaterial}
                                        dataSourceConfig={{
                                            text: 'value',
                                            value: 'key',
                                        }}
                                        label="Prepaid Account"
                                        placeholder="Choose an account"
                                        isDisabled={readOnly}
                                        validate={[required]}
                                        required
                                        dataSourceApi={
                                            `${API.SEARCH.ACCOUNTS}?type=prepaid expenses&` +
                                            'typeIgnoreList=consolidation,view&' +
                                            `accountType=&subCompany=${subCompany || ''}&searchString=`
                                        }
                                        autocomplete
                                    />
                                </Grid>
                                <Grid item lg={3} sm={3} md={3}>
                                    <Field
                                        testId="expense-account"
                                        name={prepaidExpenseFieldNames.EXPENSEACCOUNT}
                                        component={ReduxFormReactSelectMaterial}
                                        dataSourceConfig={{
                                            text: 'value',
                                            value: 'key',
                                        }}
                                        required
                                        label="Expense Account"
                                        placeholder="Choose an account"
                                        validate={[required]}
                                        isDisabled={readOnly}
                                        dataSourceApi={
                                            `${API.SEARCH.ACCOUNTS}?type=${getAllowedDefaultAccountTypes('prepaidExpenseExpenseAccountTypes', this.props.allowedAccountTypes)}&typeIgnoreList=consolidation,view&` +
                                            `accountType=&subCompany=${subCompany || ''}&searchString=`
                                        }
                                        autocomplete
                                    />
                                </Grid>
                                <Grid item lg={2} sm={2} md={2}>
                                    <Field
                                        testId="total-amount"
                                        name="totalAmount"
                                        component={OutlinedTextField}
                                        label="Amount"
                                        required
                                        type="number"
                                        placeholder="Enter Amount"
                                        disabled={readOnly}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        validate={[required]}
                                        actionHandlers={{
                                            handleAmountChange: this.handleAmountChange,
                                        }}
                                        onChangeHandlers={['handleAmountChange']}
                                    />
                                </Grid>
                                <Grid item lg={2} sm={2} md={2}>
                                    <Field
                                        name="taxes"
                                        testId="taxes"
                                        component={ReduxFormReactSelectMaterial}
                                        dataSourceConfig={{
                                            text: 'name',
                                            value: 'id',
                                        }}
                                        multiple
                                        label="Taxes"
                                        isDisabled={readOnly}
                                        autocomplete
                                        dataSourceApi={`${API.SEARCH.PURCHASE_TAXES}?size=20&name=`}
                                        actionHandlers={{
                                            handleTaxSelect: this.handleTaxSelect,
                                        }}
                                        onSelectHandlers={['handleTaxSelect']}
                                    />
                                </Grid>
                                <Grid item lg={2} sm={2} md={2}>
                                    <Field
                                        name="amountAfterTax"
                                        component={OutlinedTextField}
                                        label="Amount After Tax"
                                        type="number"
                                        disabled
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={32} justify="flex-start" className="mt-1">
                                <Grid item lg={3} sm={3} md={3}>
                                    <Field
                                        testId="period-type"
                                        name="periodType"
                                        component={ReduxFormSelectField}
                                        label="Period Type"
                                        validate={[required]}
                                        placeholder="Period Type"
                                        required
                                        disabled={readOnly}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        menuItems={Object.values(PERIOD_TYPE).map(period => (
                                            <MenuItem key={period.value} value={period.value}>
                                                {period.label}
                                            </MenuItem>
                                        ))}
                                    />
                                </Grid>
                                <Grid item lg={2} sm={3} md={3}>
                                    <Field
                                        name="date"
                                        component={DateInput}
                                        label="Start Date"
                                        required
                                        placeholder="Start Date"
                                        disabled={readOnly}
                                        validate={[required]}
                                    />
                                </Grid>
                                <Grid item lg={3} sm={3} md={3}>
                                    <Field
                                        testId="period-duration"
                                        name="period"
                                        component={OutlinedTextField}
                                        label="Period Duration"
                                        required
                                        type="number"
                                        placeholder="Period Duration"
                                        disabled={readOnly}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        validate={[required]}
                                    />
                                </Grid>
                                <Grid item lg={3} sm={3} md={3}>
                                    <Field
                                        testId="number-of-periods"
                                        name="numberOfPeriods"
                                        component={OutlinedTextField}
                                        label="Number of Periods"
                                        required
                                        type="number"
                                        placeholder="Number of Periods"
                                        disabled={readOnly}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        validate={[required]}
                                    />
                                </Grid>
                            </Grid>
                            {
                                shouldGenerateLines && readOnly && canCompute &&
                                <Grid container justify="flex-end" className="mt-1">
                                    <Grid item lg={1} sm={1} md={1}>
                                        <ActionButton
                                            primary
                                            disableRipple
                                            onClick={this.handleCompute}
                                        >
                                            Compute
                                        </ActionButton>
                                    </Grid>
                                </Grid>
                            }
                            {
                                isObjectValidAndNotEmpty(prepaidExpenseSchema) &&
                                isArrayValidAndNotEmpty(periodicLines) &&
                                <Grid
                                    container
                                    justify="center"
                                    alignContent="center"
                                    spacing={16}
                                    className="mt-2"
                                    style={{ background: '#fff', padding: '1rem' }}
                                >
                                    <Grid item lg={10} sm={12} md={10}>
                                        <MaterialTable
                                            data={periodicLines}
                                            uniqueKey="uuid"
                                            schema={prepaidExpenseSchema}
                                            noPagination
                                            handleSelectAction={this.handleSelectOption}
                                        />
                                    </Grid>
                                </Grid>
                            }
                            <Grid container className="mt-1">
                                <Grid item sm={6} md={4} lg={3}>
                                    <Field
                                        name="notes"
                                        component={OutlinedTextField}
                                        label="Memo"
                                        multiline
                                        rows={5}
                                        rowsMax={5}
                                        disabled={readOnly}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions style={{ background: '#fafafa', marginTop: '0px', padding: '12px' }} >
                            <ActionButton
                                primary={false}
                                onClick={handleClose}
                            >
                                Cancel
                            </ActionButton>
                            {
                                !readOnly && (status === 'draft') &&
                                <ActionButton
                                    primary
                                    className="ml-1"
                                    onClick={handleSubmit(this.handleSave)}
                                >
                                    Save
                                </ActionButton>
                            }
                            {
                                (!readOnly || (readOnly && (status === 'draft'))) &&
                                <ActionButton
                                    testId="confirm"
                                    primary
                                    onClick={handleSubmit(this.handleConfirm)}
                                >
                                    Confirm
                                </ActionButton>
                            }
                            {
                                readOnly && (status === 'confirmed') &&
                                <ActionButton
                                    primary
                                    onClick={this.handlePrint}
                                >
                                    Print
                                </ActionButton>
                            }
                        </DialogActions>
                    </Dialog>
                    <Print
                        data={{
                            ...formValues,
                            periodicLines,
                            company,
                        }}
                        subCompany={subCompany}
                        print={print}
                        url={`${APPLICATION_CONFIG_URL}/HtmlPrint/BookManagement/prepaidExpense.html`}
                    />
                </form>
            </React.Fragment>
        );
    }
}

PrepaidExpenseDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    formValues: PropTypes.object,
    prepaidExpense: PropTypes.object,
    targetId: PropTypes.number,
    change: PropTypes.func,
    company: PropTypes.string,
    allowedAccountTypes: PropTypes.object,
};

PrepaidExpenseDialog.defaultProps = {
    formValues: {},
    prepaidExpense: {},
    targetId: null,
    company: '',
    change: () => {},
    allowedAccountTypes: {},
};

const mapStateToProps = state => ({
    company: getStringFromObject('appConfiguration.companyName', state, ''),
    formValues: getFormValues(formName)(state),
    prepaidExpense: getStringFromObject('prepaidExpense.selected', state, null),
    allowedAccountTypes: getStringFromObject('appConfiguration.allowedAccountTypes', state),
});

export default connect(mapStateToProps)(reduxForm({
    form: formName,
})(withStyles(style)(PrepaidExpenseDialog)));
