import { IMPLEMENTATION_LOCATIONS } from '../../constants/constants';
import { payerMangementTabs } from './PurchaseRoutesContainer';

export const filterTabsBasedOnConditions = (tabObjects, conditions) => {
    const newTabObjects = [...tabObjects];
    const { implementationLocation } = conditions;
    if (implementationLocation === IMPLEMENTATION_LOCATIONS.BAHRAIN) {
        return newTabObjects.filter(e => e.value !== payerMangementTabs.ACCEPT_PACKAGES.value);
    }
    return tabObjects;
};

export const a = 1;
