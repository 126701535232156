import React from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'react-fast-compare';
import connect from 'react-redux/lib/connect/connect';
import { withStyles } from '@material-ui/core/styles';
import BackArrow from '@material-ui/icons/KeyboardBackspace';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import OutlineSelectWithArrow from '../../components/OutlineSelectWithArrow';
import {
    isArrayValidAndNotEmpty,


} from '../../constants/CommonUtil';
import {
    fetchPeriods,
    renderAttendanceEditForm,
    revertApprovedAttendance,
} from '../../redux/modules/attendance/attendance-actions';
import {
    attendanceList,
    csvImportName,
    employeeAttendance,
    PAGE,
    SIZE,
    SORT_ORDER_DESC,
} from '../../constants/constants';
import {
    APPROVED_ATTENDANCE_LIST_FILTERS,
    ATTENDANCE_DEFAULT_SORT_COL,
    fetchAttendanceListFromServer,
} from '../../components/Attendance/AttendanceListUtil';
import ActionButton from '../../components/ActionButton/ActionButton';
import GenericFilterWithSearch from '../../components/GenericFilterComponent/GenericFilterWithSearch';
import { formatDateToString, getPreviousPayrollMonth, parseDate } from '../../constants/DateUtil';
import CommonCSVImportDialog from '../../components/CommonCSVImportDialog/CommonCSVImportDialog';
import AttendanceUploadDialog from './AttendanceUploadDialog';
import DialogComponent from '../../components/DialogComponent/DialogComponent';
import RegenerateAttendanceForEmployee from '../RegenerateAttendanceForEmployee/RegenerateAttendanceForEmployee';
import AddAttendanceForEmployee from '../AddAttendanceForEmployee/AddAttendanceForEmployee';
import { errorMessage } from '../../redux/modules/message/message-actions';
import { checkIfPrivilegeExistsForUser } from '../../constants/privilegeChecker';
import { revertApprovedAttendancePrivilege } from '../../constants/privilegeConstants';
import { getStringFromObject } from '../../constants/lodashUtils';
import { NumberOf } from '../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

const styles = () => ({
    card: {
        width: '100%',
        borderRadius: '8px',
        minHeight: '100%',
        marginBottom: '1rem',
    },
});

class AttendanceContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            importDialog: false,
            selectMonth: false,
            importMonthly: false,
            regenerateAttendanceDialog: false,
        };
    }
    componentDidMount() {
        this.fetchPeriods();
    }

    onToggleImportDialog = () => {
        this.setState(prevState => ({
            importDialog: !prevState.importDialog,
        }));
    };

    onToggleImportMonthlyDialog = (e, relod, monthAndYear) => {
        this.setState(prevState => ({
            importMonthly: !prevState.importMonthly,
            monthAndYear,
        }), () => {
            if (relod) {
                this.onToggleSelectMonthDialog();
                this.fetchPeriods();
            }
        });
    };

    onToggleSelectMonthDialog = () => {
        this.setState(prevState => ({
            selectMonth: !prevState.selectMonth,
        }));
    };

    onToggleRegenerateAttendanceForEmployee = () => {
        this.setState(prevState => ({
            regenerateAttendanceDialog: !prevState.regenerateAttendanceDialog,
        }));
    };

    onToggleAddAttendanceForEmployee = () => {
        this.setState(prevState => ({
            addEmployeeAttendanceDialog: !prevState.addEmployeeAttendanceDialog,
        }));
    };

    getNextOptions = () => {
        const {
            dispatch, page, size, last, filters, sortCol, sortOrder,
        } = this.props;
        if (!last) {
            fetchAttendanceListFromServer(dispatch, NumberOf(page) + 1, size, sortCol, sortOrder, filters,
                (response) => {
                    const list = getStringFromObject('page.content', response, []);
                    if (isArrayValidAndNotEmpty(list)) {
                        this.replaceRoute(NumberOf(page) + 1, size, getStringFromObject('period', filters), list[0]);
                    }
                });
        }
    };

    getPrevOptions = () => {
        const {
            dispatch, page, size, first, filters, sortCol, sortOrder,
        } = this.props;
        if (!first) {
            fetchAttendanceListFromServer(dispatch, NumberOf(page) - 1, size, sortCol, sortOrder, filters,
                (response) => {
                    const list = getStringFromObject('page.content', response, []);
                    if (isArrayValidAndNotEmpty(list)) {
                        this.replaceRoute(NumberOf(page) - 1, size,
                            getStringFromObject('period', filters), list[list.length - 1]);
                    }
                });
        }
    };

    isListingPage = () => {
        const { history } = this.props;
        const path = getStringFromObject('location.pathname', history);
        return path === attendanceList;
    };

    fetchPeriods = () => {
        const {
            dispatch, page, size, filters, sortCol, sortOrder, startDateForHrPayrollPeriod,
        } = this.props;
        dispatch(fetchPeriods((periods) => {
            if (this.isListingPage()) {
                const prevMonth = getPreviousPayrollMonth(new Date(), startDateForHrPayrollPeriod);
                let monthToSelect = null;
                if (isArrayValidAndNotEmpty(periods)) {
                    for (let i = 0; i < periods.length; i += 1) {
                        const aPeriod = periods[i];
                        const aPeriodMonth = parseDate(aPeriod.endDate, 'yyyy-MM-dd').getMonth();
                        if (aPeriodMonth === prevMonth) {
                            monthToSelect = aPeriod;
                            break;
                        }
                    }
                }
                fetchAttendanceListFromServer(dispatch, page, size, sortCol, sortOrder, {
                    ...filters,
                    period: monthToSelect,
                });
            }
        }));
    };

    handleHideApproved = (e, checked) => {
        const {
            dispatch, page, size, filters, sortCol, sortOrder,
        } = this.props;
        const status = checked ? 'DRAFT' : null;
        fetchAttendanceListFromServer(dispatch, page, size, sortCol, sortOrder, { ...filters, status });
    };

    handlePeriod = (period) => {
        const {
            dispatch, size, filters, sortCol, sortOrder,
        } = this.props;
        if (this.isListingPage()) {
            fetchAttendanceListFromServer(dispatch, 0, size, sortCol, sortOrder, { ...filters, period });
        }
    };

    handleSelectEmployee = (value) => {
        const {
            list, page, size, filters,
        } = this.props;
        if (isArrayValidAndNotEmpty(list)) {
            const index = list.findIndex(a => a.employee.key === value.key);
            if (index > -1) {
                const listElement = list[index];
                this.replaceRoute(page, size, getStringFromObject('period', filters), listElement);
            }
        }
    };

    replaceRoute = (_page, _size, period, attendance) => {
        const { history, dispatch } = this.props;
        const uuid = getStringFromObject('employee.key', attendance);
        if (!isObjectValidAndNotEmpty(period) || !period.uuid) {
            dispatch(errorMessage('Please select a period for viewing attendance.'));
            history.replace({
                pathname: attendanceList,
            });
            return;
        }
        const urlParams = [
            `employee=${uuid}`,
            `period=${period.uuid}`,
        ].join('&');
        history.replace({
            pathname: employeeAttendance,
            search: urlParams,
        });
    };

    goBack = () => {
        const { history } = this.props;
        history.push(attendanceList);
        const {
            dispatch, page, size, filters, sortOrder, sortCol,
        } = this.props;
        fetchAttendanceListFromServer(dispatch, page, size, sortCol, sortOrder, filters);
    };

    handleEdit = () => {
        const { dispatch } = this.props;
        dispatch(renderAttendanceEditForm(true));
    };

    handleRevertAttendance = () => {
        const {
            dispatch,
            selectedAttendance,
        } = this.props;
        dispatch(revertApprovedAttendance(getStringFromObject('uuid', selectedAttendance)));
    }

    applyFilters = (newFilters) => {
        const {
            dispatch,
            page,
            size,
            filters,
            sortCol,
            sortOrder,
        } = this.props;
        const status = getStringFromObject('status', filters);
        const period = getStringFromObject('period', filters, null);
        console.log('asdahsd98hdasd', newFilters, period);
        fetchAttendanceListFromServer(
            dispatch,
            page,
            size,
            sortCol,
            sortOrder,
            {
                ...newFilters,
                status,
                period,
            },
        );
    };

    preProcessPayload = (payload) => {
        const { monthAndYear } = this.state;
        const month = formatDateToString(monthAndYear, 'MMMM yyyy');
        return payload.map(p => ({ ...p, month }));
    };

    render() {
        const {
            classes,
            children,
            periods,
            isEditable,
            list,
            first,
            last,
            selectedAttendance,
            filters,
        } = this.props;
        const {
            importDialog,
            importMonthly,
            selectMonth,
            monthAndYear,
            regenerateAttendanceDialog,
            addEmployeeAttendanceDialog,
        } = this.state;
        const revert = checkIfPrivilegeExistsForUser(revertApprovedAttendancePrivilege);
        const hideApproved = getStringFromObject('status', filters) === 'DRAFT';
        const period = getStringFromObject('period', filters, null);
        const status = getStringFromObject('status', selectedAttendance);
        const isListing = this.isListingPage();
        let employees = [];
        if (!isListing && isArrayValidAndNotEmpty(list)) {
            employees = list.map(a => a.employee);
        }
        const selectedEmployee = getStringFromObject('employee', selectedAttendance, null);
        const hideEmployeeLeftArrow = first && deepEqual(employees[0], selectedEmployee);
        const hideEmployeeRightArrow = last && deepEqual(employees[employees.length - 1], selectedEmployee);
        console.log('afjakhdakld', this.props, monthAndYear);
        return (
            <React.Fragment>
                <div style={{ padding: '2rem', height: '100%' }}>
                    <Card className={classes.card}>
                        <div style={{ background: '#ffffff', padding: '2rem' }}>
                            <Grid container spacing={16} alignItems="center">
                                {
                                    !isListing &&
                                    <Grid
                                        item
                                        className="cursor-pointer"
                                    >
                                        <Button onClick={this.goBack} test-id="back-to-list">
                                            <BackArrow
                                                style={{ fontSize: '2em', marginRight: '2px' }}
                                            />
                                            Back to list
                                        </Button>
                                    </Grid>
                                }
                                <Grid item lg={3} md={3} sm={3}>
                                    <OutlineSelectWithArrow
                                        testId="period"
                                        id="period"
                                        name="period"
                                        options={periods}
                                        handleChange={this.handlePeriod}
                                        label="Period"
                                        hideLeftArrow={isEditable || !isListing}
                                        hideRightArrow={isEditable || !isListing}
                                        value={period}
                                        disabled={!isListing}
                                        dataSourceConfig={{
                                            text: 'displayWithDates',
                                            value: 'uuid',
                                        }}
                                    />
                                </Grid>
                                {
                                    isListing ?
                                        <Grid item lg={2} md={2} sm={3}>
                                            <div className="ml-1">
                                                <FormControlLabel
                                                    test-id="hide-approved-attendance"
                                                    control={
                                                        <Checkbox
                                                            test-id="Hideapprovedattendance"
                                                            checked={hideApproved}
                                                            onChange={this.handleHideApproved}
                                                            value="hideApproved"
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Hide approved attendance"
                                                />
                                            </div>
                                        </Grid>
                                        :
                                        <Grid item lg={3} md={3}>
                                            <OutlineSelectWithArrow
                                                testId="employee"
                                                label="Employee"
                                                options={employees}
                                                value={selectedEmployee}
                                                hideLeftArrow={hideEmployeeLeftArrow || isEditable}
                                                hideRightArrow={hideEmployeeRightArrow || isEditable}
                                                handleChange={this.handleSelectEmployee}
                                                getNextOptions={this.getNextOptions}
                                                getPrevOptions={this.getPrevOptions}
                                                dataSourceConfig={{
                                                    text: 'value',
                                                    value: 'key',
                                                }}
                                            />
                                        </Grid>
                                }
                                {
                                    !isListing &&
                                    <React.Fragment>
                                        <Grid item>
                                            <h5
                                                data-test-id="attendance-status"
                                                style={{
                                                    color: status === 'APPROVED' ? '#00cc5b' : '#CC0000',
                                                    margin: '0 0 0 2em',
                                                }}
                                            >
                                                {status}
                                            </h5>
                                        </Grid>
                                        {
                                            !isEditable && status === 'DRAFT' &&
                                            <Grid item lg={2} sm={2} md={2}>
                                                <div className="flo-right">
                                                    <ActionButton
                                                        testId="edit"
                                                        onClick={this.handleEdit}
                                                    >
                                                        Edit
                                                    </ActionButton>
                                                </div>
                                            </Grid>
                                        }
                                        {
                                            status === 'APPROVED' && revert &&
                                            <Grid item lg={2} sm={2} md={2}>
                                                <div className="flo-right">
                                                    <ActionButton
                                                        test-id="edit"
                                                        onClick={this.handleRevertAttendance}
                                                    >
                                                        Revert Attendance
                                                    </ActionButton>
                                                </div>
                                            </Grid>
                                        }
                                    </React.Fragment>
                                }
                                {
                                    isListing &&
                                    <React.Fragment>
                                        <Grid item lg={4} md={4} sm={6}>
                                            <GenericFilterWithSearch
                                                placeholder="Search with Employee Name/ID"
                                                filterSchema={APPROVED_ATTENDANCE_LIST_FILTERS}
                                                saveFilter={this.applyFilters}
                                                filterValues={filters}
                                            />
                                        </Grid>
                                        <Grid item lg={1} md={2}>
                                            <ActionButton
                                                onClick={this.onToggleImportDialog}
                                                data-test-id="import-attendance"
                                            >
                                                Import
                                            </ActionButton>
                                        </Grid>
                                        <Grid item lg={3} md={2}>
                                            <ActionButton
                                                onClick={this.onToggleSelectMonthDialog}
                                                data-test-id="import-monthly"
                                            >
                                                Import Monthly Attendance
                                            </ActionButton>
                                        </Grid>
                                        <Grid item lg={3} md={2}>
                                            <ActionButton
                                                onClick={this.onToggleRegenerateAttendanceForEmployee}
                                                testId="regenerate"
                                            >
                                                Regenerate For Employee
                                            </ActionButton>
                                        </Grid>
                                        <Grid item lg={3} md={2}>
                                            <ActionButton
                                                onClick={this.onToggleAddAttendanceForEmployee}
                                                data-test-id="attendance-employee"
                                            >
                                                Add Attendance For Employee
                                            </ActionButton>
                                        </Grid>
                                    </React.Fragment>
                                }
                            </Grid>
                            { children }
                        </div>
                    </Card>
                    {
                        importDialog &&
                        <CommonCSVImportDialog
                            open
                            handleClose={this.onToggleImportDialog}
                            header="Import Attendance"
                            importName={csvImportName.ATTENDANCE_PUNCHES}
                        />
                    }
                    {
                        importMonthly &&
                        <CommonCSVImportDialog
                            open
                            handleClose={this.onToggleImportMonthlyDialog}
                            header={`Import Attendance for ${formatDateToString(monthAndYear, 'MMMM yyyy')}`}
                            importName={csvImportName.ATTENDANCE_MONTHLY_CONSOLIDATED}
                            preProcessPayload={this.preProcessPayload}
                        />
                    }
                    {
                        selectMonth &&
                        <AttendanceUploadDialog
                            handleSelectMonth={v => this.onToggleImportMonthlyDialog(null, false, v)}
                            handleClose={this.onToggleSelectMonthDialog}
                        />
                    }
                    {
                        regenerateAttendanceDialog &&
                        <DialogComponent
                            open
                            handleClose={this.onToggleRegenerateAttendanceForEmployee}
                            header="Regenerate Attendance For Employee"
                        >
                            <RegenerateAttendanceForEmployee
                                dispatch={this.props.dispatch}
                                handleClose={this.onToggleRegenerateAttendanceForEmployee}
                            />
                        </DialogComponent>
                    }
                    {
                        addEmployeeAttendanceDialog &&
                        <DialogComponent
                            open
                            handleClose={this.onToggleAddAttendanceForEmployee}
                            header="Add Attendance For Employee"
                        >
                            <AddAttendanceForEmployee
                                dispatch={this.props.dispatch}
                                handleClose={this.onToggleAddAttendanceForEmployee}
                            />
                        </DialogComponent>
                    }
                </div>
            </React.Fragment>
        );
    }
}

AttendanceContainer.propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    periods: PropTypes.array,
    list: PropTypes.array,
    page: PropTypes.number,
    size: PropTypes.number,
    isEditable: PropTypes.bool,
    first: PropTypes.bool,
    last: PropTypes.bool,
    selectedAttendance: PropTypes.object,
    filters: PropTypes.object,
    sortCol: PropTypes.string,
    sortOrder: PropTypes.string,
    startDateForHrPayrollPeriod: PropTypes.number,
};

AttendanceContainer.defaultProps = {
    page: PAGE,
    size: SIZE,
    periods: [],
    isEditable: false,
    first: false,
    last: false,
    list: [],
    selectedAttendance: {},
    filters: {},
    sortCol: ATTENDANCE_DEFAULT_SORT_COL,
    sortOrder: SORT_ORDER_DESC,
    startDateForHrPayrollPeriod: 1,
};

const mapStateToProps = state => ({
    isEditable: getStringFromObject('attendance.edit', state, false),
    selectedAttendance: getStringFromObject('attendance.selectedAttendance', state, null),
    periods: getStringFromObject('attendance.periods', state, []),
    page: getStringFromObject('attendance.page', state, PAGE),
    size: getStringFromObject('attendance.size', state, SIZE),
    list: getStringFromObject('attendance.list', state, []),
    first: getStringFromObject('attendance.first', state, false),
    last: getStringFromObject('attendance.last', state, false),
    filters: getStringFromObject('attendance.filters', state, {}),
    sortCol: getStringFromObject('attendance.sortCol', state, ATTENDANCE_DEFAULT_SORT_COL),
    sortOrder: getStringFromObject('attendance.sortOrder', state, SORT_ORDER_DESC),
    startDateForHrPayrollPeriod:
        getStringFromObject('appConfiguration.start_date_for_hr_payroll_period', state) || 1,
});

export default connect(mapStateToProps)(withStyles(styles)(AttendanceContainer));
