import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import message from './modules/message/message-reducer';
import warningDialog from './modules/warningDialog/warningDialog-reducer';
import login from './modules/login/login-reducer';
import navigator from './modules/navigator/navigator-reducer';
import spinner from './modules/spinner/spinner';
import selectedPatient from './modules/selectedPatient/selectedPatient-reducer';
import doctorQList from './modules/doctorQueueList/doctorQueueList-reducer';
import holidays from './modules/holidays/holiday-reducer';
import socket from './modules/websocket/websocket-reducer';
import appConfiguration from './modules/appConfiguration/appConfiguration-reducer';
import workflow from './modules/workflow/workflow-reducer';
import inbox from './modules/inbox/inbox-reducer';
import profile from './modules/profile/profile-reducer';
import asset from './modules/assets/asset-reducer';
import announcements from './modules/announcements/announcements-reducer';
import patients from './modules/patients/patients-reducer';
import leaves from './modules/leaves/leave-reducer';
import attendance from './modules/attendance/attendance-reducer';
import organization from './modules/organization/organization-reducer';
import payroll from './modules/payroll/payroll-reducer';
import vitalsQueue from './modules/vitalsQueue/vitalsQueue-reducer';
import advanceSearch from './modules/advanceSearch/advanceSearch-reducer';
import dashboard from './modules/dashboard/dashboard-reducer';
import ordersQueue from './modules/ordersQueue/ordersQueue-reducer';
import purchaseOrder from './modules/purchaseOrder/purchaseOrder-reducer';
import stockMove from './modules/stockMove/stockMove-reducer';
import stockIntentReducer from './modules/stockIntent/stockIntent-reducer';
import materialReceiveReducer from './modules/materialReceive/materialReceive-reducer';
import batches from './modules/batches/batches-reducer';
import accountInvoice from './modules/accountInvoice/accountInvoice-reducer';
import language from './modules/language/language-reducer';
import patientDetails from './modules/patientDetails/patientDetails-reducer';
import departmentService from './modules/departmentService/department-service-reducer';
import registrationList from './modules/registrationList/registrationList-reducer';
import employeePayroll from './modules/employeePayroll/employeePayroll-reducer';
import appointmentSlot from './modules/appointmentSlot/appointmentSlot-reducer';
import visitDetails from './modules/visitDetails/visitDetails-reducer';
import appointment from './modules/appointment/appointment-reducer';
import appointmentDetails from './modules/appointmentDetails/appointmentDetails-reducer';
import workflowConfig from './modules/workflowConfig/workflow-reducer';
import appointmentsList from './modules/appointmentsList/appointmentsList-reducer';
import supplier from './modules/supplier/supplier-reducer';
import products from './modules/products/products-reducer';
import productCategory from './modules/productCategory/productCategory-reducer';
import slotTemplate from './modules/appointmentSlotTemplate/appointmentSlotTemplate-reducer';
import addStock from './modules/addStock/addStock-reducer';
import internalMoves from './modules/internalMoves/internalMoves-reducer';
import consumables from './modules/consumables/consumable-reducer';
import payors from './modules/payors/payers/payors-reducer';
import subInsurers from './modules/payors/subInsurers/subInsurer-reducer';
import accountTransfer from './modules/accountTransfer/accountTransfer-reducer';
import accountExpense from './modules/accountExpense/accountExpense-reducer';
import receivePayment from './modules/receivePayment/receivePayment-reducer';
import transaction from './modules/transaction/transaction-reducer';
import accounts from './modules/accounts/accounts-reducer';
import agingReport from './modules/agingReport/agingReport-reducer';
import payment from './modules/payment/payment-reducer';
import approvalQueue from './modules/approvalQueue/approvalQueue-reducer';
import { insuranceInvoiceListReducer as insuranceInvoice } from './modules/invoice/invoice-reducer';
import order from './modules/order/order-reducer';
import claimsAndBundles from './modules/claimsAndBundles/claimsAndBundles-reducer';
import journalEntries from './modules/journalEntries/journalEntries-reducer';
import analyticalAccounts from './modules/analyticalAccounts/analyticalAccounts-reducer';
import createAdvancedSalaryReducer from './modules/salaryAdvance/salaryAdvance-reducers';
import createPettyCashReducer from './modules/pettyCash/pettycash-reducer';
import accountExpenseReducer from './modules/employeeExpense/employeeExpense-reducer';
import recallList from './modules/recallList/recallList-reducer';
import prepaidExpense from './modules/prepaidExpense/prepaidExpense-reducer';
import advancePayment from './modules/advancePayment/advancePayment-reducer';
import registrationShop from './modules/registrationShop/registrationShop-reducer';
import { assetCategoryAdd, assetCategoryList } from './modules/assetCategory/assetCategory-reducer';
import {
    assetManufacturerAdd,
    assetManufacturerList,
    assetManufacturerUpdate,
} from './modules/assetManufacturer/assetManufacturer-reducer';

import { addOrderableService, addProductService, productsFetcReducer } from './modules/servicesPage/products-reducer';
import cashClose from './modules/cashClose/cashClose-reducer';
import consolidatedCashClose from './modules/consolidatedCashClose/consolidatedCashClose-reducer';
import { addOrUpdatePackageReducer, fetchPackagesReducer } from './modules/servicePackage/servicePackage-reducer';
import medicalPackages from './modules/medicalPackage/medicalPackage-reducer';
import retryRequestsReducer from './modules/retryRequests/retryRequests-reducer';
import {
    addOrUpdateDepartment,
    addOrUpdateDictionary,
    addOrUpdatePanel,
    addOrUpdateSample,
    addOrUpdateTest,
    addOrUpdateUom,
    addOrUpdateTemplate,
    labDepartments,
    labDictionary,
    labPanels,
    labSamples,
    labTests,
    labUom,
} from './modules/LabSettings/labTest-reducer';
import {
    insuranceRequestLinesReducer,
    insuranceRequestsReducer,
} from './modules/insuranceRequestsList/insuranceRequestsList-reducer';
import policyView from './modules/policyView/policyView-reducer';
import genericList, { genericAddOrUpdateReducer } from './modules/genericMastersRedux/genericMaster-reducer';
import doctorTabCount from './modules/doctorTabCount/doctorTabCount-reducer';
import insuranceSchemeView from './modules/insuranceSchemeView/insuranceSchemeView-reducer';
import favouriteReports from './modules/favouriteReports/favouriteReports-reducer';
import policy from './modules/payors/policy/policy-reducer';
import scheme from './modules/payors/scheme/scheme-reducer';
import bedType from './modules/bedManagement/bedType/bedType-reducer';
import paidOnAccount from './modules/paidOnAccount/paidOnAccount-reducer';
import eformConfig from './modules/eformConfig/eformConfig-reducer';
import previousPurchaseInvoiceLines
    from './modules/previousPurchaseInvoiceLines/previousPurchaseInvoiceLines-reducer';
import {
    cashPaymentVoucherReducer as cashPaymentVoucher,
    debitAndCreditLinesReducer as debitAndCreditLines,
} from './modules/cashPaymentVoucher/cashPaymentVoucher-reducer';
import accountVoucher from './modules/accountVoucher/accountVoucher-reducer';
import accountInvoiceTable from './modules/accountInvoiceTable/accountInvoiceTable-reducer';
import encounterOrders from './modules/encounterOrders/encounterOrders-reducer';
import { queueReducer as queue } from './modules/queue/queue-reducer';
import {
    acceptPackageReducer as acceptPackage,
    getPackageDetailsReducer as acceptPackageDetails,
    materialReceiveReducer as packageReceive,
} from './modules/acceptPackage/acceptPackage-reducer';
import {
    favoriteForDoctorReducer as favoriteForDoctor,
} from './modules/favoriteAccounts/favoritesForDoctor-reducer';
import confirmDialogWithUserInput from './modules/confirmDialogWithUserInput/confirmDialogWithUserInput-reducer';
import billedMedicalPackage from './modules/billedMedicalPackage/billedMedicalPackage-reducer';

export default combineReducers({
    routing: routerReducer,
    form: formReducer,
    navigator,
    favoriteForDoctor,
    assetManufacturerAdd,
    assetCategoryAdd,
    assetManufacturerUpdate,
    message,
    warningDialog,
    login,
    assetCategoryList,
    assetManufacturerList,
    spinner,
    selectedPatient,
    doctorQList,
    holidays,
    socket,
    appConfiguration,
    workflow,
    inbox,
    asset,
    profile,
    announcements,
    leaves,
    patients,
    attendance,
    organization,
    payroll,
    vitalsQueue,
    advanceSearch,
    dashboard,
    ordersQueue,
    purchaseOrder,
    stockMove,
    stockIntentReducer,
    materialReceiveReducer,
    batches,
    accountInvoice,
    language,
    patientDetails,
    departmentService,
    registrationList,
    employeePayroll,
    appointmentSlot,
    visitDetails,
    appointment,
    appointmentDetails,
    workflowConfig,
    eformConfig,
    appointmentsList,
    supplier,
    products,
    productCategory,
    slotTemplate,
    addStock,
    internalMoves,
    consumables,
    payors,
    accountTransfer,
    accountExpense,
    receivePayment,
    transaction,
    accounts,
    agingReport,
    payment,
    approvalQueue,
    insuranceInvoice,
    order,
    journalEntries,
    claimsAndBundles,
    analyticalAccounts,
    createAdvancedSalaryReducer,
    createPettyCashReducer,
    accountExpenseReducer,
    recallList,
    prepaidExpense,
    advancePayment,
    registrationShop,
    productsFetcReducer,
    addProductService,
    addOrderableService,
    fetchPackagesReducer,
    medicalPackages,
    cashClose,
    consolidatedCashClose,
    addOrUpdatePackageReducer,
    labTests,
    retryRequestsReducer,
    addOrUpdateTest,
    labPanels,
    labDepartments,
    labSamples,
    labDictionary,
    labUom,
    addOrUpdateUom,
    addOrUpdateDepartment,
    addOrUpdatePanel,
    addOrUpdateSample,
    addOrUpdateDictionary,
    addOrUpdateTemplate,
    insuranceRequestLinesReducer,
    insuranceRequestsReducer,
    policyView,
    genericList,
    genericAddOrUpdateReducer,
    doctorTabCount,
    insuranceSchemeView,
    favouriteReports,
    subInsurers,
    policy,
    scheme,
    bedType,
    paidOnAccount,
    previousPurchaseInvoiceLines,
    debitAndCreditLines,
    cashPaymentVoucher,
    accountVoucher,
    accountInvoiceTable,
    encounterOrders,
    queue,
    acceptPackage,
    acceptPackageDetails,
    packageReceive,
    confirmDialogWithUserInput,
    billedMedicalPackage,
});
