import memoizeOne from 'memoize-one';
import cloneDeep from 'clone-deep';
import axios from 'axios';

import {
    apiCatchBlockFunction,
    isArrayValidAndNotEmpty,
    isStringNullOrUndefined,
    roundedValueFixedToTwoDigits,
    sortArrayOfObjectsByFieldValue,
    valueToFixedTwoDigits,
} from '../../../constants/CommonUtil';
import { getAddressFromAddressDtoMemoized } from '../../PrintComponents/PurchaseOrderPrintUtil';
import { hideSpinner, showSpinner } from '../../../redux/modules/spinner/spinner';
import API from '../../../constants/api';
import { addOrSubtractDays, addOrSubtractMonth, formatDateToString } from '../../../constants/DateUtil';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { NumberOf } from '../../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

export const makeBillDialogTabs = {
    ITEM_DETAILS: 'ITEM DETAILS',
    PAYMENT_DETAILS: 'PAYMENT DETAILS',
};

const getPaymentInvoiceDetailsToPrint = (selectedInvoice) => {
    console.log('sad0-asiadasda', selectedInvoice);
    const selectedInvoiceCopy = cloneDeep(selectedInvoice);
    const accountInvoiceLineDtoList = getStringFromObject('accountInvoiceLineDtoList', selectedInvoice);
    if (isArrayValidAndNotEmpty(accountInvoiceLineDtoList)) {
        const accountInvoiceLineDtoListMapped = accountInvoiceLineDtoList.map((anInvoiceLine) => {
            const invoiceLineCopy = cloneDeep(anInvoiceLine);
            const { taxAccountDetails, discount, discount2 } = invoiceLineCopy;
            const discountPerc = NumberOf(discount);
            const discountPerc2 = NumberOf(discount2);

            const amount = NumberOf(valueToFixedTwoDigits(NumberOf(invoiceLineCopy.quantity) * NumberOf(invoiceLineCopy.priceUnit)));
            const discountAmount = amount * (discountPerc / 100);
            const discountAmount2 = (amount - discountAmount) * (discountPerc2 / 100);
            invoiceLineCopy.discountAmount = valueToFixedTwoDigits(discountAmount);
            invoiceLineCopy.discountAmount2 = valueToFixedTwoDigits(discountAmount2);
            let totalLineTax = 0;
            if (isArrayValidAndNotEmpty(taxAccountDetails)) {
                for (let i = 0; i < taxAccountDetails.length; i += 1) {
                    totalLineTax += taxAccountDetails[i].amount * anInvoiceLine.priceSubtotal;
                }
            }
            invoiceLineCopy.totalLineTax = valueToFixedTwoDigits(NumberOf(totalLineTax));
            invoiceLineCopy.amountTotal = valueToFixedTwoDigits(NumberOf(totalLineTax) + NumberOf(anInvoiceLine.priceSubtotal));
            return invoiceLineCopy;
        });
        selectedInvoiceCopy.accountInvoiceLineDtoList = sortArrayOfObjectsByFieldValue(accountInvoiceLineDtoListMapped, 'sequence');
        const addr = getStringFromObject('partnerAddress', selectedInvoice);
        if (isObjectValidAndNotEmpty(addr)) {
            selectedInvoiceCopy.addressDisplay = getAddressFromAddressDtoMemoized(addr);
        }
        selectedInvoiceCopy.amountUntaxed = NumberOf(selectedInvoiceCopy.amountUntaxed);
        selectedInvoiceCopy.finalAmount = roundedValueFixedToTwoDigits(NumberOf(selectedInvoiceCopy.amountTotal));
        selectedInvoiceCopy.discountAmount = roundedValueFixedToTwoDigits(NumberOf(selectedInvoiceCopy.discount));
        if (selectedInvoiceCopy.isCashPurchase) {
            selectedInvoiceCopy.patientName = getStringFromObject('cashSupplierName', selectedInvoiceCopy) || '';
            selectedInvoiceCopy.vatNumber = getStringFromObject('cashSupplierVatNumber', selectedInvoiceCopy) || '';
        }
    }
    console.log('sad0-asiadasda', selectedInvoiceCopy);
    return selectedInvoiceCopy;
};

const getConsolidatedJournalEntries = (accountMove) => {
    console.log('sad0-asiadasda', accountMove);
    const accountMoveConsolidated = cloneDeep(accountMove);
    const consolidatedMoveLines = {};
    if (isArrayValidAndNotEmpty(accountMoveConsolidated.moveLineDtos)) {
        accountMoveConsolidated.moveLineDtos.map((aMoveLine) => {
            const accountUuidOfMoveLine = getStringFromObject('account.key', aMoveLine);
            if (isObjectValidAndNotEmpty(consolidatedMoveLines[accountUuidOfMoveLine])) {
                consolidatedMoveLines[accountUuidOfMoveLine].credit += NumberOf(aMoveLine.credit);
                consolidatedMoveLines[accountUuidOfMoveLine].debit += NumberOf(aMoveLine.debit);
            } else {
                consolidatedMoveLines[accountUuidOfMoveLine] = {
                    ...aMoveLine,
                    name: '',
                };
            }
            return null;
        });
    }
    accountMoveConsolidated.moveLineDtos = Object.values(consolidatedMoveLines);
    accountMoveConsolidated.isConsolidated = true;
    if (!isStringNullOrUndefined(accountMove.cashSupplierName)) {
        accountMoveConsolidated.partner = { key: '', value: accountMove.cashSupplierName };
        accountMoveConsolidated.vatNumber = accountMove.cashSupplierVatNumber || '';
    }
    return accountMoveConsolidated;
};

const getPaymentInvoiceDetailsToPrintMemoize = memoizeOne(getPaymentInvoiceDetailsToPrint);

export const getConsolidatedJournalEntriesMemoize = memoizeOne(getConsolidatedJournalEntries);

export default getPaymentInvoiceDetailsToPrintMemoize;

export const getSupplierSubCompany = async (supplier, dispatcher) => {
    let subCompany = '';
    if (isObjectValidAndNotEmpty(supplier) && supplier.uuid && !supplier.uuid.startsWith('NCD-')) {
        try {
            dispatcher(showSpinner());
            const response = await axios.get(`${API.SUPPLIER.FIND}${supplier.uuid}`);
            dispatcher(hideSpinner());
            // eslint-disable-next-line
            subCompany = response.data.subCompany;
        } catch (e) {
            apiCatchBlockFunction(e, dispatcher);
        }
    }
    return subCompany;
};

export const calculateDueDateFromPaymentTerm = (term) => {
    let date = new Date();
    if (term) {
        const split = term.split(':');
        if (split.length > 0) {
            switch (split[0]) {
            case 'NXT':
                if (date.getDate() > NumberOf(split[1])) {
                    date = addOrSubtractMonth(date, 1, '+');
                }
                date.setDate(Math.min(NumberOf(split[1])));
                break;
            case 'ADD':
                date = addOrSubtractDays(date, NumberOf(split[1]));
                break;
            default:
            }
        }
    }
    return formatDateToString(date, 'yyyy-MM-dd');
};
