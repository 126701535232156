import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import DateFnsUtils from '@date-io/date-fns';
import { InlineTimePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import {
    isArrayValidAndNotEmpty,
    isValidDate,
    isValidFunction,
} from '../../../constants/CommonUtil';
import { timeMask12Hours, timeMask24Hours } from '../../../constants/constants';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const styles = () => ({
    input: {
        paddingTop: '12px',
        paddingBottom: '12px',
    },
});

class TimeInput extends React.Component {
    onBlur = (e) => {
        const {
            onBlur,
            input,
            field,
            form,
            onBlurHandlers,
        } = this.props;
        if (isValidFunction(onBlur)) {
            onBlur(e);
        } else if (isObjectValidAndNotEmpty(input) && isValidFunction(input.onBlur)) {
            input.onBlur(e);
        } else if (isObjectValidAndNotEmpty(field) && isValidFunction(field.onBlur)) {
            // field.onBlur(e);
            this.callFormHandlers(onBlurHandlers, field.value, field.name, form);
        }
    };
    onError = (_, error) => {
        const {
            form,
            field,
        } = this.props;
        console.log('as-duas0d-ad', form, error);
        let errMsg = `${error || ''}`;
        if (errMsg === 'Invalid Date Format') {
            errMsg = 'Invalid Time Format';
        }
        if (isObjectValidAndNotEmpty(form)) {
            form.setFieldError(field.name, errMsg);
        }
    };
    handleDateSelect = (date) => {
        const {
            onChange,
            input,
            field,
            form,
            onChangeHandlers,
        } = this.props;
        let onChangeHandler = null;
        console.log('asd9ua0duas9da', onChangeHandlers, this.props.actionHandlers);
        this.onError(null, '');
        if (isValidFunction(onChange)) {
            onChangeHandler = onChange;
        } else if (isObjectValidAndNotEmpty(input) && isValidFunction(input.onChange)) {
            onChangeHandler = input.onChange;
        } else if (isObjectValidAndNotEmpty(form) && isValidFunction(form.setFieldValue)) {
            form.setFieldValue(field.name, date, true);
            this.callFormHandlers(onChangeHandlers, date, field.name, form);
            return;
        }
        if (isValidFunction(onChangeHandler)) {
            if (isValidDate(date)) {
                onChangeHandler(date.getTime());
            } else {
                onChangeHandler(null);
            }
        }
    };

    callFormHandlers = (handlers, value, name, props) => {
        const { actionHandlers } = this.props;
        if (isArrayValidAndNotEmpty(handlers)) {
            handlers.forEach((action) => {
                const handler = actionHandlers[action];
                if (isValidFunction(handler)) {
                    handler(value, name, props);
                }
            });
        }
    };

    render() {
        const {
            value,
            input,
            field,
            label,
            classes,
            disabled,
            variant,
            ampm,
            form,
        } = this.props;
        let dateValue = null;
        if (value) {
            dateValue = value;
        } else if (isObjectValidAndNotEmpty(input)) {
            dateValue = input.value;
        } else if (isObjectValidAndNotEmpty(field)) {
            dateValue = field.value;
        }
        const name = getStringFromObject('field.name', this.props);
        const error = getStringFromObject(`errors.${name}`, form, '');
        console.log('fahdjahfdla', error, form.errors);
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <InlineTimePicker
                    disabled={disabled}
                    clearable
                    keyboard
                    mask={ampm ? timeMask12Hours : timeMask24Hours}
                    variant={variant}
                    label={label}
                    value={dateValue ? new Date(dateValue) : null}
                    onChange={this.handleDateSelect}
                    onBlur={this.onBlur}
                    onError={this.onError}
                    InputProps={{
                        classes: {
                            input: classes.input,
                        },
                        error: !!error,
                        'data-test-id': `${name}`,
                    }}
                    // FormHelperTextProps={{
                    //     style: error ? {
                    //         color: '#ff0000',
                    //     } : {},
                    // }}
                    helperText={error || ''}
                    ampm={ampm}
                    // InputLabelProps={{
                    //     shrink: true,
                    //     style: error ? {
                    //         color: '#ff0000',
                    //     } : {},
                    // }}
                />
            </MuiPickersUtilsProvider>
        );
    }
}

TimeInput.propTypes = {
    classes: PropTypes.object.isRequired,
    value: PropTypes.number,
    label: PropTypes.number,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    input: PropTypes.object,
    field: PropTypes.object,
    form: PropTypes.object,
    disabled: PropTypes.bool,
    ampm: PropTypes.bool,
    variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
    onBlurHandlers: PropTypes.array,
    onChangeHandlers: PropTypes.array,
    actionHandlers: PropTypes.object,
};

TimeInput.defaultProps = {
    value: null,
    label: null,
    onChange: null,
    onBlur: null,
    input: null,
    field: null,
    form: null,
    disabled: false,
    ampm: false,
    variant: 'outlined',
    onBlurHandlers: [],
    onChangeHandlers: [],
    actionHandlers: {},
};

export default withStyles(styles)(TimeInput);

