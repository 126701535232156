import { call, put } from 'redux-saga/effects';
import { retriable } from '../retriable';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import {
    didApiCallFailDueToUnauthorizedUser,
    didApiCallFailDueToVersionMismatch,
    getErrorMessage,
    isValidFunction,
} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import messages from '../../constants/messages';
import {
    CREATE_MATERIAL_RECEIVE_FAILURE,
    CREATE_MATERIAL_RECEIVE_SUCCESS,
    FETCH_MATERIAL_RECEIVE_SUCCESS,
    FETCH_MATERIAL_RECEIVE_FAILURE,
} from '../../redux/modules/materialReceive/materialReceive-actions';
import { MATERIAL_RECEIVE_OPERATION } from '../../mapper/MaterialReceiveMapper';


export function* createMaterialReceiveWorker(action) {
    console.log('createMaterialReceiveWorker', action);
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().post, action.api, action.payload);
        yield put({
            type: CREATE_MATERIAL_RECEIVE_SUCCESS,
            data: response.data,
        });
        yield put({
            type: FETCH_MATERIAL_RECEIVE_SUCCESS,
            data: response.data,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
        console.log('payloadoperation', action.payload.state);
        if (action.payload.state === MATERIAL_RECEIVE_OPERATION.SAVE) {
            const msg = action.payload.uuid ? messages.MATERIAL_RECEIVE.UPDATE.SUCCESS :
                messages.MATERIAL_RECEIVE.CREATE.SUCCESS;
            yield put({ type: SUCCESS_MESSAGE, message: msg });
        } else {
            yield put({ type: SUCCESS_MESSAGE, message: messages.MATERIAL_RECEIVE.CONFIRM.SUCCESS });
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            let msg = '';
            console.log('payloadoperation', action.payload.operation);
            if (action.payload.status === MATERIAL_RECEIVE_OPERATION.SAVE) {
                msg = action.payload.uuid ? messages.MATERIAL_RECEIVE.UPDATE.FAILED :
                    messages.MATERIAL_RECEIVE.CREATE.FAILED;
            } else {
                msg = messages.MATERIAL_RECEIVE.CONFIRM.FAILED;
            }
            yield put({ type: ERROR_MESSAGE, message: `${msg}: ${getErrorMessage(e)}` });
        }
        yield put({ type: CREATE_MATERIAL_RECEIVE_FAILURE, data: action.payload });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}
export function* fetchMaterialReceive(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().get, action.api);
        yield put({
            type: FETCH_MATERIAL_RECEIVE_SUCCESS,
            data: response.data,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({
                type: ERROR_MESSAGE,
                message: `${messages.MATERIAL_RECEIVE.FETCH_FAILED}: ${getErrorMessage(e)}`,
            });
        }
        yield put({ type: FETCH_MATERIAL_RECEIVE_FAILURE, data: action.payload });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* markMaterialReceive(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().post, action.api);
        yield put({
            type: FETCH_MATERIAL_RECEIVE_SUCCESS,
            data: response.data,
        });
        console.log('successCallback=>', action.successCallback);
        if (isValidFunction(action.successCallback)) {
            console.log('successCallback====>', action.successCallback);
            action.successCallback(response.data);
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({
                type: ERROR_MESSAGE,
                message: `${messages.MATERIAL_RECEIVE.CONFIRM.FAILED}: ${getErrorMessage(e)}`,
            });
        }
        yield put({ type: FETCH_MATERIAL_RECEIVE_FAILURE, data: action.payload });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

