
import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { retriable } from '../retriable';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import {
    didApiCallFailDueToUnauthorizedUser,
    didApiCallFailDueToVersionMismatch,
    getErrorMessage,
    isValidFunction,
} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import { addSeekDirectionAndParamsToUrlParams } from '../../constants/UrlUtil';
import {
    FETCH_PACKAGES_SUCCESS, FETCH_PACKAGES_FAILURE,
    FETCH_PACKAGE_DETAILS_SUCCESS, FETCH_PACKAGE_DETAILS_FAILURE,
    FETCH_MATERIAL_RECEIVE_RSD_SUCCESS, FETCH_MATERIAL_RECEIVE_RSD_FAILURE,
    ACCEPT_PACKAGE_FAILURE, ACCEPT_PACKAGE_SUCCESS,
    IGNORE_SELECTED_PACKAGES_FAILURE, IGNORE_SELECTED_PACKAGES_SUCCESS, DOWNLOAD_PACKAGE_SUCCESS,
} from '../../redux/modules/acceptPackage/acceptPackage-actions';
import { getStringFromObject } from '../../constants/lodashUtils';

export function* downloadPackage(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(axios.post, action.api, action.payload);
        yield put({
            type: DOWNLOAD_PACKAGE_SUCCESS,
            data: {
                ...(response.data || {}),
            },
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            const msg = 'Download package failed';
            yield put({ type: ERROR_MESSAGE, message: `${msg}: ${getErrorMessage(e)}` });
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* fetchPackages(action) {
    try {
        const {
            api,
            page,
            size,
            searchConstruct,
        } = action;
        const apiWithParams = `${api}?page=${page}&size=${size}`;
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        console.log('immadefenid', searchConstruct);
        const response = yield call(axios.post, apiWithParams, searchConstruct);
        console.log('theruahveit', response);
        yield put({
            type: FETCH_PACKAGES_SUCCESS,
            data: {
                ...(response.data || {}),
                page,
            },
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            const msg = 'Accept packages fetch failed';
            yield put({ type: ERROR_MESSAGE, message: `${msg}: ${getErrorMessage(e)}` });
        }
        yield put({ type: FETCH_PACKAGES_FAILURE, data: {} });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* fetchPackageDetails(action) {
    try {
        const {
            api,
            page,
            size,
            notificationId,
            filters,
        } = action;
        const searchString = getStringFromObject('searchText', filters, '');
        const status = getStringFromObject('status.key', filters, '');
        const onlyUnmapped = getStringFromObject('onlyUnmapped', filters) || false;
        const apiWithParams = addSeekDirectionAndParamsToUrlParams(`${api}${notificationId}&page=${page}&size=${size}&searchString=${searchString}&status=${status}&onlyUnmapped=${onlyUnmapped}`);
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(axios.get, apiWithParams);
        yield put({
            type: FETCH_PACKAGE_DETAILS_SUCCESS,
            data: {
                ...(response.data || {}),
                page,
            },
            filters,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            const msg = 'Package Details not Received';
            yield put({ type: ERROR_MESSAGE, message: `${msg}: ${getErrorMessage(e)}` });
        }
        yield put({ type: FETCH_PACKAGE_DETAILS_FAILURE, data: {} });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* fetchMaterialReceive(action) {
    try {
        const {
            api,
        } = action;
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(axios.get, api);
        yield put({
            type: FETCH_MATERIAL_RECEIVE_RSD_SUCCESS,
            data: {
                ...(response.data || {}),
            },
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
        console.log('theddets', response.data);
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            const msg = 'Package Details not Received';
            yield put({ type: ERROR_MESSAGE, message: `${msg}: ${getErrorMessage(e)}` });
        }
        yield put({ type: FETCH_MATERIAL_RECEIVE_RSD_FAILURE, data: {} });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* acceptPackage(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().post, action.api, action.list);
        yield put({
            type: ACCEPT_PACKAGE_SUCCESS,
            data: response.data,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
        yield put({ type: SUCCESS_MESSAGE, message: 'Done' });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            const msg = 'XML Not Created';
            yield put({ type: ERROR_MESSAGE, message: `${msg}: ${getErrorMessage(e)}` });
        }
        yield put({ type: ACCEPT_PACKAGE_FAILURE, data: {} });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export function* ignoreSelectedPackages(action) {
    try {
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(retriable().post, action.api, action.list);
        yield put({
            type: IGNORE_SELECTED_PACKAGES_SUCCESS,
            data: response.data,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
        yield put({ type: SUCCESS_MESSAGE, message: 'Success' });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            const msg = 'Failed';
            yield put({ type: ERROR_MESSAGE, message: `${msg}: ${getErrorMessage(e)}` });
        }
        yield put({ type: IGNORE_SELECTED_PACKAGES_FAILURE, data: {} });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}
