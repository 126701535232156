import { takeLatest } from 'redux-saga/effects';
import {
    CREATE_MATERIAL_RECEIVE_REQUEST,
    FETCH_MATERIAL_RECEIVE_REQUEST,
    MARK_MATERIAL_RECEIVE_AS_COMPLETE,
} from '../../redux/modules/materialReceive/materialReceive-actions';
import {
    createMaterialReceiveWorker,
    fetchMaterialReceive,
    markMaterialReceive,
} from './materialReceiveWorkerSaga';

export function* createMaterialReceiveWatcher() {
    yield takeLatest(CREATE_MATERIAL_RECEIVE_REQUEST, createMaterialReceiveWorker);
}

export function* fetchMaterialReceiveRequestWatcher() {
    yield takeLatest(FETCH_MATERIAL_RECEIVE_REQUEST, fetchMaterialReceive);
}

export function* markMaterialReceiveAsComplete() {
    yield takeLatest(MARK_MATERIAL_RECEIVE_AS_COMPLETE, markMaterialReceive);
}
