import { takeLatest } from 'redux-saga/effects';
import {
    FETCH_PACKAGES,
    FETCH_PACKAGE_DETAILS_REQUEST,
    FETCH_MATERIAL_RECEIVE_RSD_REQUEST,
    ACCEPT_PACKAGE_REQUEST,
    IGNORE_SELECTED_PACKAGES_REQUEST,
    DOWNLOAD_PACKAGE,
} from '../../redux/modules/acceptPackage/acceptPackage-actions';
import {
    fetchPackages,
    fetchPackageDetails,
    fetchMaterialReceive,
    acceptPackage,
    ignoreSelectedPackages,
    downloadPackage,
} from './acceptPackageWorkerSaga';


export function* fetchPackagesWatcher() {
    yield takeLatest(FETCH_PACKAGES, fetchPackages);
}

export function* fetchPackageDetailsWatcher() {
    yield takeLatest(FETCH_PACKAGE_DETAILS_REQUEST, fetchPackageDetails);
}

export function* fetchMaterialReceiveWatcher() {
    yield takeLatest(FETCH_MATERIAL_RECEIVE_RSD_REQUEST, fetchMaterialReceive);
}

export function* acceptPackageWatcher() {
    yield takeLatest(ACCEPT_PACKAGE_REQUEST, acceptPackage);
}

export function* ignoreSelectedPackagesWatcher() {
    yield takeLatest(IGNORE_SELECTED_PACKAGES_REQUEST, ignoreSelectedPackages);
}

export function* downloadPackageWatcher() {
    yield takeLatest(DOWNLOAD_PACKAGE, downloadPackage);
}
