import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip/Chip';
import Close from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import ActionButton from '../ActionButton/ActionButton';
import {
    apiCatchBlockFunction,
    isArrayValid,
    isArrayValidAndNotEmpty,
    isEnterKeyPressed,
    isObjectEmptyOrValuesEmpty,
    isStringNullOrUndefined,
    // isValidFunction,
} from '../../constants/CommonUtil';
import Popper from '../Popper';
import OutlinedTextField from '../OutlinedTextField';
import api from '../../constants/api';
import { hideSpinner, showSpinner } from '../../redux/modules/spinner/spinner';
import { errorMessage, successMessage } from '../../redux/modules/message/message-actions';

const style = theme => ({
    header: {
        fontSize: '1.5rem',
    },
    textField: {
        borderRadius: '5px',
        backgroundColor: '#fff',
        border: `2px solid ${theme.palette.borderColor}`,
    },
    reactSelectTextField: {
        padding: '0',
        borderRadius: '5px',
        backgroundColor: '#fff',
        border: `2px solid ${theme.palette.borderColor}`,
    },
    input: {
        padding: '2px 6px',
    },
    title: {
        fontSize: '1.3rem',
        fontWeight: '400',
        padding: '12px 24px',
    },
    dialogContent: {
        minWidth: '920px',
        marginTop: '1rem',
        overflow: 'visible',
    },
    dialog: {
        overflow: 'visible',
    },
    paper: {
        overflow: 'visible',
    },
    buttonStyle: {
        height: '1.8rem',
        minWidth: '6rem',
        minHeight: '1rem',
        borderRadius: '1rem',
        fontSize: '0.8rem',
        padding: '4px 16px',
        textTransform: 'capitalize',
        marginLeft: '1rem',
        color: '#fff',
    },
    cancelButton: {
        border: `1px solid ${theme.palette.secondaryTextColor}`,
        color: theme.palette.secondaryTextColor,
    },
});

class GtinMapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openPopper: false,
            buttonAnchorElement: null,
            value: this.props.gtin,
            allProductGtins: [],
        };
    }
    componentDidMount() {
        this.fetchGtinValues();
    }

    fetchGtinValues = () => {
        const { product } = this.props;

        axios.get(`${api.SEARCH.SEARCH_GTINS_BY_PRODUCT_UUID}?productUuid=${product.uuid}`)
            .then((response) => {
                this.setState({ allProductGtins: response.data });
            })
            .catch((error) => {
                apiCatchBlockFunction(error);
            });
    }
    handleAddClick = (event) => {
        this.setState({
            openPopper: true,
            buttonAnchorElement: event.currentTarget,
        });
    }

    handlePopperClose = () => {
        this.setState({
            openPopper: false,
            buttonAnchorElement: null,
        });
    }

    handleChange = (newValue) => {
        this.setState({
            value: newValue,
        });
    }

    handleAddItem = () => {
        const { value, allProductGtins } = this.state;
        if (isArrayValid(allProductGtins)) {
            const gtinAlreadyAdded = allProductGtins.filter(aProductGtinMap => aProductGtinMap.gtin === value);
            console.log('checandletuknwo', gtinAlreadyAdded);
            console.log('chilldomo', value);
            const updateAllproductGtins = [...allProductGtins];
            if (gtinAlreadyAdded.length === 0) {
                console.log('pushcheyyanam');
                updateAllproductGtins.push({
                    gtin: value,
                });
            }
            this.setState({
                openPopper: false,
                buttonAnchorElement: null,
                allProductGtins: updateAllproductGtins,
            });
        }
    }

    handleDelete = index => async () => {
        console.log('dloading', index);
        const { dispatch } = this.props;
        const { allProductGtins } = this.state;
        const productGtinMap = allProductGtins[index];
        let canbeDeleted = true;
        if (isObjectEmptyOrValuesEmpty(productGtinMap) && !isStringNullOrUndefined(productGtinMap.uuid)) {
            try {
                dispatch(showSpinner());
                const isProductGtinMapPresentInStockProdLot =
                    await axios.get(`${api.PRODUCT.IS_PRODUCT_GTIN_MAP_PRESENT_IN_STOCK_PRODUCTION_LOT
                    }?productGtinMapUuid=${productGtinMap.uuid}`);
                canbeDeleted = !isProductGtinMapPresentInStockProdLot.data;
                dispatch(hideSpinner());
            } catch (e) {
                apiCatchBlockFunction(e, dispatch);
            }
        }
        const updatedAllProductGtins = [...allProductGtins];
        if (canbeDeleted) {
            updatedAllProductGtins.splice(index, 1);
            this.setState({
                allProductGtins: updatedAllProductGtins,
            });
        } else {
            dispatch(errorMessage('Unable to remove: Gtin has batch associated with it.'));
        }
    }

    handleKeyDown = (e) => {
        if (isEnterKeyPressed(e)) {
            this.handleAddItem();
        }
    }

    handleClick = productGtinMap => async () => {
        console.log('oldhead');
        try {
            await navigator.clipboard.writeText(productGtinMap.gtin);
        } catch (error) {
            console.error(error);
        }
    }

    handleSave = async () => {
        const {
            dispatch, product, postGtinSave,
        } = this.props;
        const { allProductGtins, value } = this.state;
        const needsSaving = allProductGtins.filter(aProductGtinMap => (aProductGtinMap.uuid === null));
        console.log('needsSaving', needsSaving);
        try {
            dispatch(showSpinner());
            await axios.post(`${api.PRODUCT.SAVE_PRODUCT_GTIN}${product.uuid}`,
                allProductGtins,
            )
                .then((resposne) => {
                    const addedGtin = resposne.data.find(ele => ele.gtin === value);
                    postGtinSave(addedGtin);
                    dispatch(successMessage('Added Gtin Successfully'));
                })
                .catch((err) => {
                    apiCatchBlockFunction(err, dispatch);
                });
            dispatch(hideSpinner());
            this.fetchGtinValues();
        } catch (e) {
            apiCatchBlockFunction(e, dispatch);
        }
        this.setState({
            value: '',
        });
    }

    handleClose = () => {
        const { handleClose } = this.props;
        handleClose();
    }

    render() {
        const {
            open,
            header,
            classes,
        } = this.props;
        const { allProductGtins } = this.state;
        const { openPopper, buttonAnchorElement, value } = this.state;
        return (
            <React.Fragment>
                <Dialog
                    open={open}
                    maxWidth="md"
                    scroll="body"
                    aria-labelledby="form-dialog-title"
                    className={classes.dialog}
                    classes={{
                        paper: classes.paper,
                    }}
                >
                    <DialogTitle id="form-dialog-title" style={{ position: 'relative' }}className={classes.title}>
                        <Grid container justify="space-between">
                            <div>{header}</div>
                            <Close style={{ position: 'absolute', right: '0' }} className="cursor-pointer" onClick={this.handleClose} test-id="close-mapper" />
                        </Grid>
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <Grid container item lg={12} md={12} sm={12} spacing={8} justify="space-between">
                            <Grid item lg={8} md={8} sm={8}>
                                <Typography variant="h6">
                                    Product GTIN
                                </Typography>
                            </Grid>
                            <Grid item lg={2} md={2} sm={2}>
                                <ActionButton
                                    variant="outlined"
                                    disableRipple
                                    onClick={this.handleAddClick}
                                >
                                    Add
                                </ActionButton>
                            </Grid>
                            <Grid item lg={2} md={2} sm={2}>
                                <ActionButton
                                    variant="contained"
                                    disableRipple
                                    onClick={this.handleSave}
                                >
                                    Save
                                </ActionButton>
                            </Grid>
                            <br />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} justify="space-between">
                            {
                                (isArrayValidAndNotEmpty(allProductGtins)) &&
                                allProductGtins.map((aField, index) => (
                                    <Chip
                                        key={aField.id}
                                        className="ml0-5"
                                        label={aField.gtin}
                                        onClick={this.handleClick(aField)}
                                        onDelete={this.handleDelete(index, allProductGtins)}
                                    />
                                ))
                            }
                        </Grid>
                        <Popper
                            open={openPopper}
                            anchorEl={buttonAnchorElement}
                            placement="left"
                            handleClose={this.handlePopperClose}
                        >
                            <DialogContent>
                                <OutlinedTextField
                                    onChange={this.handleChange}
                                    onKeyDown={this.handleKeyDown}
                                    value={value}
                                    label="Value"
                                    testId="text-field-array-component"
                                />
                            </DialogContent>
                            <DialogActions>
                                <ActionButton
                                    variant="outlined"
                                    disableRipple
                                    onClick={this.handleAddItem}
                                    disabled={!value}
                                >
                                    Add
                                </ActionButton>
                                <ActionButton
                                    variant="outlined"
                                    disableRipple
                                    onClick={this.handlePopperClose}
                                >
                                    Cancel
                                </ActionButton>
                            </DialogActions>
                        </Popper>
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        );
    }
}

GtinMapper.propTypes = {
    classes: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
    postGtinSave: PropTypes.func.isRequired,
    header: PropTypes.string,
    gtin: PropTypes.string,
};

GtinMapper.defaultProps = {
    header: 'Product Gtin Map',
    gtin: '',
};

export default withStyles(style)(GtinMapper);
