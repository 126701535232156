import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, FastField } from 'formik';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ReadOnlyTextField from '../../components/ReadOnlyTextField/ReadOnlyTextField';
import SideLabelTextField from '../../components/FormFieldComponents/SideLabelTextField/SideLabelTextField';
import SideLabelSelectField from '../../components/FormFieldComponents/SideLabelSelectField/SideLabelSelectField';
import ButtonGroup from '../../components/ButtonGroup/ButtonGroup';
import ReduxFormChipWithLabel from '../../components/FormFieldComponents/ReduxFormChip/ReduxFormChipWithLabel';
import ReduxFormRadio from '../../components/FormFieldComponents/ReduxFormRadio/ReduxFormRadio';
import {
    choices,
    dateNotLessThanToday,
    dateOfBirth,
    dateShouldGreaterThan,
    email,
    landlineNumber,
    maxLength,
    maxValue,
    minLength,
    minValue,
    number,
    phoneNumber,
    regex,
    required,
    requiredOnlyIfNotEmpty,
} from '../../constants/FormValidations';
import ReduxFormSwitch from '../../components/FormFieldComponents/ReduxFormSwitch/ReduxFormSwitch';
import API from '../../constants/api';
import {
    isArrayValidAndNotEmpty,
    isValidFunction,
} from '../../constants/CommonUtil';
import InputCode from '../../components/WorkflowComponent/InputCodeTable';
import TextFieldWithAsyncValidation
    from '../../components/FormFieldComponents/ReduxFormTextField/TextFieldWithAsyncValidation';
import ReduxFormMenuButton from '../../components/FormFieldComponents/SelectFieldWithAction/ReduxFormMenuButton';
import ReduxFormMultiFileUpload
    from '../../components/FormFieldComponents/ReduxFormChipFileUpload/ReduxFormMultiFileUpload';
import MenuActionButton from '../../components/FormFieldComponents/MenuActionButton/MenuActionButton';
import FormikTextField from './FieldComponents/FormikTextField';
import FormikSideLabelReadOnlyText from './FieldComponents/FormikSideLabelReadOnlyText';
import FormikText from './FieldComponents/FormikText';
import FormikReactSelectMaterial from './FieldComponents/FormikReactSelectMaterial';
import SideLabelReactSelect from './FieldComponents/SideLabelReactSelect';
import FormikTable from './FormikTable/FormikTable';
import FormikCheckbox from './FieldComponents/FormikCheckbox';
import ReactToggle from './FieldComponents/ReactToggle';
import MaskedInput from '../FormFieldComponents/MaskedInput/MaskedInput';
import TimeInput from '../FormFieldComponents/DateInput/TimeInput';
import DatePicker from '../FormFieldComponents/DateInput/DatePicker';
import KeyboardDatePicker from '../FormFieldComponents/DateInput/KeyboardDatePicker';
import FormikDisabledCheckbox from './FieldComponents/FormikDisabledCheckbox';
// import FormikTextFieldWithRadioButton from './FieldComponents/FormikTextFieldWithRadioButton';
import FormikCardsForTextField from './FieldComponents/FormikCardsForTextField';
import FormikMappingComponent from './FieldComponents/FormikMappingComponent';
import FormikLeavePlanComponent from './FieldComponents/FormikLeavePlanComponent';
import FormikLanguageTab from './FieldComponents/FormikLanguageTab';
import FormikFormChipFileUpload from './FieldComponents/FormikFormChipFileUpload';
import { getStringFromObject } from '../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

const validationFunctions = {
    required,
    number,
    choices,
    email,
    regex,
    minValue,
    maxValue,
    maxLength,
    minLength,
    phoneNumber,
    landlineNumber,
    dateOfBirth,
    dateShouldGreaterThan,
    dateNotLessThanToday,
    requiredOnlyIfNotEmpty,
};

const generateNewOrReturnOldFunction = (baseValidationFunctionName, valueForFunction, formValues, member) => {
    let valueForValidation = valueForFunction;
    if (
        baseValidationFunctionName === 'dateShouldGreaterThan' ||
        baseValidationFunctionName === 'requiredOnlyIfNotEmpty'
    ) {
        valueForValidation = member ? `${member}.${valueForValidation}` : valueForValidation;
        valueForValidation = getStringFromObject(valueForValidation, formValues);
    }
    if (!validationFunctions[`${baseValidationFunctionName}-${valueForValidation}`]) {
        if (validationFunctions[baseValidationFunctionName]) {
            validationFunctions[`${baseValidationFunctionName}-${valueForValidation}`] =
                validationFunctions[baseValidationFunctionName](valueForValidation);
        }
    }
    return validationFunctions[`${baseValidationFunctionName}-${valueForValidation}`];
};

// const getSuggestionValue = suggestion => suggestion.value;

const getSearchApi = (api, formValues, member, prependMember) => {
    let dataSourceApi = api;
    const reg = /\{(.*?)\}/;
    let t = reg.exec(dataSourceApi);
    while (t !== null) {
        let matchedParam = t[1];
        if (prependMember) {
            matchedParam = `${member}.${t[1]}`;
        }
        const value = getStringFromObject(matchedParam, formValues, '');
        dataSourceApi = dataSourceApi.replace(new RegExp(reg), value);
        t = reg.exec(dataSourceApi);
    }
    return dataSourceApi;
};

// const mapSearchResult = responseData => responseData; */

const generateFormFields = ({
    fieldData,
    fields,
    actionHandlers,
    ...otherProps
}) => {
    let inputComponent = null;
    const {
        dataSourceConfig,
        fieldType,
        noLabel,
        fastField,
        gridStyle,
        tableCells,
        fullWidth,
        validations,
        dataSourceApi,
        replaceText,
        showInReadOnlyMode,
        showExpiryBatchWithColors,
        hideInEditableMode,
        showInNonEditableMode,
        enableIfFormValue,
        enableIfNotNull,
        isComponent,
        fieldsToChange,
        useMemberForValidation,
        ...propsForInput
    } = fieldData;
    const { paramMap } = otherProps;
    const {
        handleButtonAction,
        handleSelectAction,
        disabled,
        formValues,
        dispatch,
        formName,
        fieldChoices,
        actionParams,
        customValidationFunctions,
        addRef,
    } = otherProps;
    let { label } = propsForInput;
    let inputProps = {
        actionHandlers,
        InputProps: {
            ...propsForInput.InputProps,
        },
        fullWidth,
        formValues,
        addRef,
        ...propsForInput,
    };

    let fieldName = fieldData.name;
    let isField = true;
    let isFieldArray = false;

    if (otherProps.member) {
        fieldName = `${otherProps.member}.${fieldData.name}`;
    }

    let fieldTypeSwitch = fieldType;
    if (!otherProps.editable && (
        (fieldType === 'textField') ||
        (fieldType === 'selectField')
    )) {
        fieldTypeSwitch = 'reduxFormText';
    }
    if (!otherProps.editable && (
        (fieldType === 'sideLabelTextField') ||
        (fieldType === 'autosuggest')
    )) {
        fieldTypeSwitch = 'sideLabelReadOnlyText';
    }

    let isMandatory = false;
    console.log('asfdsdsdfdsf', fieldName);
    let validate = [];
    if (validations && (otherProps.editable)) {
        Object.keys(validations).map((validation) => {
            if (validationFunctions[validation]) {
                if (validation === 'required') {
                    isMandatory = true;
                }
                if (
                    validation === 'required' ||
                    validation === 'email' ||
                    validation === 'number' ||
                    validation === 'dateOfBirth' ||
                    validation === 'phoneNumber' ||
                    validation === 'landlineNumber' ||
                    validation === 'dateNotLessThanToday'
                ) {
                    validate = validations[validation] ? validate.concat(validationFunctions[validation]) : validate;
                } else {
                    const member = useMemberForValidation && otherProps.member ? otherProps.member : '';
                    validate =
                        validations[validation] ?
                            validate.concat(generateNewOrReturnOldFunction(validation, validations[validation],
                                formValues, member)) :
                            validate;
                }
            }
            if (isObjectValidAndNotEmpty(customValidationFunctions) &&
                isValidFunction(customValidationFunctions[validation])) {
                validate = validate.concat(customValidationFunctions[validation]);
            }
            return null;
        });
    }
    const validator = (value, formikBag) => {
        for (let i = 0; i < validate.length; i += 1) {
            const error = validate[i](value, fieldName, formikBag);
            if (error) {
                return error;
            }
        }
        return '';
    };
    let autoSuggestDataSourceApi = '';
    switch (fieldTypeSwitch) {
    case 'title':
        inputComponent = <div><h3 style={{ fontWeight: 400 }}>{fieldData.title}</h3></div>;
        isField = false;
        break;
    case 'reduxFormText':
        inputComponent = FormikText;
        inputProps.dataSourceConfig = dataSourceConfig;
        break;
    case 'text':
        isField = false;
        inputComponent = <div style={{ ...fieldData.style }} >{fieldData.text}</div>;
        break;
    case 'emptyDiv':
        isField = false;
        inputComponent = <div />;
        break;
    case 'switch':
        // TODO
        inputComponent = ReduxFormSwitch;
        break;
    case 'chipUpload':
        // TODO
        inputComponent = FormikFormChipFileUpload;
        inputProps.uploadApi = API.FILE.UPLOAD;
        inputProps.downloadApi = API.FILE.DOWNLOAD_WITH_TOKEN;
        break;
    case 'chipMultiUpload':
        // TODO
        inputComponent = ReduxFormMultiFileUpload;
        inputProps.uploadApi = API.FILE.UPLOAD;
        inputProps.downloadApi = API.FILE.DOWNLOAD_WITH_TOKEN;
        break;
    case 'readOnlyTextField':
        // TODO
        inputComponent = ReadOnlyTextField;
        break;
    case 'sideLabelReadOnlyText':
        inputComponent = FormikSideLabelReadOnlyText;
        inputProps.dataSourceConfig = dataSourceConfig;
        break;
    case 'sideLabelTextField':
        // TODO
        inputComponent = SideLabelTextField;
        break;
    case 'sideLabelSelectField':
        // TODO
        inputComponent = SideLabelSelectField;
        inputProps = {
            ...inputProps,
            dataSourceConfig,
            dataSourceApi: getSearchApi(dataSourceApi, formValues),
            menuitems: fieldData.choices.map(aChoice => (
                <MenuItem
                    value={aChoice[fieldData.dataSourceConfig.value]}
                    key={aChoice[fieldData.dataSourceConfig.value]}
                >
                    {aChoice[fieldData.dataSourceConfig.text]}
                </MenuItem>
            )),
        };
        break;
    case 'selectFieldWithAction':
        // inputComponent = FormikSelectFieldWithAction;
        inputComponent = SideLabelReactSelect;
        inputProps = {
            ...inputProps,
            autocomplete: false,
            dataSourceConfig,
            editable: otherProps.editable,
        };
        inputProps.handleSelectAction = handleSelectAction;
        if (fieldData.takeChoicesFromRow) {
            inputProps.options = fieldChoices;
        }
        if (isArrayValidAndNotEmpty(fieldData.choices)) {
            inputProps.options = fieldData.choices;
        }
        inputProps.disableMinWidth = fieldData.disableMinWidth;
        console.log('fhakdfhkadfa', fieldName, inputProps);
        break;
    case 'menuActionButton':
        // TODO
        inputComponent = MenuActionButton;
        inputProps = {
            ...inputProps,
            handleSelectAction,
            choices: fieldData.choices,
            label: fieldData.label,
            dataSourceConfig,
        };
        break;
    case 'buttonWithAction':
        // TODO
        inputComponent = ReduxFormMenuButton;
        inputProps = {
            ...inputProps,
            handleSelectAction,
            choices: fieldData.choices,
            label: fieldData.label,
        };
        isField = false;
        break;
    case 'buttonGroup':
        inputComponent = (
            <ButtonGroup
                {...propsForInput}
                disabled={disabled || !otherProps.editable}
                member={otherProps.member}
                handleButtonAction={handleButtonAction}
            />);
        isField = false;
        break;
    case 'deleteIconButton':
        inputComponent = (
            <IconButton
                disabled={disabled || !otherProps.editable}
                onClick={() => handleButtonAction(getStringFromObject('action', propsForInput), otherProps.member)}
            >
                <DeleteIcon color="primary" />
            </IconButton>
        );
        isField = false;
        break;
    case 'chipWithLabel':
        // TODO
        inputComponent = ReduxFormChipWithLabel;
        inputProps.actionColor = true;
        break;
    case 'materialTable':
        inputComponent = (
            <FormikTable
                fieldName={fieldName}
                tableCells={tableCells}
                dispatch={dispatch}
                {...inputProps}
                dataSourceApi={getSearchApi(dataSourceApi, formValues)}
                isEditable={otherProps.editable}
            />);
        isField = false;
        break;
    case 'inputCode':
        // TODO
        inputComponent = (
            <InputCode
                {...inputProps}
                name={fieldName}
                dispatch={dispatch}
                formName={formName}
                isEditable={otherProps.editable}
            />
        );
        isField = false;
        break;
    case 'radio':
        // TODO
        inputComponent = ReduxFormRadio;
        inputProps.dataSourceConfig = dataSourceConfig;
        break;
    case 'checkbox':
        inputComponent = FormikCheckbox;
        break;
    case 'disabledCheckbox':
        inputComponent = FormikDisabledCheckbox;
        break;
    case 'textField':
        console.log('abfadkfjakd', fieldName);
        inputComponent = FormikTextField;
        break;
    case 'textFieldWithAsynValidation':
        // TODO
        inputComponent = TextFieldWithAsyncValidation;
        break;
    case 'selectField':
        inputComponent = FormikReactSelectMaterial;
        console.log('ahfjkaf', fieldData.choices, fieldChoices);
        if (fieldData.takeChoicesFromRow) {
            inputProps.menuItems = fieldChoices;
        } else {
            inputProps.options = fieldData.options;
            inputProps.dataSourceConfig = fieldData.dataSourceConfig;
            // inputProps.menuItems = isArrayValidAndNotEmpty(fieldData.choices) ? fieldData.choices.map(aChoice => (
            //     <MenuItem
            //         value={aChoice[fieldData.dataSourceConfig.value]}
            //         key={aChoice[fieldData.dataSourceConfig.value]}
            //     >
            //         {aChoice[fieldData.dataSourceConfig.text]}
            //     </MenuItem>
            // )) : null;
        }
        break;
    case 'reactSelectAutocomplete':
        inputComponent = FormikReactSelectMaterial;
        autoSuggestDataSourceApi = getSearchApi(dataSourceApi, formValues, otherProps.member, propsForInput.prependMember);
        inputProps = {
            ...inputProps,
            dataSourceApi: autoSuggestDataSourceApi,
            dataSourceConfig,
            editable: otherProps.editable,
        };
        break;
    case 'autosuggest':
        inputComponent = SideLabelReactSelect;
        autoSuggestDataSourceApi =
            getSearchApi(dataSourceApi, formValues, otherProps.member, propsForInput.prependMember);
        inputProps = {
            ...inputProps,
            autocomplete: true,
            dataSourceApi: autoSuggestDataSourceApi,
            key: autoSuggestDataSourceApi,
            dataSourceConfig,
        };
        break;
    case 'toggle':
        inputComponent = ReactToggle;
        break;
    case 'maskedInput':
        inputComponent = MaskedInput;
        break;
    case 'timeInput':
        inputComponent = TimeInput;
        break;
    case 'datePicker':
        inputComponent = DatePicker;
        break;
    case 'keyboardDatePicker':
        inputComponent = KeyboardDatePicker;
        break;
    case 'textFieldWithRadioButton':
        inputComponent = FormikCardsForTextField;
        break;
    case 'mappingComponent':
        inputComponent = FormikMappingComponent;
        isField = false;
        isFieldArray = true;
        break;
    case 'leavePlanComponent':
        inputComponent = FormikLeavePlanComponent;
        isField = false;
        isFieldArray = true;
        break;
    case 'languagesTab':
        inputComponent = FormikLanguageTab;
        break;
    default:
        inputComponent = FormikTextField;
    }

    if (!otherProps.editable) {
        inputProps.disabled = true;
        inputProps.InputProps = {
            ...inputProps.InputProps,
            disabled: true,
            disableUnderline: true,
        };
    } else {
        inputProps.disabled = false;
    }

    label = noLabel ? '' : label;
    console.log('InputPropsRefss', isField, inputProps, inputComponent);
    if (isField) {
        const commonProps = {
            component: inputComponent,
            paramMap,
            actionParams,
            name: fieldName,
            showExpiryBatchWithColors,
            label,
            disabled: disabled || !otherProps.editable,
            validate: validator,
            isMandatory,
            isComponent,
        };
        if (fastField) {
            return (
                <FastField
                    {...inputProps}
                    {...commonProps}
                />
            );
        }
        return (
            <Field
                {...inputProps}
                {...commonProps}
            />
        );
    }

    if (isFieldArray) {
        return (
            <FieldArray
                {...inputProps}
                component={inputComponent}
                paramMap={paramMap}
                actionParams={actionParams}
                name={fieldName}
                label={label}
                disabled={disabled || !otherProps.editable}
                validate={validator}
                isMandatory={isMandatory}
                isComponent={isComponent}
            />
        );
    }

    return inputComponent;
};

generateFormFields.propTypes = {
    fieldData: PropTypes.object,
    fields: PropTypes.object,
    actionHandlers: PropTypes.object,
};

generateFormFields.defaultProps = {
    fieldData: {
        hideInEditableMode: false,
    },
    fields: {},
    actionHandlers: {},
};

export default (generateFormFields);
