import React from 'react';
import { connect } from 'react-redux';
import deepEqual from 'react-fast-compare';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Grid from '@material-ui/core/Grid/Grid';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Close from '@material-ui/icons/Close';
import { Field, initialize, reduxForm, formValueSelector, SubmissionError } from 'redux-form';
import classNames from 'classnames';
import { bahrainVatNumber, email, required, vatNumber } from '../../constants/FormValidations';
import ReduxFormCheckbox from '../../components/FormFieldComponents/ReduxFormCheckbox/ReduxFormCheckbox';
import ReduxFormReactSelectMaterial from '../ReduxFormReactSelectMaterial';
import API from '../../constants/api';
import {
    didApiCallFailDueToUnauthorizedUser, didApiCallFailDueToVersionMismatch,
    extractTextFromDoubleQuotes,
    isArrayValidAndNotEmpty,

} from '../../constants/CommonUtil';
import { mismatchInVersion, sessionTimedOut } from '../../redux/modules/login/login-actions';
import { errorMessage } from '../../redux/modules/message/message-actions';
import messages from '../../constants/messages';
import { retriable } from '../../sagas/retriable';
import { mapUiObjFromSupplier } from '../../mapper/SupplierMapper';
import ActionButton from '../ActionButton/ActionButton';
import OutlinedTextField from '../OutlinedTextField';
import ReduxFormChipFileUpload from '../FormFieldComponents/ReduxFormChipFileUpload/ReduxFormChipFileUpload';
// import { subCompanies } from '../../constants/ERPConstants';
import { GLOBAL_PROPERTIES, IMPLEMENTATION_LOCATIONS, ADD_ACCOUNT_PRIVILEGES } from '../../constants/constants';
import AddNewAccountHead from '../Accounts/AddNewAccountHead';
import { checkIfPrivilegeExistsForUser } from '../../constants/privilegeChecker';
import { getStringFromObject } from '../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

const formName = 'addSupplier';

const style = theme => ({
    header: {
        fontSize: '1.5rem',
    },
    textField: {
        borderRadius: '5px',
        backgroundColor: '#fff',
        border: `2px solid ${theme.palette.borderColor}`,
    },
    reactSelectOutlinedTextField: {
        padding: '0',
        borderRadius: '5px',
        backgroundColor: '#fff',
        border: `2px solid ${theme.palette.borderColor}`,
    },
    input: {
        padding: '0 6px',
    },
    multiInput: {
        padding: '6px',
    },
    title: {
        fontSize: '1.3rem',
        fontWeight: '400',
        padding: '12px 24px',
    },
    dialogContent: {
        minWidth: '920px',
        marginTop: '1rem',
        overflow: 'visible',
    },
    dialog: {
        overflow: 'visible',
    },
    paper: {
        overflow: 'visible',
    },
    buttonStyle: {
        height: '1.8rem',
        minWidth: '6rem',
        minHeight: '1rem',
        borderRadius: '1rem',
        fontSize: '0.8rem',
        padding: '4px 16px',
        textTransform: 'capitalize',
        marginLeft: '1rem',
        color: '#fff',
    },
    cancelButton: {
        border: `1px solid ${theme.palette.secondaryTextColor}`,
        color: theme.palette.secondaryTextColor,
    },
    fileUpload: {
        border: `2px solid ${theme.palette.borderColor}`,
        borderRadius: '5px',
        padding: '1em',
    },
});
class AddNewSupplierDialog extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            titles: [],
            paymentTerms: {},
        };
    }

    componentDidMount() {
        const { dispatch, selectedSupplier } = this.props;
        retriable().get(API.SEARCH.ACCOUNT_PAYMENT_TERM).then(
            (response) => {
                this.setState({ paymentTerms: response.data });
            },
            (e) => {
                if (didApiCallFailDueToUnauthorizedUser(e)) {
                    dispatch(sessionTimedOut());
                } else if (didApiCallFailDueToVersionMismatch(e)) {
                    dispatch(mismatchInVersion());
                } else {
                    dispatch(errorMessage(messages.RES_PARTNER_PAYMENT_TERMS_FETCH_FAILED));
                }
            },
        );
        retriable().get(API.SEARCH.RES_PARTNER_TITLE).then(
            (response) => {
                this.setState({ titles: response.data });
            },
            (e) => {
                if (didApiCallFailDueToUnauthorizedUser(e)) {
                    dispatch(sessionTimedOut());
                } else if (didApiCallFailDueToVersionMismatch(e)) {
                    dispatch(mismatchInVersion());
                } else {
                    dispatch(errorMessage(messages.RES_PARTNER_TITLE_FETCH_FAILED));
                }
            },
        );
        dispatch(initialize(formName, mapUiObjFromSupplier(selectedSupplier)));
    }

    componentWillReceiveProps(nextProps) {
        const { dispatch } = this.props;
        if (!deepEqual(nextProps.selectedSupplier, this.props.selectedSupplier)) {
            dispatch(initialize(formName, mapUiObjFromSupplier(nextProps.selectedSupplier)));
        }
    }

    submitForm = (values) => {
        const bankUuid = getStringFromObject('bank.key', values);
        const bankAccountNumber = getStringFromObject('bankAccountNumber', values);
        const accountHolderName = getStringFromObject('accountHolderName', values);
        if (bankUuid || accountHolderName) {
            if (!bankAccountNumber) {
                throw new SubmissionError({ bankAccountNumber: 'Required' });
            }
        }
        this.props.handleSave(values);
    };

    handleSubCompanySelect = () => {
        const { change } = this.props;
        change('accountReceivable', null);
        change('accountPayable', null);
    }

    handleChooseAccount = (account) => {
        if (isObjectValidAndNotEmpty(account)) {
            if (account.key && (typeof account.key === 'string') && account.key.includes('NCD-')) {
                const accountName = extractTextFromDoubleQuotes(getStringFromObject('value', account));
                this.setState({
                    createNewAccount: true,
                    accountName,
                });
            }
        }
    }

    handleCloseCreateNewAccount = () => {
        this.setState({
            createNewAccount: false,
            accountName: '',
        });
        const { change } = this.props;
        change('accountReceivable', null);
        change('accountPayable', null);
    }

    render() {
        const {
            open,
            handleClose,
            classes,
            handleSubmit,
            isCompany,
            payerAndSupplierAccountsMandatory,
            subCompany: subCompanyObj,
            generalPayableAndGeneralReceivableAccounts,
            implementationLocation,
        } = this.props;
        const subCompany = isObjectValidAndNotEmpty(subCompanyObj) ? subCompanyObj.key : '';
        const {
            titles,
            paymentTerms,
            createNewAccount,
            accountName,
        } = this.state;
        console.log('asdfsdfsdfdsfdsf', this.props.selectedSupplier, handleSubmit, paymentTerms);
        const notInBahrain = implementationLocation !== IMPLEMENTATION_LOCATIONS.BAHRAIN;
        let addAccount = true;
        if (!checkIfPrivilegeExistsForUser(ADD_ACCOUNT_PRIVILEGES.supplierCreateAccount)) {
            addAccount = false;
        }
        return (
            <React.Fragment>
                <form>
                    <Dialog
                        open={open}
                        maxWidth="md"
                        scroll="body"
                        aria-labelledby="form-dialog-title"
                        className={classes.dialog}
                        classes={{
                            paper: classes.paper,
                        }}
                    >
                        <DialogTitle id="form-dialog-title" className={classes.title}>
                            <Grid container justify="space-between">
                                <div>Supplier Information</div>
                                <Close className="cursor-pointer" onClick={handleClose} test-id="supplier-close" />
                            </Grid>
                        </DialogTitle>
                        <DialogContent className={classes.dialogContent}>
                            <Grid container spacing={16}>
                                <Grid item lg={4} md={4} sm={4}>
                                    <Field
                                        testId="name"
                                        name="name"
                                        component={OutlinedTextField}
                                        label="Name"
                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: {
                                                root: classNames(classes.textField, classes.input),
                                            },
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        required
                                        validate={[required]}
                                    />
                                </Grid>
                                <Grid item lg={4} md={4} sm={4}>
                                    <Field
                                        name="email"
                                        component={OutlinedTextField}
                                        label="Email"
                                        fullWidth
                                        validate={[email]}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: {
                                                root: classNames(classes.textField, classes.input),
                                            },
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={2} md={2} sm={2}>
                                    <Field
                                        testId="isCompany"
                                        name="isCompany"
                                        component={ReduxFormCheckbox}
                                        label="Is a company"
                                    />
                                </Grid>
                                <Grid item lg={2} md={2} sm={2}>
                                    <Field
                                        testId="active"
                                        name="active"
                                        component={ReduxFormCheckbox}
                                        label="Active"
                                    />
                                </Grid>
                                <Grid item lg={4} md={4} sm={4}>
                                    <Field
                                        name="phone"
                                        component={OutlinedTextField}
                                        label="Phone"
                                        fullWidth
                                        type="number"
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: {
                                                root: classNames(classes.textField, classes.input),
                                            },
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={4} md={4} sm={4}>
                                    <Field
                                        name="mobile"
                                        component={OutlinedTextField}
                                        label="Mobile"
                                        type="number"
                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: {
                                                root: classNames(classes.textField, classes.input),
                                            },
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={4} md={4} sm={4}>
                                    <Field
                                        name="fax"
                                        component={OutlinedTextField}
                                        label="Fax"
                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: {
                                                root: classNames(classes.textField, classes.input),
                                            },
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                {
                                    isCompany &&
                                    <Grid item lg={4} md={4} sm={4}>
                                        <Field
                                            name="title"
                                            component={ReduxFormReactSelectMaterial}
                                            label="Title"
                                            dataSourceConfig={{
                                                text: 'name',
                                                value: 'uuid',
                                            }}
                                            fullWidth
                                            options={titles}
                                        />
                                    </Grid>
                                }
                                {
                                    isCompany &&
                                    <Grid item lg={4} md={4} sm={4}>
                                        <Field
                                            testId="jobposition"
                                            name="jobPosition"
                                            component={OutlinedTextField}
                                            label="Job position"
                                            fullWidth
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    root: classNames(classes.textField, classes.input),
                                                },
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                }
                                {
                                    isCompany &&
                                    <Grid item lg={4} md={4} sm={4}>
                                        <Field
                                            testId="companyname"
                                            name="companyName"
                                            component={ReduxFormReactSelectMaterial}
                                            dataSourceConfig={{
                                                text: 'value',
                                                value: 'key',
                                            }}
                                            label="Company"
                                            selectProps={{
                                                textFieldProps: {
                                                    InputProps: {
                                                        disableUnderline: true,
                                                        classes: {
                                                            root: classes.reactSelectOutlinedTextField,
                                                            input: classes.selectInput,
                                                        },
                                                    },
                                                },
                                            }}
                                            autocomplete
                                            dataSourceApi={`${API.SEARCH.COMPANY}`}
                                        />
                                    </Grid>
                                }
                                <Grid item lg={4} md={4} sm={4}>
                                    <Field
                                        testId="street"
                                        name="street"
                                        component={OutlinedTextField}
                                        label="Address"
                                        placeholder="Street"
                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: {
                                                root: classNames(classes.textField, classes.input),
                                            },
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={4} md={4} sm={4}>
                                    <Field
                                        testId="citytown"
                                        name="cityTown"
                                        component={OutlinedTextField}
                                        label="City/Town"
                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: {
                                                root: classNames(classes.textField, classes.input),
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={4} md={4} sm={4}>
                                    <Field
                                        testId="state"
                                        name="state"
                                        component={ReduxFormReactSelectMaterial}
                                        dataSourceConfig={{
                                            text: 'value',
                                            value: 'key',
                                        }}
                                        label="State"
                                        selectProps={{
                                            textFieldProps: {
                                                InputProps: {
                                                    disableUnderline: true,
                                                    classes: {
                                                        root: classes.reactSelectOutlinedTextField,
                                                        input: classes.selectInput,
                                                    },
                                                },
                                            },
                                        }}
                                        autocomplete
                                        dataSourceApi={`${API.SEARCH.STATES}`}
                                    />
                                </Grid>
                                <Grid item lg={4} md={4} sm={4}>
                                    <Field
                                        testId="country"
                                        name="country"
                                        component={ReduxFormReactSelectMaterial}
                                        dataSourceConfig={{
                                            text: 'value',
                                            value: 'key',
                                        }}
                                        label="Country"
                                        autocomplete
                                        dataSourceApi={`${API.SEARCH.COUNTRY}`}
                                    />
                                </Grid>
                                <Grid item lg={4} md={4} sm={4}>
                                    <Field
                                        testId="pincode"
                                        name="pinCode"
                                        component={OutlinedTextField}
                                        placeholder="PIN code"
                                        type="number"
                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: {
                                                root: classNames(classes.textField, classes.input),
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={4} md={4} sm={4}>
                                    <Field
                                        name="website"
                                        component={OutlinedTextField}
                                        label="Website"
                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: {
                                                root: classNames(classes.textField, classes.input),
                                            },
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={4} md={4} sm={4}>
                                    <Field
                                        name="subCompany"
                                        component={ReduxFormReactSelectMaterial}
                                        label="Sub Company"
                                        testId="sub-company"
                                        fullWidth
                                        required
                                        validate={[required]}
                                        dataSourceApi={API.EMPLOYEE.GET_EMPLOYEE_SUBCOMPANY}
                                        onSelectHandlers={['handleSubCompanySelect']}
                                        actionHandlers={{
                                            handleSubCompanySelect: this.handleSubCompanySelect,
                                        }}
                                        dataSourceConfig={{
                                            text: 'value',
                                            value: 'key',
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={4} md={4} sm={4}>
                                    <Field
                                        name="accountReceivable"
                                        component={ReduxFormReactSelectMaterial}
                                        dataSourceConfig={{
                                            text: 'value',
                                            value: 'key',
                                        }}
                                        label="Account recievable"
                                        testId="account-recievable"
                                        required={!!payerAndSupplierAccountsMandatory}
                                        validate={payerAndSupplierAccountsMandatory ? [required] : []}
                                        selectProps={{
                                            textFieldProps: {
                                                InputProps: {
                                                    disableUnderline: true,
                                                    classes: {
                                                        root: classes.reactSelectOutlinedTextField,
                                                        input: classes.selectInput,
                                                    },
                                                },
                                            },
                                        }}
                                        autocomplete
                                        creatable={addAccount}
                                        isDisabled={!subCompany}
                                        onSelectHandlers={['handleChooseAccount']}
                                        actionHandlers={{
                                            handleChooseAccount: this.handleChooseAccount,
                                        }}
                                        dataSourceApi={`${API.SEARCH.ACCOUNTS}?type=payable&ignoreUuidList${generalPayableAndGeneralReceivableAccounts}&subCompany=${subCompany || ''}&searchString=`}
                                    />
                                </Grid>
                                <Grid item lg={4} md={4} sm={4}>
                                    <Field
                                        name="accountPayable"
                                        component={ReduxFormReactSelectMaterial}
                                        dataSourceConfig={{
                                            text: 'value',
                                            value: 'key',
                                        }}
                                        label="Account payable"
                                        testId="account-payable"
                                        required={!!payerAndSupplierAccountsMandatory}
                                        validate={payerAndSupplierAccountsMandatory ? [required] : []}
                                        selectProps={{
                                            textFieldProps: {
                                                InputProps: {
                                                    disableUnderline: true,
                                                    classes: {
                                                        root: classes.reactSelectOutlinedTextField,
                                                        input: classes.selectInput,
                                                    },
                                                },
                                            },
                                        }}
                                        autocomplete
                                        creatable={addAccount}
                                        isDisabled={!subCompany}
                                        onSelectHandlers={['handleChooseAccount']}
                                        actionHandlers={{
                                            handleChooseAccount: this.handleChooseAccount,
                                        }}
                                        dataSourceApi={`${API.SEARCH.ACCOUNTS}?type=payable&ignoreUuidList${generalPayableAndGeneralReceivableAccounts}&subCompany=${subCompany || ''}&searchString=`}
                                    />
                                </Grid>
                                <Grid item lg={3} md={3} sm={3}>
                                    <Field
                                        component={ReduxFormReactSelectMaterial}
                                        name="bank"
                                        dataSourceApi={API.SEARCH.SEARCH_BANKS}
                                        label="Bank"
                                        dataSourceConfig={{
                                            text: 'value',
                                            value: 'key',
                                        }}
                                        autocomplete
                                    />
                                </Grid>
                                <Grid item lg={3} md={3} sm={3}>
                                    <Field
                                        name="bankAccountNumber"
                                        component={OutlinedTextField}
                                        label="Account Number"
                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: {
                                                root: classNames(classes.textField, classes.input),
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={3} md={3} sm={3}>
                                    <Field
                                        name="accountHolderName"
                                        component={OutlinedTextField}
                                        label="Account Holder Name"
                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: {
                                                root: classNames(classes.textField, classes.input),
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={3} md={3} sm={3}>
                                    <Field
                                        name="vatNo"
                                        component={OutlinedTextField}
                                        label="VAT No"
                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: {
                                                root: classNames(classes.textField, classes.input),
                                            },
                                        }}
                                        required={notInBahrain}
                                        validate={notInBahrain ? [required, vatNumber] : [bahrainVatNumber]}
                                    />
                                </Grid>
                                <Grid item lg={3} md={3} sm={3}>
                                    <Field
                                        testId="comment"
                                        name="comment"
                                        component={OutlinedTextField}
                                        label="Notes"
                                        multiline
                                        rows={2}
                                        rowsMax={3}
                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: {
                                                root: classNames(classes.textField, classes.multiInput),
                                            },
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={3} md={3} sm={3}>
                                    <Field
                                        name="leadTime"
                                        component={OutlinedTextField}
                                        type="number"
                                        label="Lead Time(Months)"
                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: {
                                                root: classNames(classes.textField, classes.input),
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={3} md={3} sm={3}>
                                    <Field
                                        name="paymentTerms"
                                        component={ReduxFormReactSelectMaterial}
                                        label="Payment terms"
                                        dataSourceConfig={{
                                            text: 'value.value',
                                            value: 'key',
                                        }}
                                        selectProps={{
                                            textFieldProps: {
                                                InputProps: {
                                                    disableUnderline: true,
                                                    classes: {
                                                        root: classes.reactSelectOutlinedTextField,
                                                        input: classes.selectInput,
                                                    },
                                                },
                                            },
                                        }}
                                        options={isArrayValidAndNotEmpty(paymentTerms) ? paymentTerms : []}
                                    />
                                </Grid>
                                <Grid item lg={3} md={3} sm={3}>
                                    <Field
                                        testId="crNumber"
                                        name="crNumber"
                                        component={OutlinedTextField}
                                        label="CR Number"
                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: {
                                                root: classNames(classes.textField, classes.input),
                                            },
                                        }}
                                        required={notInBahrain}
                                        validate={notInBahrain ? [required] : []}
                                    />
                                </Grid>
                                {
                                    false &&
                                    <Grid item lg={6} md={6} sm={6}>
                                        <Grid container spacing={16}>
                                            <Grid item sm={12} md={12} lg={12} style={{ color: '#0000008a' }}>
                                                Attachments
                                            </Grid>
                                            <Grid item sm={12} md={12} lg={12}>
                                                <Field
                                                    name="attachments"
                                                    uploadApi={API.FILE.UPLOAD}
                                                    disabled={false}
                                                    component={ReduxFormChipFileUpload}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                        </DialogContent>
                        <DialogActions style={{ padding: '12px' }}>
                            <ActionButton
                                test-id="cancel-supplier"
                                disableRipple
                                primary={false}
                                onClick={handleClose}
                            >
                                Cancel
                            </ActionButton>
                            <ActionButton
                                testId="save-supplier"
                                type="Submit"
                                disableRipple
                                onClick={handleSubmit(this.submitForm)}
                            >
                                Save
                            </ActionButton>
                        </DialogActions>
                    </Dialog>
                </form>
                {
                    createNewAccount &&
                    <AddNewAccountHead
                        open={createNewAccount}
                        tempName={accountName}
                        selectedAccount={{ name: accountName, active: true }}
                        handleClose={this.handleCloseCreateNewAccount}
                    />
                }
            </React.Fragment>
        );
    }
}

AddNewSupplierDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    handleSave: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    selectedSupplier: PropTypes.object.isRequired,
    payerAndSupplierAccountsMandatory: PropTypes.bool,
    isCompany: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
    ]),
    subCompany: PropTypes.string,
    change: PropTypes.func,
    generalPayableAndGeneralReceivableAccounts: PropTypes.array,
    implementationLocation: PropTypes.string.isRequired,
};

AddNewSupplierDialog.defaultProps = {
    isCompany: false,
    payerAndSupplierAccountsMandatory: false,
    subCompany: '',
    change: () => { },
    generalPayableAndGeneralReceivableAccounts: [],
};

const selector = formValueSelector(formName);

const mapStateToProps = state => ({
    isCompany: selector(state, 'isCompany'),
    subCompany: selector(state, 'subCompany'),
    payerAndSupplierAccountsMandatory:
        getStringFromObject('appConfiguration.payerAndSupplierAccountsMandatory', state),
    generalPayableAndGeneralReceivableAccounts:
        getStringFromObject('appConfiguration.general_payable_and_general_receivable_accounts', state) ||
        [],
    implementationLocation: getStringFromObject(`appConfiguration.${GLOBAL_PROPERTIES.IMPLEMENTATION_LOCATION.replace(/\./g, '_')}`, state),
});

export default connect(mapStateToProps)(reduxForm({
    form: formName,
})(withStyles(style)(AddNewSupplierDialog)));
