import React from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'clone-deep';
import sumBy from 'lodash.sumby';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField/TextField';
import Grid from '@material-ui/core/Grid/Grid';
import connect from 'react-redux/lib/connect/connect';
import { withFormik } from 'formik';
import {
    extractTextFromDoubleQuotes,
    getIndexFromFieldName,
    isArrayValidAndNotEmpty,


} from '../../constants/CommonUtil';
import ActionButton from '../ActionButton/ActionButton';
import FormikTable from '../Formik/FormikTable/FormikTable';
import { BATCH_UI_OBJECT } from '../../mapper/ReceiveStockMapper';
import DialogComponent from '../DialogComponent/DialogComponent';
import {
    addOrSubtractDays,
    formatDateToString,
    getDateInYYYYMMDDFormat,
    isDateLessThan,
} from '../../constants/DateUtil';
import GtinMapper from '../AcceptPackagesTable/GtinMapper';
import { getStringFromObject } from '../../constants/lodashUtils';
import { NumberOf } from '../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

const formName = 'enterBatchDetailsForm';

const style = () => ({
    title: {
        fontSize: '1.3rem',
        fontWeight: '400',
    },
    labelDiv: {
        width: '3.5rem',
    },
    dialogContent: {
        overflow: 'auto',
    },
});

const getTextField = (name, label, value, error) => (
    <Grid item lg={2} md={3} sm={6}>
        <TextField
            name={name}
            label={label}
            value={value}
            variant="outlined"
            fullWidth
            error={Boolean(error)}
            helperText={error}
            disabled
        />
    </Grid>
);

class EnterBatchDetailsDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            createNewGtin: false,
        };
    }

    handleSave = () => {
        const { submitForm } = this.props;
        submitForm();
    };

    validatePrice = (value, fieldName) => {
        const { values } = this.props;
        const index = getIndexFromFieldName(fieldName);
        if (index != null) {
            const cost = NumberOf(getStringFromObject(`batches.${index}.batchCost`, values, 0));
            if (cost > NumberOf(value)) {
                return 'Should be more than or equal to cost price';
            }
        }
        return undefined;
    };

    validateSalePrice = (value, fieldName) => {
        const { values } = this.props;
        const validated = this.validatePrice(value, fieldName);
        if (validated) {
            return validated;
        }
        const index = getIndexFromFieldName(fieldName);
        if (index != null) {
            const taxes = getStringFromObject('taxes', values, []);
            if (isArrayValidAndNotEmpty(taxes)) {
                const tax = sumBy(taxes, line => NumberOf(line.amount));
                const salePriceWithTax = NumberOf(value) + (tax * NumberOf(value));
                const mrp = NumberOf(getStringFromObject(`batches.${index}.batchMrp`, values, 0));
                if (!(salePriceWithTax <= mrp)) {
                    return 'sale price including taxes should be less than MRP';
                }
            }
        }
        return undefined;
    };

    handleExpiryDateChange = (value, fieldName) => {
        const { setFieldError } = this.props;
        const index = getIndexFromFieldName(fieldName);
        if (index != null) {
            if (value) {
                const minDate = addOrSubtractDays(new Date(), 30, '+');
                if (isDateLessThan(new Date(value), minDate)) {
                    setFieldError(
                        `batches.${index}.batchExpiryDate`,
                        'expiry date cannot be less than 30 days from now',
                    );
                }
            } else {
                setFieldError(`batches.${index}.batchExpiryDate`, 'required');
            }
        }
    };

    handleSelectBatch = (value, fieldName) => {
        const { setFieldValue, values } = this.props;
        const index = getIndexFromFieldName(fieldName);
        if (index != null) {
            const line = cloneDeep(getStringFromObject(`batches.${index}`, values, {}));
            const expiryDate = getStringFromObject('expiryDate', value);
            line.batchExpiryDate = expiryDate ? formatDateToString(new Date(expiryDate), 'yyyy-MM-dd') : '';
            const batchUuid = getStringFromObject('key', value);
            let batchNumber = '';
            let nonCodedBatch = false;
            if (batchUuid && batchUuid.startsWith('NCD-')) {
                batchNumber = batchUuid.replace('NCD-', '');
                nonCodedBatch = true;
            }
            line.stockProdLot = value;
            line.batchNumber = batchNumber;
            line.nonCodedBatch = nonCodedBatch;
            setFieldValue(`batches.${index}`, line);
        }
    };

    handleChooseGtin = (productGtinMapDto, field) => {
        const { values } = this.props;
        const index = getIndexFromFieldName(field);
        if (values.product) {
            if (isObjectValidAndNotEmpty(productGtinMapDto)) {
                if (productGtinMapDto.uuid && (typeof productGtinMapDto.uuid === 'string') && productGtinMapDto.uuid.includes('NCD-')) {
                    const gtin = extractTextFromDoubleQuotes(getStringFromObject('gtin', productGtinMapDto));
                    this.setState({
                        createNewGtin: true,
                        gtin,
                        index,
                    });
                }
            }
        }
    }

    handleCloseCreateDialog = () => {
        this.setState({
            createNewGtin: false,
            gtin: '',
        });
    }

    postGtinSave = (value) => {
        const { setFieldValue } = this.props;
        const { index } = this.state;
        setFieldValue(`batches.${index}.productGtinMapDto`, value);
    }

    expiryDateValidation = (value) => {
        if (value) {
            const minDate = addOrSubtractDays(new Date(), 30, '+');
            if (isDateLessThan(new Date(value), minDate)) {
                return 'expiry date cannot be less than 30 days from now';
            }
        } else {
            return 'required';
        }
        return undefined;
    };

    handleAddNewLine = () => {
        const { values, setFieldValue } = this.props;
        const trackByBatch = getStringFromObject('trackByBatch', values, false);
        const rate = getStringFromObject('rate', values, '');
        const length = getStringFromObject('batches.length', values, 0);
        const sp = getStringFromObject('product.salePrice', values, 0);
        const mrp = getStringFromObject('product.mrp', values, 0);
        const newBatch = cloneDeep(BATCH_UI_OBJECT);
        newBatch.trackByBatch = trackByBatch;
        newBatch.batchCost = rate;
        newBatch.batchSalePrice = sp || rate;
        newBatch.batchMrp = mrp || sp || rate;
        setFieldValue(`batches.${length}`, newBatch);
    };

    render() {
        const {
            open,
            handleClose,
            classes,
            batchSchema,
            dispatch,
            values,
        } = this.props;
        const {
            createNewGtin,
            gtin,
        } = this.state;
        console.log('afhjdhafjka', this.props);
        // const batches = getStringFromObject('batches', values, []);
        const orderedQuant = NumberOf(getStringFromObject('quantity', values, 0));
        const bonusPo = NumberOf(getStringFromObject('bonusPo', values, 0));
        const originallyReceived = NumberOf(getStringFromObject('originallyReceived', values, 0));
        // const received = sumBy(batches, item => item.batchQuantity);
        const returned = NumberOf(getStringFromObject('returned', values, 0));
        // const receivableQuant = orderedQuant - originallyReceived;
        // const error = balanced < 0 ? `can not receive more than ${receivableQuant}` : '';
        const error = '';
        return (
            <React.Fragment>
                <form>
                    <DialogComponent
                        open={open}
                        classes={classes}
                        fullScreen
                        maxWidth="lg"
                        fullWidth
                        header="Enter Received Inventory"
                        handleClose={handleClose}
                        dialogActions={
                            <React.Fragment>
                                <ActionButton
                                    testId="receive-cancel"
                                    disableRipple
                                    onClick={handleClose}
                                    primary={false}
                                    className="mr-1"
                                >
                                    Cancel
                                </ActionButton>
                                <ActionButton
                                    testId="receive-save"
                                    disableRipple
                                    onClick={this.handleSave}
                                >
                                    Save
                                </ActionButton>
                            </React.Fragment>
                        }
                    >
                        <div style={{ background: '#fff', padding: '1em 2em 2em' }}>
                            <Grid container className="mt-2">
                                <Grid item lg={12} md={12} sm={12}>
                                    <Grid container spacing={32} justify="flex-start">
                                        {getTextField('product', 'Product',
                                            getStringFromObject('product.productName', values))}
                                        {getTextField('orderedQuant', 'Ordered Quantity', orderedQuant)}
                                        {getTextField('bonusPo', 'Bonus Quantity (from PO)', bonusPo)}
                                    </Grid>
                                    <Grid container spacing={32} justify="flex-start">
                                        {getTextField('receivedQuant', 'Received Quantity', originallyReceived, error)}
                                        {getTextField('returnedQuant', 'Returned Quantity', returned, error)}
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                isObjectValidAndNotEmpty(batchSchema) &&
                                <Grid container className="mt-2">
                                    <FormikTable
                                        {...batchSchema}
                                        tableRoot={{ minHeight: '20em', overflow: 'visible' }}
                                        dispatch={dispatch}
                                        fieldName="batches"
                                        actionHandlers={{
                                            addNewLine: this.handleAddNewLine,
                                            handleExpiryDateChange: this.handleExpiryDateChange,
                                            handleSelectBatch: this.handleSelectBatch,
                                            handleChooseGtin: this.handleChooseGtin,
                                        }}
                                        paramMap={{
                                            productUuid: getStringFromObject('product.uuid', values),
                                        }}
                                        customValidationFunctions={{
                                            salePriceValidation: this.validateSalePrice,
                                            mrpValidation: this.validatePrice,
                                            expiryDateValidation: this.expiryDateValidation,
                                            // quantityValidation: this.validateQuantity,
                                        }}
                                    />
                                </Grid>
                            }
                        </div>
                    </DialogComponent>
                </form>
                {
                    createNewGtin &&
                    <GtinMapper
                        open
                        header={getStringFromObject('productName', values.product)}
                        product={values.product}
                        handleClose={this.handleCloseCreateDialog}
                        dispatch={dispatch}
                        gtin={gtin}
                        postGtinSave={this.postGtinSave}
                    />
                }
            </React.Fragment>
        );
    }
}

EnterBatchDetailsDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    selectedProduct: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    batchSchema: PropTypes.object.isRequired,
    // eslint-disable-next-line
    handleSave: PropTypes.func.isRequired,
    /* formik props */
    submitForm: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldError: PropTypes.func.isRequired,
    values: PropTypes.object,
};

EnterBatchDetailsDialog.defaultProps = {
    values: {},
};

const handleSubmitForm = (values, { props }) => {
    console.log('asasdas-0dia-sid-asd', values.batches);
    props.handleSave(values.batches);
};

const getInitialValues = (props) => {
    const product = cloneDeep(getStringFromObject('selectedProduct', props, {}));
    let batches = getStringFromObject('selectedProduct.batches', props, []);
    const sp = getStringFromObject('product.salePrice', product, 0);
    const mrp = getStringFromObject('product.mrp', product, 0);
    if (!isArrayValidAndNotEmpty(batches)) {
        const batch = cloneDeep(BATCH_UI_OBJECT);
        if (isObjectValidAndNotEmpty(product)) {
            const cost = getStringFromObject('rate', product);
            batch.batchCost = cost;
            batch.batchSalePrice = sp || cost;
            batch.batchMrp = mrp || sp || cost;
            batch.trackByBatch = getStringFromObject('trackByBatch', product);
        }
        batches = [batch];
    } else {
        batches = batches.map((b) => {
            if (b.batchExpiryDate) {
                return {
                    ...b,
                    batchExpiryDate: getDateInYYYYMMDDFormat(new Date(b.batchExpiryDate)),
                };
            }
            return b;
        });
    }
    product.batches = batches;
    console.log('vinay verma', product);
    return product;
};

// 05/05/2019 the requirement was that we should be able to receive more than the ordered qty
/*
const validateForm = (values) => {
    const error = {};
    /* const batches = getStringFromObject('batches', values, []);
    const purchasedQuant = Number(getStringFromObject('quantity', values, 0));
    const receivedQuant = Number(getStringFromObject('originallyReceived', values, 0));
    if (isArrayValidAndNotEmpty(batches)) {
        const received = sumBy(batches, item => item.batchQuantity);
        /!* if (received > (purchasedQuant - receivedQuant)) {
            setStringPropertyToObject(`batches.${batches.length - 1}.batchQuantity`, error,
                'Can not receive more than balanced quantity');
        } *!/
    }
    return error;
};
*/

export default connect()(withFormik({
    mapPropsToValues: getInitialValues,
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: false,
    displayName: formName,
    // validate: validateForm,
    handleSubmit: handleSubmitForm,
})(withStyles(style)(EnterBatchDetailsDialog)));
