import API from '../../../constants/api';

export const SAVE_DUTY_ACTION_REQUEST = '@@attendance/SAVE_DUTY_ACTION_REQUEST';
export const SAVE_DUTY_ACTION_FAILURE = '@@attendance/SAVE_DUTY_ACTION_FAILURE';
export const SAVE_DUTY_ACTION_SUCCESS = '@@attendance/SAVE_DUTY_ACTION_SUCCESS';

export const UPDATE_APPROVED_ATTENDANCE_REQUEST = '@@attendance/UPDATE_APPROVED_ATTENDANCE_REQUEST';
export const UPDATE_APPROVED_ATTENDANCE_FAILURE = '@@attendance/UPDATE_APPROVED_ATTENDANCE_FAILURE';
export const UPDATE_APPROVED_ATTENDANCE_SUCCESS = '@@attendance/UPDATE_APPROVED_ATTENDANCE_SUCCESS';

export const REVERT_APPROVED_ATTENDANCE_REQUEST = '@@attendance/REVERT_APPROVED_ATTENDANCE_REQUEST';
export const REVERT_APPROVED_ATTENDANCE_FAILURE = '@@attendance/REVERT_APPROVED_ATTENDANCE_FAILURE';
export const REVERT_APPROVED_ATTENDANCE_SUCCESS = '@@attendance/REVERT_APPROVED_ATTENDANCE_SUCCESS';

export const FETCH_ATTENDANCE_LIST_ACTION_REQUEST = '@@attendance/FETCH_ATTENDANCE_LIST_ACTION_REQUEST';
export const FETCH_ATTENDANCE_LIST_ACTION_FAILURE = '@@attendance/FETCH_ATTENDANCE_LIST_ACTION_FAILURE';
export const FETCH_ATTENDANCE_LIST_ACTION_SUCCESS = '@@attendance/FETCH_ATTENDANCE_LIST_ACTION_SUCCESS';

export const FETCH_BY_EMPLOYEE_AND_PERIOD_REQUEST = '@@attendance/FETCH_BY_EMPLOYEE_AND_PERIOD_REQUEST';
export const FETCH_BY_EMPLOYEE_AND_PERIOD_SUCCESS = '@@attendance/FETCH_BY_EMPLOYEE_AND_PERIOD_SUCCESS';
export const FETCH_BY_EMPLOYEE_AND_PERIOD_FAILURE = '@@attendance/FETCH_BY_EMPLOYEE_AND_PERIOD_FAILURE';

export const UPDATE_ATTENDANCE_LIST_ACTION = '@@attendance/UPDATE_ATTENDANCE_LIST_ACTION';

export const FETCH_PERIODS_REQUEST = '@@attendance/FETCH_PERIODS_REQUEST';
export const FETCH_PERIODS_FAILURE = '@@attendance/FETCH_PERIODS_FAILURE';
export const FETCH_PERIODS_SUCCESS = '@@attendance/FETCH_PERIODS_SUCCESS';

export const FETCH_PERIOD_REQUEST = '@@attendance/FETCH_PERIOD_REQUEST';
export const FETCH_PERIOD_FAILURE = '@@attendance/FETCH_PERIOD_FAILURE';
export const FETCH_PERIOD_SUCCESS = '@@attendance/FETCH_PERIOD_SUCCESS';

export const SELECT_PERIOD_REQUEST = '@@attendance/SELECT_PERIOD_REQUEST';

export const RENDER_ATTENDANCE_EDIT_FORM = '@@attendance/RENDER_ATTENDANCE_EDIT_FORM';

export const SELECT_ATTENDANCE_REQUEST = '@@attendance/SELECT_ATTENDANCE_REQUEST';
export const CLEAR_SELECT_ATTENDANCE = '@@attendance/CLEAR_SELECT_ATTENDANCE';

export const saveDuty = (payload, message, successCallback, failureCallback) => ({
    type: SAVE_DUTY_ACTION_REQUEST,
    api: API.ATTENDANCE.SAVE_DUTY,
    payload,
    message,
    successCallback,
    failureCallback,
});

export const saveOrApproveAttendanceRequest = (payload, successCallback, failureCallback) => ({
    type: UPDATE_APPROVED_ATTENDANCE_REQUEST,
    api: API.ATTENDANCE.APPROVE_ATTENDANCE,
    payload,
    successCallback,
    failureCallback,
});

export const revertApprovedAttendance = uuid => ({
    type: REVERT_APPROVED_ATTENDANCE_REQUEST,
    api: API.ATTENDANCE.REVERT_APPROVED_ATTENDANCE,
    uuid,
});

export const fetchPeriodWithUuidRequest = (uuid, successCallback) => ({
    type: FETCH_PERIOD_REQUEST,
    api: `${API.ATTENDANCE.PERIOD_BY_UUID}${uuid}`,
    successAction: FETCH_PERIOD_SUCCESS,
    failureAction: FETCH_PERIOD_FAILURE,
    successCallback,
});

export const selectAttendanceRequest = selectedAttendance => ({
    type: SELECT_ATTENDANCE_REQUEST,
    selectedAttendance,
});

export const renderAttendanceEditForm = edit => ({
    type: RENDER_ATTENDANCE_EDIT_FORM,
    edit,
});

export const fetchPeriods = (successCallback, failureCallback) => ({
    type: FETCH_PERIODS_REQUEST,
    api: `${API.PAYROLL.ALL_PERIODS}?size=36&searchString=`,
    successCallback,
    failureCallback,
});

export const fetchAttendanceRequest = (page, size, sortCol, sortOrder, filters, successCallback) => ({
    type: FETCH_ATTENDANCE_LIST_ACTION_REQUEST,
    api: `${API.ATTENDANCE.FETCH_ATTENDANCE_LIST}?page=${page}&size=${size}&sortCol=${sortCol}&sortOrder=${sortOrder}`,
    page,
    size,
    sortCol,
    sortOrder,
    filters,
    successCallback,
});

export const fetchByEmployeeAndPeriod = (employeeUuid, periodUuid) => ({
    type: FETCH_BY_EMPLOYEE_AND_PERIOD_REQUEST,
    api: API.ATTENDANCE.GET_BY_EMPLOYEE_AND_PERIOD,
    employeeUuid,
    periodUuid,
});

export const updateAttendanceList = list => ({
    type: UPDATE_ATTENDANCE_LIST_ACTION,
    list,
});

export const clearSelectAttendance = () => ({
    type: CLEAR_SELECT_ATTENDANCE,
});
