import React from 'react';
import PropTypes from 'prop-types';
import { SnackbarProvider } from 'notistack';
// import deepEqual from 'react-fast-compare';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ThroughProvider } from 'react-through';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import axios from 'axios';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import { ToastContainer } from 'react-toastify';
import { qz } from '@packages/nuacare-qz-tray';

import muiTheme from './themes/baseTheme';
import SnackBar from './containers/Snackbar/Snackbar';
import HeaderContainer from './containers/HOC/HeaderContainer';
import Spinner from './components/Spinner/Spinner';
import {
    analytics, attendanceSettings,
    autoTextSettings, bedManagementSettings,
    dentalSettings, dutyRosterSettings, hrLeaveSettings,
    labSettings, medicationSettings, miscSettings, medicationSettings2,
    notificationSetting,
    rootPage, userManagementSettings, organisationSettings,
    mrsSettings,
    configurationSettings,
} from './constants/constants';
import { resetRouteRequest } from './redux/modules/navigator/navigator-actions';
import './AppContainer.css';
import { getLoggedInProviderUuid, getSessionId } from './constants/state';
import { logoutRequest, validateSessionRequest } from './redux/modules/login/login-actions';
import WarningDialog from './components/WarningDialog/WarningDialog';
import {
    apiCatchBlockFunction,
    isArrayValidAndNotEmpty, isValidFunction,
    unsubscribeToChannel,
} from './constants/CommonUtil';
import TimedOutSessionDialog from './components/TimedOutSessionDialog/TimedOutSessionDialog';
import Authorization from './containers/Authorization/Authorization';
import SettingsHeader from './components/SettingsHeader';
import AnalyticsHeader from './components/AnalyticsHeader';
import { errorMessage } from './redux/modules/message/message-actions';
import { clearWSConnection, saveWSConnection } from './redux/modules/websocket/websocket-actions';
import api from './constants/api';
import RegistrationSaleShopSelect from './containers/RegistrationSaleShopSelect/RegistrationSaleShopSelect';
import { clearApprovalQueueState } from './redux/modules/approvalQueue/approvalQueue-actions';
import { addOrSubtractMinutes } from './constants/DateUtil';
import { userProperty } from './containers/UserSettingsDialog/OtherSettings/OtherSettingsUtil';
import QueueSelect from './containers/QueueSelect/QueueSelect';
import WarningDialogWithUserInput from './components/ConfirmDialogWithUserInput/ConfirmDialogWithUserInput';
import AppVersionMismatchDialog from './components/AppVersionMismatchDialog/AppVersionMismatchDialog';
import { getStringFromObject } from './constants/lodashUtils';
import { isObjectValidAndNotEmpty } from './constants/nullCheckUtils';

const WS_ANNOUNCEMENT_URL = process.env.NODE_ENV === 'production' ?
    '/nuacare-core/ws' : 'http://localhost:8090/nuacare-core/ws';

class AppContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clientDateError: false,
        };
    }
    componentDidMount() {
        const { history, location, dispatch } = this.props;
        this.validatePage(history, location, dispatch);
        this.connectionRetry = null;
        this.establishWSConnection();
        this.validateClientDate();
    }

    componentWillReceiveProps(nextProps) {
        // const { history, location, dispatch } = nextProps;
        if (nextProps.navigator && this.props.navigator !== nextProps.navigator) {
            if (nextProps.navigator) {
                this.props.history.push(nextProps.navigator);
                this.props.dispatch(resetRouteRequest());
            }
        }

        // if (!deepEqual(this.props.location, location)) {
        //     this.validatePage(history, location, dispatch);
        // }
    }

    componentWillUnmount() {
        try {
            unsubscribeToChannel(this.subscription);
            if (this.stompClient && isValidFunction(this.stompClient.disconnect)) {
                this.stompClient.disconnect();
            }
            if (isValidFunction(qz.websocket.disconnect) && qz.websocket.isActive()) {
                qz.websocket.disconnect();
            }
            this.props.dispatch(clearApprovalQueueState());
        } catch (e) {
            console.error(e);
        }
    }

    onConnectionError = (error) => {
        this.props.dispatch(clearWSConnection());
        this.props.dispatch(errorMessage(error));
        if (this.connectionRetry) {
            clearInterval(this.connectionRetry);
        }
        this.connectionRetry = setInterval(this.establishWSConnection, 10000);
    };
    onConnectionOpen = () => {
        console.info('connection established');
        this.props.dispatch(saveWSConnection(this.stompClient));
        this.subscribeToOpdQueueChanges();
        if (this.connectionRetry) {
            clearInterval(this.connectionRetry);
        }
    };

    establishWSConnection = () => {
        console.info('connection establishing');
        const socket = new SockJS(WS_ANNOUNCEMENT_URL);
        this.stompClient = Stomp.over(socket);
        if (this.stompClient) {
            this.stompClient.connect({}, this.onConnectionOpen, this.onConnectionError);
        }
    };

    validatePage = (history, location, dispatch) => {
        let destination = location.pathname;
        if ((location.search.match(new RegExp('destination', 'g')) || []).length === 0) {
            destination = `${location.pathname}${location.search}`;
        }
        // because on reload the destination keeps repeating
        console.log('ABCDEFEddf', destination);
        if (getSessionId()) {
            dispatch(validateSessionRequest(destination));
        } else {
            dispatch(logoutRequest());
            if (destination !== '/') {
                history.push(`/?destination=${destination}`);
            } else {
                history.push('/');
            }
        }
    };

    validateClientDate = async () => {
        const {
            dispatch,
        } = this.props;
        try {
            const response = await axios.get(api.GET_SERVER_TIME);
            const maxDate = addOrSubtractMinutes(new Date(response.data), 5).getTime();
            const minDate = addOrSubtractMinutes(new Date(response.data), -5).getTime();
            const currentDate = new Date().getTime();
            console.log('a0s9dha09uasdsadas', minDate, maxDate, currentDate);
            if (!(currentDate >= minDate && currentDate <= maxDate)) {
                const errMsg =
                    `client date error occurred:  currentDate:${currentDate}, minDate:${minDate}, maxDate: ${maxDate}`;
                console.error(errMsg);
                await axios.post(api.ERROR_LOG,
                    errMsg,
                    {
                        headers: { 'Content-Type': 'text/plain' },
                    },
                );
                this.setState({
                    clientDateError: true,
                });
            }
        } catch (e) {
            apiCatchBlockFunction(e, dispatch);
        }
    };

    subscribeToOpdQueueChanges = async () => {
        if (this.stompClient) {
            try {
                const response = await axios.get(api.PROVIDER.GET_ALL_USER_PROPERTY);
                if (isArrayValidAndNotEmpty(response.data)) {
                    const disableSoundProperty =
                        response.data.find(
                            (aProperty => aProperty.property === userProperty.DISABLE_SOUND_FOR_NEW_PATIENT),
                        );
                    if (
                        isObjectValidAndNotEmpty(disableSoundProperty) &&
                        disableSoundProperty.propertyValue === 'true'
                    ) {
                        return;
                    }
                }
                this.subscription = this.stompClient.subscribe(
                    `${api.WEB_SOCKET.OPD_QUEUE_LIST}${getLoggedInProviderUuid()}`,
                    this.playSound,
                );
            } catch (e) {
                console.error('error connecting to opd queue');
            }
        }
    };

    playSound = () => {
        const music = this.audio;
        if (music) {
            const startTime = new Date().getTime();
            const interval = setInterval(() => {
                if (new Date().getTime() - startTime > 10000) {
                    clearInterval(interval);
                    return;
                }
                if (music) {
                    music.play();
                }
            }, 5000);
        }
    };

    isSettingPage = history => history.location.pathname.includes('/settings/payor') ||
        history.location.pathname.includes('/payor/network') ||
        history.location.pathname.includes('/settings/services') ||
        history.location.pathname.includes('/settings/books') ||
        history.location.pathname.includes('/settings/cost-accounting') ||
        history.location.pathname.includes(notificationSetting) ||
        history.location.pathname.includes(labSettings) ||
        history.location.pathname.includes(dentalSettings) ||
        history.location.pathname.includes(dutyRosterSettings) ||
        history.location.pathname.includes(miscSettings) ||
        history.location.pathname.includes(autoTextSettings) ||
        history.location.pathname.includes(userManagementSettings) ||
        history.location.pathname.includes(medicationSettings) ||
        history.location.pathname.includes(medicationSettings2) ||
        history.location.pathname.includes(organisationSettings) ||
        history.location.pathname.includes(bedManagementSettings) ||
        history.location.pathname.includes(configurationSettings) ||
        history.location.pathname.includes(attendanceSettings) ||
        history.location.pathname.includes(hrLeaveSettings) ||
        history.location.pathname.includes(mrsSettings);


    isAnalyticsPage = history => history.location.pathname.includes(analytics);

    updatePreRouteChangeCheck = (callbackFunc) => {
        console.log('jpjapsdasd', callbackFunc);
    };

    render() {
        const {
            children,
            history,
            profile,
            sideBarTabs,
            appShortcuts,
            silentSuccess,
            sessionTimedOut,
            appVersionMismatch,
            actionToDispatchAfterLogin,
            match,
        } = this.props;
        const {
            clientDateError,
        } = this.state;
        const locationPath = getStringFromObject('history.location.pathname', this.props);
        if (locationPath === rootPage) {
            return null;
        }
        console.log('TestHistory', sideBarTabs, history, history.location.pathname, this.isAnalyticsPage(history));
        let childrenWithProps = React.Children.map(children, child =>
            child && React.cloneElement(child, { updatePreRouteChangeCheck: this.updatePreRouteChangeCheck }),
        );
        if (clientDateError) {
            childrenWithProps = (
                <div className="flexChildren justify-content-center bigLabel2Rem mt-2">
                    System Date/Time is Wrong. Please set the correct date/time on your system and reload the page
                </div>
            );
        }
        return (
            <MuiThemeProvider theme={muiTheme}>
                <ThroughProvider>
                    <div>
                        {
                            (!this.isSettingPage(history) && !this.isAnalyticsPage(history)) &&
                            <HeaderContainer
                                profile={profile}
                                history={history}
                                appShortcuts={appShortcuts}
                                dispatch={this.props.dispatch}
                            >
                                <Authorization path={getStringFromObject('history.location.pathname', this.props)}>
                                    {childrenWithProps}
                                </Authorization>
                            </HeaderContainer>
                        }
                        {
                            (this.isSettingPage(history)) &&
                            <SettingsHeader history={history} >
                                <Authorization path={getStringFromObject('history.location.pathname', this.props)}>
                                    {childrenWithProps}
                                </Authorization>
                            </SettingsHeader>
                        }
                        {
                            (this.isAnalyticsPage(history)) &&
                            <AnalyticsHeader history={history} match={match}>
                                <Authorization path={getStringFromObject('history.location.pathname', this.props)}>
                                    {childrenWithProps}
                                </Authorization>
                            </AnalyticsHeader>
                        }
                        <Spinner />
                        <SnackbarProvider
                            maxSnack={3}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        >
                            <SnackBar />
                        </SnackbarProvider>
                        {
                            sessionTimedOut &&
                            <TimedOutSessionDialog
                                dispatch={this.props.dispatch}
                                silentSuccess={silentSuccess}
                                actionToDispatchAfterLogin={actionToDispatchAfterLogin}
                            />
                        }
                        {
                            (appVersionMismatch) &&
                            <AppVersionMismatchDialog
                                dispatch={this.props.dispatch}
                            />
                        }
                        <WarningDialog />
                        <WarningDialogWithUserInput />
                        <ToastContainer />
                        <audio
                            style={{ display: 'none' }}
                            ref={(audio) => { this.audio = audio; }}
                            src={`${import.meta.env.BASE_URL}/beep.mp3`}
                        />
                        <RegistrationSaleShopSelect
                            locationPath={locationPath}
                        />
                        <QueueSelect />
                    </div>
                </ThroughProvider>
            </MuiThemeProvider>
        );
    }
}

AppContainer.propTypes = {
    children: PropTypes.node,
    dispatch: PropTypes.func,
    history: PropTypes.object,
    sideBarTabs: PropTypes.object,
    profile: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    appShortcuts: PropTypes.array.isRequired,
    navigator: PropTypes.string.isRequired,
    silentSuccess: PropTypes.bool,
    sessionTimedOut: PropTypes.bool,
    appVersionMismatch: PropTypes.bool,
    actionToDispatchAfterLogin: PropTypes.object,
};

AppContainer.defaultProps = {
    children: null,
    dispatch: () => { },
    history: {},
    sideBarTabs: {},
    sessionTimedOut: false,
    appVersionMismatch: false,
    silentSuccess: false,
    actionToDispatchAfterLogin: {},
};

const mapStateToProps = (state) => {
    console.log('mappingState', state);
    return {
        navigator: state.navigator,
        profile: state.profile,
        sideBarTabs: state.appConfiguration.sideBarTabs,
        sessionTimedOut: state.login.timedOut,
        appVersionMismatch: state.login.appVersionMismatch,
        silentSuccess: state.login.silentSuccess,
        actionToDispatchAfterLogin: state.login.actionToDispatchAfterLogin,
        appShortcuts: getStringFromObject('appConfiguration.appShortcuts', state, []),
    };
};

export default (withRouter(connect(mapStateToProps)(AppContainer)));
