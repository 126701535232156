import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import ReactSelectMaterial from './ReactSelectMaterial/ReactSelectMaterial';
import {
    isArrayValidAndNotEmpty,
    isValidFunction,
} from '../constants/CommonUtil';
import { getStringFromObject } from '../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../constants/nullCheckUtils';

const styles = () => ({
    input: {
        padding: '0',
    },
});

class ReduxFormReactSelectMaterial extends React.Component {
    onChange = (option) => {
        console.log('OnEmptySelect', option);
        const { input, onSelect } = this.props;
        if (Array.isArray(option) && option.length === 0) {
            input.onChange(null);
            this.onSelectHandler([]);
            return;
        }
        input.onChange(option);
        if (isValidFunction(onSelect)) {
            onSelect(option);
        }
        this.onSelectHandler(option);
    };

    onSelectHandler = (value) => {
        const {
            meta: { dispatch, form }, onSelectHandlers, fieldMember, fields, actionHandlers, actionParams,
        } = this.props;
        if (isArrayValidAndNotEmpty(onSelectHandlers) && dispatch && form &&
            isObjectValidAndNotEmpty(actionHandlers)) {
            onSelectHandlers.forEach((action) => {
                const handler = actionHandlers[action];
                if (isValidFunction(handler)) {
                    handler(value, form, fieldMember, fields, dispatch, actionParams);
                }
            });
        }
    };

    onBlurHandler = (value) => {
        const {
            meta: { dispatch, form }, onBlurHandlers, fieldMember, fields, actionHandlers, actionParams,
        } = this.props;
        if (isArrayValidAndNotEmpty(onBlurHandlers) && dispatch && form &&
            isObjectValidAndNotEmpty(actionHandlers)) {
            onBlurHandlers.forEach((action) => {
                const handler = actionHandlers[action];
                if (isValidFunction(handler)) {
                    handler(value, form, fieldMember, fields, dispatch, actionParams);
                }
            });
        }
    };

    onKeyPress = (event) => {
        const { allowFreeText, input } = this.props;
        console.log('sdfsdfdsfadijfdjsdfjlasdkjfjsdfdsf', event.key, typeof input.value);
        if (event.key === 'Enter' && allowFreeText) {
            input.onChange({ key: null, value: event.target.value });
        }
    };

    handleBlur = (e, a, b) => {
        console.log('abfjkahfjkaf', e, a, b);
        const { input = {} } = this.props;
        const onBlur = getStringFromObject('onBlur', input);
        if (isValidFunction(onBlur)) {
            onBlur(input.value);
        }
        this.onBlurHandler(input.value);
    };

    render() {
        const {
            input: {
                value,
            },
            meta: {
                touched,
                error,
            },
            options,
            dataSourceApi,
            dataSourceConfig,
            label,
            selectProps,
            editable,
            multiple,
            noLabel,
            autocomplete,
            classes,
            autocompleteSelectStyle,
            creatable,
            paramMap,
            isDisabled,
            inputRef,
            fullWidth,
            placeholder,
            defaultOptions,
            required,
            cacheOptions,
            createOptionPosition,
            testId,
            icon,
            getOptionLabel,
            getOptionValue,
            fixedOptionSet,
        } = this.props;
        return (
            <ReactSelectMaterial
                testId={testId}
                placeholder={placeholder}
                value={value}
                label={label}
                multiple={multiple}
                dataSourceApi={dataSourceApi}
                dataSourceConfig={dataSourceConfig}
                options={options}
                editable={editable}
                isDisabled={isDisabled}
                fullWidth={fullWidth}
                noLabel={noLabel}
                onKeyPress={this.onKeyPress}
                autocomplete={autocomplete}
                creatable={creatable}
                icon={icon}
                cacheOptions={cacheOptions}
                onChange={this.onChange}
                errorText={touched ? error : ''}
                autocompleteSelectStyle={autocompleteSelectStyle}
                paramMap={paramMap}
                defaultOptions={defaultOptions}
                selectProps={{
                    ...selectProps,
                    classes,
                    textFieldProps: {},
                }}
                inputRef={inputRef}
                createOptionPosition={createOptionPosition}
                required={required}
                onBlur={this.handleBlur}
                getOptionValue={getOptionValue}
                getOptionLabel={getOptionLabel}
                fixedOptions={fixedOptionSet}
            />
        );
    }
}

ReduxFormReactSelectMaterial.propTypes = {
    classes: PropTypes.object,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    onSelect: PropTypes.func,
    dataSourceConfig: PropTypes.object.isRequired,
    selectProps: PropTypes.object,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.array,
    fixedOptionSet: PropTypes.array,
    dataSourceApi: PropTypes.string,
    testId: PropTypes.string,
    icon: PropTypes.string,
    editable: PropTypes.bool,
    noLabel: PropTypes.bool,
    multiple: PropTypes.bool,
    isDisabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    autocomplete: PropTypes.bool,
    creatable: PropTypes.bool,
    allowFreeText: PropTypes.bool,
    defaultOptions: PropTypes.bool,
    required: PropTypes.bool,
    cacheOptions: PropTypes.bool,
    autocompleteSelectStyle: PropTypes.object,
    onSelectHandlers: PropTypes.array,
    onBlurHandlers: PropTypes.array,
    fieldMember: PropTypes.string,
    createOptionPosition: PropTypes.string,
    fields: PropTypes.object,
    actionHandlers: PropTypes.object,
    paramMap: PropTypes.object,
    actionParams: PropTypes.object,
    inputRef: PropTypes.func,
    getOptionValue: PropTypes.func,
    getOptionLabel: PropTypes.func,
};

ReduxFormReactSelectMaterial.defaultProps = {
    onSelect: () => {},
    classes: {},
    label: 'Search and Select',
    placeholder: 'Search...',
    testId: '',
    options: [],
    fixedOptionSet: [],
    dataSourceApi: '',
    icon: '',
    selectProps: {},
    editable: true,
    fullWidth: true,
    noLabel: false,
    multiple: false,
    autocomplete: false,
    creatable: false,
    allowFreeText: false,
    isDisabled: false,
    defaultOptions: true,
    cacheOptions: false,
    required: false,
    autocompleteSelectStyle: {},
    onSelectHandlers: [],
    onBlurHandlers: [],
    fieldMember: '',
    createOptionPosition: '',
    fields: {},
    actionHandlers: {},
    paramMap: {},
    actionParams: {},
    inputRef: () => {},
    getOptionValue: null,
    getOptionLabel: null,
};

export default withStyles(styles)(ReduxFormReactSelectMaterial);

