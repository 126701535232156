import React from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'clone-deep';
import deepEqual from 'react-fast-compare';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Grid from '@material-ui/core/Grid/Grid';
import Close from '@material-ui/icons/Close';
import connect from 'react-redux/lib/connect/connect';
import axios from 'axios';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import { Field, withFormik } from 'formik';
import classNames from 'classnames';

import {
    apiCatchBlockFunction, getJsonPath,
    getRequiredFieldLabel,
    isArrayValidAndNotEmpty,

} from '../../../constants/CommonUtil';
import {
    ACTION_HANDLERS,
    setBatchDetails,
    setValuesOnBatchSelect,
    updateRowValsWIthProductInfo,
} from './InventoryAdjustmentHandlers';
import API from '../../../constants/api';
import {
    mapInternalMovesDtoForAdjustInventoryToUiObject,
    mapInternalMovesForAdjustInventoryFromUiObject,
    UI_OBJECT,
} from '../../../mapper/InternalMovesMapper';
import ActionButton from '../../ActionButton/ActionButton';
import SideLabelReadOnlyText from '../../FormFieldComponents/SideLabelReadOnlyText/SideLabelReadOnlyText';
import {
    adjustInventoryRequest,
    clearStockMoveByPurchaseOrderState, clearStockMoveSelectState,
    fetchStockMovesRequest,
} from '../../../redux/modules/stockMove/stockMove-actions';
import FormikReactSelectMaterial from '../../Formik/FieldComponents/FormikReactSelectMaterial';
import FormikTable from '../../Formik/FormikTable/FormikTable';
import { ROWS_PER_PAGE } from '../../../constants/constants';
import { required } from '../../../constants/FormValidations';
import Spinner from '../../Spinner/Spinner';
import FormikTextField from '../../Formik/FieldComponents/FormikTextField';
import DrugBarcodeEntry from '../InternalMovesDialog/DrugBarcodeEntry';
import { errorMessage } from '../../../redux/modules/message/message-actions';
import { getIndexToAdd } from '../InternalMovesDialog/InternalMovesUtil';
import dialogComponentStyles from '../../DialogComponent/DialogComponentStyles';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { NumberOf } from '../../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const formName = 'inventoryAdjustmentForm';


class InventoryAdjustmentDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            schema: null,
            loading: false,
        };
    }

    componentDidMount() {
        const { targetId, setValues } = this.props;
        if (!targetId) {
            setValues(cloneDeep(UI_OBJECT));
        }
        const jsonApi = getJsonPath('/StockManagement/InventoryAdjustment.json');
        this.getAllBatchesForPurchaseOrderInternal();
        fetch(jsonApi)
            .then(resp => resp.json())
            .then((json) => {
                this.setState({
                    schema: json,
                });
            })
            .catch((error) => {
                console.error(`There has been a problem with your fetch operation:${error.message}`);
            });
    }

    componentWillReceiveProps(nextProps) {
        if (!deepEqual(nextProps.internalMove, this.props.internalMove)) {
            if (isArrayValidAndNotEmpty(nextProps.internalMove)) {
                this.initializeForm(nextProps.internalMove[0]);
            }
        }
        if (!deepEqual(nextProps.selectedMove, this.props.selectedMove)) {
            if (isObjectValidAndNotEmpty(nextProps.selectedMove)) {
                this.initializeForm(nextProps.selectedMove);
            }
        }
    }

    componentWillUnmount() {
        this.props.dispatch(clearStockMoveByPurchaseOrderState());
        this.props.dispatch(clearStockMoveSelectState());
    }

    onAddProductViaBarcode = async (productInfo, barcodeProductQty) => {
        const {
            dispatch,
            values,
            setFieldValue,
            setFieldTouched,
        } = this.props;
        try {
            const indexToAddTo = getIndexToAdd(values, 'products');
            const response = await axios.get(`${API.PRODUCT.GET_PRODUCT_BY_CODE}${productInfo.produceCode}&codeField=${productInfo.codeField}`);
            const product = response.data;
            const rowValue = {};
            rowValue.product = product;
            rowValue.uom = product.erpUomDto;
            rowValue.taxes = product.taxes || [];
            rowValue.batchWithQty = [];
            rowValue.allBatches = [];
            rowValue.description = null;
            rowValue.quantityOnHand = NumberOf(rowValue.quantityOnHand);
            rowValue.quantity = NumberOf(barcodeProductQty);
            rowValue.isInternalLocation = false;
            let newRowValue = updateRowValsWIthProductInfo(rowValue, { sourceLoc: values.sourceLoc });
            const {
                locationUuid,
                productUuid,
            } = newRowValue;
            const allowProductsWithoutBatch = true;
            const response2 = await axios.get(API.PRODUCT.GET_BATCH_AND_QUANTITY, {
                params: {
                    productUuid,
                    locationUuid,
                    allowProductsWithoutBatch,
                },
            });
            newRowValue = setBatchDetails(response2, newRowValue, rowValue);
            console.log('asdhjd9asd9asd0asa0s-as', newRowValue);
            if (!isArrayValidAndNotEmpty(newRowValue.batchWithQty)) {
                dispatch(errorMessage('This product has no batches at location'));
                return;
            }
            const foundBatch = newRowValue.batchWithQty.find(aBatch => (
                getStringFromObject('batch.value', aBatch) === productInfo.batchName
            ));
            if (!isObjectValidAndNotEmpty(foundBatch)) {
                dispatch(errorMessage(`Batch not found with name ${productInfo.batchName} in location`));
                return;
            }
            newRowValue = setValuesOnBatchSelect(newRowValue, foundBatch);
            setFieldValue(`products[${indexToAddTo}]`, newRowValue);
            setFieldTouched(`products[${indexToAddTo}].product`);
        } catch (e) {
            apiCatchBlockFunction(e, dispatch);
        }
    };

    getAllBatchesForPurchaseOrderInternal = () => {
        const {
            dispatch, targetId,
        } = this.props;
        let api = '';
        if (targetId) {
            api = `${API.STOCK.FETCH_STOCK_MOVES_BY_PICKING_ID}${targetId}`;
            dispatch(fetchStockMovesRequest(api));
        }
        console.log('kljasdflaksjdfh', this.props);
    };

    resetLoading = (loading = false) => this.setState({ loading: Boolean(loading) });

    initializeForm = (formValues) => {
        const { dispatch, setValues } = this.props;
        this.setState({ loading: true }, () => {
            const uiObjectPromise = mapInternalMovesDtoForAdjustInventoryToUiObject(formValues, true);
            uiObjectPromise.then((response) => {
                setValues(response);
                this.resetLoading();
            }).catch((error) => {
                apiCatchBlockFunction(error, dispatch);
                this.resetLoading();
            });
        });
    };

    handleSave = () => {
        const { setFieldValue } = this.props;
        setFieldValue('operation', 'STOCK_ADJUSTMENT');
        setFieldValue('status', 'draft');
        this.props.submitForm();
    };

    handleConfirm = () => {
        const { setFieldValue } = this.props;
        setFieldValue('operation', 'STOCK_ADJUSTMENT');
        setFieldValue('status', 'done');
        this.props.submitForm();
    };

    handleSelectSourceLoc = () => {
        const { setFieldValue } = this.props;
        setFieldValue('products', cloneDeep(UI_OBJECT).products);
    };

    render() {
        const {
            open,
            handleClose,
            classes,
            handleSubmit,
            values,
            enableSave,
            setFieldValue,
            barcodeParser,
        } = this.props;
        const {
            schema,
            loading,
        } = this.state;
        const isConfirmed = ['done', 'confirmed'].indexOf(getStringFromObject('status', values)) !== -1;
        const createDate = getStringFromObject('createDate', values);
        const receivedBy = getStringFromObject('receivedBy', values);
        console.log('jklhlkjsadfklj', values);
        console.log('ddddddddjkhdaskjdf', this.props, values);
        return (
            <React.Fragment>
                <form onSubmit={handleSubmit}>
                    <Dialog
                        open={open}
                        fullScreen
                        classes={{
                            paper: classes.paper,
                        }}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle disableTypography id="form-dialog-title" className={classes.title}>
                            <Grid container justify="space-between">
                                <div className={classes.header}>
                                    Adjust Inventory
                                </div>
                                <Close
                                    className={classNames(classes.closeIcon, 'cursor-pointer')}
                                    onClick={handleClose}
                                    test-id="adjust-inventory-close"
                                />
                            </Grid>
                        </DialogTitle>
                        <DialogContent style={{ padding: '0' }}>
                            <div style={{ background: '#fff', padding: '1em 2em 2em' }}>
                                <Grid container spacing={16} justify="space-between">
                                    <Grid item lg={3} md={3} sm={6}>
                                        <Grid container style={{ padding: '1em' }}>
                                            <Field
                                                name="sourceLoc"
                                                component={FormikReactSelectMaterial}
                                                dataSourceConfig={{
                                                    text: 'name',
                                                    value: 'uuid',
                                                }}
                                                label={getRequiredFieldLabel('Location')}
                                                isDisabled={isConfirmed}
                                                placeholder="Location"
                                                setFieldValue={setFieldValue}
                                                autocomplete
                                                dataSourceApi={API.SEARCH.STOCK_LOCATION}
                                                validate={required}
                                                actionHandlers={{
                                                    handleSelectSourceLoc: this.handleSelectSourceLoc,
                                                }}
                                                onSelectHandlers={['handleSelectSourceLoc']}
                                            />
                                        </Grid>
                                    </Grid>
                                    {
                                        createDate &&
                                        <Grid item style={{ padding: '2em' }}>
                                            <Grid container direction="column" spacing={16}>
                                                <Grid item>
                                                    <SideLabelReadOnlyText
                                                        input={{ value: createDate }}
                                                        label="Date Created :"
                                                        classes={classes}
                                                    />
                                                </Grid>
                                                {
                                                    receivedBy &&
                                                    <Grid item>
                                                        <SideLabelReadOnlyText
                                                            input={{ value: receivedBy }}
                                                            label="Creator :"
                                                            classes={classes}
                                                        />
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                                {
                                    isObjectValidAndNotEmpty(values.sourceLoc) && !isConfirmed &&
                                    <DrugBarcodeEntry
                                        dispatch={this.props.dispatch}
                                        onConfirmQuantity={this.onAddProductViaBarcode}
                                        barcodeParser={barcodeParser}
                                    />
                                }
                                <Grid container className="mt-2">
                                    {
                                        values.sourceLoc && isObjectValidAndNotEmpty(schema) &&
                                        <FormikTable
                                            fieldName="products"
                                            isEditable={!isConfirmed}
                                            actionHandlers={ACTION_HANDLERS}
                                            dispatch={this.props.dispatch}
                                            styles={{ minHeight: '20em' }}
                                            {...schema}
                                            tableRoot={{ minHeight: '20em', overflow: 'visible' }}
                                            actionParams={{
                                                sourceLoc: values.sourceLoc,
                                            }}
                                            rowsPerPageOptions={ROWS_PER_PAGE}
                                            formValues={values}
                                        />
                                    }
                                </Grid>
                                {
                                    values.sourceLoc &&
                                    <Grid container className="mt-2" justify="space-between">
                                        <Grid item lg={3} md={3} sm={4}>
                                            <Field
                                                name="notes"
                                                component={FormikTextField}
                                                label="Memo"
                                                multiline
                                                rows={5}
                                                rowsMax={5}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={isConfirmed}
                                            />
                                        </Grid>
                                    </Grid>
                                }
                            </div>
                        </DialogContent>
                        <DialogActions style={{ padding: '12px' }}>
                            {
                                values.sourceLoc && !isConfirmed &&
                                    <div>
                                        {enableSave &&
                                            <ActionButton
                                                testId="adjust-inventory-save"
                                                disableRipple
                                                disabled={isConfirmed}
                                                onClick={this.handleSave}
                                            >
                                            Save
                                            </ActionButton>
                                        }
                                        <ActionButton
                                            testId="adjust-inventory-confirm"
                                            disableRipple
                                            disabled={isConfirmed}
                                            onClick={this.handleConfirm}
                                            className="ml-1"
                                        >
                                            Confirm
                                        </ActionButton>
                                    </div>
                            }
                        </DialogActions>
                    </Dialog>
                </form>
                {
                    loading &&
                        <Spinner
                            canShow
                        />
                }
            </React.Fragment>
        );
    }
}

InventoryAdjustmentDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    dispatch: PropTypes.func,
    handleClose: PropTypes.func.isRequired,
    values: PropTypes.object,
    internalMove: PropTypes.array,
    open: PropTypes.bool.isRequired,
    targetId: PropTypes.number,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    setValues: PropTypes.func.isRequired,
    company: PropTypes.string.isRequired,
    createdBy: PropTypes.string.isRequired,
    barcodeParser: PropTypes.string,
    enableSave: PropTypes.bool,
    selectedMove: PropTypes.object,
};

InventoryAdjustmentDialog.defaultProps = {
    dispatch: () => {},
    values: {},
    internalMove: [],
    targetId: null,
    barcodeParser: '',
    selectedMove: {},
    enableSave: false,
};

const mapStateToProps = state => ({
    internalMove: getStringFromObject('stockMove.stockMovesByPurchaseOrder', state, []),
    selectedMove: getStringFromObject('stockMove.selected', state, []),
    company: getStringFromObject('appConfiguration.companyName', state),
    barcodeParser: getStringFromObject('appConfiguration.barcodeParser', state),
    enableSave: getStringFromObject('appConfiguration.enableSaveAdjustInventory', state),
});

const handleSubmitForm = (values, { props, ...formikBag }) => {
    console.log('fandfjkajghajkgbfajkg', values, formikBag);
    const dispatch = getStringFromObject('dispatch', props);
    const isActionConfirm = ['done', 'confirmed'].indexOf(getStringFromObject('status', values)) !== -1;
    dispatch(adjustInventoryRequest(
        mapInternalMovesForAdjustInventoryFromUiObject(values, values.status, true),
        isActionConfirm ? props.handleClose : '',
    ));
};

export default connect(mapStateToProps)(withFormik({
    mapPropsToValues: () => cloneDeep(UI_OBJECT),
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: false,
    displayName: formName,
    handleSubmit: handleSubmitForm,
})(withStyles(dialogComponentStyles)(InventoryAdjustmentDialog)));
