import api from '../../constants/api';
import { secondaryIdentifierLabelReplaceString } from '../../constants/constants';
import { ORDER_TYPE_DATE_FILTERS } from '../../constants/CommonUtil';

export const insuranceRequestLineStates = {
    APPROVED: 'approved',
    REJECTED: 'rejected',
    DELAYED: 'delayed',
};

export const insuranceRequestsStates = {
    APPROVED: 'approved',
    REJECTED: 'rejected',
    DELAYED: 'delayed',
    LATE_APPROVE: 'late_approve',
    DRAFT: 'draft',
    DONE: 'done',
};

export const insuranceRequestLineSchema = {
    fieldType: 'materialTable',
    id: 'table',
    name: 'insuranceRequestLine',
    showInNonEditableMode: true,
    showInReadOnlyMode: true,
    processJson: true,
    hideCheckBoxIcon: true,
    rowstyle: {
        height: '56px',
    },
    headerColStyle: {
        color: '#FFFFFF',
        fontSize: '14px',
    },
    tableCells: [
        {
            id: 'createDate',
            name: 'createDate',
            fieldType: 'datetime',
            find: 'createDate',
            label: 'Date',
        },
        {
            id: 'product',
            name: 'product',
            fieldType: 'text',
            find: 'product.value',
            label: 'Item',
            fullWidth: true,
        },
        {
            id: 'coPayAmount',
            name: 'coPayAmount',
            fieldType: 'text',
            find: 'coPayAmount',
            label: 'Co Pay Amount',
            fullWidth: true,
        },
        {
            id: 'patientName',
            name: 'patientName',
            fieldType: 'text',
            find: 'patientName',
            label: 'Patient Name',
        },
        {
            id: 'patientIdentifier',
            name: 'patientIdentifier',
            fieldType: 'text',
            find: 'patientIdentifier',
            label: 'Identifier',
        },
        {
            id: 'secondaryIdentifier',
            name: 'secondaryIdentifier',
            fieldType: 'text',
            find: 'secondaryIdentifier',
            label: secondaryIdentifierLabelReplaceString,
        },
        {
            id: 'state',
            name: 'state',
            fieldType: 'text',
            find: 'state',
            label: 'State',
        },
        {
            id: 'processor',
            name: 'processor',
            fieldType: 'text',
            find: 'processor',
            label: 'Processor',
        },
        {
            id: 'processedOn',
            name: 'processedOn',
            fieldType: 'datetime',
            find: 'processedTime',
            label: 'Processed On',
        },
    ],
    grid: {
        sm: 12,
        md: 12,
        lg: 12,
    },
};

export const insuranceRequestsSchema = {
    fieldType: 'materialTable',
    id: 'table',
    name: 'insuranceRequestLine',
    showInNonEditableMode: true,
    showInReadOnlyMode: true,
    hideCheckBoxIcon: true,
    processJson: true,
    rowstyle: {
        height: '56px',
    },
    headerColStyle: {
        color: '#FFFFFF',
        fontSize: '14px',
    },
    tableCells: [
        {
            id: 'dateCreated',
            name: 'dateCreated',
            fieldType: 'datetime',
            find: 'dateCreated',
            label: 'Date',
        },
        {
            id: 'patient',
            name: 'patient',
            fieldType: 'text',
            find: 'patient.value',
            label: 'Patient',
            fullWidth: true,
        },
        {
            id: 'patientIdentifier',
            name: 'patientIdentifier',
            fieldType: 'text',
            find: 'patientIdentifier',
            label: 'Primary Identifier',
            fullWidth: true,
        },
        {
            id: 'secondaryIdentifier',
            name: 'secondaryIdentifier',
            fieldType: 'text',
            find: 'secondaryIdentifier',
            label: secondaryIdentifierLabelReplaceString,
            fullWidth: true,
        },
        {
            id: 'scheme',
            name: 'scheme',
            fieldType: 'text',
            find: 'scheme',
            label: 'Scheme',
            fullWidth: true,
        },
        {
            id: 'subInsurer',
            name: 'subInsurer',
            fieldType: 'text',
            find: 'subInsurer.value',
            label: 'Sub Insurer',
        },
        {
            id: 'payer',
            name: 'payer',
            fieldType: 'text',
            find: 'payer.value',
            label: 'Payer',
        },
        {
            id: 'invoiceQuantity',
            name: 'invoiceQuantity',
            fieldType: 'roundedNumber',
            find: 'invoiceQuantity',
            label: 'Invoice Quantity',
        },
        {
            id: 'state',
            name: 'state',
            fieldType: 'text',
            find: 'state',
            label: 'State',
        },
        {
            id: 'amountInsurance',
            name: 'amountInsurance',
            fieldType: 'price',
            find: 'amountInsurance',
            label: 'Insurance Amount',
        },
        {
            id: 'amountTotal',
            name: 'amountTotal',
            fieldType: 'price',
            find: 'amountTotal',
            label: 'Total Amount',
        },
        {
            id: 'amountPatient',
            name: 'amountPatient',
            fieldType: 'price',
            find: 'amountPatient',
            label: 'Patient Amount',
        },
    ],
    grid: {
        sm: 12,
        md: 12,
        lg: 12,
    },
};

export const insuranceRequestLineFilter = () => {
    console.log('asdsadu0s-a0dusa-d');
    return ([
        {
            name: 'datePeriod',
            type: 'select',
            label: 'Period',
            valueField: 'key',
            disableClear: false,
            options: Object.keys(ORDER_TYPE_DATE_FILTERS).map(aPeriod => ({
                key: aPeriod,
                value: ORDER_TYPE_DATE_FILTERS[aPeriod],
            })),
        },
        {
            name: 'InsuranceRequestLine.state',
            type: 'select',
            label: 'State',
            valueField: 'key',
            disableClear: false,
            options: Object.keys(insuranceRequestLineStates).map(aClaimState => ({
                key: insuranceRequestLineStates[aClaimState],
                value: aClaimState,
            })),
            searchConstructFields: {
                name: 'InsuranceRequestLine.state',
                filterOperation: '=',
            },
        },
    ]);
};

export const insuranceRequestsFilter = () => {
    console.log('asdsadu0s-a0dusa-d');
    return ([
        {
            name: 'datePeriod',
            type: 'select',
            label: 'Period',
            valueField: 'key',
            disableClear: false,
            options: Object.keys(ORDER_TYPE_DATE_FILTERS).map(aPeriod => ({
                key: aPeriod,
                value: ORDER_TYPE_DATE_FILTERS[aPeriod],
            })),
        },
        {
            name: 'InsuranceRequest.state',
            type: 'select',
            label: 'State',
            valueField: 'key',
            disableClear: false,
            options: Object.keys(insuranceRequestsStates).map(aClaimState => ({
                key: insuranceRequestsStates[aClaimState],
                value: aClaimState,
            })),
            searchConstructFields: {
                name: 'InsuranceRequest.state',
                filterOperation: '=',
            },
        },
        {
            name: 'tpa',
            type: 'autocomplete',
            label: 'TPA',
            valueField: 'key',
            disableClear: true,
            dataSourceApi:
            // eslint-disable-next-line
                `${api.SEARCH.INSURANCE_PAYERS_BY_TYPE}?payerType=COMPANY&payerType=INSURANCE&searchString=`,
            searchConstructFields: {
                name: 'InsurancePayer.uuid',
                filterOperation: '=',
            },
        },
        {
            name: 'SubInsurer.uuid',
            type: 'autocomplete',
            label: 'Sub Insurer',
            valueField: 'key',
            formatUrl: true,
            disableClear: true,
            dataSourceApi:
            // eslint-disable-next-line
                `${api.SEARCH.INSURANCE_PAYERS_BY_TYPE}?parentUuid=<<tpa.key>>&payerType=SUBINSURANCE&searchString=`,
            searchConstructFields: {
                name: 'SubInsurer.uuid',
                filterOperation: '=',
            },
        },
    ]);
};


export const insuranceRequestLineFilters = [
    {
        name: 'PatientIdentifier.identifier',
        filterOperation: ':',
    },
    {
        name: 'Person.secondaryIdentifier',
        filterOperation: ':',
    },
];

export const insuranceRequestsFilters = [
    {
        name: 'PatientIdentifier.identifier',
        filterOperation: ':',
    },
    {
        name: 'Person.secondaryIdentifier',
        filterOperation: ':',
    },
];
