/* eslint-disable jsx-a11y/click-events-have-key-events,no-useless-constructor */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { BOOK_MANAGEMENT_PRIVILEGES } from '../../constants/constants';
import { checkIfPrivilegeExistsForUser } from '../../constants/privilegeChecker';

const style = theme => ({
    container: {
        border: `1px solid ${theme.palette.borderColor}`,
        borderRadius: '3px',
        padding: '0 6px',
        background: theme.palette.common.white,
    },
    advancedText: {
        color: theme.palette.secondaryTextColor,
        cursor: 'pointer',
    },
    heading: {
        fontSize: '1.1rem',
        paddingBottom: '8px',
    },
    title: {
        fontSize: '16px',
        fontWeight: '450',
    },
    value: {
        fontSize: '14px',
        fontWeight: '400',
        margin: '9px 0 0 0',
        cursor: 'pointer',
    },
});

const menus = [
    {
        title: 'Supplier',
        categories: [
            {
                name: 'Expense',
                value: 'expense',
                privileges: BOOK_MANAGEMENT_PRIVILEGES.expense,
            },
            // {
            //     name: 'Pay On Account',
            //     value: 'paidOnAccount',
            //     privileges: BOOK_MANAGEMENT_PRIVILEGES.paidOnAccount,
            // },
            {
                name: 'Purchase Invoice',
                value: 'purchaseBill',
                privileges: BOOK_MANAGEMENT_PRIVILEGES.purchaseBill,
            },
            {
                name: 'Pay Invoice',
                value: 'payBill',
                privileges: BOOK_MANAGEMENT_PRIVILEGES.payBill,
            },
            {
                name: 'Prepaid Expense',
                value: 'prepaidExpense',
                privileges: BOOK_MANAGEMENT_PRIVILEGES.prepaidExpense,
            },
            {
                name: 'Debit Memo',
                value: 'debitMemo',
                privileges: BOOK_MANAGEMENT_PRIVILEGES.debitMemo,
            },
        ],
    },
    {
        title: 'Employees',
        categories: [
            {
                name: 'Petty Cash',
                value: 'pettyCash',
                privileges: BOOK_MANAGEMENT_PRIVILEGES.pettyCash,
            },
            {
                name: 'Salary Advance',
                value: 'salaryAdvance',
                privileges: BOOK_MANAGEMENT_PRIVILEGES.salaryAdvance,
            },
            {
                name: 'Petty Cash Expense',
                value: 'employeeExpense',
                privileges: BOOK_MANAGEMENT_PRIVILEGES.employeeExpense,
            },
            {
                name: 'Cash Close',
                value: 'cashClose',
                privileges: BOOK_MANAGEMENT_PRIVILEGES.cashClose,
            },
            {
                name: 'Consolidated Cash Close',
                value: 'consolidatedCashClose',
                privileges: BOOK_MANAGEMENT_PRIVILEGES.consolidatedCashClose,
            },
        ],
    },
    {
        title: 'Others',
        categories: [
            {
                name: 'Fund Transfer',
                value: 'transfer',
                privileges: BOOK_MANAGEMENT_PRIVILEGES.transfer,
            },
            {
                name: 'Journal Entries',
                value: 'journalEntries',
                privileges: BOOK_MANAGEMENT_PRIVILEGES.journalEntries,
            },
            {
                name: 'On Account',
                value: 'payOrReceiveOnAccount',
                privileges: BOOK_MANAGEMENT_PRIVILEGES.payOrReceiveOnAccount,
            },
            {
                name: 'Payment Voucher',
                value: 'cashPaymentVoucher',
                privileges: BOOK_MANAGEMENT_PRIVILEGES.cashPaymentVoucher,
            },
            {
                name: 'Credit Memo',
                value: 'creditMemo',
                privileges: BOOK_MANAGEMENT_PRIVILEGES.creditMemo,
            },
            {
                name: 'Issue Invoice',
                value: 'issueInvoice',
                privileges: BOOK_MANAGEMENT_PRIVILEGES.issueInvoice,
            },
        ],
    },
];

const BookAddition = (props) => {
    const { classes, handleMenuClick } = props;
    return (
        <div style={{ width: '40em' }}>
            <div className="p2">
                <Grid container>
                    {
                        menus.map(menu => (
                            <Grid item lg={4} sm={4} md={4} key={menu.title}>
                                <h5 className={classes.title} test-id={menu.title}>
                                    {menu.title}
                                </h5>
                                {
                                    menu.categories.map((c) => {
                                        if (checkIfPrivilegeExistsForUser(c.privileges)) {
                                            return (
                                                <div
                                                    className={classes.value}
                                                    key={`${menu.title}-${c.name}`}
                                                    test-id={`${menu.title}-${c.name}`}
                                                    onClick={() => handleMenuClick(c.value)}
                                                >
                                                    {c.name}
                                                </div>
                                            );
                                        }
                                        return '';
                                    })
                                }
                            </Grid>
                        ))
                    }
                </Grid>
            </div>
        </div>
    );
};

BookAddition.propTypes = {
    classes: PropTypes.object.isRequired,
    handleMenuClick: PropTypes.func,
};

BookAddition.defaultProps = {
    handleMenuClick: () => {},
};

export default withStyles(style)(BookAddition);
