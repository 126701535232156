import cloneDeep from 'clone-deep';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import addDays from 'date-fns/addDays';
import addYears from 'date-fns/addYears';
import addMonths from 'date-fns/addMonths';
import addMinutes from 'date-fns/addMinutes';
import addWeeks from 'date-fns/addWeeks';
import subMonths from 'date-fns/subMonths';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import startOfMonth from 'date-fns/startOfMonth';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import endOfMonth from 'date-fns/endOfMonth';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import subDays from 'date-fns/subDays';
import { isStringNullOrUndefined, to24HourFormat } from './CommonUtil';
import { applicationDateAndTimeFormat, applicationDateFormat, applicationTimeFormat, DATE_FILTERS } from './constants';
import { NumberOf } from './numberUtils';
import { getStringFromObject } from './lodashUtils';

export const weekDay = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const DB_DATE_FORMAT = 'yyyy-MM-dd';
export const daysOfWeek = {
    MONDAY: 'MONDAY',
    TUESDAY: 'TUESDAY',
    WEDNESDAY: 'WEDNESDAY',
    THURSDAY: 'THURSDAY',
    FRIDAY: 'FRIDAY',
    SATURDAY: 'SATURDAY',
    SUNDAY: 'SUNDAY',
};

export const weekDaysFullName = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
export const weekDaysFullNameFromSun = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const MS_PER_DAY = 1000 * 60 * 60 * 24;
const MS_PER_MINUTE = 1000 * 60;
export const daysInWeekStartingFromMonday = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export const abbreviatedMonths = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

export const parse24HoursTimeString = timeString => parse(timeString, 'HH:mm', new Date());

export const getTimeIn24HrFormat = dateObject => format(dateObject, 'HH:mm');

export const getWeekDay = a => weekDay[a];

export const getMonthName = a => months[a];

export const isValidDate = date => isValid(date);

// USE THIS TO DISPLAY DATE EVERYWHERE IN THE APP..
// we will specify a format to display dates in so that it can be shown in the same format throughout
export const formatDateForDisplay = (date) => {
    if (isValidDate(date)) {
        return format(date, applicationDateFormat);
    }
    return '';
};

export const formatDateTimeForDisplay = (date) => {
    if (isValidDate(date)) {
        return format(date, applicationDateAndTimeFormat);
    }
    return '';
};

export const formatTimeForDisplay = (date) => {
    if (isValidDate(date)) {
        return format(date, applicationTimeFormat);
    }
    return '';
};

export const get24HrsTimeStringIn12Hr = timeString => formatTimeForDisplay(parse24HoursTimeString(timeString));

export const getDateInYYYYMMDDFormat = (date) => {
    console.log('akjsdhfaksldhfalsdfh', date);
    if (isValid(date)) {
        return format(date, 'yyyy-MM-dd');
    }
    return '';
};

export const getDateInDDMMYYYYFormatWithTime = (date) => {
    console.log('akjsdhfaksldhfalsdfh', date);
    if (isValid(date)) {
        return format(date, 'dd/MM/yyyy HH:mm');
    }
    return '';
};

export const removeTimeFromDate = (date) => {
    const dateWithTimeRemoved = new Date(date);
    dateWithTimeRemoved.setHours(0);
    dateWithTimeRemoved.setMinutes(0);
    dateWithTimeRemoved.setSeconds(0);
    dateWithTimeRemoved.setMilliseconds(0);
    return dateWithTimeRemoved;
};

export const getStartOfDay = date => startOfDay(new Date(date));
export const getStartOfMonth = date => startOfMonth(date);
export const getEndOfDay = date => endOfDay(new Date(date));
export const getEndOfMonth = date => endOfMonth(date);

export const getStartOfWeek = date => startOfWeek(new Date(date), { weekStartsOn: 1 });
export const getEndOfWeek = date => endOfWeek(new Date(date), { weekStartsOn: 1 });

export const setTimeForDate = (date, HH = 0, mm = 0, ss = 0, ms = 0) => {
    const dateWithTimeRemoved = new Date(date);
    dateWithTimeRemoved.setHours(HH);
    dateWithTimeRemoved.setMinutes(mm);
    dateWithTimeRemoved.setSeconds(ss);
    dateWithTimeRemoved.setMilliseconds(ms);
    return dateWithTimeRemoved;
};

export const setMaxTimeForDate = date => setTimeForDate(date, 23, 59, 59, 59);

export const isDateLessThan = (date1, date2) => {
    if (date1 && date2) {
        const date1WithTimeRemoved = removeTimeFromDate(date1);
        const date2WithTimeRemoved = removeTimeFromDate(date2);
        return date1WithTimeRemoved.valueOf() < date2WithTimeRemoved.valueOf();
    }
    return false;
};

export const isDateGreaterThanOrEqual = (date1, date2) => {
    if (date1 && date2) {
        const date1WithTimeRemoved = removeTimeFromDate(date1);
        const date2WithTimeRemoved = removeTimeFromDate(date2);
        return date1WithTimeRemoved.valueOf() >= date2WithTimeRemoved.valueOf();
    }
    return false;
};

export const isDateLessThanOrEqual = (date1, date2) => {
    if (date1 && date2) {
        const date1WithTimeRemoved = removeTimeFromDate(date1);
        const date2WithTimeRemoved = removeTimeFromDate(date2);
        return date1WithTimeRemoved.valueOf() <= date2WithTimeRemoved.valueOf();
    }
    return false;
};

export const getDateObjectFromTimeString = (timeString, is12HourFormat) => {
    if (timeString) {
        let time = timeString;
        if (is12HourFormat) {
            time = to24HourFormat(time);
        }
        const splitTime = time.split(':');
        if (splitTime && splitTime.length) {
            const hours = splitTime[0];
            const minutes = splitTime[1];
            const dateObject = new Date();
            console.log('horrrrrrrrrrss', hours, minutes);
            dateObject.setHours(hours);
            dateObject.setMinutes(minutes);
            dateObject.setSeconds(0);
            return dateObject;
        }
        return null;
    }
    return null;
};

export const isDateValid = date => date instanceof Date && !Number.isNaN(date.getTime());

export const addOrSubtractDays = (date, n) => addDays(date, n);

export const addOrSubtractYears = (date, n) => addYears(date, n);

export const addOrSubtractMinutes = (date, n) => addMinutes(date, n);

export const addOrSubtractWeeks = (date, n) => addWeeks(date, n);

export const addOrSubtractMonth = (date, n, op) => {
    switch (op) {
    case '+':
        return addMonths(date, n);
    case '-':
        return subMonths(date, n);
    default:
        return subMonths(date, n);
    }
};

export const addPreviousWeek = (month, year, week) => {
    let i = 6;
    let date = new Date(year, month, 0);
    if (month === 12) {
        date = new Date(year - 1, 12, 0);
    }
    const temp = cloneDeep(week);
    while (i >= 0) {
        if (temp[i] === 0) {
            temp[i] = { date: date.getDate(), current: false };
            date.setDate(date.getDate() - 1);
        }
        i -= 1;
    }
    return temp;
};

export const addNextWeek = (lastDate, week, isLast = false) => {
    let i = 0;
    let date = lastDate.date + 1;
    if (isLast) {
        date = 1;
    }
    const temp = cloneDeep(week);
    console.log('sfsfasdf', temp);
    while (i < 7) {
        if (temp[i] === 0) {
            temp[i] = { date, current: false };
            date += 1;
        }
        i += 1;
    }
    return temp;
};


export const getWeeksInMonth = (month, year) => {
    const weeks = [];
    const firstDate = new Date(year, month, 1);
    const lastDate = new Date(year, month + 1, 0);
    const numDays = lastDate.getDate();

    let start = 1;
    let end = 7 - firstDate.getDay();
    while (start <= numDays) {
        weeks.push({ start, end });
        start = end + 1;
        end += 7;
        end = start === 1 && end === 8 ? 1 : end;
        if (end > numDays) {
            end = numDays;
        }
    }
    return weeks;
};

export const getAllDayInMonth = (month, year) => {
    const allWeeks = [];
    const firstDate = new Date(year, month, 1);
    const lastDate = new Date(year, month + 1, 0);
    const numDays = lastDate.getDate();
    let day = 0;
    let week = [0, 0, 0, 0, 0, 0, 0];
    while (day < numDays) {
        week[firstDate.getDay()] = { date: firstDate.getDate(), current: true, dateObj: new Date(firstDate.getTime()) };
        firstDate.setDate(firstDate.getDate() + 1);
        if (firstDate.getDay() === 0) {
            const temp = cloneDeep(week);
            week = [0, 0, 0, 0, 0, 0, 0];
            allWeeks.push(temp);
        }
        day += 1;
    }
    if (firstDate.getDay() !== 0) {
        allWeeks.push(cloneDeep(week));
    }
    week = [0, 0, 0, 0, 0, 0, 0];
    if (allWeeks.length === 4) {
        allWeeks.push(addNextWeek(0, week));
        allWeeks.push(addNextWeek(allWeeks[4][6], week, firstDate.getDay() === 0));
    } else if (allWeeks.length === 5) {
        allWeeks[4] = (addNextWeek({ date: 0 }, allWeeks[4]));
        allWeeks.push(addNextWeek(allWeeks[4][6], week, firstDate.getDay() === 0));
    } else {
        allWeeks[5] = (addNextWeek({ date: 0 }, allWeeks[5], firstDate.getDay() === 0));
    }
    allWeeks[0] = addPreviousWeek(month, year, allWeeks[0]);
    return allWeeks;
};

export const getRoundedTimeToNearestHalfHourSlot = (timeString) => {
    const coEff = 1000 * 60 * 30;
    const date = getDateObjectFromTimeString(timeString, true);
    const roundedDate = new Date(Math.floor(date.getTime() / coEff) * coEff);
    return roundedDate;
};

export const getDateInDDMMMFormat = (date) => {
    if (isValidDate(date)) {
        return format(date, 'dd-MMM');
    }
    return '';
};

export const getDateInDDMMMYYYYFormat = (date) => {
    if (isValidDate(date)) {
        return format(date, 'dd-MMM-yyyy');
    }
    return '';
};

export const convertDateStringDDMMYYYYToDate = (date) => {
    const fyDateString = date.split('/');
    return new Date(fyDateString[1].concat('-', fyDateString[0]).concat('-', fyDateString[2]).toString());
};

export const diffInDates = (d1, d2) => {
    const diffMs = (d2 - d1); // milliseconds between now & Christmas
    const days = Math.floor(diffMs / 86400000); // days
    const hrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    const mins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
    let t = '';
    if (days > 0) {
        t = `${days} days`;
    }
    t = `${t} ${hrs} Hr ${mins} Min`;
    return t;
};

export const getFullAge = (birthday, now = new Date()) => {
    const yearNow = now.getYear();
    const monthNow = now.getMonth();
    const dateNow = now.getDate();

    const yearDob = birthday.getYear();
    const monthDob = birthday.getMonth();
    const dateDob = birthday.getDate();

    let yearAge = yearNow - yearDob;

    let monthAge = 0;

    let dateAge = 0;

    if (monthNow >= monthDob) {
        monthAge = monthNow - monthDob;
    } else {
        yearAge -= 1;
        monthAge = 12 + (monthNow - monthDob);
    }

    if (dateNow >= dateDob) {
        dateAge = dateNow - dateDob;
    } else {
        monthAge -= 1;
        const daysFebruary = yearNow % 4 !== 0 || (yearNow % 100 === 0 && yearNow % 400 !== 0) ? 28 : 29;
        const daysInMonths = [31, daysFebruary, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        if (monthAge < 0) {
            monthAge = 11;
            yearAge -= 1;
        }
        dateAge = daysInMonths[monthAge] + (dateNow - dateDob);
    }
    console.log('asda0sid-aias0', yearAge, monthAge, dateAge);
    return {
        years: yearAge,
        months: monthAge,
        days: dateAge,
    };
};

export const getFullAgeString = (birthday) => {
    let ageString = '';
    let yearString = '';
    let monthString = '';
    let dayString = '';

    const age = getFullAge(birthday);

    if (age.years > 1) {
        yearString = ' years';
    } else {
        yearString = ' year';
    }
    if (age.months > 1) {
        monthString = ' months';
    } else {
        monthString = ' month';
    }
    if (age.days > 1) {
        dayString = ' days';
    } else {
        dayString = ' day';
    }
    console.log('sa0d9isa09dada', age);

    if ((age.years > 0) && (age.months > 0) && (age.days > 0)) {
        ageString = `${age.years} ${yearString}, ${age.months} ${monthString} and ${age.days} ${dayString}`;
    } else if ((age.years === 0) && (age.months === 0) && (age.days > 0)) {
        ageString = `Only ${age.days} ${dayString} old!`;
    } else if ((age.years > 0) && (age.months === 0) && (age.days === 0)) {
        ageString = `${age.years} ${yearString} old. Happy Birthday!!`;
    } else if ((age.years > 0) && (age.months > 0) && (age.days === 0)) {
        ageString = `${age.years}  ${yearString}  and ${age.months} ${monthString}  old.`;
    } else if ((age.years === 0) && (age.months > 0) && (age.days > 0)) {
        ageString = `${age.months} ${monthString} and ${age.days} ${dayString} old.`;
    } else if ((age.years > 0) && (age.months === 0) && (age.days > 0)) {
        ageString = `${age.years} ${yearString} and ${age.days} ${dayString} old.`;
    } else if ((age.years === 0) && (age.months > 0) && (age.days === 0)) {
        ageString = `${age.months} ${monthString} old.`;
    } else {
        ageString = `${age.days} Days Old`;
    }

    return ageString;
};

export const isDateString = (str) => {
    let date = str;
    if (typeof date === 'string') {
        date = date.trim();
        const reg = /\d{4}-\d{1,2}-\d{1,2}/;
        if (reg.test(date)) {
            return isValidDate(new Date(date));
        }
    }
    return false;
};

export const diffInDateInDays = (d1, d2) => {
    if (!isDateValid(d1) || !(isDateValid(d2))) {
        return 0;
    }
    return differenceInDays(d1, d2);
};
export const diffInDateInMins = (d1, d2) => {
    if (!isDateValid(d1) || !(isDateValid(d2))) {
        return 0;
    }
    return differenceInMinutes(d1, d2);
};

export const getDifferenceDaysBetween = (from, to) => {
    if (!isDateValid(from) || !(isDateValid(to))) {
        return 0;
    }
    const utc1 = Date.UTC(from.getFullYear(), from.getMonth(), from.getDate());
    const utc2 = Date.UTC(to.getFullYear(), to.getMonth(), to.getDate());

    return Math.floor((utc2 - utc1) / MS_PER_DAY);
};

export const getDifferenceMinutesBetween = (from, to) => {
    if (!isDateValid(from) || !(isDateValid(to))) {
        return 0;
    }
    return Math.floor((from.getTime() - to.getTime()) / MS_PER_MINUTE);
};

export const getMonthYearString = date => (format(date, 'MMM-yyyy'));

export const getDateMonthString = date => (format(date, 'dd MMM'));

export const formatAMPM = (date) => {
    if (isValidDate(date)) {
        return (format(date, 'hh:mm a'));
    }
    return '';
};

export const formatDate = (dateTime, formatter) => {
    if (!dateTime || dateTime == null) {
        return '';
    }
    const date = new Date(dateTime);
    let dateFormat = 'dd/MM/yyyy';
    if (formatter) {
        dateFormat = formatter;
    }
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    let hours = date.getHours();
    if (dateFormat.indexOf('a') > -1) {
        if (hours > 11) {
            dateFormat = dateFormat.replace('a', 'pm');
        } else {
            dateFormat = dateFormat.replace('a', 'am');
        }
    }
    dateFormat = dateFormat.replace('MMM', abbreviatedMonths[month - 1]);
    dateFormat = dateFormat.replace('MM', month.toString().padStart(2, '0'));
    if (dateFormat.indexOf('yyyy') > -1) {
        dateFormat = dateFormat.replace('yyyy', year.toString());
    } else if (dateFormat.indexOf('yy') > -1) {
        dateFormat = dateFormat.replace('yy', year.toString().substr(2, 2));
    }
    dateFormat = dateFormat.replace('dd', date.getDate().toString().padStart(2, '0'));
    if (dateFormat.indexOf('HH') > -1) {
        dateFormat = dateFormat.replace('HH', hours.toString().padStart(2, '0'));
    }
    if (dateFormat.indexOf('hh') > -1) {
        if (hours > 12) {
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }
        dateFormat = dateFormat.replace('hh', hours.toString().padStart(2, '0'));
    }
    if (dateFormat.indexOf('mm') > -1) {
        dateFormat = dateFormat.replace('mm', date.getMinutes().toString().padStart(2, '0'));
    }
    if (dateFormat.indexOf('ss') > -1) {
        dateFormat = dateFormat.replace('ss', date.getSeconds().toString().padStart(2, '0'));
    }
    return dateFormat;
};

export const getPeriod = (from, to) => `${getDateInDDMMMYYYYFormat(from)} - ${getDateInDDMMMYYYYFormat(to)}`;

export const getMonthLastDate = date => new Date(date.getFullYear(), date.getMonth() + 1, 0);

export const getMonthFirstDate = date => new Date(date.getFullYear(), date.getMonth(), 1);

export const convertMinInHrAndMinStr = min => (
    `${Math.floor(min / 60).toString().padStart(2, '0')}:${(min % 60).toString().padStart(2, '0')}`
);

/* NOTES : Use only If week starts from Monday */
export const getDateForAWeekDay = (date, dayOfWeek = 0) => {
    const dateTime = new Date(date);
    const day = dateTime.getDay();
    const diff = dateTime.getDate() - (day === 0 ? 7 : day);
    return dateTime.setDate((diff) + dayOfWeek);
};

// 24 hours time string format
export const getMilliSecondElapsedFromTimeString = (time) => {
    if (time) {
        const splitTime = time.split(':');
        if (splitTime && splitTime.length) {
            const hours = splitTime[0];
            const minutes = splitTime[1];
            const date = new Date();
            date.setHours(hours);
            date.setMinutes(minutes);
            return date.getTime();
        }
    }
    return '';
};

export const convertMillisElapsedToGMT = (millisElapsed) => {
    const timezoneOffset = new Date().getTimezoneOffset();
    return millisElapsed + (timezoneOffset * 60 * 1000);
};

/* month supported 1 to 12 not 0 to 11 */
export const getCurrentQuarter = (startMonth = 1, n, op) => {
    const current = new Date();
    const fiscalYearStart = new Date(current.getFullYear(), NumberOf(startMonth) - 1, 1);
    if (current.getMonth() < fiscalYearStart.getMonth()) {
        fiscalYearStart.setFullYear(fiscalYearStart.getFullYear() - 1);
    }
    const monthDiff = Math.abs(current.getMonth() - fiscalYearStart.getMonth());
    // current quarter
    const currentQuarter = parseInt(monthDiff / 3, 10);
    // current quarter startDate
    let currentQuarterStart = addMonths(fiscalYearStart, currentQuarter * 3);
    if (NumberOf(n)) {
        switch (op) {
        case '-':
            currentQuarterStart = subMonths(currentQuarterStart, 3 * n);
            break;
        case '+':
            currentQuarterStart = addMonths(currentQuarterStart, 3 * n);
            break;
        default:
            currentQuarterStart = addMonths(currentQuarterStart, 3 * n);
        }
    }
    const startDate = startOfMonth(currentQuarterStart);
    const endDate = endOfMonth(addMonths(currentQuarterStart, 2));
    return { start: startDate, end: endDate };
};

export const getWeekStartDate = (val) => {
    const d = new Date(val);
    const day = d.getDay();
    const diff = d.getDate() - (day + (day === 0 ? -6 : -1));
    return new Date(d.setDate(diff));
};

export const getMonthStartAndEnd = (date) => {
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return { start: firstDay, end: lastDay };
};

export const computeFiscalyearDates = (date, fiscalyearLastMonth, fiscalyearLastDay) => {
    const cal = new Date(date);
    if (cal.getMonth() < fiscalyearLastMonth || (cal.getMonth() === fiscalyearLastMonth
        && (cal.getDate() <= fiscalyearLastDay))) {
        cal.setMonth(fiscalyearLastMonth);
        cal.setDate(fiscalyearLastDay);
    } else if (fiscalyearLastMonth === 1 && fiscalyearLastDay === 29 && ((cal.getFullYear() + 1) % 4 !== 0)) {
        cal.setMonth(fiscalyearLastMonth);
        cal.setDate(28);
        cal.setFullYear(cal.getFullYear() + 1);
    } else {
        cal.setMonth(fiscalyearLastMonth);
        cal.setDate(fiscalyearLastDay);
        cal.setFullYear(cal.getFullYear() + 1);
    }
    const dateTo = new Date(cal);

    cal.setDate(cal.getDate() + 1);
    if (cal.getMonth() === 1 && cal.getDate() === 29) {
        cal.setDate(28);
        cal.setFullYear(cal.getFullYear() - 1);
    } else {
        cal.setFullYear(cal.getFullYear() - 1);
    }
    const dateFrom = new Date(cal);
    return { dateFrom, dateTo };
};

export const isDateBefore = (date, dateToCompare) => isBefore(date, dateToCompare);

export const isDateAfter = (date, dateToCompare) => isAfter(startOfDay(date), startOfDay(dateToCompare));

export const isTimeAfter = (date, dateToCompare) => isAfter(date, dateToCompare);

export const parseDate = (dateString, formatString) => parse(dateString, formatString, new Date());

export const minutesToHoursDisplayStringNoZero = (minutes) => {
    const min = NumberOf(minutes);
    if (min === 0) {
        return '00:00';
    }
    const hours = parseInt(min / 60, 0);
    const minFraction = parseInt(min % 60, 0);
    return `${hours.toString().padStart(2, '0')}:${minFraction.toString().padStart(2, '0')}`;
};

export const formatIntervalInHoursAndMinutes = (interval) => {
    const hrs = interval.hours;
    const min = interval.minutes;
    return `${hrs.toString().padStart(2, '0')}:${min.toString().padStart(2, '0')}`;
};

export const getWeekNoAndYear = (date, dowOffset = 0) => { // right implementation
    /* getWeek() was developed by Nick Baicoianu at MeanFreePath: http://www.meanfreepath.com */
    // eslint-disable-next-line
    const newYear = new Date(date.getFullYear(), 0, 1);
    let day = newYear.getDay() - dowOffset; // the day of week the year begins on
    day = (day >= 0 ? day : day + 7);
    // eslint-disable-next-line
    const daynum = Math.floor((date.getTime() - newYear.getTime() - (date.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) / 86400000) + 1;
    // if the year starts before the middle of a week
    if (day < 4) {
        const weeknum = Math.floor((daynum + (day - 1)) / 7) + 1;
        if (weeknum > 52) {
            const nYear = new Date(date.getFullYear() + 1, 0, 1);
            let nday = nYear.getDay() - dowOffset;
            nday = nday >= 0 ? nday : nday + 7;
            /* if the next year starts before the middle of
              the week, it is week #1 of that year */
            return (nday < 4) ? {
                weekNo: 1,
                year: nYear.getFullYear(),
            } : {
                weekNo: 53,
                year: newYear.getFullYear(),
            };
        }
        return {
            weekNo: weeknum,
            year: newYear.getFullYear(),
        };
    }
    return {
        weekNo: Math.floor((daynum + (day - 1)) / 7),
        year: newYear.getFullYear(),
    };
};

export const getWeekNumberOfYear = (d) => {
    console.log('asd');
    return NumberOf(format(d, 'I', { weekStartsOn: 1 }));
    // return getWeekNoAndYear(d, 0).weekNo;
};
export const getWeekYearNumber = (date) => {
    console.log('asd');
    // return getWeekNoAndYear(date, 0).year;
    return NumberOf(format(date, 'RRRR', {
        useAdditionalWeekYearTokens: true,
        awareOfUnicodeTokens: true,
        weekStartsOn: 1,
    }));
};

export const formatDateToString = (dateObject, formatter) => format(dateObject, formatter);

export const getPeriodsForClaimOrAttendance = () => {
    let date = startOfMonth(new Date());
    const periods = {};
    for (let i = 0; i < 4; i += 1) {
        date = subMonths(date, 1);
        const startDate = startOfMonth(date);
        const endDate = endOfMonth(date);
        periods[getMonthYearString(date)] = {
            startDate,
            endDate,
        };
    }
    return periods;
};
export const getPeriodsForTrialBalance = (fiscalYear) => {
    console.log('asd09uad0as9u', fiscalYear);
    let date = parseDate(fiscalYear.start, 'dd/MM/yyyy');
    const endDateOfFY = parseDate(fiscalYear.end, 'dd/MM/yyyy');
    const periods = {};
    let i = 0;
    while (!isDateAfter(date, endDateOfFY)) {
        const startDate = startOfMonth(date);
        const endDate = endOfMonth(date);
        periods[getMonthYearString(date)] = {
            startDate,
            endDate,
        };
        date = addMonths(date, 1);
        console.log('asdasd09asuassa', date);
        i += 1;
        if (i > 12) {
            break;
        }
    }
    return periods;
};

export const getPreviousPayrollMonth = (date, startDateForPayrollPeriod_) => {
    if (!isValidDate(date)) {
        return null;
    }
    const startDateForPayrollPeriod = NumberOf(startDateForPayrollPeriod_) || 1;
    const dateInstance = new Date(date);
    dateInstance.setMonth(dateInstance.getMonth() - 1);
    if (dateInstance.getDate() < startDateForPayrollPeriod) {
        dateInstance.setMonth(dateInstance.getMonth() - 1);
    }
    dateInstance.setDate(startDateForPayrollPeriod);
    dateInstance.setMonth(dateInstance.getMonth() + 1);
    dateInstance.setDate(dateInstance.getDate() - 1);

    return dateInstance.getMonth();
};

export const getDateWithTimeZoneRemoved = (date) => {
    if (
        !isStringNullOrUndefined(date) &&
        !isStringNullOrUndefined(date.split('T')[0])
    ) {
        return new Date(date.split('T')[0]);
    }
    return null;
};

export const getDateInLocal = (date) => {
    const dformat = [date.getDate(), date.getUTCMonth() + 1,
        date.getFullYear(),
    ].join('/');
    return dformat;
};

export const getStartAndEndDateFromDateOperation = (value, operation) => {
    const now = new Date();
    let startDate = new Date(now.getTime());
    let endDate = new Date(now.getTime());
    switch (operation) {
    case DATE_FILTERS.CUSTOM:
        startDate = new Date(getStringFromObject('from', value, new Date()));
        endDate = new Date(getStringFromObject('to', value, new Date()));
        break;
    case DATE_FILTERS.PAST_7_DAYS:
        startDate = subDays(now, 7);
        break;
    case DATE_FILTERS.PAST_30_DAYS:
        startDate = subDays(now, 30);
        break;
    case DATE_FILTERS.THIS_MONTH:
        startDate = startOfMonth(now);
        endDate = endOfMonth(now);
        break;
    case DATE_FILTERS.THIS_WEEK:
        startDate = startOfWeek(now);
        endDate = endOfWeek(now);
        break;
    case DATE_FILTERS.YESTERDAY_AND_TODAY:
        startDate = subDays(endDate, 1);
        break;
    default:
    }
    return {
        startDate,
        endDate,
    };
};
