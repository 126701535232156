/**
 * Created by vinay on 19/12/18.
 */
import { call, put } from 'redux-saga/effects';
import { retriable } from '../retriable';
import { ERROR_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import {
    didApiCallFailDueToUnauthorizedUser, didApiCallFailDueToVersionMismatch,
    getErrorMessage,
    isValidFunction,
} from '../../constants/CommonUtil';
import {
    ADVANCE_SEARCH_FAILURE,
    ADVANCE_SEARCH_SUCCESS,
} from '../../redux/modules/advanceSearch/advanceSearch-actions';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import {
    generatePurchaseOrderSearchConstruct,
    generateStockIntentConstruct,
    generateMaterialReceiveConstruct,
    getAccountMoveConstruct,
    getAddStockConstruct,
    getAdvancedSalaryLinesConstruct,
    getAnalyticalAccountLinesConstruct,
    getPettyCashLinesConstruct,
    getBillPaymentConstruct,
    getExpenseSearchConstruct,
    getFundTransferConstruct,
    getInternalMoveConstruct,
    getInventoryAdjustmentConstruct,
    getPayerInvoiceSearchConstruct,
    getPaymentReceivedConstruct,
    getPurchaseBillSearchConstruct,
    getReceiveStockConstruct,
    getReturnStockConstruct,
    getStockPickingConstruct,
    getPrepaidExpenseConstruct,
    getCashCloseConstruct,
    getPayOnAccountConstruct,
    getConsolidatedCashCloseConstruct,
    getIssueInvoiceSearchConstruct,
} from './advanceSearchUtil';
import { SEARCH_CATEGORIES } from '../../containers/BookManagementContainer/AdvanceSearchUtil';
import { SEARCH_CATEGORIES as STOCK_CATEGORY } from '../../containers/StockManagementContainer/ErpAdvanceSearchUtil';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

export default function* advanceSearchWorker(action) {
    try {
        const {
            dateFilters, jsonApi, tableJson, category, filters, page, size, api, apiParams,
        } = action;
        const config = {
            headers: { 'Content-Type': 'application/json' },
        };
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        /* FETCH JSON FOR PARTICULAR CATEGORY AND STORE IF IT IS NOT THERE */
        console.log('sdfsdfdsfdsf', jsonApi, tableJson, category, filters);
        if (!tableJson[category]) {
            const jsonResponse = yield call(retriable().get, jsonApi);
            tableJson[category] = jsonResponse.data;
        }
        let payload = null;
        const commonFilterArgs = {
            fromDate: dateFilters.fromDate,
            toDate: dateFilters.toDate,
            ...filters,
        };
        switch (action.category) {
        case 'Purchase Order':
            payload = generatePurchaseOrderSearchConstruct(commonFilterArgs);
            break;
        case SEARCH_CATEGORIES.PURCHASE_BILL:
            payload = getPurchaseBillSearchConstruct(commonFilterArgs);
            break;
        case SEARCH_CATEGORIES.ISSUE_INVOICE:
            payload = getIssueInvoiceSearchConstruct(commonFilterArgs);
            break;
        case SEARCH_CATEGORIES.EXPENSE:
            payload = getExpenseSearchConstruct(commonFilterArgs, 'EXPENSE');
            break;
        case SEARCH_CATEGORIES.PETTY_CASH_EXPENSE:
            payload = getExpenseSearchConstruct(commonFilterArgs, 'PETTY');
            break;
        case SEARCH_CATEGORIES.BILL_PAYMENT:
            payload = getBillPaymentConstruct(commonFilterArgs);
            break;
        case SEARCH_CATEGORIES.PATIENT_INVOICES:
            payload = getPayerInvoiceSearchConstruct(commonFilterArgs);
            break;
        case SEARCH_CATEGORIES.REFUND_INVOICES:
            payload = getPayerInvoiceSearchConstruct(commonFilterArgs, 'out_refund');
            break;
        case SEARCH_CATEGORIES.PAYMENTS_RECEIVED:
            payload = getPaymentReceivedConstruct(commonFilterArgs);
            break;
        case SEARCH_CATEGORIES.JOURNAL_ENTRIES:
            payload = getAccountMoveConstruct(commonFilterArgs);
            break;
        case STOCK_CATEGORY.ADD_STOCK:
            payload = getAddStockConstruct(commonFilterArgs);
            break;
        case STOCK_CATEGORY.INTERNAL_MOVE:
            payload = getInternalMoveConstruct(commonFilterArgs);
            break;
        case STOCK_CATEGORY.INVENTORY_ADJUSTMENT:
            payload = getInventoryAdjustmentConstruct(commonFilterArgs);
            break;
        case STOCK_CATEGORY.RECEIVE_STOCK:
            payload = getReceiveStockConstruct(commonFilterArgs);
            break;
        case STOCK_CATEGORY.RETURN_STOCK:
            payload = getReturnStockConstruct(commonFilterArgs);
            break;
        case STOCK_CATEGORY.STOCK_PICKING:
            payload = getStockPickingConstruct(commonFilterArgs);
            break;
        case SEARCH_CATEGORIES.FUND_TRANSFER:
            payload = getFundTransferConstruct(commonFilterArgs);
            break;
        case SEARCH_CATEGORIES.COST_ACCOUNT_LINES:
            payload = getAnalyticalAccountLinesConstruct(commonFilterArgs);
            break;
        case SEARCH_CATEGORIES.EMPLOYEE_ADVANCE_SALARY:
            payload = getAdvancedSalaryLinesConstruct(commonFilterArgs);
            break;
        case SEARCH_CATEGORIES.PETTY_CASH:
            payload = getPettyCashLinesConstruct(commonFilterArgs);
            break;
        case SEARCH_CATEGORIES.PREPAID_EXPENSE:
            payload = getPrepaidExpenseConstruct(commonFilterArgs);
            break;
        case SEARCH_CATEGORIES.CASH_CLOSE:
            payload = getCashCloseConstruct(commonFilterArgs);
            break;
        case SEARCH_CATEGORIES.PAY_ON_ACCOUNT:
            payload = getPayOnAccountConstruct(commonFilterArgs);
            break;
        case STOCK_CATEGORY.STOCK_INTENT:
            payload = generateStockIntentConstruct(commonFilterArgs);
            break;
        case STOCK_CATEGORY.MATERIAL_RECEIVE:
            payload = generateMaterialReceiveConstruct(commonFilterArgs);
            break;
        case SEARCH_CATEGORIES.CONSOLIDATED_CASH_CLOSE:
            payload = getConsolidatedCashCloseConstruct(commonFilterArgs);
            break;
        default: payload = null;
        }
        let apiWithRequestParams =
            `${api}?page=${page}&size=${size}`;
        if (isObjectValidAndNotEmpty(apiParams)) {
            Object.keys(apiParams).forEach((param) => {
                apiWithRequestParams = `${apiWithRequestParams}&${param}=${apiParams[param]}`;
            });
        }
        const response = yield call(retriable().post,
            apiWithRequestParams, payload, config);
        console.log('hkasdhfalksdhfalksdhfasj', response, 'payload', payload);
        yield put({
            type: ADVANCE_SEARCH_SUCCESS,
            data: response.data,
            category,
            filters,
            dateFilters,
            tableJson,
        });
        if (isValidFunction(action.successCallback)) {
            action.successCallback(response.data);
        }
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        // do error action
        if (isValidFunction(action.failureCallBack)) {
            action.failureCallBack();
        }
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            yield put({ type: ERROR_MESSAGE, message: getErrorMessage(e) });
        }
        yield put({ type: ADVANCE_SEARCH_FAILURE, data: action.payload });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}
