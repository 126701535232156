import React from 'react';
import { FormattedMessage } from 'react-intl';
import { isDateLessThanOrEqual } from '../../../constants/DateUtil';


/* eslint-disable*/
/* @link http://stackoverflow.com/questions/46155/validate-email-address-in-javascript */
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape

const isEmpty = value =>
    typeof value === 'undefined' || value === null || value === '';

export const required = (value, _, props) => {
    console.log('234dasdasdadsd', value, _, props);
    const errorMessage = (
        <FormattedMessage
            id='form.requiredField'
            defaultMessage="Field is Required"
        />
    );
    return isEmpty(value) ? errorMessage : undefined;
};

export const minLength = (min, message) => (value, _, props) =>
    !isEmpty(value) && value.length < min
        ?  <FormattedMessage
            id='a.b.c'
            defaultMessage={`Must be at least ${min} characters`}
        />
        : undefined;

export const maxLength = max => value =>
    value && value.length > max ? <FormattedMessage
        id='a.b.c'
        defaultMessage={`Must be at most ${max} characters`}
    /> : undefined;


export const minValue = min => (value, _, props) =>
    !isEmpty(value) && value < min
        ? <FormattedMessage
            id='a.b.c'
            defaultMessage={`Value Must Be Atleast ${min}`}
        />
        : undefined;

export const exactLength = stringLength => (value, _, props) =>
    !isEmpty(value) && value.length !==  stringLength
        ? <FormattedMessage
            id='a.b.c'
            defaultMessage={`Value Must Be Exactly ${stringLength} characters`}
        />
        : undefined;
export const secondaryIdentifierValidator = (stringLength) => (value) => {
    if(!isEmpty(value)){
        const startsWithFlag = value.toLowerCase().startsWith('t');
        if(startsWithFlag && value.length !== stringLength+1){
            return `Must be exactly ${stringLength+1} characters`;
        } else if(!startsWithFlag && value.length !== stringLength){
            return `Must be exactly ${stringLength} characters`;
        }
    }
    return undefined;
}
export const phoneNumber = value =>
    value && !/^(0|[0-9]{10}|[0-9]{11}|[0-9]{12})$/i.test(value)
        ? (
            <FormattedMessage
                id='form.invalidMobile'
                defaultMessage="Invalid phone number, must be between 10 and 12 digits"
            />
        )
        : undefined;

export const landlineNumber = value =>
    value && !/^(0|[0-9]{5}|[0-9]{6}|[0-9]{7}|[0-9]{8}|[0-9]{9}|[0-9]{10}|[0-9]{11}|[0-9]{12})$/i.test(value)
        ? (
            <FormattedMessage
                id='form.invalidLandLine'
                defaultMessage="Invalid landline number, must be atleast 5 numbers"
            />
        )
        : undefined;

export const maxValue = (max, message) => (value, _, props) =>
    !isEmpty(value) && value > max
        ?  (
            <FormattedMessage
                id='ab.c'
                defaultMessage={`Value Must Be More Than ${max}`}
            />
        )
        : undefined;

const getValueMustBe = (value) => {
    console.log('as-9adjasodpmasd', value);
    if (value === true || value === 'true') {
        return 'Checked';
    } else if (value === false || value === 'false') {
        return 'Not Checked';
    }
    return value;
};

export const equalsTo = (valueToBeEqualTo) => (value, _, props) =>
    value !== valueToBeEqualTo
        ?  (
            <FormattedMessage
                id='ab.c'
                defaultMessage={`Value Must Be ${getValueMustBe(valueToBeEqualTo)}`}
            />
        )
        : undefined;

export const number = (value, _, props) =>
    !isEmpty(value) && !/^\d+$/.test(value)
        ? (
            <FormattedMessage
                id='ab.c'
                defaultMessage="Only Numeric Digits Are Allowed"
            />
        )
        : undefined;

export const regex = (pattern) => (value, _, props) =>
    !isEmpty(value) && typeof value === 'string' && !pattern.test(value)
        ? (
            <FormattedMessage
                id='form.invalidEmail'
                defaultMessage="Invalid Email"
            />
        )
        : undefined;

export const email = regex(EMAIL_REGEX, 'aor.validation.email');

export const dateOfBirth = (value) => {
    let dateObject = new Date(value);
    if (dateObject.getFullYear() < 1900) {
        return (
            <FormattedMessage
                id='form.yearLessThan1900'
                defaultMessage="Birthday Cannot Before 120 Years from now"
            />
        );
    }
    if (!isDateLessThanOrEqual(dateObject, new Date())) {
        return (
            <FormattedMessage
                id='form.birthdayInFuture'
                defaultMessage="Birthday Cannot Be After Today"
            />
        );
    }
    return undefined;
};

export const choices = (list, message) => (value, _, props) =>
    !isEmpty(value) && list.indexOf(value) === -1
        ? props.translate(message, { _: message })
        : undefined;

export const dateNotLessThanToday = (value) => {
    if (value) {
        const date = new Date(value);
        date.setHours(0,0,0,0);
        const todayDate = new Date();
        todayDate.setHours(0,0,0,0);
        if (date < todayDate) {
            return "Date Cannot Be Before Today";
        }
    }
    return undefined;
};

export const formValidationFunctions = {
    required,
    number,
    choices,
    email,
    regex,
    minValue,
    exactLength,
    maxValue,
    maxLength,
    minLength,
    phoneNumber,
    landlineNumber,
    dateOfBirth,
    equalsTo,
    dateNotLessThanToday,
    secondaryIdentifierValidator
};
