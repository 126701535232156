/*
    eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events,
    jsx-a11y/no-noninteractive-element-interactions
*/

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import invert from 'lodash.invert';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExitToApp from '@material-ui/icons/ExitToApp';

import SideBar from '../constants/SideBar';
import { analyticsLeftPaneToPageMap, dashboard } from '../constants/constants';
import { notifyAppSwitch } from '../redux/modules/appConfiguration/appConfiguration-actions';
import { checkIfPrivilegeExistsForUser } from '../constants/privilegeChecker';
import { getStringFromObject } from '../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../constants/nullCheckUtils';

const drawerWidth = 300;

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: '100%',
        zIndex: 1,
        minHeight: '100vh',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
    },
    grow: {
        flexGrow: 1,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    closedMenuButton: {
        transform: 'rotate(0deg)',
        transition: 'transform 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
    openMenuButton: {
        transform: 'rotate(180deg)',
        transition: 'transform 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
    hide: {
        display: 'none',
    },
    drawerPaper: {
        overflow: 'auto',
        height: '100%',
        [theme.breakpoints.up('md')]: {
            overflow: 'auto',
            width: drawerWidth,
            position: 'relative',
            height: '100%',
        },
        position: 'fixed',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing.unit * 7,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing.unit * 9,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        marginTop: '4rem',
    },
    toolBar: {
        padding: '0 14px',
    },
    headerStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    buttonStyle: {
        height: '1.5rem',
        borderRadius: '1rem',
        width: '8em',
        fontSize: '0.7rem',
        padding: '0',
        textTransform: 'initial',
        minHeight: '1rem',
        color: '#1aa0e4',
        border: '1px solid #1aa0e4',
    },
});

const getAnalyticsTabs = (config) => {
    const tabs = {};
    if (isObjectValidAndNotEmpty(config)) {
        Object.keys(config).forEach((tab) => {
            if (checkIfPrivilegeExistsForUser(getStringFromObject('privilege', config[tab], []))) {
                tabs[tab] = config[tab];
            }
        });
    }
    return tabs;
};

class AnalyticsHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            activeTab: this.getActiveTabFromUrl(props),
        };
        this.opentimer = null;
        this.closetimer = null;
        this.myRef = React.createRef();
    }

    componentWillReceiveProps(nextProps) {
        console.log('AnalyticsHeaderrrrrrrr componentWillReceiveProps', nextProps, this.props);
        if (getStringFromObject('history.location.pathname', nextProps) !==
            getStringFromObject('history.location.pathname', this.props)
        ) {
            this.setState({
                activeTab: this.getActiveTabFromUrl(nextProps),
            });
        }
    }

    onListItemClick = (value, label) => {
        console.log('sasd0s9aiod', value);
        this.setState({
            activeTab: value,
        });
        this.props.history.push(analyticsLeftPaneToPageMap[value]);
        this.props.dispatch(notifyAppSwitch(label));
        this.handleDrawerClose();
        this.myRef.current.scrollTo(0, 0);
    };

    getActiveTabFromUrl = (props) => {
        console.log('AnalyticsHeaderrrrrrrr getActiveTabFromUrl', props);
        const activeTab = getStringFromObject(
            getStringFromObject('history.location.pathname', props), invert(analyticsLeftPaneToPageMap));
        if (activeTab) {
            return activeTab;
        }
        // choose first section
        const sections = getAnalyticsTabs(getStringFromObject('appConfiguration.analyticsSideTabs', props, {}));
        return getStringFromObject('[0].value', Object.values(sections));
    };

    toggleDrawer = () => {
        this.setState({ open: !this.state.open });
    };

    handleDrawerOpen = () => {
        if (this.opentimer === null && !this.state.open) {
            this.opentimer = setTimeout(() => {
                this.setState({ open: true });
                this.opentimer = null;
            }, 300);
        }
        if (this.closetimer !== null) {
            clearTimeout(this.closetimer);
            this.closetimer = null;
        }
    };

    handleDrawerClose = () => {
        if (this.opentimer !== null) {
            clearTimeout(this.opentimer);
            this.opentimer = null;
        }
        if (this.closetimer === null && this.state.open) {
            this.closetimer = setTimeout(() => {
                this.setState({ open: false });
                this.closetimer = null;
            }, 500);
        }
    };

    handleHomeIcon = () => {
        const { history } = this.props;
        history.push(dashboard);
    };

    render() {
        const {
            classes, theme, appConfiguration, disablePartialRatioRouteMatching,
        } = this.props;
        const {
            open,
            activeTab,
        } = this.state;
        const sideBarTabs = getStringFromObject('analyticsSideTabs', appConfiguration, {});
        console.log('PrimaryTabs', activeTab);
        return (
            <div ref={this.myRef} className={classes.root}>
                <AppBar
                    position="absolute"
                    className={classes.appBar}
                >
                    <Toolbar disableGutters className={classes.toolBar}>
                        <div className={classes.headerStyle}>
                            <div className="display-flex">
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={this.toggleDrawer}
                                    className={
                                        classNames(
                                            classes.menuButton,
                                            open && classes.openMenuButton,
                                            !open && classes.closedMenuButton,
                                        )
                                    }
                                >
                                    <MenuIcon />
                                </IconButton>
                            </div>
                            <Typography className={classes.title} variant="h6" color="inherit" noWrap>
                                {getStringFromObject(`${activeTab}.label`, sideBarTabs, 'Analytics')}
                            </Typography>
                            <IconButton aria-haspopup="true" onClick={this.handleHomeIcon} color="inherit">
                                <ExitToApp />
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: classNames(classes.drawerPaper,
                            !this.state.open && classes.drawerPaperClose, 'position-fixed'),
                    }}
                    open={this.state.open}
                    onMouseOver={this.handleDrawerOpen}
                    onFocus={() => {}}
                    onMouseOut={this.handleDrawerClose}
                    onBlur={() => {}}
                >
                    <div className={classes.toolbar}>
                        <IconButton onClick={this.toggleDrawer}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </div>
                    <Divider />
                    <List style={{ padding: '0px' }}>
                        <SideBar
                            onListItemClick={this.onListItemClick}
                            activeTab={activeTab}
                            sideBarTabs={sideBarTabs}
                            disablePartialRatioRouteMatching={disablePartialRatioRouteMatching}
                        />
                    </List>
                </Drawer>
                <main className={classes.content}>
                    {this.props.children}
                </main>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    appConfiguration: state.appConfiguration,
    disablePartialRatioRouteMatching: getStringFromObject('appConfiguration.disablePartialRatioRouteMatching', state) || false,
});

AnalyticsHeader.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    history: PropTypes.object.isRequired,
    // match: PropTypes.object.isRequired,
    appConfiguration: PropTypes.object.isRequired,
    disablePartialRatioRouteMatching: PropTypes.bool.isRequired,
};

AnalyticsHeader.defaultProps = {};

export default connect(mapStateToProps)(withStyles(styles, { withTheme: true })(AnalyticsHeader));
