import React from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import ReceivePaymentDialog from './SupplierPaymentDialog';
import { commonGetApiRequest } from '../../../redux/modules/common/common-actions';
import api from '../../../constants/api';
import {
    getUrlWithApiParams,
    isArrayValidAndNotEmpty,

} from '../../../constants/CommonUtil';
import DialogComponent from '../../DialogComponent/DialogComponent';
import InvoicePaymentList from './InvoicePaymentList';
import { APPLICATION_CONFIG_URL } from '../../../constants/constants';
import Print from '../../../containers/RegistrationAppComponents/PrintHTML/PrintHTML';
import { getPrintDataFromAccountVoucher } from './SupplierPaymentUtil';
import ReactSelectMaterial from '../../ReactSelectMaterial/ReactSelectMaterial';
import ActionButton from '../../ActionButton/ActionButton';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { NumberOf } from '../../../constants/numberUtils';

class SupplierPaymentDialogWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            supplier: null,
            invoices: null,
            advances: null,
            creditMemo: null,
            invoicePayments: [],
            invoicePayment: null,
            selectPayment: true,
            printVoucher: false,
            printData: null,
            selectSupplier: !!props.chooseSupplier,
            supplierChoosen: false,
        };
    }

    componentDidMount() {
        const { supplierUuid, invoiceUuid } = this.props;
        if (invoiceUuid) {
            this.loadInvoiceWithUuid(invoiceUuid).then((invoices) => {
                const partnerUuid = getStringFromObject('partnerUuid', invoices[0]);
                this.loadSupplierWithUuid(partnerUuid)
                    // eslint-disable-next-line max-len
                    .then(supplier => this.setState({ supplier, invoices }, () => this.loadInvoicePaymentsWithInvoiceUuidOrRedirect(invoiceUuid)));
            });
        } else if (supplierUuid) {
            this.loadSupplierWithUuid(supplierUuid)
                .then(supplier => this.setState({ supplier }, this.loadOpenInvoicePaymentsOrRedirect));
        }
    }

    loadSupplierWithUuid = uuid => new Promise((r) => {
        this.props.dispatch(commonGetApiRequest(`${api.SUPPLIER.FIND}${uuid}`, {
            successCallback: supplier => r(supplier),
        }));
    });

    loadInvoiceWithUuid = invoiceUuid => new Promise((r) => {
        // eslint-disable-next-line max-len
        this.props.dispatch(commonGetApiRequest(getUrlWithApiParams(api.RECEIVE_PAYMENT.OPEN_INVOICES_FOR_USER, { invoiceUuid }), {
            successCallback: invoices => r(invoices),
        }));
    });

    // loads invoice payments with invoice uuid, or redirects to payment screen
    loadInvoicePaymentsWithInvoiceUuidOrRedirect = (invoiceUuid) => {
        this.loadInvoicePaymentsWithInvoiceUuid(invoiceUuid).then((invoicePayments) => {
            if (isArrayValidAndNotEmpty(invoicePayments)) {
                this.setState({ invoicePayments, selectPayment: true });
            } else {
                this.setState({ selectPayment: false }, this.loadAllAdvances);
            }
        });
    };

    loadInvoicePaymentsWithInvoiceUuid = (invoiceUuid) => {
        const { dispatch } = this.props;
        const urlWithApiParams = getUrlWithApiParams(api.ACCOUNT_INVOICE.OPEN_INVOICE_PAYMENTS, { invoiceUuid });
        return new Promise((resolve) => {
            dispatch(commonGetApiRequest(urlWithApiParams, {
                successCallback: resolve,
            }));
        });
    };

    loadInvoicesWithSupplier = (supplierId, page) => {
        const { dispatch } = this.props;
        const promise = new Promise((resolve, reject) => {
            if (supplierId) {
                const apiParams = { partnerId: supplierId, limit: 50, page };
                dispatch(commonGetApiRequest(
                    getUrlWithApiParams(api.RECEIVE_PAYMENT.OPEN_INVOICES_FOR_USER, apiParams), {
                        successCallback: resolve,
                        failureCallBack: reject,
                    }),
                );
            } else {
                reject();
            }
        });
        return promise;
    };

    loadAdvance = (supplierUuid) => {
        const { dispatch } = this.props;
        const promise = new Promise((resolve, reject) => {
            if (supplierUuid) {
                const urlWithApiParams = getUrlWithApiParams(api.PAID_ON_ACCOUNT.UNRECONCILED, { supplierUuid });
                dispatch(commonGetApiRequest(urlWithApiParams, {
                    successCallback: resolve,
                    failureCallBack: reject,
                }));
            } else {
                reject();
            }
        });
        return promise;
    };

    loadCreditMemo = (partnerId) => {
        const { dispatch } = this.props;
        const promise = new Promise((resolve, reject) => {
            if (NumberOf(partnerId) > 0) {
                const urlWithApiParams = getUrlWithApiParams(api.ACCOUNT_INVOICE.CREATE_CREDIT_MEMO, { partnerId });
                dispatch(commonGetApiRequest(urlWithApiParams, {
                    successCallback: resolve,
                    failureCallBack: reject,
                }));
            } else {
                reject();
            }
        });
        return promise;
    };

    loadOpenInvoicePayments = () => {
        const { supplier } = this.state;
        const partnerUuid = getStringFromObject('uuid', supplier);
        const { dispatch } = this.props;
        const urlWithApiParams = getUrlWithApiParams(api.ACCOUNT_INVOICE.OPEN_INVOICE_PAYMENTS, { partnerUuid });
        return new Promise(((resolve) => {
            dispatch(commonGetApiRequest(urlWithApiParams, {
                successCallback: (invoicePayments) => {
                    if (isArrayValidAndNotEmpty(invoicePayments)) {
                        resolve(invoicePayments);
                    } else {
                        resolve([]);
                    }
                },
            }));
        }));
    };

    loadOpenInvoicePaymentsOrRedirect = () => {
        this.loadOpenInvoicePayments().then((invoicePayments) => {
            if (isArrayValidAndNotEmpty(invoicePayments)) {
                this.setState({ invoicePayments });
            } else {
                this.setState({ selectPayment: false }, this.loadSupplierInvoiceAndAdvance);
            }
        });
    };

    loadSupplierInvoiceAndAdvance = (page = 0) => {
        const { supplier } = this.state;
        Promise.all([
            this.loadInvoicesWithSupplier(getStringFromObject('id', supplier), page),
            this.loadAdvance(getStringFromObject('uuid', supplier)),
            this.loadCreditMemo(getStringFromObject('id', supplier)),
        ]).then((response) => {
            let invoices = [];
            if (page > 0) {
                invoices = this.state.invoices.concat(response[0]);
            } else {
                // eslint-disable-next-line prefer-destructuring
                invoices = response[0];
            }
            this.setState({
                invoicePayment: null,
                invoices: invoices || [],
                advances: getStringFromObject('content', response[1], []),
                creditMemo: response[2] || [],
            });
        });
    };

    loadAllAdvances = () => {
        const { supplier } = this.state;
        Promise.all([
            this.loadAdvance(getStringFromObject('uuid', supplier)),
            this.loadCreditMemo(getStringFromObject('id', supplier)),
        ]).then((response) => {
            this.setState({
                invoicePayment: null,
                advances: getStringFromObject('content', response[0], []),
                creditMemo: response[1] || [],
            });
        });
    };

    handleSelectInvoicePayment = (e, id, uid, rowVal) => {
        const invoicePaymentId = getStringFromObject('id', rowVal);
        if (invoicePaymentId) {
            const { dispatch } = this.props;
            dispatch(commonGetApiRequest(`${api.ACCOUNT_VOUCHER.FIND_INVOICE_PAYMENT}/${invoicePaymentId}`, {
                successCallback: (invoicePayment) => {
                    this.setState({ invoicePayment, selectPayment: false });
                },
            }));
        }
    };

    handleCloseSelectPaymentAndExit = () => this.setState({ selectPayment: false }, this.props.handleClose);

    handleDeleteInvoicePayment = () => {
        this.loadOpenInvoicePayments().then((invoicePayments) => {
            if (isArrayValidAndNotEmpty(invoicePayments)) {
                this.setState({ invoicePayments });
            } else {
                this.props.handleClose();
            }
        });
    };

    handlePrintVoucher = (voucher) => {
        this.setState({
            printVoucher: true,
            printData: {
                ...getPrintDataFromAccountVoucher(voucher),
                company: getStringFromObject('companyName', this.props.appConfiguration),
            },
        });
    };

    handleMakeNewPayment = () => this.setState({ selectPayment: false }, this.loadSupplierInvoiceAndAdvance);

    handleChangeSupplier = (s) => {
        const supplierUuid = getStringFromObject('uuid', s);
        if (supplierUuid) {
            this.loadSupplierWithUuid(supplierUuid)
                // eslint-disable-next-line max-len
                .then(supplier => this.setState({ supplier, selectSupplier: false, supplierChoosen: true }, this.loadOpenInvoicePaymentsOrRedirect));
        }
    };

    handleCloseSelectSupplier = () => this.setState({ selectSupplier: false, supplierChoosen: false }, this.props.handleClose);

    render() {
        const {
            supplier,
            invoices,
            advances,
            invoicePayments,
            invoicePayment,
            selectPayment,
            creditMemo,
            printVoucher,
            printData,
            selectSupplier,
            supplierChoosen,
        } = this.state;
        const {
            handleClose,
            dispatch,
        } = this.props;
        console.log('SupplierPaymentDialogWrapper props', this.props);
        console.log('SupplierPaymentDialogWrapper state', this.state);
        return (
            <React.Fragment>
                {
                    selectPayment && !printVoucher && !selectSupplier && supplierChoosen &&
                        <DialogComponent
                            header="Non confirmed payments"
                            open={selectPayment}
                            maxWidth="lg"
                            handleClose={this.handleCloseSelectPaymentAndExit}
                            dialogActions={
                                <ActionButton
                                    onClick={this.handleMakeNewPayment}
                                >
                                    Make New Payment
                                </ActionButton>
                            }
                        >
                            <InvoicePaymentList
                                onSelectInvoicePayment={this.handleSelectInvoicePayment}
                                handleDeleteInvoicePayment={this.handleDeleteInvoicePayment}
                                invoicePayments={invoicePayments}
                                dispatch={dispatch}
                            />
                        </DialogComponent>
                }
                {
                    !selectPayment && !selectSupplier &&
                    <ReceivePaymentDialog
                        open
                        supplierAdvance={advances}
                        creditMemo={creditMemo}
                        selectedSupplier={supplier}
                        handleClose={handleClose}
                        accountInvoices={invoices}
                        invoicePayment={invoicePayment}
                        reloadInvoiceAndAdvance={this.loadSupplierInvoiceAndAdvance}
                        handlePrintVoucher={this.handlePrintVoucher}
                    />
                }
                <Print
                    url={`${APPLICATION_CONFIG_URL}/HtmlPrint/PaymentVoucher/SupplierPaymentVoucher.html`}
                    data={printData}
                    print={printVoucher}
                    subCompany={getStringFromObject('subCompany', printData)}
                    handleClose={handleClose}
                />
                {
                    selectSupplier &&
                    <DialogComponent
                        header="Select Supplier"
                        open={selectSupplier}
                        maxWidth="md"
                        handleClose={this.handleCloseSelectSupplier}
                    >
                        <ReactSelectMaterial
                            testId="select-supplier"
                            fullWidth
                            dataSourceConfig={{
                                text: 'name',
                                value: 'uuid',
                            }}
                            label="Supplier"
                            autocomplete
                            dataSourceApi={api.SEARCH.SUPPLIER}
                            onChange={this.handleChangeSupplier}
                        />
                    </DialogComponent>
                }
            </React.Fragment>
        );
    }
}

SupplierPaymentDialogWrapper.propTypes = {
    handleClose: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    supplierUuid: PropTypes.string,
    invoiceUuid: PropTypes.string,
    appConfiguration: PropTypes.object,
    chooseSupplier: PropTypes.bool,
};

SupplierPaymentDialogWrapper.defaultProps = {
    supplierUuid: null,
    invoiceUuid: null,
    appConfiguration: null,
    chooseSupplier: false,
};

const mapStateToProps = state => ({
    appConfiguration: state.appConfiguration,
});

export default connect(mapStateToProps)(SupplierPaymentDialogWrapper);

