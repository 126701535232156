import React from 'react';
import PropTypes from 'prop-types';
import {
    findValueByChoices,
    isArrayValidAndNotEmpty,

} from '../../../constants/CommonUtil';
import {
    formatDateForDisplay,
    formatDateTimeForDisplay,
    getDateInDDMMMYYYYFormat,
    isDateString,
} from '../../../constants/DateUtil';
import { round } from '../../../constants/PrecisionUtil';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';


const FormikText = (props) => {
    const {
        field,
        label,
        style,
        isComponent,
        ...otherProps
    } = props;
    const {
        InputProps, multiple, dataSourceConfig, disableAutoDateFormat,
    } = otherProps;
    let value = getStringFromObject('value', field);
    console.log('asdfsdfsdfsdfsfsdf anme', props);
    if (otherProps.choices) {
        const { choices } = otherProps;
        value = findValueByChoices(choices, dataSourceConfig, value);
    }
    if (multiple && isArrayValidAndNotEmpty(value)) {
        value = value.map((v) => {
            if (isObjectValidAndNotEmpty(dataSourceConfig) && dataSourceConfig.text) {
                return v[dataSourceConfig.text];
            }
            return v;
        }).join(', ');
    }
    if (value != null && (typeof value === 'object')) {
        if (isObjectValidAndNotEmpty(dataSourceConfig) && dataSourceConfig.text) {
            value = getStringFromObject(dataSourceConfig.text, value, '-');
        } else if (!isComponent) {
            value = JSON.stringify(value);
        }
    }
    /* check if contains date format like 2018-02-11 the convert into standard format */
    if (!disableAutoDateFormat && value && (isDateString(value) || otherProps.isDate)) {
        value = getDateInDDMMMYYYYFormat(new Date(value));
    }
    if (value && otherProps.isDateTime) {
        value = formatDateTimeForDisplay(new Date(value));
    }
    if (value && otherProps.type === 'date') {
        value = formatDateForDisplay(new Date(value));
    }
    if (typeof value === 'boolean' || otherProps.type === 'boolean') {
        if (otherProps.humanReadable) {
            value = value ? 'YES' : 'NO';
        } else {
            value = value.toString();
        }
    }
    if (getStringFromObject('isCurrency', otherProps, false)) {
        value = round(value);
    }
    if (value == null) {
        value = '';
    }

    // are there any errors
    const errorObject = getStringFromObject(field.name, getStringFromObject('form.errors', otherProps));
    if (errorObject) {
        console.log('as-d9ua-sdasd', errorObject);
    }
    const touched = getStringFromObject(field.name, getStringFromObject('form.touched', otherProps));
    if (touched) {
        console.log('as-d9ua-sdasd', errorObject);
    }
    return (
        <div>
            {
                (InputProps) && (!InputProps.noLabel) &&
                <div>{label}</div>
            }
            <div style={{ fontSize: '1rem', ...style }}>
                {value}
            </div>
            <div style={{ color: 'red' }}>
                {
                    touched && errorObject
                }
            </div>
        </div>
    );
};

FormikText.propTypes = {
    style: PropTypes.object,
    label: PropTypes.string,
    isComponent: PropTypes.bool,
    /* formik props */
    field: PropTypes.object.isRequired,
};

FormikText.defaultProps = {
    style: { fontSize: '14px' },
    label: '',
    isComponent: false,
};

export default FormikText;
