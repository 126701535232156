/* eslint-disable indent */
/**
 * Created by vinay on 18/12/18.
 */
import API from '../../constants/api';
import { ADVANCE_SEARCH_OPERATORS, DATE_FILTERS, STOCK_MANAGEMENT_PRIVILEGES } from '../../constants/constants';
import { checkIfPrivilegeExistsForUser } from '../../constants/privilegeChecker';
import { getStringFromObject } from '../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

export const SEARCH_CATEGORIES = {
    PURCHASE_ORDER: 'Purchase Order',
    STOCK_PICKING: 'Stock Picking',
    ADD_STOCK: 'Add Stock',
    RECEIVE_STOCK: 'Receive Stock',
    RETURN_STOCK: 'Return Stock',
    INTERNAL_MOVE: 'Stock Move',
    INVENTORY_ADJUSTMENT: 'Adjust Inventory',
    STOCK_INTENT: 'Stock Intent',
    MATERIAL_RECEIVE: 'Material Receive',
};

const ERP_TRANSACTION_PRIVILEGES = {
    [SEARCH_CATEGORIES.PURCHASE_ORDER]: STOCK_MANAGEMENT_PRIVILEGES.purchaseOrder,
    [SEARCH_CATEGORIES.STOCK_PICKING]: STOCK_MANAGEMENT_PRIVILEGES.stockPicking,
    [SEARCH_CATEGORIES.ADD_STOCK]: STOCK_MANAGEMENT_PRIVILEGES.addStock,
    [SEARCH_CATEGORIES.RECEIVE_STOCK]: STOCK_MANAGEMENT_PRIVILEGES.receiveStock,
    [SEARCH_CATEGORIES.RETURN_STOCK]: STOCK_MANAGEMENT_PRIVILEGES.returnStock,
    [SEARCH_CATEGORIES.INTERNAL_MOVE]: STOCK_MANAGEMENT_PRIVILEGES.internalMove,
    [SEARCH_CATEGORIES.INVENTORY_ADJUSTMENT]: STOCK_MANAGEMENT_PRIVILEGES.adjustInventory,
    [SEARCH_CATEGORIES.STOCK_INTENT]: STOCK_MANAGEMENT_PRIVILEGES.stockIntent,
    [SEARCH_CATEGORIES.MATERIAL_RECEIVE]: STOCK_MANAGEMENT_PRIVILEGES.returnStock,
};

export const getSearchCategories = () => {
    const categories = {};
    Object.keys(SEARCH_CATEGORIES).forEach((cat) => {
        console.log('sidemenuitem', cat, getStringFromObject(SEARCH_CATEGORIES[cat], ERP_TRANSACTION_PRIVILEGES), checkIfPrivilegeExistsForUser(getStringFromObject(SEARCH_CATEGORIES[cat], ERP_TRANSACTION_PRIVILEGES)));
        if (checkIfPrivilegeExistsForUser(getStringFromObject(SEARCH_CATEGORIES[cat], ERP_TRANSACTION_PRIVILEGES))) {
            categories[cat] = SEARCH_CATEGORIES[cat];
        }
    });
    return categories;
};

export const APPLICABLE_FILTERS = {
    [SEARCH_CATEGORIES.PURCHASE_ORDER]: {
        SUPPLIER: {
            label: 'Supplier',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.SUPPLIER,
            dataSourceConfig: { text: 'name', value: 'uuid' },
        },
        STOCK_LOCATION: {
            label: 'Shipped To',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.STOCK_LOCATION_WITH_REPLENISHMENT('PURCHASE_ORDER'),
            dataSourceConfig: { text: 'name', value: 'id' },
        },
        STATUS: {
            label: 'Status',
            type: 'select',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            options: [
                { label: 'draft', value: 'draft' },
                { label: 'confirmed', value: 'confirmed' },
            ],
        },
        PRODUCT: {
            label: 'Product',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.PRODUCT,
            dataSourceConfig: { text: 'productName', value: 'uuid' },
        },
    },
    [SEARCH_CATEGORIES.ADD_STOCK]: {
        SOURCE_LOCATION: {
            label: 'Source Location',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.STOCK_LOCATION,
            dataSourceConfig: { text: 'name', value: 'id' },
        },
        DESTINATION_LOCATION: {
            label: 'Destination Location',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.STOCK_LOCATION,
            dataSourceConfig: { text: 'name', value: 'id' },
        },
        STATUS: {
            label: 'Status',
            type: 'select',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            options: [
                { label: 'draft', value: 'draft' },
                { label: 'confirmed', value: 'confirmed' },
                { label: 'done', value: 'done' },
            ],
        },
        PRODUCT: {
            label: 'Product',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.PRODUCT,
            dataSourceConfig: { text: 'productName', value: 'productId' },
        },
        BATCH: {
            label: 'Batch',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.BATCHES_SUGGESTIONS,
            dataSourceConfig: { text: 'batchName', value: 'batchId' },
        },
    },
    [SEARCH_CATEGORIES.INTERNAL_MOVE]: {
        SOURCE_LOCATION: {
            label: 'Source Location',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.STOCK_LOCATION,
            dataSourceConfig: { text: 'name', value: 'id' },
        },
        DESTINATION_LOCATION: {
            label: 'Destination Location',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.STOCK_LOCATION,
            dataSourceConfig: { text: 'name', value: 'id' },
        },
        STATUS: {
            label: 'Status',
            type: 'select',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            options: [
                { label: 'draft', value: 'draft' },
                { label: 'confirmed', value: 'confirmed' },
                { label: 'done', value: 'done' },
            ],
        },
        PRODUCT: {
            label: 'Product',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.PRODUCT,
            dataSourceConfig: { text: 'productName', value: 'productId' },
        },
        BATCH: {
            label: 'Batch',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.BATCHES_SUGGESTIONS,
            dataSourceConfig: { text: 'batchName', value: 'batchId' },
        },
        REFERENCE_NUMBER: {
            label: 'Reference No',
            type: 'text',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
        },
    },
    [SEARCH_CATEGORIES.INVENTORY_ADJUSTMENT]: {
        SOURCE_LOCATION: {
            label: 'Source Location',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.STOCK_LOCATION,
            dataSourceConfig: { text: 'name', value: 'id' },
        },
        DESTINATION_LOCATION: {
            label: 'Destination Location',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.STOCK_LOCATION,
            dataSourceConfig: { text: 'name', value: 'id' },
        },
        STATUS: {
            label: 'Status',
            type: 'select',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            options: [
                { label: 'draft', value: 'draft' },
                { label: 'confirmed', value: 'confirmed' },
                { label: 'done', value: 'done' },
            ],
        },
        PRODUCT: {
            label: 'Product',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.PRODUCT,
            dataSourceConfig: { text: 'productName', value: 'productId' },
        },
        BATCH: {
            label: 'Batch',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.BATCHES_SUGGESTIONS,
            dataSourceConfig: { text: 'batchName', value: 'batchId' },
        },
        MEMO: {
            label: 'Memo',
            type: 'text',
            operation: [ADVANCE_SEARCH_OPERATORS.CONTAINS],
        },
    },
    [SEARCH_CATEGORIES.RECEIVE_STOCK]: {
        SOURCE_LOCATION: {
            label: 'Source Location',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.STOCK_LOCATION,
            dataSourceConfig: { text: 'name', value: 'id' },
        },
        DESTINATION_LOCATION: {
            label: 'Destination Location',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.STOCK_LOCATION,
            dataSourceConfig: { text: 'name', value: 'id' },
        },
        STATUS: {
            label: 'Status',
            type: 'select',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            options: [
                { label: 'draft', value: 'draft' },
                { label: 'confirmed', value: 'confirmed' },
                { label: 'done', value: 'done' },
            ],
        },
        PRODUCT: {
            label: 'Product',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.PRODUCT,
            dataSourceConfig: { text: 'productName', value: 'productId' },
        },
        BATCH: {
            label: 'Batch',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.BATCHES_SUGGESTIONS,
            dataSourceConfig: { text: 'batchName', value: 'batchId' },
        },
        SUPPLIER: {
            label: 'Supplier',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.SUPPLIER,
            dataSourceConfig: { text: 'name', value: 'uuid' },
        },
    },
    [SEARCH_CATEGORIES.RETURN_STOCK]: {
        SOURCE_LOCATION: {
            label: 'Source Location',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.STOCK_LOCATION,
            dataSourceConfig: { text: 'name', value: 'id' },
        },
        PRODUCT: {
            label: 'Product',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.PRODUCT,
            dataSourceConfig: { text: 'productName', value: 'productId' },
        },
        BATCH: {
            label: 'Batch',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.BATCHES_SUGGESTIONS,
            dataSourceConfig: { text: 'batchName', value: 'batchId' },
        },
        PARTNER: {
            label: 'Supplier',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.SUPPLIER,
            dataSourceConfig: { text: 'name', value: 'uuid' },
        },
    },
    [SEARCH_CATEGORIES.STOCK_PICKING]: {
        TYPE: {
            label: 'Type',
            type: 'select',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            options: [
                { label: 'Add Stock', value: 'add_stock' },
                { label: 'Receive Stock', value: 'in' },
                { label: 'Out Stock', value: 'out' },
                { label: 'StockMove', value: 'internal' },
                { label: 'Adjust Inventory', value: 'stock_adjustment' },
            ],
        },
        SOURCE_LOCATION: {
            label: 'Source Location',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.STOCK_LOCATION,
            dataSourceConfig: { text: 'name', value: 'id' },
        },
        DESTINATION_LOCATION: {
            label: 'Destination Location',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.STOCK_LOCATION,
            dataSourceConfig: { text: 'name', value: 'id' },
        },
        STATUS: {
            label: 'Status',
            type: 'select',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            options: [
                { label: 'draft', value: 'draft' },
                { label: 'confirmed', value: 'confirmed' },
                { label: 'done', value: 'done' },
            ],
        },
        PRODUCT: {
            label: 'Product',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.PRODUCT,
            dataSourceConfig: { text: 'productName', value: 'productId' },
        },
        BATCH: {
            label: 'Batch',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.BATCHES_SUGGESTIONS,
            dataSourceConfig: { text: 'batchName', value: 'batchId' },
        },
    },
    /* [SEARCH_CATEGORIES.BILL_PAYMENT]: {
        PARTNER: {
            label: 'Supplier',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.SUPPLIER,
            dataSourceConfig: { text: 'name', value: 'uuid' },
        },
        AMOUNT: {
            label: 'Amount',
            type: 'number',
            operation: [
                ADVANCE_SEARCH_OPERATORS.EQUALS, ADVANCE_SEARCH_OPERATORS.BETWEEN,
                ADVANCE_SEARCH_OPERATORS.GREATER_THAN_OR_EQUALS, ADVANCE_SEARCH_OPERATORS.LESS_THAN_OR_EQUALS,
            ],
        },
        REFERENCE_NUMBER: {
            label: 'Reference Number',
            type: 'text',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS, ADVANCE_SEARCH_OPERATORS.CONTAINS],
        },
        PAYMENT_MODE: {
            label: 'Payment Mode',
            type: 'select',
            options: [
                { label: 'Cash', value: 'cash' },
                { label: 'Cheque', value: 'cheque' },
                { label: 'Bank Transfer', value: 'bankTransfer' },
            ],
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
        },
        MEMO: {
            label: 'Memo',
            type: 'text',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS, ADVANCE_SEARCH_OPERATORS.CONTAINS],
        },
    }, */
    [SEARCH_CATEGORIES.STOCK_INTENT]: {
        TYPE: {
            label: 'Type',
            type: 'select',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            options: [
                { label: 'Purchase', value: 'PURCHASE' },
                { label: 'Material Request', value: 'MATERIAL_REQUEST' },
                { label: 'Material Withdrawal', value: 'MATERIAL_WITHDRAWAL' },

            ],
        },
        SOURCE_LOCATION: {
            label: 'Source Location',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.STOCK_LOCATION,
            dataSourceConfig: { text: 'name', value: 'id' },
        },
    },
    [SEARCH_CATEGORIES.MATERIAL_RECEIVE]: {
        LOCATION: {
            label: 'Location',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.STOCK_LOCATION,
            dataSourceConfig: { text: 'name', value: 'id' },
        },
        SUPPLIER: {
            label: 'Supplier',
            type: 'asyncSelect',
            operation: [ADVANCE_SEARCH_OPERATORS.EQUALS],
            api: API.SEARCH.SUPPLIER,
            dataSourceConfig: {
                text: 'name',
                value: 'uuid',
            },
        },
        CASH_SUPPLIER: {
            label: 'Cash Supplier',
            type: 'text',
            operation: [ADVANCE_SEARCH_OPERATORS.CONTAINS],
        },
    },
};

export const getAppliedFilterChips = (category, filters, applicableFilters = {}) => {
    const filterChips = [];
    if (category && isObjectValidAndNotEmpty(filters)) {
        const applicableFilter = applicableFilters[category];
        if (isObjectValidAndNotEmpty(applicableFilter)) {
            const appliedFiltersKeys = Object.keys(filters);
            appliedFiltersKeys.forEach((filter) => {
                const filterVal = getStringFromObject(filter, filters, {});
                const filterConfig = getStringFromObject(filter, applicableFilter, {});
                let value = getStringFromObject('value', filterVal);
                if (isObjectValidAndNotEmpty(filterConfig.dataSourceConfig)) {
                    value = getStringFromObject(getStringFromObject('dataSourceConfig.text', filterConfig),
                        filterVal.value);
                }
                let operation = getStringFromObject('operation', filterVal);
                switch (operation) {
                    case ADVANCE_SEARCH_OPERATORS.CONTAINS: operation = '~'; break;
                    case ADVANCE_SEARCH_OPERATORS.EQUALS: operation = '='; break;
                    default:
                }
                let display;
                if (operation === ADVANCE_SEARCH_OPERATORS.BETWEEN) {
                    display = `${filterConfig.label} > ${getStringFromObject('from', value)} and < ${getStringFromObject('to', value)}`;
                } else {
                    display = `${filterConfig.label} ${operation} ${value}`;
                }
                if (filterConfig.type === 'date') {
                    display = `${filterConfig.label} : ${operation}`;
                    if (operation === DATE_FILTERS.CUSTOM) {
                        display = `${filterConfig.label} : from ${getStringFromObject('from', value)} to ${getStringFromObject('to', value)}`;
                    }
                }
                filterChips.push({
                    key: filter,
                    display,
                });
            });
        }
    }
    return filterChips;
};
